import { 
  ResourceEntityList,
  EntityDetail,
} from '../pages'

const routes = [
	{
    path: '/entity/list',
    component: ResourceEntityList,
  },
  {
    path: '/entity/detail/:id',
    component: EntityDetail,
  },
]

export default routes