import React, { useState, useEffect } from 'react'
import { 
  Select, 
} from 'antd'
import styles from './Filter.module.css'
import {
  searchChildrenDistrict,
  getFullDistrictById,
} from '../../../../api'

const { Option } = Select

interface Props {
  submit?: any
}

interface AreaType {
  provinceList: Array<any>,
  cityList: Array<any>,
  districtList: Array<any>
}
const defaultArea = {
  provinceList: [],
  cityList: [],
  districtList: []
}

const defaultLocation = {
  province: '',
  city: '',
  district: '',
}
// 珠海香洲
const currentLocation = {
  province: '',
  city: '',
  district: '',
}

const host = 'https://apis.map.qq.com'
const key = encodeURI('XPJBZ-CJEC5-OK2IG-QCRUY-GSJYV-F4FCT')
const output = 'jsonp'
const api = {
  getIP: '/ws/location/v1/ip',
  // 逆地址解析
  geocoder: '/ws/geocoder/v1/'
}

export const Filter: React.FC<Props> = (props) => {
  const [curPosition, setCurPosition] = useState({
    location: {
      lat: '',
      lng: '',
    },
    cityName: '',
  })
  // 获取当前用户的ip
  const getIP = () => {
    createScript({
      apiUrl: api.getIP,
      callbackName: 'getIpCallback',
      params: '',
      fn: async (data) => {
        const { result, status } = data
        if (status === 0) {
          const location = result.location
          const adInfo = result.ad_info
          const cur = {
            location: {
              lat: location.lat,
              lng: location.lng,
            },
            cityName: adInfo.city
          }
          setCurPosition(cur)

          const ad:any = await geocoder(cur.location)
          getCityNameById(ad.ad_info.adcode)
        }
      }
    })
  }
  // 逆地址解析
  const geocoder = (location) => {
    return new Promise((resolve, reject) => {
      createScript({
        apiUrl: api.geocoder,
        callbackName: 'geocoderCallback',
        params: `location=${ location.lat },${ location.lng }`,
        fn: (data) => {
          if(data.status === 0) {
            resolve(data.result)
          } else {
            reject(data)
          }
        }
      })
    })
  }
  // 生成script标签
  const createScript = ({ apiUrl, callbackName, fn, params }) => {
    let url = encodeURI(`${host}${apiUrl}?key=${key}&output=${output}&callback=${callbackName}`)
    if (params) {
      url += `&${params}`
    }

    let script = document.getElementById(callbackName)
    if (script && script.parentNode && script.parentNode.removeChild) {
      script.parentNode.removeChild(script)
    }

    // 创建script标签，设置其属性
    script = document.createElement('script')
    script.setAttribute('src', url)
    script.setAttribute('id', callbackName)
    document.getElementsByTagName('head')[0].appendChild(script)

    window[callbackName] = fn
  }
  // 获取当前位置
  useEffect(() => {
    getIP()
  }, [])

  // 根据一个CODE获取到省级市区级中文名称(定位回显用)
  const getCityNameById = async(districtId) => {
    const { data } = await getFullDistrictById({
      districtId,
    })
    const cur = {
      province: data.provinceCode,
      city: data.cityCode,
      district: data.districtCode,
    }
    setCurLocation(cur)
  }

  // 行政区信息列表
  const [area, setArea] = useState<AreaType>({...defaultArea})

  const [districtType, setDistrictType] = useState('')
  const [location, setLocation] = useState({ ...defaultLocation })
  useEffect(() => {
    if (districtType !== 'DISTRICT') {
      getChildrenDistrict()
    }
  }, [districtType])

  // 获取到的当前位置
  const [curLocation, setCurLocation] = useState({ ...currentLocation })
  // 查询当前位置
  const queryCurLocation = async () => {
    await handleProvinceChange(curLocation.province)

    await handleCityChange(curLocation.city)

    // await handleDistrictChange(curLocation.district)

    // 触发查询
    props.submit(Object.assign({}, curLocation, {
      district: '',
    }))
  }

  const handleProvinceChange = (value) => {
    const type = 'province'
    setLocation(state => {
      return {
        ...state,
        [type]: value || '',
        city: '',
        district: '',
      }
    })
    setArea(state => {
      return {
        ...state,
        cityList: [],
        districtList: [],
      }
    })

    value && setDistrictType(type.toLocaleUpperCase())
  }
  const handleCityChange = (value) => {
    const type = 'city'
    setLocation(state => {
      return {
        ...state,
        [type]: value || '',
        district: '',
      }
    })
    setArea(state => {
      return {
        ...state,
        districtList: [],
      }
    })
    value && setDistrictType(type.toLocaleUpperCase())
  }
  const handleDistrictChange = (value) => {
    const type = 'district'
    setLocation(state => {
      return {
        ...state,
        [type]: value || '',
      }
    })
    setDistrictType(type.toLocaleUpperCase())
  }

  // 查询地区列表
  const getChildrenDistrict = async() => {
    const id = (() => location[districtType.toLocaleLowerCase()])()
    const result = await searchChildrenDistrict({
      districtType,
      districtId: id,
    })
    const resultList = result.data || []
    let list: any[] = []
    resultList.forEach(i => {
      list.push({
        label: i.districtFullName,
        value: i.districtId
      })
    })
    
    if (!districtType) {
      setArea(state => {
        return {
          ...state,
          provinceList: list,
        }
      })
    } else if (districtType === 'PROVINCE') {
      setArea(state => {
        return {
          ...state,
          cityList: list,
        }
      })
    } else if (districtType === 'CITY') {
      setArea(state => {
        return {
          ...state,
          districtList: list,
        }
      })
    }
  }

  // 查询
  const submit = () => {
    props.submit(location)
  }

  // 重置
  const reset = () => {
    setDistrictType('')

    setLocation({...defaultLocation})
    setArea(state => {
      return {
        ...state,
        cityList: [],
        districtList: [],
      }
    })
  }

  return (
    <div className={ styles['filter-wrap'] }>
      <div className={ styles['filter'] }>
        <div className={ styles['filter-form'] }>
          <div className={ styles['form-item'] }>
            <div className={ styles['form-label'] }>省</div>
            <div className={ styles['form-value'] }>
              <Select 
                className={ styles['form-select'] }
                placeholder="请选择"
                allowClear={ true }
                value={ location.province }
                options={ area.provinceList }
                onChange={ handleProvinceChange }>
              </Select>
            </div>
          </div>
          <div className={ styles['form-item'] }>
            <div className={ styles['form-label'] }>市</div>
            <div className={ styles['form-value'] }>
              <Select 
                className={ styles['form-select'] }
                placeholder="请选择"
                allowClear={ true }
                value={ location.city }
                options={ area.cityList }
                onChange={ handleCityChange }>
              </Select>
            </div>
          </div>
          <div className={ styles['form-item'] }>
            <div className={ styles['form-label'] }>区</div>
            <div className={ styles['form-value'] }>
              <Select 
                className={ styles['form-select'] }
                placeholder="请选择"
                allowClear={ true }
                value={ location.district }
                options={ area.districtList }
                onChange={ handleDistrictChange }>
              </Select>
            </div>
          </div>
          <div className={ styles['form-item'] } onClick={ queryCurLocation }>
            <div className={ `${ styles['form-label'] } ${ styles['location-label'] }` }>
              <i className={ `ifont i-location ${ styles['icon-location'] }` }></i>
              定位城市
            </div>
            <div className={ `${ styles['form-value'] } ${ styles['location-current'] }` }>{ curPosition.cityName }</div>
          </div>
        </div>
        <div className={ styles['opts'] }>
          <button className={ `${ styles['btn'] } ${ styles['btn-confirm'] }` } onClick={ submit }>查询</button>
          <button className={ `${ styles['btn'] } ${ styles['btn-reset'] }` } onClick={ reset }>重置</button>
        </div>
      </div>
    </div>
  )
}

