interface StepType {
  title: string,
  list: string[],
  btns: {
    text: string,
    step: 'step1' | 'step2' | 'step3' | undefined,
    style: object
  }[]
}

interface DialogInfoType {
  title: string,
  step1: StepType,
  step2: StepType,
  step3: StepType
}

export const defaultBtnStyle = {
  borderColor: '#e2a45a',
  borderRadius: '4px',
  backgroundColor: '#e2a45a',
  color: '#fff',
  boxShadow: '0px 4px 10px 0px rgba(57, 57, 57, 0.5)'

}

export const snowBtnStyle = {
  backgroundColor: '#fff',
  color: '#e2a45a'
}

export const bodyStyle = {
  padding: 0,
  width: '862px',
  height: '393px'
}

export const dialogInfo: DialogInfoType = {
  title: '热幕精灵服务启动指引',
  step1: {
    title: '请确认是否已安装了热幕精灵 (影院版)',
    list: [
      '1、将“热幕精灵（影院版）.zip”置于电脑“D：\\”根目录下',
      '2、解压“热幕精灵（影院版）.zip”文件',
      '3、选中“D：\\热幕精灵（影院版）\\jttmedia_agent\\”文件夹下的“安装热幕精灵（影院版）.bat”文件，右键选择“以管理员身份运行”'
    ],
    btns: [
      {
        text: '已安装',
        step: 'step2',
        style: defaultBtnStyle
      }
    ]
  },
  step2: {
    title: '请更新影院点位管理配置信息',
    list: [
      '1、停止热幕精灵服务，选中“D：\\热幕精灵（影院版）\\jttmedia_agent\\”文件夹下的“停止热幕精灵（影院版）.bat”文件，右键选择“以管理员身份运行”',
      '2、下载当前影院【xxx】的点位管理配置信息',
      '3、用下载的文件，替换“D：\\热幕精灵（影院版）\\jttmedia_agent\\agent_CINEMA_M”目录下的同名文件。'
    ],
    btns: [
      {
        text: '上一步',
        step: 'step1',
        style: {
          ...defaultBtnStyle,
          ...snowBtnStyle,
          marginRight: '16px'
        }
      },
      {
        text: '已更新',
        step: 'step3',
        style: defaultBtnStyle
      }
    ]
  },
  step3: {
    title: '启动热幕精灵服务',
    list: [
      '选中“D：\\热幕精灵（影院版）\\jttmedia_agent\\”文件夹下的“启动热幕精灵（影院版）.bat”文件，右键选择“以管理员身份运行”'
    ],
    btns: [
      {
        text: '上一步',
        step: 'step2',
        style: {
          ...defaultBtnStyle,
          ...snowBtnStyle,
          marginRight: '16px'
        }
      },
      {
        text: '已启动',
        step: undefined,
        style: defaultBtnStyle
      }
    ]
  }
}