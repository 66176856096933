import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import styles from './SignIn.module.css'

import util from '../../../../libs/util'
import store from '../../../../redux/store'
import { RootState } from '../../../../store'
import { getUserInfoAction } from '../../../../store/user/action'
import { UPDATE_USER } from '../../../../store/actionTypes'
import { defaultState as defaultUserState } from '../../../../store/user/reducer'
import auth from './auth'

import { Footer } from '../../../../components'
import { SignUpGuideModal } from '../../../../components/header/signUpGuideModal'
import { CompanySignIn, PersonalSignIn } from './index'

interface Props {
  handleSignIn?: () => void
}

interface Tabs {
  key: string,
  name: string,
  active: boolean
}

const defaultTabs = [
  {
    key: 'COMPANY',
    name: '企业登录',
    active: true
  },
  {
    key: 'PERSONAL',
    name: '个人登录',
    active: false
  }
]

export const AssistantSignIn: React.FC<Props> = (props) => {
  const history = useHistory()

  const [loginMode, setLoginMode] = useState<'COMPANY' | 'PERSONAL'>('COMPANY')
  const [tabs, setTabs] = useState<Tabs[]>(defaultTabs)

  const dispatch = useDispatch()
  const onLogout = async () => {
    const result = await util.request({
      method: 'POST',
      url: '/uc/userLogout'
    })

    if (result.code === 1) {
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
  }
  useEffect(() => {
    console.log(defaultTabs)
    defaultTabs.forEach(item => {
      if (item.key === 'COMPANY') {
        item.active = true
      } else {
        item.active = false
      }
    })

    setTabs([...defaultTabs])

    const userToken = util.Cookies.get('userToken')
    console.log('userToken', userToken)
    if (userToken) {
      util.Cookies.set('userToken', userToken)
      checkLogin(userToken)
    } else {
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
  }, [])

  const checkLogin = async (userToken) => {
    const result = await util.request({
      method: 'POST',
      url: 'uc/getMyUserInfo',
      headers: {
        userToken: userToken,
      }
    })

    const data = result.data

    if (data) {
      if (data.permissionDenied) {
        message.error('当前账号尚未开通热幕影院银幕广告助手服务！')
        // 调退出登录
        onLogout()
        return
      }

      const hide = message.loading('已登录,自动跳转中...', 0)
      setTimeout(() => {
        hide()

        util.storage.set('myUserInfo', data.myUserInfo)

        const myAuth: any = auth(data)
        history.push({ 
          pathname: myAuth.url, 
          state: myAuth 
        })

      }, 1000)
    } else {
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
  }

  const handleSignIn = async () => {
    getUserInfo()
  }

  // 获取用户信息
  const getUserInfo = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'uc/getMyUserInfo'
    })

    if (result.data.permissionDenied) {
      message.error('当前账号尚未开通热幕影院银幕广告助手服务！')

      // 调退出登录
      onLogout()

      return
    }

    util.storage.set('myUserInfo', result.data.myUserInfo)

    const myAuth: any = auth(result.data)
    console.log('login get ', myAuth)
    history.push({ 
      pathname: myAuth.url, 
      state: myAuth 
    })

    store.dispatch({
      type: 'UPDATE_USER',
      payload: result.data
    })
  }

  const onSwitch = (event) => {
    tabs.forEach((item, index) => {
      if(item.key === event.key) {
        item.active = true
      }else {
        item.active = false
      }
    })

    setLoginMode(event.key)
    setTabs([...tabs])
  }

  const childRef: any = useRef()
  const handleSignUp = () => {
    childRef.current.onToggle()
    // util.bus.emit('handleSignUp')
  }

  return (
    <div className={ styles['sign-in'] }>
      <div className={ styles['sign-cont'] }>
        <div className={ styles['welcome'] }>
           <div className={ `icon ${ styles['welcome-text'] }` }></div>
           
           <div className={ styles['welcome-cont'] }>
           <div className={ styles['welcome-subtit'] }>【映前广告助手】</div>
             <div className={ styles['feature-list'] }>
               <div className={ styles['feature-item'] }>· 广告素材自动下载</div>
               <div className={ styles['feature-item'] }>· 多种放映格式自动转换</div>
             </div>
           </div>
        </div>

        <div className={ `${ styles.sign } py12` }>
          <div className={ styles.tabs }>
            <div className={ `${ styles.switch } mb6` }>
              <div className="flex justify-start px12">
                {
                  tabs.map((item, index) => {
                    return (
                      <div 
                        className={ `${ styles.tab } ${ item.active ? styles.active : '' }` }
                        key={ index }
                        data-item={ item }
                        onClick={ onSwitch.bind(this, item) }
                      >{ item.name }</div>
                    )
                  })
                }
              </div>
            </div>

            <div className={ `${ styles.panel } px12` }>
              { loginMode === 'COMPANY' ? 
                <CompanySignIn handleSignIn={ handleSignIn } isCinema={ true } color='#465870' /> : 
                <PersonalSignIn handleSignIn={ handleSignIn } color='#465870' /> 
              }
              <p 
                className={ styles['btn-registe'] }
                onClick={ handleSignUp }
              >注册</p>
            </div>
          </div>
        </div>
      </div>

      <div className={ styles.footer }>
        <Footer />
      </div>

      <SignUpGuideModal childRef={ childRef } isCinema={ true } />
    </div>
  )
}