import React, { useState, useEffect } from 'react'
import styles from './CinemaIssue.module.css'

import { Elf } from './elf'
import { List } from './list'
import util from '../../../libs/util'

import { Select } from 'antd'
const { Option } = Select

interface CinemaType {
  entityName: string,
  orgEntityInfoId: string
}

interface ContextValueType {
  entityName: string,
  deliveryDate: string,
  orgEntityInfoId: string,
  visibleIssueDialog: boolean,
  hasList: boolean,
  reportStatus: any,
  currStep: 1 | 2 | 3
}

const nextDay = util.moment(Date.now() + 86400000).format('YYYY-MM-DD')
// const nextDay = util.moment(Date.now() - 86400000 * 3).format('YYYY-MM-DD')

const defaultCinema = {
  entityName: '',
  orgEntityInfoId: ''
}

const defaultContextValue: ContextValueType = {
  entityName: '',
  deliveryDate: nextDay,
  orgEntityInfoId: '',
  visibleIssueDialog: false,
  hasList: false,
  reportStatus: -1,
  currStep: 1
}

export const cinemaIssueContext = React.createContext(defaultContextValue)
export const setCinemaIssueContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | undefined>(undefined)

export const CinemaIssue: React.FC = () => {
  const [tomorrow] = useState<string>(nextDay)
  const [cinamaList, setCinemaList] = useState<CinemaType[]>([defaultCinema])
  const [selectCinema, setSelectCinema] = useState<CinemaType>(defaultCinema)
  const [contextValue, setContextValue] = useState<ContextValueType>(defaultContextValue)

  useEffect(() => {
    getCinema()
  }, [])

  const getCinema = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/searchOwnerPointEntityList',
      data: {
        operatorType: 'CINEMA',
        releaseStatus: 'RELEASE'
      }
    })

    if(result.code === 1) {
      const list: CinemaType[] = []
      result.data.map(item => {
        list.push({
          entityName: item.entityName,
          orgEntityInfoId: item.orgEntityInfoId
        })
      })

      setCinemaList(list)
      setSelectCinema(list[0])
      setContextValue({ ...contextValue, ...list[0] })
    }
  }

  const onSelectCinema = (val, option) => {
    const record: CinemaType = {
      entityName: option.children,
      orgEntityInfoId: val
    }

    console.log(val)

    setSelectCinema(record)
    setContextValue({ ...contextValue, ...record })
  }

  return (
    <div className={ styles['cinema-issue'] }>
      <h3 className={ styles.title }>影院银幕上刊</h3>

      <div className="py6 px9">
        <div className={ `${ styles.filter } flex align-center` }>
          <div className={ styles.date }>
            <span className={ `${ styles.label } mr2` }>上刊日期:</span>
            <span className={ styles.text }>{ tomorrow }</span>
          </div>

          <div className={ `${ styles.cinema } ml12` }>
            <span className={ `${ styles.label } mr2` }>上刊影院:</span>
            <Select 
              style={{ width: 240 }}
              value={ selectCinema?.orgEntityInfoId } 
              onChange={ onSelectCinema }
            >
              {
                cinamaList?.map((item, index) => {
                  return (
                    <Option 
                      key={ `cinema_item_${ index }` } 
                      value={ item.orgEntityInfoId }
                    >{ item.entityName }</Option>
                  )
                })
              }
            </Select>
          </div>
        </div>
        
        <cinemaIssueContext.Provider value={ contextValue }>
          <setCinemaIssueContext.Provider value={ setContextValue }>
            {
              contextValue.hasList ? <Elf /> : ''
            }
            <List />
          </setCinemaIssueContext.Provider>
        </cinemaIssueContext.Provider>
      </div>
    </div>
  )
}