import React from 'react'

interface ColumnType {
  title: string,
  dataIndex: string,
  align?: 'left' | 'center' | 'right'
}

export interface DessertsType {
  key: React.Key
  hallName: string
  deviceOnline: string | undefined,
  deliveryTaskCount: number,
  succeed: number,
  failed: number,
  situation: string
}

const deviceOnlineMap = new Map([
  ['ONLINE', '在线'],
  ['OFFLINE', '离线'],
  [undefined, '-']
])

export const columns1: ColumnType[] = [
  {
    title: '影厅',
    dataIndex: 'hallName',
    align: 'center'
  },
  {
    title: '服务状态',
    dataIndex: 'deviceOnline',
    align: 'center'
  },
  {
    title: '计划广告上刊数',
    dataIndex: 'deliveryTaskCount',
    align: 'center'
  },
  {
    title: '下载成功',
    dataIndex: 'succeed',
    align: 'center'
  },
  {
    title: '下载异常',
    dataIndex: 'failed',
    align: 'center'
  },
  {
    title: '上刊任务执行情况',
    dataIndex: 'situation',
    align: 'center'
  }
]

export const columns2: ColumnType[] = [
  {
    title: '影厅',
    dataIndex: 'hallName',
    align: 'center'
  },
  {
    title: '计划广告上刊数',
    dataIndex: 'deliveryTaskCount',
    align: 'center'
  },
  {
    title: '下载成功',
    dataIndex: 'succeed',
    align: 'center'
  },
  {
    title: '下载异常',
    dataIndex: 'failed',
    align: 'center'
  },
  {
    title: '上刊任务执行情况',
    dataIndex: 'situation',
    align: 'center'
  }
]

export const defaultDesserts: DessertsType[] = [
  {
    key: '',
    hallName: '',
    deviceOnline: 'ONLINE',
    deliveryTaskCount: 0,
    succeed: 0,
    failed: 0,
    situation: ''
  }
]

export const deviceOnlineText = (value): string | undefined => {
  return deviceOnlineMap.get(value)
}

