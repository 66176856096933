let welcomeUrl = '/welcome'

const mineHomeUrl = '/mine/home'

const personalAuth = (user) => {
  const { companyAuth, personAuth } = user
  // 已企业认证
  const hasCompanyAuth = companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'YES'
  // 已个人认证
  const hasPersonAuth = personAuth && personAuth.status === 'YES'

  let status = 'unauth'
  let reason = '未认证'

  // 只有个人认证通过后，才企业授权
  // 未个人认证
  if (!personAuth) {
    status = 'unauth'
    reason = '未认证'
  } else {
  // 已个人认证
    if (personAuth.status === 'WAIT') {
    // 认证审核中
      status = 'inreview'
      reason = '认证审核中'
    } else if (personAuth.status === 'NO') {
    // 认证审核不通过
      status = 'failed'
      reason = '认证审核不通过'
    } else if (personAuth.status === 'YES' && (!companyAuth || (companyAuth.status !== 'YES' || companyAuth.roleStatus !== 'YES'))) {
    // 无权限(认证通过　但企业未通过认证)
      status = 'noaccess'
    } else if (hasPersonAuth && hasCompanyAuth) {
      // 广告执行人员
      const isMediaCheck = !!user.userRoleList.find(i => i.roleItemKey === 'MEDIA_CHECK')
      // 跳转至无权限的欢迎页
      if (isMediaCheck) {
        status = 'rolepermissions'
        reason = '广告执行人员无权限'
      } else {
        // 跳转至个人我的点位主端主页
        return {
          url: mineHomeUrl
        }
      }    
    }
  }

  return {
    url: welcomeUrl + status,
    status,
    reason,
  }
}

const companyAuth = (user) => {
  const { companyAuth } = user

  if(!companyAuth || companyAuth && (companyAuth.status == 'DRAFT' || !companyAuth.roleStatus)) {
    const status = 'registeredunauth'
    // 企业用户已注册，未提交认证
    return {
      url: welcomeUrl + status,
      status,
      reason: '企业用户已注册，未提交认证'
    }
  }
  else if(companyAuth && (companyAuth.status === 'WAIT' || companyAuth.roleStatus === 'WAIT')) {
    const status = 'inreview'
    // 企业认证审核中
    return {
      url: welcomeUrl + status,
      status,
      reason: '企业认证审核中'
    }
  }
  else if(companyAuth && companyAuth.status === 'NO') {
    const status = 'companyfailed'
    // 企业认证审核不通过
    // 审核不通过原因：companyAuth.noStatusMore.approveOpinion
    return {
      url: welcomeUrl + status,
      status,
      reason: '企业认证审核不通过'
    }
  }
  else if(companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'NO') {
    const status = 'mediafailed'
    // 点位主入驻审核不通过
    // 查询接口/uc/checkMediaApply返回status==‘NO’
    return {
      url: welcomeUrl + status,
      status,
      reason: '点位主入驻审核不通过'
    }
  }
  else if(companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'YES') {
    // 跳转至企业我的点位主端主页
    return {
      url: mineHomeUrl
    }
  } else {
    const status = 'auth'
    // 广告主用户登录（认证通过）
    return {
      url: welcomeUrl + status,
      status,
      reason: '认证通过'
    }
  }
}

export default (user) => {
  const { myUserInfo: { userType } } = user

  switch (userType) {
    case 'PERSON':
      welcomeUrl = '/welcome/personal/'
      return personalAuth(user)
    default:
      welcomeUrl = '/welcome/company/'
      return companyAuth(user)
  }
}

