import React, { useState, useContext, useEffect } from 'react'
import util from '../../../../libs/util'
import styles from './Filter.module.css'

import { filterPointsContext, setFilterPointsContext } from '../SelectPoints'

import { Select, Input, message, Checkbox, Radio } from 'antd'
const { Option } = Select
const { Search } = Input

interface Props {
  ad: any,
}

const distanceList = [
  {
    itemKey: null,
    itemValue: '不限',
  },
  {
    itemKey: 1500,
    itemValue: '1.5km',
  },
  {
    itemKey: 2000,
    itemValue: '2km',
  },
  {
    itemKey: 3000,
    itemValue: '3km',
  },
  {
    itemKey: 5000,
    itemValue: '5km',
  },
]

export const FilterPointsPanel: React.FC<Props> = ({ ad }) => {
  const filter = useContext(filterPointsContext)
  const setFilter = useContext(setFilterPointsContext)

  const cityList = ad?.districtList

  const [defaultDistrictId, setDefaultDistrictId] = useState('')
  const [cList, setCList] = useState()

  const toggleDistance = (value) => {
  	setFilter((state) => {
      return {
        ...state,
        geoDistance: value,
      }
    })
  }

  useEffect(() => {
  	findEnumItemList()
    // getIP()
  }, [])

  useEffect(() => {
    if (!cityList || !cityList.length) {
      return
    }
    const list:any = []
    cityList.forEach(i => {
      list.push({
        value: i.districtId,
        label: i.districtName,
      })
    })
    setCList(list)
    setDefaultDistrictId(cityList[0].districtId)
    console.log('cityList', cityList, defaultDistrictId, cityList[0].districtId, list)
    getIP()
  }, [cityList])

  // 院线
  const [cinemaChainIdItems, setCinemaChainIdItems] = useState<any>([])
  const findEnumItemList = async() => {
  	const key = ['CINEMA_CHAIN']
    const params = {
      groupKeyArray: key.join(','),
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    if (code === 1) {
      setCinemaChainIdItems(data['CINEMA_CHAIN'])
    }
  }
  const onCinemaChainIdChange = (value) => {
  	setFilter((state) => {
      return {
        ...state,
        cinemaChainId: value,
      }
    })
  }

  const [continuousDateSearch, setContinuousDateSearch] = useState(false)
  const onContinuousDateSearchChange = ({ target }) => {
    const value = target.value
  	setFilter((state) => {
      return {
        ...state,
        pointMinDays: value ? ad.deliveryDays : 1,
      }
    })
    setContinuousDateSearch(value)
  }

  const [isInCityList, setIsInCityList] = useState(false)
  const [curCityInfo, setCurCityInfo] = useState<any>()

  const host = 'https://apis.map.qq.com'
  const key = encodeURI('Q2WBZ-DDSKP-XAJDG-VD56L-FAER6-DJF4H')
  const output = 'jsonp'
  const api = {
    getIP: '/ws/location/v1/ip',
    search: '/ws/place/v1/search',
    suggestion: '/ws/place/v1/suggestion',
    getDistrict: '/ws/district/v1/list',
    // 逆地址解析
    geocoder: '/ws/geocoder/v1/'
  }
  const createScript1 = ({ apiUrl, callbackName, fn, params }) => {
    let url = encodeURI(`${host}${apiUrl}?key=${key}&output=${output}&callback=${callbackName}`)
    if (params) {
      url += `&${params}`
    }

    let script:any = document.getElementById(callbackName)
    if (script) {
      script.parentNode.removeChild(script)
    }

    // 创建script标签，设置其属性
    script = document.createElement('script')
    script.setAttribute('src', url)
    script.setAttribute('id', callbackName)
    document.getElementsByTagName('head')[0].appendChild(script)

    window[callbackName] = fn
  }
  const createScript = () => {
    const callbackName = 'getIpCallback'
    let url = encodeURI(`${host}${api.getIP}?key=${key}&output=${output}&callback=${callbackName}`)

    let script:any = document.getElementById(callbackName)
    if (script) {
      script.parentNode.removeChild(script)
    }

    // 创建script标签，设置其属性
    script = document.createElement('script')
    script.setAttribute('src', url)
    script.setAttribute('id', callbackName)
    document.getElementsByTagName('head')[0].appendChild(script)

    window[callbackName] = (data) => {
      const { result, status } = data
      if (status === 0) {
        const { location, ad_info: adInfo } = result
        const cityCode = `${ String(adInfo.adcode).substring(0, 4) }00`
        let { lat: latitude, lng: longitude } = location
        const cityName = adInfo.city
        // 当前位置在在投放城市内
        const isInCityList = cityList.find(i => i.districtId === cityCode)

        setIsInCityList(isInCityList)
        setCurCityInfo(adInfo)
        isInCityList && setDefaultDistrictId(cityCode)

        // 在投放城市内，且当前未选择城市
        if (isInCityList && !filter.administrativeAreaId.length) {
          setFilter((state) => {
            return {
              ...state,
              administrativeAreaId: [cityCode],
              entityLocation: isInCityList ? [latitude, longitude] : [],
            }
          })
        } else {
          // 默认第1个
          setFilter((state) => {
            return {
              ...state,
              administrativeAreaId: [ad?.districtList[0].districtId],
            }
          })
        }

        console.log('getIP', result, cityCode)
      }
    }
  }

  const getIP = () => {
    //
    createScript()
  }

  const onCityChange = (value, list) => {
    setFilter((state) => {
      return {
        ...state,
        administrativeAreaId: [value],
      }
    })
    setDefaultDistrictId(value)
    console.log('onCityChange', value, list )
  }

  const onSearch = (e) => {
    setFilter((state) => {
      return {
        ...state,
        keyWord: e.target.value,
      }
    })
  }

  const setCenterLocation = () => {
    console.log('curCityInfo', curCityInfo)
    const cityCode = `${ String(curCityInfo.adcode).substring(0, 4) }00`
    setDefaultDistrictId(cityCode)
    setFilter((state) => {
      return {
        ...state,
        administrativeAreaId: [cityCode],
      }
    })
  }

  const [isFold, setIsFold] = useState(false)
  const toggleFold = () => {
    setIsFold(!isFold)
  }

  const [isBrandFold, setIsBrandFold] = useState(true)
  const toggleBrand = () => {
    setIsBrandFold(!isBrandFold)
  }

  return(
  	<div className={ `${ styles['ad-filter'] }` }>
  		<div className={ `${ styles['ad-filter-top'] }` }>
  			<Input.Group compact style={{ width: '60%' }}>
		      <Select 
		      	value={ defaultDistrictId }
		      	style={{ width: 100 }}
            options={ cList }
            onChange={ onCityChange }
		      	>
		      	{/*{
		      		cityList?.map((city) => {
		      			return (
		      				<Option value={ city.districtId } key={ city.districtId }>{ city.districtName }</Option>
		      			)
		      		})
		      	}*/}
		      </Select>
		      <Input.Search allowClear style={{ width: 300 }} onPressEnter={ onSearch } placeholder='影院、院线名称' />
		    </Input.Group>

		    {/* <div className={ `${ styles['btn-toggle'] }` } onClick={ toggleFold }>
          {
            isFold ? '展开' : '收起'
          }
          筛选<span className={ `${ styles['icon-toggle'] } imediafont i-trangle-b ${ isFold ? styles['bot'] : '' }` }></span>
        </div> */}
  		</div>

  		<div className={ `${ styles['form'] } ${ isFold ? styles['hide'] : '' }` }>
  			{/* <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>投放中心点：</div>
  				<div className={ `${ styles['form-value'] }` }>
  					<div className={ `${ styles['text-location'] }` } onClick={ setCenterLocation }><span className={ `${ styles['icon-location'] } imediafont i-trangle-b` }></span>{ curCityInfo?.city }</div>

  					<div className={ `${ styles['text-other'] }` }>其他<span className={ `${ styles['icon-other'] } imediafont i-trangle-b` }></span></div>
  				</div>
  			</div> */}
  			
  			{/* <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>距离投放中心点位置：</div>
  				<div className={ `${ styles['form-value'] }` }>
  					{
  						distanceList.map((distance) => {
  							return (
  								<div 
	  								className={ `${ styles['distance-item'] } ${ filter.geoDistance === distance.itemKey ? styles['is-selected'] : '' }` }
	  								key={ distance.itemKey }
	  								onClick={ () => { toggleDistance(distance.itemKey) } }
	  								>
  										{ distance.itemValue }
  								</div>
  							)
  						})
  					}
  				</div>
  			</div> */}

  			<div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>院线：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['brand-wrap'] } ${ isBrandFold ? styles['small'] : '' }` }>
            <Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} onChange={ onCinemaChainIdChange }>
	  					{
	  						cinemaChainIdItems.map((item) => {
	  							return (
	  								<span className={ `${ styles['brand-item'] }` } key={ item.itemKey }><Checkbox value={ item.itemKey }>{ item.itemValue }</Checkbox></span>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
	  				<div className={ `${ styles['btn-more'] }` } onClick={ toggleBrand }>{ isBrandFold ? '更多' : '收起' }<span className={ `${ styles['icon-other'] } imediafont i-trangle-b ${ isBrandFold ? styles['bot'] : '' }` }></span></div>
  				</div>
  			</div>

  			<div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>只显示投放日期满投的点位：</div>
  				<div className={ `${ styles['form-value'] }` }>
  					<Radio.Group onChange={ onContinuousDateSearchChange } value={ continuousDateSearch }>
				      <span className={ `${ styles['radio-item'] }` }>
				      	<Radio value={ true }>是</Radio>
				      </span>
				      <span className={ `${ styles['radio-item'] }` }>
				      	<Radio value={ false }>否</Radio>
				      </span>
				    </Radio.Group>
  				</div>
  			</div>
  		</div>
  	</div>
  )
}