import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Input } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems, approveStatusList } from '../../../../../libs/util.constants'
import { GuideModal, VideoPlayModal } from '../../../../../components'
import { Review } from '../review-modal'

import styles from './BaseInfo.module.css'

interface Props {
  ad: any,
}

export const BaseInfo: React.FC<Props> = ({ ad }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  // 接单确认
  const reviewModalRef: any = useRef()
  const showReviewModal = (ad) => {
    // reviewModalRef.current.showModal(ad)
    reviewModalRef.current.onToggle()
  }


  // 广告所属行业
  const getDeliveryIndustry = (ad) => {
    return (<>{ Object.values(ad.deliveryIndustryMore?.itemMap).join(',') }</>)
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const [servicePhoneNumber, setServicePhoneNumber] = useState()
  // 查客服电话
  const getServicePhoneNumber = async() => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/biz/getBizConfig`,
      params: {
        itemKey: 'SERVICE_PHONE'
      },
    })
    if (code === 1) {
      setServicePhoneNumber(data.itemValue)
    } 
  }

  useEffect(() => {
    getServicePhoneNumber()
  }, [])

  const onReport = (ad) => {
    history.push(`/remo-ad/report/home/${ ad.mallOrderMediaId }`)
  }

  useEffect(() => {
    ad.mediaDeliveryName && setDeliveryName(ad.mediaDeliveryName)
  }, [ad.mediaDeliveryName])
  const [mediaDeliveryName, setDeliveryName] = useState('')
  const inputRef = useRef<any>(null)
  const onDeliveryNameChange = ({ target }) => {
    setDeliveryName(target.value)
  }
  const onDeliveryNameBlur = async() => {
    // 更新计划名称
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/media/order/updateOrderMediaName`,
      data: {
        mallOrderMediaId: ad.mallOrderMediaId,
        mediaDeliveryName,
      },
    })

    if (code !== 1) {
      message.error(msg || '操作失败')
    } else {
      setIsNameEditMode(false)
      message.success('广告计划名称更新成功')
    }
  }
  const clearInput = () => {
    setDeliveryName('')
  }
  const focusInput = () => {
    inputRef.current.focus({
      cursor: 'start',
    })
  }

  const [isNameEditMode, setIsNameEditMode] = useState(false)
  const toggleIptMode = (show) => {
    setIsNameEditMode(show === undefined ? !isNameEditMode : show)
  }

  const videoPlayModalRef:any = useRef()
  const previewMedia = () => {
    videoPlayModalRef.current.onToggle(ad.contentUrl)
  }

  return (
  	<>
			<div className={ `${ styles['ad-page-top'] }` }>
        <div className={ `${ styles['ad-labels'] }` }>
          <div className={ `${ styles['ad-label'] } ${ styles['operator'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad?.operatorType) }
          </div>
          <div className={ `${ styles['ad-label'] } ${ styles['delivery'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad?.deliveryCinemaModel) }</div>
        </div>

        {/* 状态等 */}
        <div className={ `${ styles['ad-page-top-r'] }` }>
          {/*右边操作及提醒*/}
          {/*计划关闭提醒*/}
          {
            (ad.approveStatus === 'NO' || ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P' || ad.approveStatus === 'CANCEL') && (
              <div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.orderTimeoutStatus(ad.approveStatus, servicePhoneNumber) }</div>
            )
          } 

          {/*1分钟之内不显示提示*/}
          {
            (ad.approveStatus === 'APPROVE_WAIT' && util.tools.hasLeftTime(ad.approveTimeOut)) && (
              <>
                <div className={ `${ styles['ad-content-tips'] }` }>剩余<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad.approveTimeOut) }</span>将自动接单</div>

                <div className={ `${ styles['btn-confirm'] }` } onClick={ () => { showReviewModal(ad) } }>接单确认</div>
              </>
              )
          }

          {/*刊播提醒 */}
          {
            (ad.approveStatus === 'DELIVERY_WAIT' && (!ad.showPlanStatusMore?.includes('CONTENT_NULL') && !ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) && (
              <div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.startBetweenDays }</span>天</div>
              )
          }

          {/*查看刊播数据*/}
          {
            (ad.approveStatus === 'EXECUTING' || ad.approveStatus === 'FINISHED') && (
              <div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onReport(ad) } }>刊播数据</div>
            )
          }

          {/*订单状态*/}
          <div className={ `${ styles['ad-status'] }` }>
            { approveStatusList.find(i => i.itemKey.split(',').includes(ad.approveStatus))?.itemValue }
          </div>
        </div>
      </div>

      <div className={ `${ styles['ad-page-cont-inner'] }` }>
        {/*左边*/}
        {
          (ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P' || !ad.thumbnailUrl || !ad.thumbnailUrl.length) && (
            <div className={ `${ styles['ad-page-cont-inner-l'] }` }>
              <div className={ `icon ${ styles['order-material-default'] }` }>默认图片，体现素材未提供</div>
              <div className={ `${ styles['order-material-tips'] }` }>
                广告主{ (ad.approveStatus !== 'CONTENT_TIMEOUT' && ad.approveStatus !== 'TIMEOUT_P') && ('暂') }未<br/>提供广告内容
              </div>
            </div>
          )
        }
        {/*上传过素材的*/}
        {
          (ad.approveStatus !== 'CONTENT_TIMEOUT' && ad.thumbnailUrl && ad.thumbnailUrl.length) && (
            <div className={ `${ styles['ad-page-cont-inner-l'] }` }　onClick={ previewMedia }>
              <div className={ `${ styles['order-material-cont'] }` }>
                <img className={ `${ styles['order-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/x368`} />
                <div className={ `${ styles['order-content-duration'] }` }>
                  <div className={ `${ styles['btn-play'] }` }><div className={ `${ styles['icon-play'] } imediafont i-play` }></div></div>
                  <div>{ util.tools.fixedTime(ad.contentDuration) }</div>
                </div>
              </div>
            </div>
          )
        }
        
        <div className={ `${ styles['ad-page-cont-inner-r'] }` }>
          {
            (ad?.mediaDeliveryName) && (
              <div className={ `${ styles['flex'] }` }>
                <div className={ `${ styles['ad-tit-label'] }` }>广告计划名称</div>
                <div className={ `${ styles['delivery-name'] }` }>
                  {
                    isNameEditMode && (
                      <>
                      <Input 
                        className={ `${ styles['delivery-name-ipt'] }` } 
                        value={ mediaDeliveryName }
                        size='large'
                        ref= { inputRef }
                        onChange={ onDeliveryNameChange }
                        maxLength={ 32 }
                         />
                        <div className={ `${ styles['btn-checked'] }` } onClick={ onDeliveryNameBlur }>
                          <div className={ `imediafont i-checked` }></div>
                        </div>
                      </>
                    )
                  }

                  { !isNameEditMode && (
                    <div className={ `${ styles['text-deliveryName'] }` } onClick={ () => { toggleIptMode(true) } }>
                    { mediaDeliveryName || ad.mediaDeliveryName }
                    <div className={ `${ styles['btn-pencile'] }` }>
                      <div className={ `imediafont i-pencile-simple` }></div>
                    </div>
                    </div>
                  )
                  }
                </div>
              </div>
            )
          }

          <div className={ `${ styles['ad-form-wrap'] }` }>
            <div className={ `${ styles['ad-tit-label'] }` }>投放需求</div>

            {/*订单详细数据*/}
            {
              ad?.timePeriods && (
                <div className={ `${ styles['ad-form'] }` }>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
                      投放周期：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      { util.tools.formatDateStr(ad.deliveryStart) }-{ util.tools.formatDateStr(ad.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
                      广告时长：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
                      {
                        (ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
                      播放时段：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        util.tools.convertTimePeriods(ad)
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
                      广告所属行业：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        getDeliveryIndustry(ad)
                      }
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>

      {/* 接单 */}
      {/*<Review childRef={ reviewModalRef } />*/}

      <GuideModal childRef={ reviewModalRef } />

      {/* 视频预览 */}
      <VideoPlayModal childRef={ videoPlayModalRef } />
  	</>
  )
}