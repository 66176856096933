import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './PersonalHome.module.css'

import { Profile, Task } from './index'
import { Token }  from '../companyHome/token'
import { Point }  from '../companyHome/point'
import { RemoPoint }  from '../companyHome/remo-point'
import { Order }  from '../companyHome/order'
import { OrderExec } from '../companyHome/order-exec'
import { Wallet } from '../companyHome/wallet'
import { Staff }  from '../companyHome/staff'

import util from '../../../../libs/util'
interface Props {
}
export const PersonalHome: React.FC<Props> = () => {
  const history = useHistory()
  const goToPage = (path) => {
    history.push(path)
  }

  useEffect(() => {
    getAuth()
  }, [])

  const [hasAdExec, setHasAdExec] = useState(false)
  const [hasAmount, setHasAmount] = useState(false)
  const [hasStaff, setHasStaff] = useState(false)
  const [hasAd, setHasAd] = useState(false)
  const [hasRemoAmount, setHasRemoAmount] = useState(false)
  const [hasEntityResource, setHasEntityResource] = useState(false)
  const [hasRemoResource, setHasRemoResource] = useState(false)
  const getAuth = async() => {
    const { code, data } = await util.request({
      method: 'POST',
      url: '/uc/queryMyElementList',
      data: {
        menuItemKey: 'MEDIA_WEB_WoDeZhuYe'
      },
    })

    if (code === 1) {
      const haskey = (key) => {
        return data.find(i => i.elementItemKey === key && i.authType === 'ENABLED')
      }
      // 我的钱包
      const hasAmount = haskey('WoDeQianBao_MEDIA_WEB_WoDeZhuYe')
      // 人员管理
      const hasStaff = haskey('RenYuanGuanLi_MEDIA_WEB_WoDeZhuYe')
      // 广告执行单
      const hasAdExec = haskey('GuangGaoZhiXingDan_MEDIA_WEB_WoDeZhuYe')
      // 热幕订单
      const hasAd = haskey('ReMuDingDan_MEDIA_WEB_WoDeZhuYe')
      // 热幕收益
      const hasRemoAmount = haskey('ReMuShouYi_MEDIA_WEB_WoDeZhuYe')
      // 媒体资源
      const hasEntityResource = haskey('MeiTiZiYuan_MEDIA_WEB_WoDeZhuYe')
      // 热幕资源
      const hasRemoResource = haskey('ReMuZiYuan_MEDIA_WEB_WoDeZhuYe')
      
      setHasAmount(hasAmount)
      setHasStaff(hasStaff)
      setHasAdExec(hasAdExec)
      setHasAd(hasAd)
      setHasRemoAmount(hasRemoAmount)
      setHasEntityResource(hasEntityResource)
      setHasRemoResource(hasRemoResource)

      console.log('hasStaff', hasStaff)
    }
  }

  return (
    <div className={ `${ styles['personal-home'] }` }>
      <div className={　styles['personal-home-tit'] }>我的主页</div>
      <div className={ styles['personal-cont-t'] }>
        <div className={ `${ styles['personal-cont-l'] }` }>
          <Profile switchRole={ getAuth } />
        </div>
        
        {
          hasStaff && <div className={ styles['personal-cont-r'] }><Staff /></div>
        }
        
      </div>
      
      <div className={ styles['personal-cont'] }>
        <div className={ `${ styles['personal-cont-l'] }` }>
          <div className={ styles['personal-cont-item'] }>
            {/*广告执行单*/}
            {
              hasAdExec && <OrderExec />
            }
            
            {/*热幕订单*/}
            {
              hasAd && (<div className={ styles['mt'] }>
              <Order />
            </div>)
            }
          </div>
          <div className={ styles['personal-cont-item'] }>
            {/*媒体资源*/}
            {
              hasEntityResource && (<div onClick={ () => { goToPage('/point/list') } }><Point /></div>)
            }
            
            {/*热幕资源*/}
            {
              hasRemoResource && (<div className={ styles['mt'] } onClick={ () => { goToPage('/remo-point/list') } }>
              <RemoPoint />
            </div>)
            }
          </div>
        </div>
        <div className={ `${ styles['personal-cont-item'] } ${ styles['spec'] } `}>
            {/*我的钱包*/}
            {
              hasAmount && (
                <div onClick={ () => { goToPage('/wallet/index') } } className={ styles['mb'] }><Wallet /></div>
              )
            }
            
            {/*热幕收益*/}
            {
              hasRemoAmount && (<div onClick={ () => { goToPage('/remo-income/index') } }>
              <Token />
            </div>)
            }
          </div>
      </div>
    </div>
  )
}