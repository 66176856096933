import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Progress  } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import * as echarts from 'echarts'

import { operatorTypeItems, deliveryCinemaModelItems, approveStatusList } from '../../../../../libs/util.constants'

import util from '../../../../../libs/util'
import { Crumb } from '../../../../../components'
import { ReceiptModal } from './receipt-modal'
import { ReportDayDetailModal } from '../list/report-day-detail-modal'

import styles from './Home.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderMediaId?: String,
    },
  },
}

export const AdReportHome: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()
  const [mallOrderMediaId, setMallOrderMediaId] = useState(props.match.params.mallOrderMediaId)
  const [mallOrderPlanId, setMallOrderPlanId] = useState()

  const onBack = () => {
    history.push(`${ isRemo ? '/remo-ad/list' : '/ad-exec/list' }`)
  }

  useEffect(() => {
    init()
  }, [])

  const init = async() => {
    const ad = await getAdDetail()
    const list = await getSellerOrderDayDetails(ad)
    initChart(ad, list)

    getMediaOrderReceiptState()
  }

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [deliveryPercent, setDeliveryPercent] = useState(0)
  const [isRemo, setIsRemo] = useState(false)
  // 详情
  const [ad, setAd] = useState<any>()
  const [isShowMode, setIsShowMode] = useState(false)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getOrderMedia`,
      params: {
        mallOrderMediaId,
      },
    })
    if (code === 1) {
      setAd(data)
      setIsShowMode(data.settleStandardType === 'SHOW')
      setDeliveryPercent(data.approveStatus === 'FINISHED' ? 100 : Math.floor((data.hasDeliveryDays / data.deliveryDays) * 100))
      setIsSubmiting(false)
      setIsRemo(data.planAim === 'NORMAL' || data.planAim === 'KA')
      setMallOrderPlanId(data.mallOrderPlanId)
      return data
    }
  }

  const [list, setList] = useState()
  const [totalInfo, setTotalInfo] = useState<any>()
  // 查询点位主（卖家）订单的每日金额明细（包括合计）
  const getSellerOrderDayDetails = async(ad) => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getSellerOrderDayDetails`,
      params: {
        mallOrderMediaId,
      },
    })

    const deliveryStart = new Date(ad.deliveryStart)
    const deliveryEnd = ad.deliveryEnd
    const deliveryDays = ad.deliveryDays

    const today = util.tools.formatDate(new Date())

    let item
    let list:any = []
    const dayTime = 24 * 60 * 60 * 1000
    // 无投放 -1 刊播中 0 已刊播 未生成报告 1 已刊播 有报告 2 待刊播 3
    for (let i = 0, len = deliveryDays; i < len; i++) {
      const now = new Date(deliveryStart.getTime() + i * dayTime)
      const rItem = data.find(j => new Date(j.clearDate).getTime() === now.getTime())
      if (rItem) {
        item = rItem
        // 是否是今天
        item.status = now.getTime() === new Date(today).getTime() ? 0 : (item.settleStatus === 0 ? 3 : item.settleStatus)
      } else {
        item = {
          clearDate: util.tools.formatDate(now),
          status: -1,
        }
      }
      item.shortDate = item.clearDate.substring(5)
      list.push(item)
    }

    setList(list)
    setTotalInfo(data.length ? data[data.length - 1] : null)
    return list
  }

  const goToListPage = () => {
    const detail = ad
    const deliveryInfo = {
      deliveryStart: detail.deliveryStart,
      deliveryEnd: detail.deliveryEnd,
      deliveryDays: detail.deliveryDays,
      deliveryModel: detail.deliveryModel,
    }
    history.push(`/remo-ad/report/list/${ ad.mallOrderMediaId }/${ JSON.stringify(deliveryInfo) }`)
  }

  const [curItem, setCurItem] = useState<any>()
  const [hasData, setHasData] = useState(false)
  // 初始化图表
  const initChart = (detail, list) => {
    let option = {
      grid: {
        left: 60,
        top: 60,
        bottom: 40,
        right: 60,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: '{c0}%',
        backgroundColor: 'rgba(0,0,0,1)',
        textStyle: {
          color: '#fff',
        },
        axisPointer: {
          type: 'cross',
          label: {
            show: false,
          },
          lineStyle: {
            color: '#FE8135',
            type: 'dashed',
          },
          crossStyle: {
            color: '#FE8135',
            type: 'dashed',
          },
        },
      },
      xAxis: {
        type: 'category',
        data: [],
        boundaryGap: true,
        axisLabel: {
          color: '#333',
        },
        axisLine: {
          lineStyle: {
            color: '#ccc',
            type: 'solid',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: true,
        name: '上刊率',
        nameTextStyle: {
          align: 'right',
          color: '#333',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ccc',
            type: 'solid',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#EDEDED',
            type: 'solid',
          },
        },
        axisLabel: {
          formatter: '{value}%',
          color: '#333',
        },
      },
      series: [{
        type: 'line',
        symbolSize: 2,
        symbol: 'circle', 
        data: [],
        lineStyle: {
          color: '#FE8135',
          width: 2,
        },
        itemStyle: {
          emphasis: {
            borderWidth: 1,
            borderColor: '#fff',
            color: '#FE8135',
            shadowColor: 'rgba(255, 139, 53, 0.3)',
            shadowBlur: 2,
          },
        },
      }],
      color: ['#FE8135'],
    }

    // 今天
    const today = util.tools.formatDate(new Date())
    // 投放开始日期
    const deliveryStart = new Date(detail.deliveryStart).getTime()
    // 投放结束日期
    const deliveryEnd = detail.deliveryEnd
    // 投放天数
    const deliveryDays = detail.deliveryDays
    // 已刊播完毕
    const isOver = detail.showPlanStatus === 'FINISHED'

    // 终点是当前有投放报告的最后一天
    const tempEndDay = new Date(isOver ? deliveryEnd : today).getTime()
    let endDay = tempEndDay
    // 是否存在已投放的数据
    let hasData = false

    for (let i = 0, len = list.length; i < len; i++) {
      const item = list[i]
      const clearDateTime = new Date(item.clearDate).getTime()
      if (item.settleStatus === 2) {
        endDay = clearDateTime
        hasData = true
      }
      if (clearDateTime > tempEndDay) {
        break
      }
    }

    const dayTime = 24 * 60 * 60 * 1000
    // 横轴数据
    let xAxisList:any = []
    // const end = hasData ? endDay : new Date(deliveryEnd).getTime()

    for (let i = deliveryStart; i <= endDay; i += dayTime) {
      xAxisList.push(util.tools.formatDate(new Date(i), '-'))
    }

    option.xAxis.data = xAxisList

    const yData = list.slice(0, xAxisList.length)

    option.series[0].data = yData.map(i => Math.round(i.workSuccessRatio * 100) || 0)
    const xIndex = xAxisList.length - 1

    setCurItem(hasData ? yData[xIndex] : null)
    setHasData(hasData)

    // 基于准备好的dom，初始化echarts实例
    let chart = echarts.init(document.getElementById('report-chart'))
    // 绘制图表
    chart.setOption(option)

    chart.on('highlight', (params:any) => {
      const dataIndex = params.batch[0].dataIndex
      setCurItem(yData[dataIndex])
    })
    chart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: xIndex,
    })
    console.log('xAxisList', xAxisList, yData)
  }

  const goToDayDetail = (record) => {
    showReportDayDetailModal(curItem)
    // history.push(`/remo-ad/report/detail?dayInfo=${ JSON.stringify(curItem) }&mallOrderMediaId=${ ad.mallOrderMediaId }`) 
  }

  const crumbList = [
    {
      name: '热幕订单',
      path: '/remo-ad/list',
    },
    {
      name: '热幕订单详情',
      path: `/remo-ad/detail/${ mallOrderMediaId }`,
    },
    {
      name: '刊播数据',
      path: ``,
    }
  ]
  const crumbAdExecList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '广告执行单详情',
      path: `/ad-exec/media-detail/${ mallOrderPlanId }`,
    },
    {
      name: '执行报告',
      path: ``,
    }
  ]

  const [receiptState, setReceiptState] = useState<any>(null)
  const getMediaOrderReceiptState = async() => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/resource/delivery/cinema_v3/getMediaOrderReceiptCounts`,
      params: {
        mallOrderMediaId,
      },
    })

    if (code === 1) {
      setReceiptState(data)
    }
  }

  const receiptModalRef: any = useRef()
  const showModal = (record) => {
    receiptModalRef.current.onToggle()
  }

  const reportDayDetailModalRef: any = useRef()
  const showReportDayDetailModal = (record) => {
    const params = {
      mallOrderMediaId,
      pointDate: record.clearDate,
    }
    reportDayDetailModalRef.current.onToggle(params, record)
  }

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ isRemo ? crumbList : crumbAdExecList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>{ isRemo ? '刊播数据' : '执行报告' }</div>
        </div>

        <div className={ `${ styles['ad-page-top'] }` }>
          <div className={ `${ styles['ad-labels'] }` }>
            <div className={ `${ styles['ad-label'] } ${ styles['operator'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad?.operatorType) }
            </div>
            <div className={ `${ styles['ad-label'] } ${ styles['delivery'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad?.deliveryCinemaModel) }</div>
          </div>
        </div>

        <div className={ `${ styles['flex'] }` }>
          <div className={ `${ styles['ad-tit-label'] }` }>{ isRemo ? '广告计划名称' : '广告执行单名称' }</div>
          <div className={ `${ styles['delivery-name'] }` }>
            { ad?.mediaDeliveryName }
          </div>
        </div>

        <div className={ `${ styles['ad-form-wrap'] } ${ !isRemo ? styles['flex'] : '' }` }>
          <div className={ `${ styles['ad-form-cont'] }` }>
            <div className={ `${ styles['ad-cont-tit'] }` }>
              <div className={ `${ styles['ad-tit-label'] }` }>刊播进度</div>
            </div>

            <div className={ `${ styles['ad-form'] }` }>
              <div className={ `${ styles['ad-form-item'] }` }>
                <div className={ `${ styles['ad-form-label'] }` }>
                  <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
                  投放周期：
                </div>
                <div className={ `${ styles['ad-form-value'] }` }>
                  { util.tools.formatDateStr(ad?.deliveryStart) }-{ util.tools.formatDateStr(ad?.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad?.deliveryDays }天)</span>
                </div>
              </div>
              <div className={ `${ styles['ad-progress-wrap'] }` }>
                <div className={ `${ styles['ad-progress'] }` }>
                  <Progress 
                    strokeColor="#E2A45A"
                    trailColor="#E6E6E6"
                    strokeWidth={ 16 }
                    showInfo={ true }
                    percent={ deliveryPercent } />
                </div>
                {/*{ deliveryPercent }%*/}
                已刊播  { deliveryPercent === 100 ? ad?.deliveryDays : ad?.hasDeliveryDays }天
              </div>
            </div>
          </div>

          {
            !isRemo && (
              <div className={ `${ styles['ad-form-cont'] }` }>
                <div className={ `${ styles['ad-cont-tit'] }` }>
                  <div className={ `${ styles['ad-tit-label'] }` }>刊播回执</div>
                  <div className={ `${ styles['btn-all'] }` } onClick={ showModal }>查看详情<span className={ `${ styles['icon-more'] } imediafont i-arrow-right` }></span></div>
                </div>

                <div className={ `${ styles['receipt-list'] }` }>
                  <div className={ `${ styles['receipt-item'] }` }>
                    <div className={ `${ styles['receipt-item-value'] }` }>{ receiptState?.contentCounts }</div>
                    <div className={ `${ styles['receipt-item-label'] }` }>已上传回执</div>
                  </div>
                </div>

              </div>
            )
          }
        </div>
        
      </div>

      {
        isRemo && (
          <div className={ `${ styles['ad-page-cont-wrap'] }` }>
            {/*刊播回执*/}
            <div className={ `${ styles['ad-page-cont'] }` }>
              <div className={ `${ styles['ad-cont-tit'] }` }>
                <div className={ `${ styles['ad-tit-label'] }` }>刊播回执</div>
                <div className={ `${ styles['btn-all'] }` } onClick={ showModal }>查看详情<span className={ `${ styles['icon-more'] } imediafont i-arrow-right` }></span></div>
              </div>

              <div className={ `${ styles['receipt-list'] }` }>
                <div className={ `${ styles['receipt-item'] }` }>
                  <div className={ `${ styles['receipt-item-value'] }` }>{ receiptState?.contentCounts }</div>
                  <div className={ `${ styles['receipt-item-label'] }` }>已上传回执</div>
                </div>
              </div>

            </div>

            {/*本单收益*/}
            <div className={ `${ styles['ad-page-cont'] }` }>
              <div className={ `${ styles['ad-tit-label'] }` }>本单收益(元)</div>
              <div className={ `${ styles['report-income-list'] }` }>
                <div className={ `${ styles['report-income-item'] }` }>
                  <div className={ `${ styles['report-income-label'] }` }>
                    { isShowMode ? '预估收益' : '预计最高' }
                  </div>
                  <div className={ `${ styles['report-income-value'] }` }>
                    <div className={ `${ styles['text-price'] }` }>
                      <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                      { util.tools.splitNum2String(totalInfo?.payableAmount).int }
                      { util.tools.splitNum2String(totalInfo?.payableAmount).dec }
                    </div>
                  </div>
                </div>
                <div className={ `${ styles['report-income-item'] }` }>
                  <div className={ `${ styles['report-income-label'] }` }>累计收益</div>
                  <div className={ `${ styles['report-income-value'] }` }>
                    <div className={ `${ styles['text-price'] }` }>
                      <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                      { util.tools.splitNum2String(totalInfo?.paymentAmount).int }
                      { util.tools.splitNum2String(totalInfo?.paymentAmount).dec }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      

      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-cont-tit'] }` }>
          <div className={ `${ styles['ad-tit-label'] }` }>刊播情况</div>
          <div className={ `${ styles['btn-all'] }` } onClick={ goToListPage }>查看全部<span className={ `${ styles['icon-more'] } imediafont i-arrow-right` }></span></div>
        </div>

        {/*无数据*/}
        {
          !curItem && (
            <div className={ `${ styles['report-data'] } ${ styles['no-record'] }` }>暂无已生成报告数据</div>
          )
        }
        {/*起点是投放开始日期到今天的日期*/}
        {
          curItem && isShowMode && (
            <div className={ `${ styles['report-data'] }` } onClick={ goToDayDetail }>
              <div className={ `${ styles['report-date'] }` }>{ curItem?.clearDate }</div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ curItem?.entityNums || 0 }</div>
                <div className={ `${ styles['report-item-label'] }` }>投放影院（个）</div>
              </div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ curItem?.pointNums || 0 }</div>
                <div className={ `${ styles['report-item-label'] }` }>投放点位（个）</div>
                {
                  (curItem.workFailedNums > 0) && (
                    <div className={ `${ styles['report-item-tips'] } ${ styles['is-error'] }` }>缺刊<span className={ `${ styles['count'] }` }>{ parseInt(curItem.workFailedNums) }</span>个</div>
                  )
                }
              </div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ (curItem?.settleStatus === 2 ? curItem?.flowRealNum : curItem?.flowPlanNum) || 0 }{
                  (curItem?.settleStatus === 0 || curItem?.settleStatus === 1) && (
                    <div className={ `${ styles['text-maybe'] }` }>预估</div>
                  )
                }</div>
                <div className={ `${ styles['report-item-label'] }` }>触达人次</div>
              </div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ Math.floor(curItem.workSuccessRatio * 100) || 0 }%</div>
                <div className={ `${ styles['report-item-label'] }` }>上刊率</div>
              </div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ (curItem?.settleStatus === 2 ? curItem?.workRealNum : curItem?.workExpectNum) || 0 }{
                  (curItem?.settleStatus === 0 || curItem?.settleStatus === 1) && (
                    <div className={ `${ styles['text-maybe'] }` }>预估</div>
                  )
                }</div>
                <div className={ `${ styles['report-item-label'] }` }>刊播场次</div>
              </div>
              {
                isRemo && (
                  <div className={ `${ styles['report-item'] }` }>
                    <div  className={ `${ styles['report-item-value'] }` }>
                      {/*<!-- 2-已结算 -->*/}
                      {/*<!-- payableAmount 预估收益 paymentAmount 实际收益 -->*/}
                      {
                        (curItem?.settleStatus === 2) && (
                          <div className={ `${ styles['text-price'] }` }>
                            <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                            { util.tools.splitNum2String(curItem?.paymentAmount).int }
                            { util.tools.splitNum2String(curItem.paymentAmount).dec }
                          </div>
                        )
                      }
                      {
                        (curItem?.settleStatus !== 2) && (
                          <>
                          <div className={ `${ styles['text-price'] }` }>
                            <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                            { util.tools.splitNum2String(curItem.payableAmount).int }
                            { util.tools.splitNum2String(curItem.payableAmount).dec }
                          </div>
                          {
                            (curItem?.settleStatus === 0 || curItem?.settleStatus === 1) && <div className={ `${ styles['text-maybe'] }` }>预估</div>
                          }
                          </>
                        )
                      }
                    </div>
                    <div className={ `${ styles['report-item-label'] }` }>订单日收益</div>
                    {
                      (curItem.refundAmount > 0) && (
                        <div className={ `${ styles['report-item-tips'] } ${ styles['is-error'] }` }>已退<span className={ `${ styles['count'] }` }>{ util.tools.splitNum2String(curItem.refundAmount).int }{ util.tools.splitNum2String(curItem.refundAmount).dec }</span></div>
                      )
                    }
                  </div>
                )
              }
              
              {/* {
                curItem?.settleStatus !== 0 && (
                  <div className={ `${ styles['report-status'] }` }>
                    {
                      curItem?.settleStatus === 2 ? '已结算' : '未结算'
                    }
                  </div>
                )
              } */}
              
            </div>
          )
        }

        {
          curItem && !isShowMode && (
            <div className={ `${ styles['report-data'] }` } onClick={ goToDayDetail }>
              <div className={ `${ styles['report-date'] }` }>{ curItem?.clearDate }</div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ curItem?.pointNums || 0 }</div>
                <div className={ `${ styles['report-item-label'] }` }>投放点位（个）</div>
                {
                  (curItem.workFailedNums > 0) && (
                    <div className={ `${ styles['report-item-tips'] } ${ styles['is-error'] }` }>缺刊<span className={ `${ styles['count'] }` }>{ parseInt(curItem.workFailedNums) }</span>个</div>
                  )
                }
              </div>
              <div className={ `${ styles['report-item'] }` }>
                <div className={ `${ styles['report-item-value'] }` }>{ Math.floor(curItem.workSuccessRatio * 100) || 0 }%</div>
                <div className={ `${ styles['report-item-label'] }` }>上刊率</div>
              </div>

              {
                isRemo && (
                  <div className={ `${ styles['report-item'] }` }>
                    <div  className={ `${ styles['report-item-value'] }` }>
                      {/*<!-- 2-已结算 -->*/}
                      {/*<!-- payableAmount 预估收益 paymentAmount 实际收益 -->*/}
                      {
                        (curItem?.settleStatus === 2) && (
                          <div className={ `${ styles['text-price'] }` }>
                            <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                            { util.tools.splitNum2String(curItem?.paymentAmount).int }
                            { util.tools.splitNum2String(curItem.paymentAmount).dec }
                          </div>
                        )
                      }
                      {
                        (curItem?.settleStatus !== 2) && (
                          <>
                          <div className={ `${ styles['text-price'] }` }>
                            <div className={ `${ styles['text-price-unit'] }` }>￥</div>
                            { util.tools.splitNum2String(curItem.payableAmount).int }
                            { util.tools.splitNum2String(curItem.payableAmount).dec }
                          </div>
                          <div className={ `${ styles['text-maybe'] }` }>预估</div>
                          </>
                        )
                      }
                    </div>
                    <div className={ `${ styles['report-item-label'] }` }>订单日收益</div>
                    {
                      (curItem.refundAmount > 0) && (
                        <div className={ `${ styles['report-item-tips'] } ${ styles['is-error'] }` }>已退<span className={ `${ styles['count'] }` }>{ util.tools.splitNum2String(curItem.refundAmount).int }{ util.tools.splitNum2String(curItem.refundAmount).dec }</span></div>
                      )
                    }
                  </div>
                )
              }
              
              {/* {
                curItem?.settleStatus !== 0 && (
                  <div className={ `${ styles['report-status'] }` }>
                    {
                      curItem?.settleStatus === 2 ? '已结算' : '未结算'
                    }
                  </div>
                )
              } */}
              
            </div>
          )
        }

        {/* 图表 */}
        <div id="report-chart" className={ `${ styles['echarts-panel'] } ${ !curItem ? styles['hidden'] : '' }` }></div>
      </div>
      
      <ReceiptModal childRef={ receiptModalRef } isRemo={ isRemo } mallOrderMediaId={ mallOrderMediaId } />

      <ReportDayDetailModal childRef={ reportDayDetailModalRef } isShowMode={ isShowMode } isRemo={ isRemo }  />
    </div>
  )
}