import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import * as echarts from 'echarts'
import { Modal, Select } from 'antd'
import util from '../../../../libs/util'
import styles from './Sole.module.css'

interface Props {
  childRef: any,
}

export const SoleModal: React.FC<Props> = ({ childRef }) => {
	useImperativeHandle(childRef, () => ({
    showModal(resourceOrgEntityId, resourceEntityInfoId) {
      setResourceEntityInfoId(resourceEntityInfoId)
      getDetail(resourceOrgEntityId)
      showModal()
    }
  }))
  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const [resourceEntityInfoId, setResourceEntityInfoId] = useState()

  const year = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(year)
  const maxYears = 5
  const yearList = (() => {
    let list:any = []
    for(let i = year; i >= year - maxYears; i--) {
      list.push({
        label: `${ i }年`,
        value: i,
      })
    }
    return list
  })()
  const handleDateChange = (value) => {
  	setSelectedYear(value)
  }
  useEffect(() => {
    if (!resourceEntityInfoId) {
      return
    }
    getEntityPeriodsMap(resourceEntityInfoId)
  }, [selectedYear, resourceEntityInfoId])
  
  const [detail, setDetail] = useState<any>()
  const getDetail = async(resourceOrgEntityId) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/resource/getStateEntityById`,
      params: {
        resourceOrgEntityId,
      },
    })

    if (code === 1) {
      setDetail(data)
    }
  }

  const getEntityPeriodsMap = async(resourceEntityInfoId) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/sku_period/getEntityPeriodsMap`,
      params: {
        resourceEntityInfoId,
        firstDay: `${ selectedYear }-01-01`,
        lastDay: `${ selectedYear }-12-31`,
      },
    })

    if (code === 1) {
      console.log('getEntityPeriodsMap', data)
      initChart(data)
    }
  }

  const [curItem, setCurItem] = useState<any>()
  // 初始化图表
  const initChart = (list) => {
    // 基于准备好的dom，初始化echarts实例
    let chart = echarts.init(document.getElementById('sole-chart'))
    let option = {
      grid: {
        left: 60,
        top: 60,
        bottom: 40,
        right: 60,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: '{c0}%',
        backgroundColor: 'rgba(0,0,0,1)',
        textStyle: {
          color: '#fff',
        },
        axisPointer: {
          type: 'cross',
          label: {
            show: false,
          },
          lineStyle: {
            color: '#FE8135',
            type: 'dashed',
          },
          crossStyle: {
            color: '#FE8135',
            type: 'dashed',
          },
        },
      },
      xAxis: {
        type: 'category',
        name: '日期',
        data: [],
        boundaryGap: true,
        axisLabel: {
          color: '#333',
        },
        axisLine: {
          lineStyle: {
            color: '#ccc',
            type: 'solid',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: true,
        name: '映前10分钟',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        nameTextStyle: {
          align: 'right',
          color: '#333',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ccc',
            type: 'solid',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#EDEDED',
            type: 'solid',
          },
        },
        axisLabel: {
          color: '#333',
        },
      },
      series: [
        // {
        //   type: 'line',
        //   symbolSize: 2,
        //   symbol: 'circle', 
        //   data: [],
        //   lineStyle: {
        //     color: '#FE8135',
        //     width: 2,
        //   },
        //   itemStyle: {
        //     emphasis: {
        //       borderWidth: 1,
        //       borderColor: '#fff',
        //       color: '#FE8135',
        //       shadowColor: 'rgba(255, 139, 53, 0.3)',
        //       shadowBlur: 2,
        //     },
        //   },
        // },
      ],
      color: ['#FE8135'],
    }

    let xAxisList:any = []
    for (let i = 1; i <= 12; i += 1) {
      xAxisList.push(`${ i }月1日`)
    }

    option.xAxis.data = xAxisList
    
    // list
    let allList:any = []
    for(let day in list) {
      allList.push(...list[day])
    }
    let customerIds = Array.from(new Set(allList.map(i => i.customerId)))
    console.log('customerId', allList, customerIds)
    // const yData = list.slice(0, xAxisList.length)
    // option.series[0].data = yData.map(i => Math.round(i.workSuccessRatio * 100) || 0)

    
    // let yData:any = []
    // for (let i = 1; i <= 12; i += 1) {
    //   yData.push(`${ i }月1日`)
    // }
    // option.series[0].data = yData

    // const xIndex = xAxisList.length - 1
    // 绘制图表
    chart.setOption(option)

    // chart.on('highlight', (params:any) => {
    //   const dataIndex = params.batch[0].dataIndex
    //   setCurItem(yData[dataIndex])
    // })
    // chart.dispatchAction({
    //   type: 'showTip',
    //   seriesIndex: 0,
    //   dataIndex: xIndex,
    // })

    // setCurItem(hasData ? yData[xIndex] : null)
    console.log('xAxisList', xAxisList, )
  }
  
  return (
  	<Modal 
	    width={ 1140 }
	    title="影院库存分布情况"
	    visible={ isModalVisible }
	    footer={ null }
	    onCancel={ onModalCancel }
	    >
      <div className={ styles['modal-cont'] }>
        <div className={ styles['entity-name'] }>{ detail?.entityName }</div>

        <div className={ `${ styles['entity-top'] }` }>
        	<div className={ `${ styles['stoke-top-l'] }` }>
            <Select 
              className={ styles['stoke-select'] }
              placeholder="请选择"
              size='large'
              value={ selectedYear }
              options={ yearList }
              onChange={ handleDateChange }>
            </Select>
          </div>
          <div className={ `${ styles['entity-top-r'] }` }>
            <div className={ `${ styles['entity-tips-item'] } ` }>
              <span className={ `${ styles['entity-tips-icon'] } ${ styles['is-remo'] }` }></span>
              热幕
            </div>
            <div className={ `${ styles['entity-tips-item'] } ` }>
              <span className={ `${ styles['entity-tips-icon'] } ${ styles['is-remo'] }` }></span>
              影院自营
            </div>
            <div className={ `${ styles['entity-tips-item'] } ` }>
              <span className={ `${ styles['entity-tips-icon'] } ${ styles['is-remo'] }` }></span>
              独家 - 分众
            </div>
            <div className={ `${ styles['entity-tips-item'] } ` }>
              <span className={ `${ styles['entity-tips-icon'] } ${ styles['is-remo'] }` }></span>
              独家 - 万达
            </div>
          </div>
        </div>

        {/* 图表 */}
        <div id="sole-chart" className={ `${ styles['echarts-panel'] }` }></div>
      </div>
	  </Modal>
  )
}