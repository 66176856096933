import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Pagination, Table, Select } from 'antd'
import styles from './ReceiptModal.module.css'
import util from '../../../../../libs/util'
import { PreviewImg } from '../../../../../components'

interface Props {
  childRef: any,
  mallOrderMediaId: any,
  isRemo: boolean,
}
const dateFormat = 'YYYY-MM-DD'
const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

export const AdExecReceiptModal: React.FC<Props> = ({ childRef, mallOrderMediaId, isRemo }) => {
  useImperativeHandle(childRef, () => ({
    onToggle() {
      showModal()
    }
  }))
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
    getList()
  }

  // 
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [list, setList] = useState<any[]>([])
  const [sourceList, setSourceList] = useState<any[]>([])
  const getList = async () => {
    setIsSubmiting(true)
    
    const { data = [], code } = await util.request({
      method: 'GET',
      url: `/resource/delivery/cinema_v3/getMediaOrderReceipts`,
      params: {
        mallOrderMediaId,
      },
    })
    if (code === 1) {
      let result:any = []
      data.forEach(rec => {
        const index = result.findIndex(i => i.resourceEntityInfoId === rec.resourceEntityInfoId)
        if (index !== -1) {
          result[index].list.push(rec)
        } else {
          result.push({
            entityName: rec.entityName,
            resourceEntityInfoId: rec.resourceEntityInfoId,
            list: [rec],
          })
        }
      })
      setSourceList(result)
      setList(result)
    }
  }

  const receiptCont = (list) => {
    const today = util.tools.formatDate(new Date())
    const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

    return (
      <div className={ `${ styles['receipt-cont-list'] }` }>
        {
          list.map((item, index) => {
            return (
              <div className={ `${ styles['receipt-tips'] }` } key={ index } onClick={ () => { previewImage(`${ baseURL }${ item.contentUrl }`, item.contentType === 'VIDEO', item) } }>
                {/* 图片 */}
                <img src={ `${ baseURL }${ item.thumbnailUrl ? item.thumbnailUrl[0] : item.contentUrl }?imageMogr2/thumbnail/x112` } className={ `${ styles['receipt-image'] }` } />
                {
                  (item.contentType === 'VIDEO') && (
                    <>
                    {
                      (item.contentDuration > 0) && (
                        <div className={ `${ styles['mask'] }` }>
                          <div className={ `imediafont i-play ${ styles['icon-play'] }` }></div>
                          <div>{ util.tools.fixedTime(item.contentDuration / 1000) }</div>
                        </div>
                      )
                    }
                    {
                      (item.contentDuration <= 0) && (
                        <div className={ `${ styles['mask'] }` }>
                          视频处理中
                        </div>
                      )
                    }
                    </>
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  const [isVideo, setIsVideo] = useState(false)
  const [entityInfo, setEntityInfo] = useState()
  const previewImage = (url, isVideo, entityInfo) => {
    previewImgRef.current.onToggle(url)
    setIsVideo(isVideo)
    setEntityInfo(entityInfo)
  }

  const columns = [
    {
      title: '场所名称',
      dataIndex: 'entityName',
      key: 'entityName',
      render(text, record, index) {
        return (
          <div className={ styles['entity-name'] }>{ text }</div>
        )
      },
    },
    {
      title: '回执',
      dataIndex: 'pointGrade',
      key: 'pointGrade',
      render(text, entity, index) {
        return (
          <div className={ `${ styles['receipt-list'] }`}>
            {
              entity.list.map((item, receiptIndex) => {
                return (
                  <div
                    className={ `${ styles['receipt-item'] }`}
                    key={ receiptIndex }
                    >
                    <div className={ `${ styles['receipt-tit'] }`}>
                      <span className={ `${ styles['date'] }`}>{ item.receiptDate }</span>
                      {
                        (item.isFirstDay || item.isLastDay) && (
                          <div className={ `${ styles['receipt-label'] }`}>
                            {
                              (item.isFirstDay) && '上'
                            }
                            {
                              (item.isLastDay) && '下'
                            }刊日</div>
                        )
                      }
                      <div className={ `${ styles['count'] } `}>{ item.contents.length }个</div>
                    </div>
                    {
                      ((item.isFirstDay || item.isLastDay) && !item.contents.length) && <div className={ `${ styles['receipt-no-record'] } `}>超时未上传</div>
                    }
                    
                    {
                      receiptCont(item.contents)
                    }
                  </div>
                )
              })
            }
          </div>
        )
      },
    },
  ]
  
  const previewImgRef: any = useRef()

  const [entityName, setEntityName] = useState('')
  // 选择城市
  const onEntityNameChange = (value) => {
    setEntityName(value)
    const list = value ? sourceList.filter(i => i.resourceEntityInfoId === value) : sourceList
    setList(list)
  }

  return (
    <Modal 
      title={ isRemo ? '刊播回执' : '执行回执' }
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) } }
      >
        <div className={ `${ styles['filter-wrap'] }` }>
          <div className={ `${ styles['form-item'] }` }>
            <div className={ `${ styles['form-item-label'] }` }>场所名称：</div>
            <Select 
              onChange={ onEntityNameChange }
              placeholder="全部"
              allowClear 
              style={{ width: 200 }}>
              {
                sourceList.map((record, index) => {
                  return (
                    <Select.Option key={ record.resourceEntityInfoId } value={ record.resourceEntityInfoId }>{ record.entityName }</Select.Option>
                  )
                })
              }
            </Select>
          </div>
        </div>
      
        <Table
          rowKey="receiptId"
          columns={ columns }
          rowClassName={ () => { return styles['table-tr'] } }
          dataSource={ list }
          pagination={ false }
        ></Table>

        <PreviewImg childRef={ previewImgRef } isVideo={ isVideo } entityInfo={ entityInfo } />
    </Modal>
  )
}