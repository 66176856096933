import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, message, Spin, Upload, Progress, Input } from 'antd'

import { getContentAdByMd5, createOriginal, getContentAdById, getStorageSpace } from '../../../../api'
import util from '../../../../libs/util'
import { uploadMedia } from '../../../../libs/util.upload'
import SparkMD5 from 'spark-md5'
import styles from './AddVideoModal.module.css'

import { ExclamationCircleFilled, RightOutlined } from '@ant-design/icons'

import { ScreenEffectModal } from '../screenEffectModal/index'

import MediaInfoFactory from 'mediainfo.js'
interface Props {
  childRef: any,
  callBack?: any,
}

const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

const videoInfo = {
  videMaxDuration: 60,
  videoMaxSize: 1000, // 1000M
  minWidth: 1920,
  minHeight: 820,
  bestWidth: 2048,
  bestHeight: 858,
  bestRatio: 2.39,
  minRatio: 2.35,
  videoType: ['mp4', 'mkv', 'avi', 'mov'],
}

export const CinemaScreenTypeItems = [
  {
    itemKey: 'SCOPE',
    itemValue: '宽幅影幕',
    tips: '2.39:1',
    text: '',
  },
  {
    itemKey: 'FLAT',
    itemValue: '遮幅银幕',
    tips: '1.85:1',
    text: '',
  },
]

export const AddVideoModal: React.FC<Props> = ({ childRef, callBack }) => {
  useImperativeHandle(childRef, () => ({
    onToggle() {
      showUploadModal()
      getStorageSpaceFn()
    }
  }))

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)

  // 获取存储空间
  const [spaceTotal, setSpaceTotal] = useState(0)
  const [usedContentLength, setUsedContentLength] = useState(0)
  const getStorageSpaceFn = async () => {
    const result = await getStorageSpace({
      roleType: 'MEDIA',
    })
    const data = result.data
    setSpaceTotal(data.spaceTotal)
    setUsedContentLength(data.usedContentLength)
  }

  const [contentAdId, setContentAdId] = useState('')
  useEffect(() => {
    if (!contentAdId) {
      return
    }
    const timer = setInterval(() => {
      if (!contentAdId) {
        clearInterval(timer)
        return
      }
      checkId(contentAdId)
     }, 3000)

    return () => clearInterval(timer)
  }, [contentAdId])

  // 获取媒体源文件的创建记录
  const checkId = async(contentAdId) => {
    setIsSubmiting(true)
    const result:any = await getContentAdById({
      contentAdId,
    })

    if (result.code === 1) {
      // 处理中
      if (result.data.contentAdId === 'PROCESSING') {
        return
      }

      if (result.data.contentAdId !== 'NOT_EXIST') {
        setContentAdId('')
        message.success('操作成功！')
        // 关闭弹窗
        setIsUploadModalVisible(false)
        setIsSubmiting(false)
        callBack && setTimeout(() => {
          callBack()
        }, 1000)
      }
    }
    setIsSubmiting(false)
  }

  const [contentNote, setContentNote] = useState('')
  const onVideoTitleInputChange = (e) => {
    setContentNote(e.target.value)
  }
  const [percent, setPercent] = useState(0)

  // 0 未上传 1 上传中 2 上传完成
  const [uploadStatus, setUploadStatus] = useState(0)
  const [isUploadFailed, setIsUploadFailed] = useState(false)
  const [isCheckedFailed, setIsCheckedFailed] = useState(false)
  const [uploadErrorTips, setUploadErrorTips] = useState('')
  const [originalUrl, setOriginalUrl] = useState('')
  // HD_2K(1920, 1080, 30.0f),  SCOPE_2K(2048,858, 24.0f),  FLAT_2K(1998, 1080, 24.0f)
  const [targetResolution, setTargetResolution] = useState('')
  const [cinemaScreenTypeItems, setCinemaScreenTypeItems] = useState(JSON.parse(JSON.stringify(CinemaScreenTypeItems)))
  const [videoRatio, setVideoRatio] = useState(1)

  const uploadProps = {
    maxCount: 1,
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async(file, fileList) => {
      // 未上传
      setUploadStatus(0)
      setIsUploadFailed(false)
      setIsCheckedFailed(false)
      setUploadErrorTips('')
      setOriginalUrl('')

      const isVideoType = file.type.includes('video/')
      // 文件类型
      if (!isVideoType) {
        setIsCheckedFailed(true)
        setUploadErrorTips(`${file.name}不是视频格式文件`)
        return false
      }

      const { minWidth, minHeight, bestRatio, minRatio, bestWidth, bestHeight, videoMaxSize } = videoInfo
      // 读取文件的宽高和大小
      const getMetadata = (mediainfo, file) => {
        return new Promise((resolve) => {
          const readChunk = (chunkSize, offset) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = (event:any) => {
                if (event.target.error) {
                  reject(event.target.error)
                }
                resolve(new Uint8Array(event.target.result as ArrayBuffer))
              }
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
            })
          }
          const getSize = () => file.size
          const p = mediainfo.analyzeData(getSize, readChunk)
          p.then((result) => {
            resolve(result)
          }).catch((res) =>
            resolve("Can't get media information")
          )
        })
      }
      const getMediaInfo = () => {
        return new Promise((resolve) => {
          MediaInfoFactory({ format: 'JSON' }, async(mediainfo) => {
            const info:any = await getMetadata(mediainfo, file)
            const result = JSON.parse(info)
            resolve({
              videoWidth: result.media.track[1].Width,
              videoHeight: result.media.track[1].Height,
              fileSize: result.media.track[0].FileSize,
            })
          })
        })
      }

      const result:any = await getMediaInfo()
      const { videoWidth, videoHeight, fileSize } = result
      console.log('videoInfo', fileSize, videoWidth, videoHeight)

      // 视频超过大小
      if (parseInt(fileSize) > videoMaxSize * 1024 * 1024) {
        setIsCheckedFailed(true)
        // 视频超过大小
        setUploadErrorTips(`上传的素材文件大小不超过${ videoMaxSize }M`)
        return false
      }

      // 低于最小高度
      // if (videoHeight < bestHeight) {
      //   setIsCheckedFailed(true)
      //   // 视频超过大小
      //   setUploadErrorTips(`视频的最小高度为${ bestHeight }`)
      //   return false
      // }

      // 取消上传视频的宽高比例限制
      // if (parseInt(videoWidth) < minWidth) {
      //   setIsCheckedFailed(true)
      //   // 视频分辨率太低
      //   setUploadErrorTips(`视频分辨率太低，最低要求为${ minWidth }*${ minHeight }像素`)
      //   return false
      // }

      // if (Math.ceil((parseInt(videoWidth) / parseInt(videoHeight)) * 100) / 100 < minRatio) {
      //   setIsCheckedFailed(true)
      //   // 视频尺寸比例不符合要求
      //   setUploadErrorTips(`视频尺寸比例不符。宽高比至少为${ minRatio }:1，即宽至少是高的${ minRatio }倍`)
      //   return false
      // }

      const fileReader = new FileReader()
      const fileReaderLoaded = () => {
        return new Promise((resolve, reject) => {
          fileReader.onload = (e:any) => {
            let spark = new SparkMD5.ArrayBuffer()
            spark.append(e.target.result)
            const result = spark.end(false)

            if (result) {
              resolve(result)
            } else {
              reject('读取不成功！')
            }
          }
        })
      }
      fileReader.readAsArrayBuffer(file)
      
      const hexHash = await fileReaderLoaded()

      const hasThisFile = await getContentAdByMd5({ md5: hexHash, roleType: 'MEDIA' })
      // 重复文件
      if (hasThisFile) {
        setIsCheckedFailed(true)
        setUploadErrorTips('已存在相同视频，请勿重复上传')
        return false
      }

      // 超过可用空间
      const leftContentLen = spaceTotal - usedContentLength
      const isLeftContentLenValid = file.size <= leftContentLen
      if (!isLeftContentLenValid) {
        setIsCheckedFailed(true)
        setUploadErrorTips(`上传失败！文件大小超出可用空间容量(${util.tools.convertStorage(leftContentLen)}M)。`)
        return false
      }

      setIsCheckedFailed(false)
      setIsUploadFailed(false)
      setUploadErrorTips('')

      const videoRatio = parseFloat((Math.ceil((videoWidth / videoHeight) * 100) / 100).toFixed(2))
      // 判断宽度是不是 >=1998，如果是判断一下原始媒体文件的宽高比，看接近于 SCOPE_2K(2048,858, 24.0f),  FLAT_2K(1998, 1080, 24.0f)，然后选择一个
      if (videoWidth >= 1998) {
        // 看更接近于哪个
        setTargetResolution(Math.abs((2048 / 858) - videoRatio) < Math.abs((1998 / 1080) - videoRatio) ? 'SCOPE_2K' : 'FLAT_2K')
      } else {
      // 如果宽度<1998 ，则HD_2K(1920, 1080, 30.0f)
        setTargetResolution('HD_2K')
      }
      
      cinemaScreenTypeItems.forEach(item => {
        const ratio = parseFloat(item.tips.split(':')[0])
        const type = item.itemKey
        if (videoRatio > ratio) {
          item.text = '上下黑边'
        } else if (videoRatio == ratio) {
          item.text = '全屏铺满'
        } else {
          item.text = '左右黑边'
        }
      })
      setCinemaScreenTypeItems(cinemaScreenTypeItems)
      setVideoRatio(videoRatio)
      console.log(file,fileList)
      return true
    },
    customRequest: async(res) => {
      // 上传中
      setUploadStatus(1)
      const type = 'video'
      try {
        const result = await uploadMedia({
          file: res.file,
          type,
          callBack: (percent) => {
            setPercent(percent)
          }
        })
        // 上传完成
        setUploadStatus(2)
        setOriginalUrl(result.location)
      } catch({ message: msg  }) {
        // message.error(e.message)
        // 未上传
        setUploadStatus(0)
        setIsUploadFailed(true)
        setUploadErrorTips(msg || '上传失败，请重试！')
      }
    },
  }
  const onUploadConfirm = async () => {
    if (!contentNote || !originalUrl) {
      message.warn('请先上传视频文件并填写名件名！')
      return
    }
    if (isSubmiting) {
      return
    }
    setIsSubmiting(true)
    const result = await createOriginal({
      contentType: 'VIDEO',
      contentNote,
      originalUrl,
      targetResolution,
      roleType: 'MEDIA',
    })

    if (result.code === 1) {
      console.log('contentAdId', result.data)
      setIsSubmiting(true)
      setContentAdId(result.data.contentAdId)
    } else {
      setIsSubmiting(false)
      message.error(result.message || '操作失败，请重试！')
    }
  }

  const initUploadModal = () => {
    setUploadStatus(0)
    setIsCheckedFailed(false)
    setIsUploadFailed(false)
    setUploadErrorTips('')

    setContentNote('')
    setOriginalUrl('')
  }

  const showUploadModal = () => {
    // 重置一些数据
    initUploadModal()
    setIsSubmiting(false)

    setIsUploadModalVisible(true)
  }

  const screenEffectModalRef: any = useRef()
  const onPreviewEffect = () => {
    screenEffectModalRef.current.onToggle()
  }

  {/*添加视频弹窗*/}
  return (
    <>
    <Modal 
      title="" 
      width={ 1104 }
      visible={ isUploadModalVisible } 
      footer={ null }
      bodyStyle={{
        padding: 0,
      }}
      closeIcon=""
      onCancel={() => { setIsUploadModalVisible(false) }}
      >
      <div className={ styles['upload-modal-tit'] }>上传视频</div>

      <Spin className={ styles['upload-modal-spin'] } spinning={ isSubmiting } tip={ '操作中,请稍候...' }>
        <div className={ styles['upload-modal-content'] }>
          <div className={ styles['upload-modal-t'] }>
            <div className={ styles['upload-modal-t-l'] }>
              {
                (uploadStatus === 0  || uploadStatus === 2) && 
                <div className={ styles['upload-cont-wrap'] }>
                  <Upload {...uploadProps}>
                    {/*未上传*/}
                    {
                      uploadStatus === 0 && 
                      <div className={ styles['upload-modal-btn'] }>
                        <div className={ styles['upload-icon'] }></div>
                        <div>点击上传</div>
                      </div>
                    }
                    {/*上传完成*/}
                    {
                      uploadStatus === 2 && 
                      <>
                        <video className={ styles['upload-modal-video'] } src={ baseURL + originalUrl } />
                        <div className={ styles['upload-modal-edit'] }><span className={ `${styles['imediafont']} imediafont i-edit` }></span>重新上传</div>
                      </>
                    }
                  </Upload>
                  {/*　检查的提示　*/}
                  {
                    uploadStatus === 0 && isCheckedFailed && 
                    <div className={ styles['upload-error-tips'] }><ExclamationCircleFilled className={ styles['upload-error-tips-icon'] } />{ uploadErrorTips }</div>
                  }
                  {/*　上传出错的提示 */}
                  {
                    uploadStatus === 0 && isUploadFailed && 
                    <div className={ `${ styles['upload-error-tips'] } ${ styles['is-error'] }`}><ExclamationCircleFilled className={ `${ styles['upload-error-tips-icon'] } ${ styles['is-error'] }`} />{ uploadErrorTips }</div>
                  }
                </div>
              }
              {/*上传中*/}
              {
                uploadStatus === 1 && 
                <>
                  <div className={ styles['upload-ing-mask'] }></div>
                  <div className={ styles['upload-ing-cont'] }>
                    <div className={ `${styles['imediafont-video']} imediafont i-video` }></div>
                    <div className={ styles['upload-ing-tips'] }>视频上传中...</div>
                  </div>
                  
                  <Progress className={ styles['upload-progress'] } strokeColor="#23C89B" trailColor="#000" strokeLinecap="square" strokeWidth={ 4 } size="small" percent={ percent } showInfo={ false } />
                </>
              }

            </div>
            {/*上传完成*/}
            {
              uploadStatus === 2 && 
              <div className={ styles['upload-modal-t-r'] }>
                <div className={ styles['preview-tit'] }>您的广告（比例为{ videoRatio }:1）在不同银幕画幅上的效果预览：</div>
                <div className={ styles['preview-list'] }>
                  {
                    cinemaScreenTypeItems.map((item, index) => {
                      return (
                        <div className={ styles['preview-item'] } key={ index }>
                          <div className={ styles['preview-item-top'] }>
                            <div className={ styles['preview-item-label'] }>{ item.itemValue }{ item.tips }</div>
                            <video className={ styles['preview-item-image'] } src={ baseURL + originalUrl } />
                          </div>
                          <div className={ styles['preview-item-text'] }>{ item.text }</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }
          </div>
          <div className={ styles['upload-modal-tips'] }>
            <div className={ styles['upload-modal-tips-tit'] }>视频文件要求：</div>
            <div className={ styles['upload-modal-tips-item'] }>1. 为让广告在各类画幅的银幕上呈现最佳效果，建议上传{ videoInfo.bestRatio }:1（最低像素{ videoInfo.bestWidth }*{ videoInfo.bestHeight }）的视频。<br/><span className={ styles['upload-modal-link'] } onClick={ onPreviewEffect }>查看</span>4种常见视频比例的银幕呈现效果<RightOutlined /></div>
            <div className={ styles['upload-modal-tips-item'] }>2. 仅支持{
                videoInfo.videoType.map((record, index) => {
                  return (<span key={ index }>{ record }{ index < videoInfo.videoType.length - 1 && '、' }</span>)
                })}格式</div>
            <div className={ styles['upload-modal-tips-item'] }>3. 上传的素材文件大小不超过{ videoInfo.videoMaxSize }M，不支持断点续传</div>
            <div className={ styles['upload-modal-tips-item'] }>4. 视频时长限{ videoInfo.videMaxDuration }秒以内</div>
          </div>

          <div className={ styles['upload-modal-form'] }>
            <div className={ styles['upload-modal-form-tit'] }>标题</div>
            <Input placeholder="请输入6-18个字符" value={ contentNote } maxLength={ 18 } className={ styles['upload-modal-form-input'] } onChange={ onVideoTitleInputChange } />
          </div>
        </div>

        <div className={ styles['upload-modal-footer'] }>
          <Button size="large" type="primary" className={ styles['btn-confirm'] } disabled={ !contentNote || !originalUrl || isSubmiting } onClick={ onUploadConfirm }>完成</Button>
        </div>
      </Spin>
    </Modal>

    <ScreenEffectModal childRef={ screenEffectModalRef }  />
    </>
  )
}