import moment from 'moment'
import events from 'events'
import request from './util.request'
import storage from './util.storage'
import { cookies } from './util.cookies'
import tools from './util.tools'
// import Cookies from 'js-cookie'

const util = {
  moment,
  bus: new events(),
  request,
  storage,
  tools,
  Cookies: cookies,
}

export default util