import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, Select, Input, Carousel, message, Spin, Progress } from 'antd'
import { ExclamationCircleOutlined, VideoCameraOutlined, PlayCircleOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { makeNewVideo, getContentAdById } from '../../../../api'
import util from '../../../../libs/util'
import { uploadMedia } from '../../../../libs/util.upload'

import styles from './ComposeModal.module.css'

import { Upload, } from 'antd'

const { confirm } = Modal;

interface ImgType {
  contentType: string,
  contentUrl: string,
  contentDuration: string | number,
}
const defaultImgType: ImgType = {
  contentType: 'IMAGE',
	contentUrl: '',
	contentDuration: '',
}

interface Props {
  childRef: any,
  callBack?: any,
}

const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

export const ComposeModal: React.FC<Props> = ({ childRef, callBack }) => {
	useImperativeHandle(childRef, () => ({
    onToggle() {
      showSelectDurationModal()
    }
  }))

	const [contentAdId, setContentAdId] = useState('')
  useEffect(() => {
  	if (!contentAdId) {
  		return
  	}
    const timer = setInterval(() => {
      if (!contentAdId) {
        clearInterval(timer)
        return
      }
      checkId(contentAdId)
     }, 3000)

    return () => clearInterval(timer)
  }, [contentAdId])

  // 图片合成视频
  const imageInfo = {
    imgRatio: 2.39,
    minImgWidth: 2048,
    minImgHeight: 858,
    imgType: ['jpg', 'png'],
    imageCount: 3,
    // 图片默认的时长为5秒
    imgDuration: 5 * 1000,
  }
  const durationList = [
    {
      id: 15,
      value: 15,
      text: '15秒',
    },
    {
      id: 30,
      value: 30,
      text: '30秒',
    },
    {
      id: 60,
      value: 60,
      text: '60秒',
    },
  ]
  const [composeStep, setComposeStep] = useState(1)
  const [isSelectDurationModalVisible, setIsSelectDurationModalVisible] = useState(false)
  const [curDurationIndex, setCurDurationIndex] = useState(0)
  // HD_2K(1920, 1080, 30.0f),  SCOPE_2K(2048,858, 24.0f),  FLAT_2K(1998, 1080, 24.0f)
  const [targetResolution, setTargetResolution] = useState('')

  const [contentNote, setContentNote] = useState('')
  const onVideoTitleInputChange = (e) => {
    setContentNote(e.target.value)
  }

  const showSelectDurationModal = () => {
    // 重置一些数据 TODO
    setComposeStep(1)
    setCurDurationIndex(0)
    setIsSubmiting(false)
    setContentList([])
    setContentNote('')

    setIsSelectDurationModalVisible(true)
  }
  const onNext = () => {
    setComposeStep(2)
  }
  const selectDuration = (value, index) => {
    setCurDurationIndex(index)
  }
  
  // 0 未上传 1 上传中 2 上传完成
  const [uploadStatus, setUploadStatus] = useState(0)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const uploadImageProps = {
    maxCount: 1,
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log('no uploading', info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async(file, fileList) => {
      setIsSubmiting(true)

      const { type } = file
      const isImgTypeChecked = type.includes('image/jpeg') || type.includes('image/jpg') || type.includes('image/png')
      // 文件类型
      if (!isImgTypeChecked) {
        Modal.warning({
          title: '文件类型不支持',
          content: `仅支持${ imageInfo.imgType.join('、') }格式`,
        })
        setIsSubmiting(false)
        return false
      }

      // 判断图片的尺寸
      const imageLoaded:any = () => {
        return new Promise((resolve, reject) => {
          const image = document.createElement('img')
          image.src = URL.createObjectURL(file)
          image.onload = (e:any) => {
            const width = e.target.width
            const height = e.target.height
            console.log('imageLoaded', width, height)
            if (width) {
              resolve({
                width: width,
                height: height,
              })
            } else {
              reject({
                width: 0,
                height: 0,
              })
            }
          }
        })
      }
      const { width, height } = await imageLoaded()
      const { minImgWidth, minImgHeight, imgRatio } = imageInfo

      // if (height < minImgHeight) {
      // // 图片分辨率高度过小
      //   console.log(`图片分辨率高度不小于${ minImgHeight }像素`)
      //   let title = `上传失败，图片分辨率太低`
      //   let content = `图片分辨率高度不小于${ minImgHeight }像素`
      //   confirm({
      //     title,
      //     icon: <ExclamationCircleOutlined />,
      //     content,
      //     okText: '重新选择',
      //     onOk() {
      //       const btn = document.querySelector('#upload-btn') as HTMLElement || null
      //       btn?.click()
      //       setIsSubmiting(false)
      //     },
      //     onCancel() {
      //       setIsSubmiting(false)
      //       console.log('Cancel');
      //     },
      //   })
      //   setIsSubmiting(false)
      //   return false
      // }

      // 判断宽度是不是 >=1998，如果是判断一下原始媒体文件的宽高比，看接近于 SCOPE_2K(2048,858, 24.0f),  FLAT_2K(1998, 1080, 24.0f)，然后选择一个
      if (width >= 1998) {
        const ratio = width / height
        // 看更接近于哪个
        setTargetResolution(Math.abs((2048 / 858) - ratio) < Math.abs((1998 / 1080) - ratio) ? 'SCOPE_2K' : 'FLAT_2K')
      } else {
      // 如果宽度<1998 ，则HD_2K(1920, 1080, 30.0f)
        setTargetResolution('HD_2K')
      }

      console.log('beforeUpload', file)
      return true
    },
    customRequest: async({ file }) => {
      // 上传中
      setUploadStatus(1)
      const type = 'image'
      try {
        const { location } = await uploadMedia({
          file: file,
          type,
          callBack: (percent) => {
            // 要不要进度条？
          }
        })
        // 上传完成
        setUploadStatus(2)
        addToContent(location)
        console.log('location', location)
      } catch({ message }) {
        // message.error(e.message)
        // 未上传
        setUploadStatus(0)
        Modal.error({
          title: '上传失败',
          content: message || '上传失败，请重试！',
        })
        setIsSubmiting(false)
      }
    },
  }

  const [contentList, setContentList] = useState<any[]>([])
  // 添加至内容列表
  const addToContent = (location) => {
    let list = JSON.parse(JSON.stringify(contentList))
    list.push(JSON.parse(JSON.stringify(defaultImgType)))
    list[list.length - 1].contentUrl = location
    const contentDuration = Math.floor(durationList[curDurationIndex].value / list.length * 100) / 100 * 1000
    list.forEach(i => {
      i.contentDuration = contentDuration
    })
    setIsSubmiting(false)
    setContentList(list)
  }
  // 删除某一个
  const deleteContent = (index) => {
    let list = JSON.parse(JSON.stringify(contentList))
    list.splice(index, 1)
    const contentDuration = Math.floor(durationList[curDurationIndex].value / list.length * 100) / 100 * 1000
    list.forEach(i => {
      i.contentDuration = contentDuration
    })
    setContentList(list)
  }

  const videPlayRef: any = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    const timer = setInterval(() => {
      if (!isPlaying) {
      	setPercent(0)
        clearInterval(timer)
        return
      }
      videPlayRef.current.next()
     }, durationList[curDurationIndex].value / contentList.length * 1000)

    return () => clearInterval(timer)
  }, [isPlaying])

  const toggleCarousel = () => {
  	if (!videPlayRef || !contentList.length) {
  		return
  	}
  	setIsPlaying(!isPlaying)
  }

  const [percent, setPercent] = useState(0)
	useEffect(() => {
		if (!isPlaying) {
			return
		}
    const timer = setInterval(() => {
      if (percent >= 100) {
        clearInterval(timer)
        setIsPlaying(false)
        setPercent(0)
        return
      }
      // 视频总时长
      const step = Math.abs(100 / (durationList[curDurationIndex].value * 1000 / 1000))
      console.log('percent', percent, step)
      setPercent(percent + step)
     }, 1000)

    return () => clearInterval(timer)
  }, [percent, isPlaying])

  // 合成视频
  const onCompose = async() => {
  	if (isSubmiting) {
  		return
  	}
  	setIsSubmiting(true)
  	const result:any = await makeNewVideo({
      contentNote,
      roleType: 'MEDIA',
      bgAudioUrl: null,
      fadeEffect: null,
      targetResolution,
      contents: contentList,
  	})

    if (result.code === 1) {
    	setContentAdId(result.data.contentAdId)
    }
  }
  // 获取媒体源文件的创建记录
  const checkId = async(contentAdId) => {
    const result:any = await getContentAdById({
      contentAdId,
    })

    if (result.code === 1) {
      // 处理中
      if (result.data.contentAdId === 'PROCESSING') {
        return
      }

      if (result.data.contentAdId !== 'NOT_EXIST') {
      	setContentAdId('')
        message.success('视频合成成功')
        setIsSelectDurationModalVisible(false)
        callBack && setTimeout(() => {
          callBack()
        }, 1000)
      }
    }
    setIsSubmiting(false)
  }

  // 取消合成
  const onComposeCancel = () => {
  	confirm({
	    title: '是否确认放弃合成视频',
	    icon: <ExclamationCircleOutlined />,
	    content: ``,
	    okText: '确认',
	    onOk() {
	      setIsSelectDurationModalVisible(false)
	    },
	    onCancel() {
	    },
	  })
  }

  const beforeCrop = async(file, fileList) => {
  	setIsSubmiting(true)
  	// 判断图片的尺寸
    const imageLoaded:any = () => {
      return new Promise((resolve, reject) => {
        const image = document.createElement('img')
        image.src = URL.createObjectURL(file)
        image.onload = (e:any) => {
          const width = e.target.width
          const height = e.target.height
          console.log('imageLoaded', width, height)
          if (width) {
            resolve({
              width: width,
              height: height,
            })
          } else {
            reject({
              width: 0,
              height: 0,
            })
          }
        }
      })
    }
    const { width, height } = await imageLoaded()

  	const { type } = file
  	const isImgTypeChecked = type.includes('image/jpeg') || type.includes('image/jpg') || type.includes('image/png')
    // 文件类型
    if (!isImgTypeChecked) {
    	Modal.warning({
		    title: '文件类型不支持',
		    content: `仅支持${ imageInfo.imgType.join('、') }格式`,
		  })
		  setIsSubmiting(false)
      return false
    }
    const { minImgWidth, minImgHeight, imgRatio } = imageInfo

    const isGtRatio = Math.ceil((width / height) * 100) / 100 > imgRatio
    const isLtRatio = Math.ceil((width / height) * 100) / 100 < imgRatio
    // 比例不对的大尺寸图片
    if (width > minImgWidth && isGtRatio) {
      // 去到图片裁剪页
      console.log('去到图片裁剪页', file)
      setIsSubmiting(true)
      return true
    } else if (width < minImgWidth || height < minImgHeight || isLtRatio) {
    // 尺寸过小 || 比例太小
      console.log(`图片分辨率最低要求为${ minImgWidth }*${ minImgHeight }像素`)
      let title = `上传失败，图片分辨率太低`
      let content = `图片分辨率最低要求为${ minImgWidth }*${ minImgHeight }像素`
      if (isLtRatio) {
        title = `上传失败，图片宽高比不符合要求`
        content = `图片宽高比为${ imgRatio }:1`
      }
      confirm({
		    title,
		    icon: <ExclamationCircleOutlined />,
		    content,
		    okText: '重新选择',
		    onOk() {
		      const btn = document.querySelector('#upload-btn') as HTMLElement || null
		      btn?.click()
		      setIsSubmiting(false)
		    },
		    onCancel() {
		    	setIsSubmiting(false)
		      console.log('Cancel');
		    },
		  })
		  setIsSubmiting(false)
      return false
    }
    console.log('beforeCrop', file)
    handleUpload(file)
    return false
  }
  const handleUpload = async(file) => {
  	// 上传中
    setUploadStatus(1)
    const type = 'image'
    try {
      const { location } = await uploadMedia({
        file: file,
        type,
        callBack: (percent) => {
        	// 要不要进度条？
        }
      })
      // 上传完成
      setUploadStatus(2)
    	addToContent(location)
    	console.log('location', location)
    } catch({ message }) {
      // message.error(e.message)
      // 未上传
      setUploadStatus(0)
      Modal.error({
		    title: '上传失败',
		    content: message || '上传失败，请重试！',
		  })
      setIsSubmiting(false)
    }
  }
  const cropperProps = {
  	width: imageInfo.minImgWidth,
		height: imageInfo.minImgHeight,
  }

  {/*图片合成视频*/}
  return (
	  <Modal 
	    title="" 
	    maskClosable={ false }
	    width={ composeStep === 1 ? 696 : 1104 }
	    visible={ isSelectDurationModalVisible } 
	    footer={ null }
	    bodyStyle={{
	      padding: 0,
	    }}
	    closeIcon=""
	    onCancel={() => { setIsSelectDurationModalVisible(false) }}
	    >
	    <div className={ styles['upload-modal-tit'] }>图片合成视频</div>

	    <Spin className={ styles['upload-modal-spin'] } spinning={ isSubmiting } tip={ uploadStatus == 1 ? '上传中...' : '' }>
		    <div className={ styles['upload-modal-content'] }>
		      {
		        composeStep == 1 && 
		          <>
		            <div className={ styles['duration-modal-tit'] }>请选择合成的视频时长</div>
		            <div className={ `${ styles['duration-list'] }` }>
		            {
		              durationList.map((record, index) => {
		                return (
		                  <div className={ `${ styles['duration-item'] } ${ curDurationIndex === index ? styles['is-selected'] : '' }` } key={ record.value } onClick={ () => selectDuration(record.value, index) }>{ record.text }</div>
		                )
		              })
		            }
		            </div>

		            <div className={ styles['upload-modal-tips'] }>
		              <div className={ styles['upload-modal-tips-tit'] }>要求说明：</div>
		              {<div className={ styles['upload-modal-tips-item'] }>1. 为保障播放效果，最多可选择<span className={ `${ styles['count'] }` }>{ durationList[curDurationIndex].value * 1000 /  imageInfo.imgDuration }</span>张图片</div>}
		              <div className={ styles['upload-modal-tips-item'] }>2. 图片分辨率高度不小于<span className={ `${ styles['count'] }` }>{ imageInfo.minImgHeight }</span>像素</div>
		              <div className={ styles['upload-modal-tips-item'] }>3. 图片仅支持{
		            imageInfo.imgType.map((record, index) => {
		              return (<span key={ index }>{ record }{ index < imageInfo.imgType.length - 1 && '、' }</span>)
		            })}格式</div>
		            </div>
		          </>
		      }

		      {
		        composeStep == 2 && 
		        <>
		          <div className={ styles['duration-modal-tit'] }>图片上传</div>
		          {<div className={ styles['upload-modal-tips-item'] }>1. 视频时长 <span className={ `${ styles['count'] } ${ styles['f-black'] }` }>{ durationList[curDurationIndex].value }</span>秒，最多上传<span className={ `${ styles['count'] } ${ styles['f-black'] }` }>{ durationList[curDurationIndex].value * 1000 / imageInfo.imgDuration } </span>张：仅支持{
		            imageInfo.imgType.map((record, index) => {
		              return (<span key={ index }>{ record }{ index < imageInfo.imgType.length - 1 && '、' }</span>)
		            })}格式，图片分辨率高度不小于<span className={ `${ styles['count'] }` }>{ imageInfo.minImgHeight }</span>像素</div>}
		          <div className={ styles['upload-modal-tips-item'] }>2. 每张图片的时长，将按视频时长均分</div>

		          <div className={ styles['image-cont'] }>
		            <div className={ styles['image-count'] }>已上传<span className={ styles['image-count-text'] }>{ contentList.length }</span>张</div>
		            <div className={ styles['image-list-wrap'] }>
		            	{
		            		(contentList.length < durationList[curDurationIndex].value * 1000 / imageInfo.imgDuration ) && (
	            				<div className={ styles['image-btn'] }>
	            					{/*<ImgCrop 
	            						beforeCrop={ beforeCrop }
	            						modalTitle="编辑图片"
		            					modalWidth={ 1104 }
		            					rotationSlider
		            					showReset
		            					aspect={ imageInfo.imgRatio }
		            					{ ...cropperProps }
		            					onModalOk={ handleUpload }
	            						>*/}
				                <Upload { ...uploadImageProps } id="upload-btn">
				                  <div className={ styles['image-btn-icon'] }></div>
				                  <div className={ styles['image-btn-text'] }>点击上传</div>
				                </Upload>
				                {/*</ImgCrop>*/}
				              </div>
	            			)
		            	}

		              <div className={ styles['image-list-inner'] }>
		                <div className={ styles['image-list'] }>
		                	{
		                		contentList.map((record, index) => {
		                			return (
		                				<div className={ styles['image-item'] } key={ index }>
					                    <div className={ styles['image-index'] }>{ index + 1 }</div>
					                    <img className={ styles['image-node'] } src={ baseURL + record.contentUrl } />
					                    <div className={ styles['image-opts'] }>
					                      <a href={ baseURL + record.contentUrl } target="_blank"><EyeOutlined className={ styles['image-preview'] } /></a>
					                      <DeleteOutlined className={ styles['image-del'] } onClick={ () => { deleteContent(index) } } />
					                    </div>
					                  </div>
		                			)
		                		})
		                	}
		                </div>
		              </div>
		            </div>
		          </div>

		          <div className={ styles['image-compose'] } onClick={ toggleCarousel }>
		          	{
		          		!contentList.length && <div className={ styles['image-compose-empty'] }>
		          			<VideoCameraOutlined className={ styles['video-icon'] } /><div>等待上传图片后合成</div>
		          		</div>
		          	}
		            {
		            	contentList.length > 0 && (
		            		<div className={ styles['image-compose-video'] }>
		            			<Carousel 
		            				className={ styles['image-compose-carousel'] }
		            				dots={ false }
		            				ref={ videPlayRef }
		            				autoplay={ false }>
		            				{
		            					contentList.map((record, index) => {
			                			return (
			                				<img className={ styles['image-compose-node'] } key={ index } src={ baseURL + record.contentUrl } />
			                			)
			                		})
		            				}
		            			</Carousel>
		            			{
		            				isPlaying && (
		            					<Progress 
		            						className={ styles['video-progress'] } 
		            						strokeColor="green" 
		            						strokeWidth={ 4 }
		            						percent={ percent } 
		            						showInfo={ false } />
		            				)
		            			}
		            			{
		            				!isPlaying && (
		            					<div className={ styles['video-btn'] }>
				            				<PlayCircleOutlined className={ styles['video-btn-icon'] } />    
						              	<div className={ styles['video-durtaion'] }>{ util.tools.fixedTime(durationList[curDurationIndex].value) }</div>
						              </div>
		            				)
		            			}
				            </div>
				            )
		            }
		            
		          </div>

		          <div className={ styles['upload-modal-form'] }>
		            <div className={ styles['upload-modal-form-tit'] }>标题</div>
		            <Input placeholder="请输入6-18个字符" value={ contentNote } maxLength={ 18 } className={ styles['upload-modal-form-input'] } onChange={ onVideoTitleInputChange } />
		          </div>

		        </>
		      }
		      
		    </div>
	    </Spin>

	    <div className={ styles['upload-modal-footer'] }>
	      {
	        composeStep == 1 && <Button size="large" type="primary" className={ styles['btn-confirm'] } disabled={ isSubmiting } onClick={ onNext }>下一步</Button>
	      }
	      {
	        composeStep == 2 && 
	        <>
	          <Button size="large" type="default" className={ styles['btn-cancel'] } onClick={ onComposeCancel }>取消</Button>
	          <Button size="large" type="primary" className={ styles['btn-confirm'] } disabled={ isSubmiting || !contentList.length || contentNote.length < 6 } onClick={ onCompose }>确认合成</Button>
	        </>
	      }
	    </div>
	  </Modal>
  )
}