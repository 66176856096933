import React, { useState, useEffect, useRef } from 'react'
import { DatePicker, Table, Spin, Pagination, Modal, Select } from 'antd'
import type { Moment } from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store'

import moment from 'moment'
import { GuideModal } from '../../../../components'
import util from '../../../../libs/util'
import { transactionTypeNames, appIdItems, appProductItems, refundReasonItems, transactionTypeItems } from '../../../../libs/util.constants'
import miniCode from '../../../../assets/images/common/mini-code.jpg'

import styles from './Home.module.css'

interface OrgAccount {
	totalAmount: string | number,
	creditAmount: string | number,
	balance: string | number,
}

const defaultOrgAccount:OrgAccount = {
	totalAmount: 0,
	creditAmount: 0,
	balance: 0,
}

interface Record {
	appUserId?: string,
	appUserName?: string,
	transactionType?: string,
	childReferList?: any,
}
const defaultRecord:Record = {
	appUserId: '',
	appUserName: '',
	transactionType: '',
	childReferList: [],
}

const { RangePicker } = DatePicker

export const Income: React.FC = () => {
	const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
	const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'MEDIA_OPERATION') : false
  })

	const [orgAccount, setOrgAccount] = useState<OrgAccount>(defaultOrgAccount)
	const [currencyTypeId, setCurrencyTypeId] =  useState('DJ')

	useEffect(() => {
		init()
	}, [])

	const init = async() => {
		if (isManager || userType === 'COMPANY') {
			getSellerAmount()
			getReferAccountInfo()
		}
		
		findEnumItemList()
		getEntityList()
		await getBizConfig()
		setStartEndDate()
		getSellerFundsDayList()
	}

  const [sellerIncomeAmount, setSellerIncomeAmount] = useState(0)
  const [sellerRecentAmount, setSellerRecentAmount] = useState(0)
  const [sellerRecentDate, setSellerRecentDate] = useState()
	// 查询收益汇总
  const getSellerAmount = async() => {
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/finance/trans/getSellerAmount`,
    })
    if (code === 1) {
    	const { sellerIncomeAmount, sellerRecentAmount, sellerRecentDate } = data
    	setSellerIncomeAmount(sellerIncomeAmount)
    	setSellerRecentAmount(sellerRecentAmount)
    	setSellerRecentDate(sellerRecentDate)
    }
  }

	// 查询相关资金账户（个人与所在机构）
  const getReferAccountInfo = async() => {
  	const { data, code } = await util.request({
      method: 'POST',
      url: `/finance/account/getReferAccountInfo`,
      data: {
        currencyTypeId,
      },
    })
    if (code === 1) {
      setOrgAccount(data.orgAccount)
    }
  }

  // 提现 弹窗
  const childRef: any = useRef()
	const goToWithdraw = () => {
		childRef.current.onToggle()
	}

	const [queryDate, setQueryDate] = useState([])
	const onDateChange = (dates, dateStrings) => {
	  if (dates) {
	    setQueryDate(dateStrings)
	  } else {
	  	setQueryDate([])
	  }
	}
	const onEntityChange = (value) => {
		setQueryForm((state) => {
      return {
        ...state,
        resourceEntityInfoId: value,
      }
    })
	}

	const columns = [
	  {
	    title: '序号',
	    dataIndex: 'index',
	    key: 'index',
	    render: (text, record, index) => {
	    	return `${ index + 1}`
	    },
	  },
	  {
	    title: '操作类型',
	    dataIndex: 'transactionType',
	    key: 'transactionType',
	    render: (text, record, index) => {
	    	return util.tools.convertTransactionType(record, transactionTypeItems)
	    },
	  },
	  {
	    title: '金额(元)',
	    dataIndex: 'transactionMoney',
	    key: 'transactionMoney',
	    render: (text, record, index) => {
	    	return <span className={ `${ styles['income-money'] } ${ record.transactionAction === 1 ? styles['is-positive']  : '' }` }>{ record.transactionAction === 1 ? '+' : '-' }{ util.tools.splitNum2String(text).int }{ util.tools.splitNum2String(text).dec }</span>
	    },
	  },
	  {
	    title: '操作时间',
	    key: 'transactionTime',
	    dataIndex: 'transactionTime',
	  },
	  {
	    title: '操作',
	    key: 'action',
	    render: (text, record) => (
	      <a className={ `${ styles['important'] }` } onClick={ () => { onClickDetail(record.transactionId) } }>查看详情</a>
	    ),
	  },
	]
	const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getList(pageNum)
  }, [pageNum, queryDate])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)

	const getList = async(num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/finance/trans/querySellerTrans?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: {
      	currencyTypeId,
      	startDate: queryDate.length ? queryDate[0] : '',
				endDate: queryDate.length ? queryDate[1] : '',
				pageNum,
				pageSize,
				queryType: 'COMPANY',
      },
    })
		console.log('setIsSubmiting', data, code)
		setIsSubmiting(false)
    if (code === 1) {
	    setList(data)
	    setTotal(total)
	    setIsSubmiting(false)
	  }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }


  // 详情弹窗
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const showDetailModal = () => {
    setIsDetailModalVisible(true)
  }
  const onDetailModalCancel = () => {
    setIsDetailModalVisible(false)
  }
	const onClickDetail = (id) => {
		getDetail(id)
		showDetailModal()
	}

	const [record, setRecord] = useState<any>()
	// 查询交易详情
  const getDetail = async(id) => {
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/finance/trans/getSellerTrans`,
      params: {
      	transactionId: id,
      },
    })
    if (code === 1) {
    	setRecord(data)
    	if (data.transactionType === 'PAY') {
    		// 获取用户信息
      	getAppUserInfo(data.appUserId)
    	}
    }
  }
  // 获取用户信息
  const getAppUserInfo = async(appUserId) => {
    if (!appUserId) {
      return
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/uc/org/getOrgOrUserReal`,
      params: {
      	userInId: appUserId,
      },
    })
    if (code === 1) {
    	setRecord((state) => {
        return {
          ...state,
          appUserName: !data ? '未知' : (data.userType === 'PERSON' ? data.chinaName : data.companyAuthName),
        }
      })
    }
  }

  const [entityList, setEntityList] = useState([])
  const [queryForm, setQueryForm] = useState({
  	clearDateStart: '',
  	clearDateEnd: '',
  	resourceEntityInfoId: '',
  	pageSize: 30,
  })
  const [settleDays, setSettleDays] = useState(0)

  // 获取影院列表
  const getEntityList = async() => {
    const { data = [], code } = await util.request({
      method: 'POST',
      url: `/resource/searchOwnerPointEntityList`,
      data: {
      	operatorType: 'CINEMA',
      	pageNum: 1,
				pageSize: 999,
      },
    })
    if (code === 1) {
    	let list:any = [{
  			label: '全部影院',
  			value: '',
  		}]
			data.forEach(i => {
    		list.push({
    			label: i.entityName,
    			value: i.resourceEntityInfoId,
    		})
    	})
    	
    	setEntityList(list)
    }
  }
  // 查结算日
  const getBizConfig = async() => {
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/config/biz/getBizConfig`,
      params: {
      	itemKey: 'SETTLE_DAYS'
      },
    })
    if (code === 1) {
    	setSettleDays(Number(data.itemValue || 0))
    } 
  }
  const setStartEndDate = () => {
    const startDate = new Date()
    const settleStartDate = new Date(+new Date() - (settleDays + 1) * 24 * 60 * 60 * 1000)
    
    setQueryForm((state) => {
      return {
        ...state,
        clearDateStart: util.tools.formatDate(startDate),
        clearDateEnd: util.tools.formatDate(settleStartDate),
      }
    })
  }
  const getSellerFundsDayList = async() => {
  	const { data = [], code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getSellerFundsDayList`,
      params: queryForm,
    })
    if (code === 1) {
    	console.log('getSellerFundsDayList', data)
    }
  }

  const [fundsBankList, setFundsBankList] = useState<any>()
  const findEnumItemList = async() => {
  	const key = 'FUNDS_BANK_LIST'
    const params = {
      groupKeyArray: key,
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })

    if (code === 1) {
    	setFundsBankList(data[key])
    }
  }

  return (
    <div className={ `${ styles['income-page'] }` }>
      <div className={ `${ styles['income-page-tit'] }` }>
        <h3>热幕收益</h3>
      </div>

      <div className={ `${ styles['income-page-cont'] }` }>
				{
					(isManager || userType === 'COMPANY') && (
						<div className={ `${ styles['income-page-top'] }` }>
							<div className={ `${ styles['income-list'] }` }>
								<div className={ `${ styles['income-item'] }` }>
									<div className={ `${ styles['income-item-tit'] }` }>余额<span className={ `${ styles['income-unit'] }` }>(元)</span></div>
									<div className={ `${ styles['income-item-amount'] }` }>
										<span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(orgAccount.balance).int }</span>
										<span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(orgAccount.balance).dec }</span>
									</div>
								</div>
								<div className={ `${ styles['income-item'] }` }>
									<div className={ `${ styles['income-item-tit'] }` }>最近日收益<span className={ `${ styles['income-unit'] }` }>(元)</span></div>
									<div className={ `${ styles['income-item-amount'] }` }>
										<span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(sellerRecentAmount).int }</span>
										<span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(sellerRecentAmount).dec }</span>
									</div>
								</div>
								<div className={ `${ styles['income-item'] }` }>
									<div className={ `${ styles['income-item-tit'] }` }>累计收益<span className={ `${ styles['income-unit'] }` }>(元)</span></div>
									<div className={ `${ styles['income-item-amount'] }` }>
										<span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(sellerIncomeAmount).int }</span>
										<span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(sellerIncomeAmount).dec }</span>
									</div>
								</div>
							</div>

							<div className={ `${ styles['btn-recharge'] }` } onClick={ goToWithdraw }>提现</div>
						</div>
					)
				}
        <div className={ `${ styles['income-date'] }` }>
	      	<RangePicker
	      		size='large'
			      ranges={{
			        '本月': [moment().startOf('month'), moment().endOf('month')],
			        '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
			      }}
			      onChange={ onDateChange }
			    />
			    <Select
			    	size='large'
			      style={{ width: 300, marginLeft: '100px' }}
			      allowClear
			      options={ entityList }
			      onChange={ onEntityChange }
			    />
	      </div>

	    	{/* TODO */}
	      {/*<div className={ `${ styles['income-data'] }` }>
	      	<div className={ `${ styles['income-data-tit'] }` }>每日收益统计趋势图</div>
	      	<div className={ `${ styles['income-data-total'] }` }>总收益<span className={ `${ styles['income-unit'] }` }>(元)</span>：<span className={ `${ styles['income-item-amount'] }` }>600.00</span></div>


	      </div>*/}

				{
					(isManager || userType === 'COMPANY') && (
					<div className={ `${ styles['income-data'] }` }>
						<div className={ `${ styles['income-data-tit'] }` }>收支明细</div>
						<Spin spinning={ isSubmiting } tip="请稍候...">
							<div className={ styles['income-table']}>
								<Table 
									rowKey="transactionId"
									columns={ columns } 
									dataSource={ list }
									pagination={ false } />
							</div>
					
							{
								// 翻页
								(list.length > 0) && (
									<div className={ `${ styles.paging } flex justify-end` }>
										<Pagination 
											showQuickJumper 
											showSizeChanger
											showTotal={ total => `共 ${total} 条`}
											defaultCurrent={ defaultCurrent }
											current={ pageNum }
											pageSize={ pageSize }
											pageSizeOptions={ pageSizeOptions }
											total={ total } 
											onChange={ onPageChange }
											onShowSizeChange={ onShowSizeChange }
												/>
									</div>
								)
							}
						</Spin>
					</div>
					)
				}

      </div>
      
      {/* 提现提醒 弹窗*/}
      <GuideModal childRef={ childRef } />  

    	{/* 详情 弹窗*/}
      <Modal 
        width={ 480 }
        title="收益明细详情"
        footer={ null }
        visible={ isDetailModalVisible }
        onCancel={ onDetailModalCancel }
        >
        <div className={ `${ styles['detail-modal-cont'] }` }>
	        <div className={ `${ styles['order-form'] }` }>
	        	<div className={ `${ styles['order-form-item'] }` }>
			        <div className={ `${ styles['order-form-label'] }` }>操作类型</div>
			        <div className={ `${ styles['order-form-value'] }` }>{ record && util.tools.convertTransactionType(record, transactionTypeItems) }</div>
			      </div>
			      <div className={ `${ styles['order-form-item'] }` }>
			        <div className={ `${ styles['order-form-label'] }` }>金额</div>
			        <div className={ `${ styles['order-form-value'] } ${ styles['income-money'] } ${ record?.transactionAction === 1 ? styles['is-positive']  : '' }` }>{ record?.transactionAction === 1 ? '+' : '-' }{ util.tools.splitNum2String(record?.transactionMoney).int }{ util.tools.splitNum2String(record?.transactionMoney).dec }</div>
			      </div>

				    {/*<!-- 提现 -->*/}
				    {
				    	(record?.transactionType === 'WITHDRAW' || record?.transactionType === 'REVERSE_WITHDRAW') && (
				    		<>
				    		<div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>到账银行</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ fundsBankList?.find(i => i.itemKey === record.withdrawRequest.bankNameId)?.itemValue }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>提现单号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.withdrawRequest.withdrawId }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>提现时间</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.withdrawRequest.withdrawTime }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>交易流水号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionId }</div>
					      </div>
					      </>
				    	)
				    }
				    {
				    	(record?.transactionType !== 'WITHDRAW' && record?.transactionType !== 'REVERSE_WITHDRAW') && (
				    		<>
				    		<div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>结算日期</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record?.transactionTime }</div>
					      </div>
				    		</>
				    	)
				    }
				  </div>

				  {/*<!-- 明细 -->*/}
				  {
				  	(record?.childReferList.length > 0 && (record?.transactionType !== 'WITHDRAW' && record?.transactionType !== 'REVERSE_WITHDRAW')) && (
				  		<div className={ `${ styles['other-item'] }` }>
						    <div className={ `${ styles['other-tit'] }` }>明细</div>
						    
						    <div className={ `${ styles['other-info'] }` }>
						    	{
						    		record.childReferList.map(item => {
						    			return (
						    				<div 
									      	className={ `${ styles['record-item'] }` }
									        key={ item.transactionId }
									        >
									        <div className={ `${ styles['record-item-top'] }` }>
									        	<div className={ `${ styles['record-item-top-l'] }` }>
										          <span className={ `${ styles['record-item-label'] }` }>订单编号: </span>
										          <span className={ `${ styles[''] }` }>{ item.appGoods.split(' ')[1] }</span>
									          </div>
									          <div className={ `${ styles['record-item-top-r'] }` }>
										          <div className={ `${ styles['record-money'] }` }>
										            <span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(item.transactionMoney).int }</span>
										            <span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(item.transactionMoney).dec }</span>
										          </div>
									          </div>
									        </div>
									      </div>
						    			)
						    		})
						    	}
						    </div>
			        </div>
				  	)
				  }
        </div>
      </Modal>

    </div>
  )
}