import React, { useState, useEffect, useImperativeHandle, useContext } from 'react'
import {
	Modal,
	Button,
} from 'antd'

import util from '../../../../../libs/util'
import {
  findEnumItemList,
} from '../../../../../api'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

import { appContext } from '../../../../../App.state'

interface Props {
  childRef: any,
  confirmFn?: any,
  helpUrls?: any,
}

export const DownloadModal: React.FC<Props> = ({ confirmFn, childRef, helpUrls }) => {
	const appValue = useContext(appContext)

	const [isModalVisible, setIsModalVisible] = useState(false)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })

	const handleCancel = () => {
		toggle(false)
		setCurStepIndex(0)
	}

	const stepList = ['下载热幕精灵', '安装热幕精灵']
	const [curStepIndex, setCurStepIndex] = useState(0)

	const goToNext = () => {
		setCurStepIndex(curStepIndex + 1)
	}
	const goToPrev = () => {
		setCurStepIndex(curStepIndex - 1)
	}

	const helpPrev = `https://${ util.tools.env }static.remudooh.com/cms/html/web/help/`
  const [helpPageUrl, setHelpPageUrl] = useState(helpPrev)
  const getHelpPageUrl = () => {
    setHelpPageUrl(`${ helpPrev }${ helpUrls?.find(i => i.itemKey === 'INSTALL_FAIL').itemValue }`)
  }
  useEffect(() => {
  	if (!helpUrls || !helpUrls.length) {
  		return
  	}
  	getHelpPageUrl()
  }, [helpUrls])

	return (
		<Modal 
			width={ 1110 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>
			<div className={ stylesCommon['title'] }>热幕精灵下载安装</div>

			<div className={ styles['steps-wrap'] }>
				<div className={ styles['steps'] }>
					{
						stepList.map((step, stepIndex) => {
							return (
								<div className={ `${ styles['step-item'] } ${ stepIndex === curStepIndex ? styles['is-selected'] : '' }` } key={ stepIndex }>
									<div className={ styles['step-index'] }>{ stepIndex + 1 }</div>
									<div className={ styles['step-text'] }>{ step }</div>
								</div>
							)
						})
					}
				</div>
			</div>

			{
				curStepIndex === 0 && 
				<div className={ styles['steps-cont'] }>
					<div className={ styles['steps-cont-item'] }>
						<div className={ styles['steps-cont-item-l'] }>第1步</div>
						<div className={ styles['steps-cont-item-r'] }>
							<div className={ styles['steps-cont-item-tit'] }>下载热幕精灵文件包</div>
							<a className={ `${ stylesCommon['btn'] } ${ styles['btn-download'] }` } target="_blank" download="jttmedia_agent" href={ `${ appValue.rHost }/apps/RemuAgent_Cinema_1.1.1.zip` }>立即下载</a>
						</div>
					</div>

					<div className={ styles['steps-cont-item'] }>
						<div className={ styles['steps-cont-item-l'] }>第2步</div>
						<div className={ styles['steps-cont-item-r'] }>
							<div className={ styles['steps-cont-item-tit'] }>解压缩文件包</div>
							<div className={ styles['steps-cont-item-tips'] }>热幕精灵文件包下载成功后，将文件解压缩到合适目录</div>
							<div className={ styles['steps-cont-item-text'] }>
								<div className={ `icon ${ styles['pic-download'] } ${ styles['pic-download-1'] }` }></div>
								<div className={ `icon ${ styles['pic-download'] } ${ styles['pic-download-2'] }` }></div>
							</div>
						</div>
					</div>
				</div>
			}

			{
				curStepIndex === 1 && 
				<div className={ styles['steps-cont'] }>
					<div className={ styles['steps-cont-tips'] }><i className={ `ifont i-tips ${ styles['i-tips'] }` }></i>安装前<span className={ styles['important'] }>必须先关闭</span>“360、电脑管家”等杀毒软件的所有进程</div>

					<div className={ styles['steps-cont-item-text'] }>在解压缩后的文件包“<span className={ styles['bold'] }>...\RemuAgent_Cinema</span>”里面，按下方左图进行安装操作：</div>
					<div className={ styles['steps-cont-item-text'] }>
						<div className={ styles['steps-cont-item-pic'] }>
							<div className={ `icon ${ styles['pic-install'] } ${ styles['pic-install-1'] }` }></div>
							<div className={ styles['steps-cont-item-pic-text'] }>安装热幕精灵的操作方法</div>
						</div>
						<div className={ styles['steps-cont-item-pic'] }>
							<div className={ `icon ${ styles['pic-install'] } ${ styles['pic-install-2'] }` }></div>
							<div className={ styles['steps-cont-item-pic-text'] }>热幕精灵安装界面</div>
						</div>
					</div>

					<a className={ styles['steps-cont-fail-tips'] } href={ helpPageUrl } target="_blank">安装失败怎么办？</a>
				</div>
			}

      <div className={ stylesCommon['footer'] }>
      {
      	curStepIndex === 0 && 
      	<button className={ stylesCommon['btn'] } onClick={ goToNext }>下一步</button>
      }
      {
      	curStepIndex === 1 && 
      	<>
      	<button className={ `${ stylesCommon['btn'] } ${ stylesCommon['btn-cancel'] }` } onClick={ goToPrev }>上一步</button>

      	<button className={ stylesCommon['btn'] } onClick={ handleCancel }>完成</button>
      	</>
      }
      </div>
    </Modal>
	)
}