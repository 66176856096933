import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../store'
import { getUserInfoAction } from '../../../store/user/action'
import styles from './Choose.module.css'

import util from '../../../libs/util'
import { Header, AssistantHeader, Footer } from '../../../components'
import { getOrgUsersByCode, codeLogin } from '../../../api'
import { UPDATE_USER } from '../../../store/actionTypes'
import { defaultState as defaultUserState } from '../../../store/user/reducer'
import auth from '../signIn/auth'
import cinemaAuth from '../../assistant/login/loginCont/auth'

interface Props {
  location: {
    state: {
      id?: String,
      phoneNumber?: String,
      isCinema?: Boolean,
    }
  },
}

export const Choose: React.FC<Props> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [list, setList] = useState<any[]>([])
  const [phoneNumber, setPhoneNumber] = useState(props.location.state.phoneNumber)
  const [tempCode, setTempCode] = useState(props.location.state.id)
  const [isCinema, setIsCinema] = useState(props.location.state.isCinema)
  console.log('props', props.location.state.id)

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  const onLogout = async () => {
    const result = await util.request({
      method: 'POST',
      url: '/uc/userLogout'
    })

    if (result.code === 1) {
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
  }
  useEffect(() => {
    if (userInfo.permissionDenied) {
      message.error('当前账号尚未开通热幕媒体商家服务！')
      // 调退出登录
      onLogout()
      return
    }
    // 已登录
    if (userInfo.myUserInfo.userType) {
      util.storage.set('myUserInfo', userInfo.myUserInfo)

      const myAuth: any = isCinema ? cinemaAuth(userInfo) : auth(userInfo)

      history.replace({ 
        pathname: myAuth.url, 
        state: myAuth 
      })
    }
  }, [userInfo])

  useEffect(() => {
    getList()
  }, [])

  // 获取列表
  const getList = async() => {
    const { code, data } = await getOrgUsersByCode({
      tempCode,
    })
    if (code === 1) {
      setList(data)
    }
  }

  const login = async(record) => {
    const { code, data } = await codeLogin({
      tempCode,
      userInId: record.userInId,
    }, {
      headers: {
        userToken: '',
      }
    })
    if (code === 1) {
      const userToken = data.userToken
      util.Cookies.set('userToken', userToken)
      dispatch(getUserInfoAction({}))
    }
  }
  return (
    <>
      {
        isCinema && <AssistantHeader />
      }
      {
        !isCinema && <Header fixed={ false } />
      }
      <div className={ styles.cont }>
        <div className={ styles['cont-tit'] }>{ phoneNumber } 手机号有<span className={ styles['cont-tit-number'] }>{ list.length }</span>个媒体商家账号，请选择登录账号</div>

        <div className={ styles.list }>
          {
            list.map((record, index) => {
              return (
                <div className={ styles.item } key={ record.userInId } onClick={ () => login(record) }>
                  <div className={ styles['company-name'] }>{ record.chinaName || '-' }</div>
                  <div className={ styles['company-id'] }>热幕号：{ record.userShowId }</div>
                  { record.roleStatus !== 'YES' && <div className={ styles.status }><span className={ `${ styles['icon-status'] } imediafont i-tips`}></span>媒体商家入驻审核{ (record.roleStatus === 'NO' || record.status === 'NO') && '不通过' }{ record.roleStatus === 'WAIT' && '中' }</div> }

                  <div className={ `${ styles['icon-more'] } imediafont i-trangle-b`}></div>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

