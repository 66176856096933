import { 
  PointList,
  StockList,
  PointDetail,
} from '../pages'

const routes = [
	{
    path: '/point/list',
    component: PointList,
  },
  {
    path: '/point/detail/:id/:isAudit',
    component: PointDetail,
  },
  {
    path: '/point/stock/:id',
    component: StockList,
  },
]

export default routes