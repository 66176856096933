import { AssistantMainLayout } from '../layouts'
import { 
  AssistantLogin,
  AssistantSignIn,
  AssistantWelcome,
  EntityList,
  CinemaPointList,
} from '../pages'

const routes = [
	{
    path: '/assistant/login',
    exact: true,
    component: AssistantSignIn,
    name: 'AssistantSignIn',
  },
  {
    path: '/assistant',
    component: AssistantMainLayout,
    routes: [
      {
        path: '/assistant/welcome/:id/:status',
        component: AssistantWelcome,
      },
      {
        path: '/assistant/home',
        component: EntityList,
      },
      {
        path: '/assistant/pointlist/:id',
        component: CinemaPointList,
      },
    ],
  },
]

export default routes