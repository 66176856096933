import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../../libs/util'
import styles from './OrderBottom.module.css'

interface Props {
  ad: any,
}

export const OrderBottom: React.FC<Props> = ({ ad }) => {
  return (
  	<div className={ `${ styles['ad-page-cont'] } ${ styles['ad-page-bottom'] } ` }>
      <div className={ `${ styles['ad-page-bottom-label'] }` }>{ ad.settleStandardType === 'SHOW' ? '预估' : '订单' }收益</div>
      <div className={ `${ styles['text-price'] }` }>
        <span className={ `${ styles['text-price-unit'] }` }>￥</span>
        <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2StringFixed(ad?.realPayAmount)?.split('.')[0] }</span>
        <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2StringFixed(ad?.realPayAmount)?.split('.').length > 1 ? `.${ util.tools.splitNum2StringFixed(ad.realPayAmount).split('.')[1] }` : '' }</span>
      </div>
      {
        ad.settleStandardType !== 'SHOW' && <div className={ `${ styles['ad-price-label'] }` }>预估最高</div>
      }
    </div>
  )
}