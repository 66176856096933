import React, { useState, useEffect } from 'react'
import styles from './SignLayout.module.css'

import { Header } from '../../components'

export const SignLayout: React.FC = (props) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const innerHeight = window.innerHeight
    setHeight(innerHeight - 100)
  }, [height])


  return (
    <div className={ styles['main'] }>
      <Header />
      <div 
        className={ styles['content'] }
        style={{ height: `${ height }px`, paddingTop: '100px' }}
      >
        { props.children }
      </div>
    </div>
  )
}