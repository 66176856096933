import React, { useState, useEffect, useRef } from 'react'
import styles from './PointList.module.css'

import { RemoFilterPanel, RemoList } from './index'

interface ContextValueType {
  city: string | null,
  district: string | null,
  operatorType: 'CINEMA',
  entityName: string | null,
  pointNumber: string,
  pointName: string | null,
  showPointStatus: string | null,
}

export const defaultRemoFilterContextValue: ContextValueType = {
	city: null,
  district: null,
  operatorType: 'CINEMA',
  entityName: null,
  pointNumber: '',
  pointName: null,
  showPointStatus: null,
}

export const filterRemoContext = React.createContext(defaultRemoFilterContextValue)
export const setRemoFilterContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | any>(undefined)

export const RemoPointList: React.FC = () => {
	const [filterData, setFilterData] = useState(defaultRemoFilterContextValue)

  const listRef: any = useRef()
  const onSearch = () => {
    listRef.current.getPointList(1)
  }

  return (
    <div className={ `${ styles['point-page'] }` }>
      <div className={ `${ styles['point-page-tit'] }` }>
        <h3>热幕资源</h3>
      </div>
      <filterRemoContext.Provider value={ filterData }>
      	<setRemoFilterContext.Provider value={ setFilterData }>
		      <RemoFilterPanel onSearch={ onSearch } />
		      <RemoList childRef={ listRef } />
	      </setRemoFilterContext.Provider>
      </filterRemoContext.Provider>

    </div>
  )
}