import { 
  RemoPointList,
  AdList,
  AdDetail,
  AdReportHome,
  AdReportList,
  Income,
} from '../pages'

const routes = [
	{
    path: '/remo-point/list',
    component: RemoPointList,
  },
  {
    path: '/remo-ad/list',
    component: AdList,
  },
  {
    path: '/remo-ad/detail/:mallOrderMediaId',
    component: AdDetail,
  },
  {
    path: '/remo-ad/report/home/:mallOrderMediaId',
    component: AdReportHome,
  },
  {
    path: '/remo-ad/report/list/:mallOrderMediaId/:query',
    component: AdReportList,
  },
  {
    path: '/remo-income/index',
    component: Income,
  },
]

export default routes