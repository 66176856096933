import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
} from 'antd'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  confirmFn: any,
  childRef: any,
  modeText: any,
}

export const ActiveConfirmModal: React.FC<Props> = ({ confirmFn, childRef, modeText }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		confirmFn()
		toggle(false)
	}

	const handleCancel = () => {
		toggle(false)
	}

	return (
		<Modal 
			width={ 660 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>

			<div className={ stylesCommon['title'] }>点位{ modeText }确认</div>

      <div className={ styles['tips'] }>是否已完成点位{ modeText }？</div>

      <div className={ stylesCommon['footer'] }>
      	<button className={ `${ stylesCommon['btn'] } ${ stylesCommon['btn-cancel'] }` } onClick={ handleCancel }>取消</button>
      	<button className={ stylesCommon['btn'] } onClick={ handleOk }>已完成{ modeText }</button>
      </div>
    </Modal>
	)
}