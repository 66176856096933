import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, } from 'antd'
import util from '../../../../../libs/util'
import styles from './PriceRulesIntroModal.module.css'

interface Props {
  childRef: any,
}

export const PriceRulesIntroModal: React.FC<Props> = ({ childRef }) => {
	useImperativeHandle(childRef, () => ({
    showModal: (isShowM) => {
      setIsShowSettleModel(isShowM)
      showModal()
      init()
    }
  }))
  const [isShowSettleModel, setIsShowSettleModel] = useState(false)
  const [showSettleStandard, setShowSettleStandard] = useState<any>([])
  const init = async() => {   
    const { isRatioSettleModel, settleType }:any = await getStandardData()
    const type = settleType.join(',')
    findEnumItemList(type)

    if (!isShowSettleModel) {
      await searchStandardPrice(type)
    }
    // setList(getListData(list, cSettleStandard))

    // 接口返回的是settleModel是“RATIO”按比例结算（正常是NORMAL），就要获取平台的的定价列表 X 合同比例
    if (isRatioSettleModel) {
      setRatio()
    }
  }

  const [cSettleStandard, setCSettleStandard] = useState()
  const [sandardPricRatioList, setSandardPricRatioList] = useState<any>()
  const [sandardPricRatio, setSandardPricRatio] = useState<any>()
  const [cinemaHolidayItems, setCinemaHolidayItems] = useState<any>()
  const [scheduleRatio, setScheduleRatio] = useState<any>()
  const findEnumItemList = async(type) => {
    let name = type === 'SHOW' ? 'SHOW_SETTLE_PRICE_RATIO' : type
    const { code, data } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: [name, 'CINEMA_HOLIDAY'].join(',')
      },
    })

    if (code === 1) {
      let dataList = data[name] || []
      let tempList = [
        {
          text: '倒1位置',
          key: `${ type }_CORE_PLACE_RATIO`,
        },
        {
          text: '映前2分钟内',
          key: `${ type }_TWO_PLACE_RATIO`,
        },
        {
          text: '映前5分钟内',
          key: `${ type }_FIVE_PLACE_RATIO`,
        },
        {
          text: '映前6-10分钟',
          key: `${ type }_TEN_PLACE_RATIO`,
        },
      ]
      // 基础日刊例价
      tempList.forEach(i => {
        const item = dataList.find(j => j.itemKey === i.key)
        item && Object.assign(i, item)
      })
      setSandardPricRatioList(tempList)

      // 银幕广告基础日结算价
      setSandardPricRatio(data[name] || [])
      // 节假日/档期调价比例
      setCinemaHolidayItems(data['CINEMA_HOLIDAY'] || [])
      setScheduleRatio(JSON.parse(data[name]?.find(i => i.itemKey === `${ type }_SCHEDULE_RATIO`).itemValue))
      console.log('findEnumItemList', data, JSON.parse(data[name]?.find(i => i.itemKey === `${ type }_SCHEDULE_RATIO`).itemValue))
    }
  }

  // 
  const [standardData, setStandardData] = useState<any>()
  const [cpt, setCpt] = useState<any>()
  const getStandardData = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/biz_entity/getBizSaleEntityStateMore`,
      params: {},
    })

    if (code === 1) {
      const { settleModel, settleType, settleRatio, showSettleStandard: s } = data
      const isRatioSettleModel = settleModel === 'RATIO'
      setStandardData(state => {
        return {
          ...state,
          isRatioSettleModel,
          settleType,
          settleRatio,
        }
      })
      setShowSettleStandard(s)
      console.log('getStandardData', data)
      return { settleModel, settleType, settleRatio, isRatioSettleModel }
    }
  }

  const [list, setList] = useState<any>()
  // 平台参数
  const searchStandardPrice = async(settleType) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/standard_price/searchStandardPrice`,
      params: {
        standardType: settleType,
      },
    })

    if (code === 1) {
      setList(sortList(data, true))
      console.log('searchStandardPrice', list)
    }
  }

  // 额外比率
  const setRatio = () => {
    
  }

  // 列表排序
  const sortList = (data, isReverse) => {
    data.sort((a, b) => {
      // 15, 30, 60
      if (a.standardLevel === b.standardLevel) {
        return a.standardPrice - b.standardPrice
      }
      return isReverse ? (b.standardLevel - a.standardLevel) : (a.standardLevel - b.standardLevel)
    })

    let list:any = []
    for (var i = 0; i < data.length - 2; i += 3) {
      list.push({
        standardLevel: data[i].standardLevel,
        list: [
          data[i],
          data[i + 1],
          data[i + 2],
        ]
      })
    }

    return list
  }

  // 媒体主数据覆盖平台数据
  const getListData = (d, s) => {
    let source = [...s]
    let dir = JSON.parse(JSON.stringify(d))
    if (source.length) {
      dir.forEach(levelItem => {
        levelItem.list.forEach(i => {
          const index = source.findIndex(j => j.mallStandardPriceId === i.mallStandardPriceId)
          if (index !== -1) {
            i.standardPrice = source[index].standardPrice
            source.splice(index, 1)
          }
        })
      })
    }
    //  else {
    //   dir.forEach(levelItem => {
    //     levelItem.list.forEach(i => {
    //       i.standardPrice = ''
    //     })
    //   })
    // }
    return dir
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const computeRatioPrice = (price) => {
    return (parseFloat(price) * parseFloat(standardData.settleRatio)).toFixed(2)
  }

  const getTips = (value) => {
    if (value > 1) {
      return `原价×${ value }`
    } else if (value == 1) {
      return `原价`
    } else {
      return `${ value * 10 }折`
    } 
  }

  return (
  	<Modal 
	    width={ 1104 }
	    title="结算规则"
	    visible={ isModalVisible }
	    footer={ null }
      onCancel={ onModalCancel }
	    >
      <div className={ `${ styles['intro-page'] }` } >
        {/* <div className={ `${ styles['intro-cont'] }` }>
          <div className={ `${ styles['intro-tit'] }` }>规则说明</div>
          <div className={ `${ styles['intro-tips'] }` }>
            <div className={ `${ styles['intro-p'] }` }>1. 媒体商家收益将按照刊例价的{ Math.round(standardData?.settleRatio * 100) }%进行结算</div>
            <div className={ `${ styles['intro-p'] }` }>2. 刊例价为平台的统一售价</div>
          </div>
        </div> */}

        <div className={ `${ styles['intro-cont'] }` }>
          <div className={ `${ styles['intro-tit'] }` }>
            {
              isShowSettleModel && '点位单场次结算价格计算规则'
            }
            {
              !isShowSettleModel && '单银幕单日结算规则'
            }
          </div>
          <div className={ `${ styles['intro-tips'] }` }>
            <div className={ `${ styles['intro-p'] }` }>{
              isShowSettleModel && '单场次基础结算价'
            }
            {
              !isShowSettleModel && '基础日结算价'
            }    ×    播放时段调价比例     ×    刊播日调价比例</div>
          </div>
        </div>

        <div className={ `${ styles['price-box'] }` }>
          <div className={ `${ styles['price-box-l'] }` }>
            <div className={ `${ styles['intro-box'] }` }>
              <div className={ `${ styles['intro-tit'] }` }>
              {
                isShowSettleModel && '单场次基础结算价'
              }
              {
                !isShowSettleModel && <>基础日结算价<span className={ `${ styles['intro-tit-tips'] }` }>（单位：元/天）</span></>
              }
              </div>
              {
                isShowSettleModel && (
                  <div className={ `${ styles['intro-table'] }` }>
                    <div className={ `${ styles['intro-th'] }` }>
                      <div className={ `${ styles['intro-td'] }` }>
                        广告时长
                      </div>

                      <div className={ `${ styles['intro-td'] }` }>
                        单场次基础结算价格（元/场）
                      </div>
                    </div>

                    <div className={ `${ styles['intro-tbody'] }` }>
                      {
                        showSettleStandard?.map((item, index) => {
                          return (
                            <div
                              className={ `${ styles['intro-tr'] }` } 
                              key={ index }
                              >
                              <div className={ `${ styles['intro-td'] }` }>
                                { item.itemKey }秒
                              </div>
                              <div className={ `${ styles['intro-td'] }` }>
                                {
                                  item.itemValue && <>{ util.tools.moneyClearZero(item.itemValue) }</>
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
              {
                !isShowSettleModel && (
                  <div className={ `${ styles['intro-table'] }` }>
                    <div className={ `${ styles['intro-th'] }` }>
                      <div className={ `${ styles['intro-td'] }` }>
                        点位级别
                      </div>

                      <div className={ `${ styles['intro-td'] }` }>
                        15秒
                      </div>

                      <div className={ `${ styles['intro-td'] }` }>
                        30秒
                      </div>

                      <div className={ `${ styles['intro-td'] }` }>
                        60秒
                      </div>
                    </div>

                    <div className={ `${ styles['intro-tbody'] }` }>
                      {
                        list?.map((item, index) => {
                          return (
                            <div
                              className={ `${ styles['intro-tr'] }` } 
                              key={ index }
                              >
                              <div className={ `${ styles['intro-td'] }` }>
                                <div className={ styles['rate-list'] }>
                                  {
                                    new Array(item?.standardLevel).fill(1).map((item, index) => {
                                      return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
                                    })
                                  }
                                </div>
                              </div>
                              {
                                item.list.map((priceItem, priceIndex) => {
                                  return (
                                    <div className={ `${ styles['intro-td'] }` } key={ priceIndex }>
                                      ￥{ util.tools.moneyClearZero(computeRatioPrice(priceItem.standardPrice)) }
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
            </div>

            <div className={ `${ styles['intro-box'] }` }>
              <div className={ `${ styles['intro-tit'] }` }>播放时段调价比例</div>
              {
                sandardPricRatioList?.map((item, index) => {
                  return (
                    <div className={ `${ styles['intro-form-item'] }` } key={ item.key }>
                      <div className={ `${ styles['intro-form-label'] }` }>{ item.text }</div>
                      <div className={ `${ styles['intro-form-value'] }` }>
                        {
                          (item.itemValue == 1) && (<span className={ `${ styles['intro-form-value-tips'] }` }>（不调价）</span>)
                        }
                        { getTips(item.itemValue) }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className={ `${ styles['price-box-r'] }` }>
            <div className={ `${ styles['intro-box'] }` }>
              <div className={ `${ styles['intro-tit'] }` }>刊播日调价比例<span className={ `${ styles['intro-tit-tips'] }` }>若周末恰逢节假日/档期，则执行节假日/档期对应系数</span></div>
              <div className={ `${ styles['intro-form-item'] }` }>
                <div className={ `${ styles['intro-form-label'] }` }>平日</div>
                <div className={ `${ styles['intro-form-value'] }` }>
                  {
                    (sandardPricRatio?.find(i => i.itemKey === `${ isShowSettleModel ? 'SHOW' : 'CPT' }_ORIGINAL_RATIO`)?.itemValue == 1 && !standardData?.isRatioSettleModel) && (<span className={ `${ styles['intro-form-value-tips'] }` }>（不调价）</span>)
                  }
                  { sandardPricRatio?.find(i => i.itemKey === `${ isShowSettleModel ? 'SHOW' : 'CPT' }_ORIGINAL_RATIO`)?.itemValue }倍
                </div>
              </div>
              <div className={ `${ styles['intro-form-item'] }` }>
                <div className={ `${ styles['intro-form-label'] }` }>周末</div>
                <div className={ `${ styles['intro-form-value'] }` }>
                  {
                    (sandardPricRatio?.find(i => i.itemKey === `${ isShowSettleModel ? 'SHOW' : 'CPT' }_WEEKEND_RATIO`)?.itemValue == 1 && !standardData?.isRatioSettleModel) && (<span className={ `${ styles['intro-form-value-tips'] }` }>（不调价）</span>)
                  }
                  { sandardPricRatio?.find(i => i.itemKey === `${ isShowSettleModel ? 'SHOW' : 'CPT' }_WEEKEND_RATIO`)?.itemValue }倍
                </div>
              </div>

              {/* 节假日*/}
              {
                <div className={ `${ styles['has-border'] }` }>
                {
                  scheduleRatio && (cinemaHolidayItems?.filter(i => !i.itemKey.includes('BB_')).map((holidayItem, holidayIndex) => {
                    return (
                      <>
                        <div className={ `${ styles['intro-form-item'] }` }>
                          <div className={ `${ styles['intro-form-label'] }` }>{ holidayItem.itemValue }</div>
                          <div className={ `${ styles['intro-form-value'] }` }>
                            { scheduleRatio[holidayItem.itemKey] }倍
                          </div>
                        </div>
                      </>
                    )
                  }))
                }
                </div>
              }

              {/* 大片档*/}
              {
                <div className={ `${ styles['has-border'] }` }>
                {
                  scheduleRatio && (cinemaHolidayItems?.filter(i => i.itemKey.includes('BB_')).map((holidayItem, holidayIndex) => {
                    return (
                      <>
                        <div className={ `${ styles['intro-form-item'] }` }>
                          <div className={ `${ styles['intro-form-label'] }` }>{ holidayItem.itemValue }</div>
                          <div className={ `${ styles['intro-form-value'] }` }>
                            { scheduleRatio[holidayItem.itemKey] }倍
                          </div>
                        </div>
                      </>
                    )
                  }))
                }
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>
	  </Modal>
  )
}