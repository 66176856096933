import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'

import { AdExec, defaultAdExec } from '../../index'
import styles from './OrderInfo.module.css'

interface Props {
  ad: AdExec,
}

export const OrderInfo: React.FC<Props> = ({ ad }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const copyText = () => {
    navigator.clipboard.writeText(ad?.mallOrderNumber || '').then(() => {
      message.success('复制成功')
    })
  }

  const getIsPaySuc = () => {
    return ad?.approveStatus === 'DELIVERY_WAIT' || ad?.approveStatus === 'EXECUTING' || ad?.approveStatus === 'FINISHED'
  }
  
  return (
  	<div className={ `${ styles['ad-page-cont'] }` }>
      <div className={ `${ styles['ad-tit-label'] }` }>广告执行单信息</div>

      <div className={ `${ styles['ad-info-form'] }` }>
        {
          (ad?.planAim !== 'SELF' && ad?.planAim !== 'EXCLUSIVE_MEDIA') && (
            <>
              <div className={ `${ styles['ad-info-form-item'] }` }>
                <div className={ `${ styles['ad-info-form-label'] }` }>执行单编号</div>
                <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.mallOrderNumber }<span className={ `${ styles['btn-copy'] }` } onClick={ copyText }>复制</span></div>
              </div>
              <div className={ `${ styles['ad-info-form-item'] }` }>
                <div className={ `${ styles['ad-info-form-label'] }` }>执行单创建时间</div>
                <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.confirmOrderTime }</div>
              </div>
            </>
          )
        }
        {
          (ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA') && (
            <>
              <div className={ `${ styles['ad-info-form-item'] }` }>
                <div className={ `${ styles['ad-info-form-label'] }` }>执行单编号</div>
                <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.mallOrderNumber }<span className={ `${ styles['btn-copy'] }` } onClick={ copyText }>复制</span></div>
              </div>
              <div className={ `${ styles['ad-info-form-item'] }` }>
                <div className={ `${ styles['ad-info-form-label'] }` }>创建人</div>
                <div className={ `${ styles['ad-info-form-value'] }` }>
                  {
                    (ad?.confirmUser?.userType !== 'COMPANY' && ad?.deliveryUser?.userType !== 'COMPANY') && (
                      <span className={ `${ styles['role-label'] }` }>{ util.tools.convertRoleName(ad?.confirmUser?.operatorUcRoleItemId || ad?.deliveryUser?.operatorUcRoleItemId) }</span>
                    )
                  }
                  { ad?.confirmUser?.operatorName || ad?.deliveryUser?.operatorName || '-' }
                </div>
              </div>
              <div className={ `${ styles['ad-info-form-item'] }` }>
                <div className={ `${ styles['ad-info-form-label'] }` }>创建时间</div>
                <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.confirmOrderTime }</div>
              </div>

              {
                (getIsPaySuc()) && (
                  <>
                    <div className={ `${ styles['ad-info-form-item'] }` }>
                      <div className={ `${ styles['ad-info-form-label'] }` }>付款者</div>
                      <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.payUser?.operatorName || '-' }</div>
                    </div>
                    <div className={ `${ styles['ad-info-form-item'] }` }>
                      <div className={ `${ styles['ad-info-form-label'] }` }>付款时间</div>
                      <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.payOrderTime || '-' }</div>
                    </div>
                    <div className={ `${ styles['ad-info-form-item'] }` }>
                      <div className={ `${ styles['ad-info-form-label'] }` }>支付方式</div>
                      <div className={ `${ styles['ad-info-form-value'] }` }>在线付款</div>
                    </div>
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
}