import React  from 'react'

import { HashRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'

import {
  ConfigProvider,
} from 'antd'
import 'antd/dist/antd.css'
import zhCN from 'antd/lib/locale/zh_CN'

import './assets/style/reset.css'
import './assets/style/custom-antd.css'
import './assets/style/common.css'
import './assets/style/iconfont/index.css'
/* 菜单图标 */
import './assets/style/iconfont/menufont.css'
/*热幕精灵 - 影院银幕广告助手*/
import './assets/style/iconfont-assistant/index.css'

import styles from './App.module.css'

import { routes } from './router'

export const App = () => {
  // 修改网站标题及icon
  let favicon:any = document.querySelector('link[rel="icon"]')
  const isCinemaHost = window.location.host.includes('cinema.remudooh.com')

  if (isCinemaHost || window.location.hash.includes('/assistant/')) {
    favicon.href = '/assistant-logo.ico?v=1'
    document.title = '映前广告助手'
  }

  return (
    <ConfigProvider locale={ zhCN }>
      <div className={ styles.App }>
        <HashRouter >
          { renderRoutes(routes) } 
        </HashRouter>
      </div>
    </ConfigProvider>
  )
}