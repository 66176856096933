import React, { useState, useEffect, useContext } from 'react'
import styles from './GuideDialog.module.css'

import { Modal, Button } from 'antd'

import { Icons } from '../../../../components'
import util from '../../../../libs/util'
import { bodyStyle, dialogInfo, snowBtnStyle } from './cells'
import { appContext } from '../../../../App.state'
import { cinemaIssueContext } from '../CinemaIssue'

export const GuideDialog: React.FC = () => {
  const appValue = useContext(appContext)
  const value = useContext(cinemaIssueContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [step, setStep] = useState<'step1' | 'step2' | 'step3'>('step1')

  useEffect(() => {
    util.bus.on('showGuideDialog', toggleDialog)
  }, [])

  useEffect(() => {
    dialogInfo.step2.list[1] = `2、下载当前影院【${ value.entityName }】的点位管理配置信息`
  }, [value.entityName])

  const onSwitchStep = (item) => {
    if(!item.step) {
      util.bus.emit('handleCountDown')
      toggleDialog()
      return
    }
    setStep(item.step)
  }

  const toggleDialog = () => {
    setIsModalVisible(!isModalVisible)
  }

  const onDownload = () => {
    const link = document.createElement('a')
    link.setAttribute('href', `${ appValue.eHost }`)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', 'jttmedia_agent')
    link.click()
  }

  const onDownloadAgent = async () => {
    let orgEntityInfoId = value.orgEntityInfoId.replace(/#/g, '%23')

    const result = await util.request({
      mehtod: 'POST',
      url: `resource/device/generateCinemaAgentDb?orgEntityInfoId=${ orgEntityInfoId }`
    })

    if(result.code === 1) {
      const link = document.createElement('a')
      link.setAttribute('href', result.data)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', 'jttmedia_agent')
      link.click()
    }
  }

  return (
    <Modal 
      visible={ isModalVisible }
      closable={ false }
      footer={ null }
      width="862"
      centered={ true }
      bodyStyle={ bodyStyle }
      onCancel={ toggleDialog }
    >
      <div className={ styles['guide-dialog'] }>
        <header className="flex justify-between align-center px4">
          <div></div>
          <h3 className="fs16 fb">
            <span className="mr1">{ dialogInfo.title }</span>
            <Icons color="#999" icon="question" />
          </h3>
          
          <i onClick={ toggleDialog }>
            <Icons color="#999" icon="close" />
          </i>
        </header>
        <div className={ `${ styles.main } pt8 px8` }>
          <h3 className="pb8 fs18 fb">{ dialogInfo[step].title }</h3>
          {
            step === 'step1' ? 
            <div className="mb4">
              <Icons icon="download" />
              <span className="ml2">热幕精灵 (影院版)</span>
              <span className="ml2">
                <Button style={ snowBtnStyle } onClick={ onDownload }>立即下载</Button>
              </span>
            </div> : ''
          }
          
          <ul>
            {
              dialogInfo[step].list.map((val, index) => (
                <li className="mb3" key={ `${ step }_${ index }` }>
                  { val }
                  {
                    (step === 'step2' && index === 1) ?
                    <span className="ml2">
                      <Button style={ snowBtnStyle } onClick={ onDownloadAgent }>立即下载</Button>
                    </span>
                    :
                    ''
                  }
                  
                </li>
              ))
            }
          </ul>
        </div>
        <footer className="flex justify-center align-center">
            {
              dialogInfo[step].btns.map((item, index) => (
                <Button 
                  style={ item.style }
                  data-record={ item }
                  key={ `btns_${ index }` }
                  onClick={() => onSwitchStep(item)}
                >{ item.text }</Button>
              ))
            }
        </footer>
      </div>
    </Modal>
  )
}