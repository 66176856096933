import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Input } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../../libs/util.constants'
import { AddMaterialModal } from '../add-material'
import { PreviewEffectModal } from '../preview-effect-modal'
import { CreateForm } from '../create-form'
import { ScreenEffectModal } from '../screen-effect-modal'
import { VideoPlayModal } from '../../../../components/video-play-modal'

import { AdExec, defaultAdExec } from '../../index'
import styles from './BaseInfo.module.css'

interface Props {
  ad: AdExec,
  onUpDateDeliveryName?: Function,
  onUpdateBaseInfo?: Function,
  isConfirmPage?: boolean,
}

export const BaseInfo: React.FC<Props> = ({ ad, onUpDateDeliveryName, onUpdateBaseInfo, isConfirmPage }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [isEditMode, setIsEditMode] = useState((ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE') && !isConfirmPage)
  // const me = ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED' || (ad.showPlanStatus === 'ORDER_TIMEOUT' && !ad.showPlanStatusMore.includes('PAY_TIMEOUT') && ad.showPlanStatusMore.includes('CONTENT_TIMEOUT')) || ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED'
  const [showMaterial, setShowMaterial] = useState(false)

  const [submitForm, setSubmitForm] = useState<any>()
  useEffect(() => {
    setShowMaterial(ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED' || ad.showPlanStatusMore.includes('CONTENT_YES'))
    setSubmitForm({
      planAim: ad.planAim,
      administrativeAreaId: ad?.administrativeAreaId,
      deliveryStart: ad.deliveryStart,
      deliveryEnd: ad.deliveryEnd,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad?.deliveryIndustry,
      timePeriods: ad?.timePeriods,
      customerId: ad?.customerId,
      operatorType: ad?.operatorType,
      playSequence: ad?.playSequence,
      planAimExt: ad?.planAimExt,
    })
  }, [])
  useEffect(() => {
    ad.mediaDeliveryName && setDeliveryName(ad.mediaDeliveryName)
  }, [ad.mediaDeliveryName])

  const screenEffectModalRef:any = useRef()
  const onShowMaterial = () => {
    screenEffectModalRef.current.onToggle()
  }

  const videoPlayModalRef:any = useRef()
  const previewMedia = () => {
    videoPlayModalRef.current.onToggle(ad.contentUrl)
  }

  const addMaterialModalRef:any = useRef()
  const onMaterial = () => {
    hideMenu()
    addMaterialModalRef.current.onToggle({
      isFormAd: true,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad.deliveryIndustry,
      mallOrderPlanId: ad.mallOrderPlanId,
    })
  }

  const [isMenuShow, setIsMenuShow] = useState(false)
  const showMenu = () => {
    setIsMenuShow(true)
  }
  const hideMenu = () => {
    setIsMenuShow(false)
  }
  const onChangeMaterial = () => {
    history.push(`/ad-exec/content/list/${ ad.mallOrderPlanId }`)
    hideMenu()
  }

  const previewEffectModalRef: any = useRef()
  const onPreview = (record) => {
    previewEffectModalRef.current.showModal(record)
    hideMenu()
  }

  // 广告所属行业
  const getDeliveryIndustry = (ad) => {
    return (<>{ Object.values(ad.deliveryIndustryMore?.itemMap).join(',') }</>)
  }

  const [dayOrderTotal, setDayOrderTotal] = useState(0)
  const [mediaDeliveryName, setDeliveryName] = useState('')

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const afterUpdateBaseInfo = () => {
    onModalCancel()
    onUpdateBaseInfo && onUpdateBaseInfo()
  }

  const inputRef = useRef<any>(null)
  const onDeliveryNameChange = ({ target }) => {
    setDeliveryName(target.value)
  }
  const onDeliveryNameBlur = async() => {
    // 更新计划名称
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/media/order/updateOrderMediaName`,
      data: {
        mallOrderMediaId: ad.mallOrderMediaId,
        mediaDeliveryName,
      },
    })

    if (code !== 1) {
      message.error(msg || '操作失败')
    } else {
      setIsNameEditMode(false)
      message.success('广告计划名称更新成功')
    }
  }
  const clearInput = () => {
    setDeliveryName('')
  }
  const focusInput = () => {
    inputRef.current.focus({
      cursor: 'start',
    })
  }

  const getHasMenu = () => {
    return (ad.showPlanStatus === 'DELIVERY_WAIT' && ad?.showPlanStatusMore.includes('CONTENT_YES') || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED')
  }
  const getIsDefaultImg = () => {
    return ad.showPlanStatus === 'ORDER_TIMEOUT' && !ad?.showPlanStatusMore.includes('PAY_TIMEOUT') && ad?.showPlanStatusMore.includes('CONTENT_TIMEOUT') || ad?.showPlanStatusMore.includes('CONTENT_NULL') || ad?.showPlanStatusMore.includes('CONTENT_DELETE') || ad.showPlanStatus === 'DELIVERY_WAIT' && !ad?.thumbnailUrl.length
  }

  const getImgUrl = () => {
    return ad && ad?.thumbnailUrl && ad?.thumbnailUrl.length ? `${ baseURL }${ ad?.thumbnailUrl[0] }` : ''
  }

  const [isNameEditMode, setIsNameEditMode] = useState(false)
  const toggleIptMode = (show) => {
    setIsNameEditMode(show === undefined ? !isNameEditMode : show)
  }

  return (
  	<>
			<div className={ `${ styles['ad-page-top'] }` }>
        <div className={ `${ styles['ad-labels'] }` }>
          <div className={ `${ styles['ad-label'] } ${ styles['operator'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad?.operatorType) }</div>
          {
            util.tools.convertAdExecChannelName(ad) && (<div className={ `${ styles['ad-label'] } ${ styles['channel'] }` }>{ util.tools.convertAdExecChannelName(ad) }</div>)
          }
          <div className={ `${ styles['ad-label'] } ${ styles['delivery'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad?.deliveryCinemaModel) }</div>
        </div>

        {/* 状态等 */}
        <div className={ `${ styles['ad-page-top-r'] }` }>
          {/*右边操作及提醒*/}
          <div className={ `${ styles['ad-item-opts'] }` }>
            {/*计划关闭提醒*/}
            {
              (util.tools.adExecTimeoutStatus(ad)) && (
                <div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.adExecTimeoutStatus(ad) }</div>
              )
            }

            {/*1分钟之内不显示提示*/}
            {
              (ad?.showPlanStatus === 'ORDER_WAIT' && util.tools.hasLeftTime(ad?.cancelOrderTime)) && (
                <div className={ `${ styles['ad-content-tips'] }` }>剩余付款时间<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad?.cancelOrderTime) }</span>  超时未付款执行单将自动关闭</div>
                )
            }

            {/*自用、独家才有 素材提交提醒*/}
            {
              ((ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA') && ad?.showPlanStatus === 'DELIVERY_WAIT' && (ad?.showPlanStatusMore?.includes('CONTENT_NULL') || ad?.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) && (
                <div className={ `${ styles['ad-content-tips'] }` }>素材需在<span className={ `${ styles['ad-end-date'] }` }>{ ad?.contentUploadDateEnd }</span>前提交，超时未提交执行单将自动关闭</div>
                )
            }

            {/*刊播提醒 */}
            {
              (ad?.showPlanStatus === 'DELIVERY_WAIT' && (!ad?.showPlanStatusMore.includes('CONTENT_NULL') && !ad?.showPlanStatusMore.includes('CONTENT_NOT_MATCH'))) && (
                <div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad?.startBetweenDays }</span>天</div>
                )
            }

            {/*刊播数据*/}
            {
              (ad?.showPlanStatus === 'EXECUTING' && ad?.hasDeliveryDays && ad?.hasDeliveryDays > 0) && (
                <div className={ `${ styles['ad-content-tips'] }` }>已刊播<span className={ `${ styles['ad-delivery-days'] }` }>{ ad?.hasDeliveryDays }</span>天</div>
                )
            }
          </div>

          {/*订单状态*/}
          <div className={ `${ styles['ad-status'] }` }>
          {/*.text*/}
            { !isConfirmPage && ((ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA') ? (ad?.showPlanStatus && ad?.showPlanStatus !== 'DRAFT' ? util.tools.showPlanStatus(ad?.showPlanStatus, true).text : '') : (ad?.showPlanStatus && ad?.showPlanStatus !== 'DRAFT' ? util.tools.adExecStatus(ad?.showPlanStatus).text : '')) }
          </div>
        </div>
      </div>

      <div className={ `${ styles['ad-page-cont-inner'] }` }>
        {/*左边*/}
        {
          showMaterial && (
            <div className={ `${ styles['ad-page-cont-inner-l'] }` }>
              {/*<!-- 素材 -->*/}
              <div className={ `${ styles['order-material'] }` }>
                {/* 超时未提交符合投放要求的素材 || 未提交过素材 || 素材已删除 || 待上刊 */}
                {
                  (getIsDefaultImg()) && (
                      <div className={ `icon ${ styles['order-material-default'] }` }>默认图片，体现素材未提供</div>
                    )
                }

                {/* mask */}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH') || ad.showPlanStatusMore && ad.showPlanStatusMore.length && ad.showPlanStatusMore[0] === 'CONTENT_YES' || (ad.showPlanStatus === 'DELIVERY_WAIT' && ad.contentUrl || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED')) && (
                      <div className={ `${ styles['order-material-mask'] }` }></div>
                    )
                }

                {/*未提交过素材 || 素材已删除*/}
                {
                  (ad.showPlanStatus === 'DELIVERY_WAIT' && (ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore.includes('CONTENT_DELETE'))) && (
                      <>
                        <div className={ `${ styles['order-material-tips'] }` }>
                          {
                            (ad.showPlanStatusMore.includes('CONTENT_DELETE')) && (
                              <div className={ `${ styles['order-material-tips-tit'] }` }>素材已删除</div>
                            )
                          }
                          <div className={ `${ styles['btn-add-material'] }` } onClick={ onMaterial }>提交素材</div>
                        </div>
                      </>
                    )
                }

                {/*素材审核中*/}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_WAIT')) && (
                    <div className={ `${ styles['order-material-tips'] }` }>审核中</div>
                  )
                }

                {/*素材不符合要求*/}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                    <div className={ `${ styles['order-material-tips'] }` }>
                      <div className={ `${ styles['order-material-tips-tit'] }` }>不符合投放要求</div>
                      {/* 素材不可投放原因 */}
                      {
                        (ad.showPlanStatusMore.includes('CONTENT_NO')) && (
                          <div className={ `${ styles['order-material-reasons'] }` }>{ ad.contentApproveOpinion }</div>
                        )
                      }
                      {
                        (ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                          <div className={ `${ styles['order-material-reasons'] }` }>素材的行业与广告计划投放的行业不符</div>
                        )
                      }
                      {
                        (ad.showPlanStatus !== 'FINISHED') && (
                          <div className={ `${ styles['btn-add-material'] }` } onClick={ onMaterial }>提交素材</div>
                        )
                      }
                    </div>
                  )
                }

                {/*上传过素材的*/}
                {
                  (ad.thumbnailUrl && ad.thumbnailUrl.length) && (
                    <div className={ `${ styles['order-material-cont'] }` }>
                      <img className={ `${ styles['order-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/x368`} />
                    </div>
                  )
                }

                {/* 左下角的 */}
                {/* 审核中 || 审核不通过 */}
                {/*上传过素材的*/}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                    <div className={ `${ styles['order-content-duration'] } ${ styles['bottom'] }` }>
                      <div className={ `${ styles['btn-play'] }` }><div className={ `${ styles['icon-play'] } imediafont i-play` }></div></div>
                      <div>{ util.tools.fixedTime((ad.contentDuration || ad.deliveryContentDuration) / 1000) }</div>
                    </div>
                  )
                }

                {/* 中间的 */}
                {
                  (ad.showPlanStatusMore?.length && ad.showPlanStatusMore.includes('CONTENT_YES')) && (
                    <div className={ `${ styles['order-content-duration'] }` } onClick={ previewMedia }>
                      <div className={ `${ styles['btn-play'] }` }><div className={ `${ styles['icon-play'] } imediafont i-play` }></div></div>
                      <div>{ util.tools.fixedTime((ad.contentDuration || ad.deliveryContentDuration) / 1000) }</div>
                    </div>
                  )
                }

                {/*<!-- 菜单 -->*/}
                {
                  (getHasMenu()) && (
                    <div className={ `${ styles['btn-opts'] }` }>
                      <div className={ `${ styles['btn-more'] } imediafont i-more` } onClick={ showMenu }></div>
                      {
                        (isMenuShow) && (
                          <div className={ `${ styles['btn-opts-menu'] }` }>
                            <div className={ `${ styles['btn-opts-menu-item'] }` } onClick={ onChangeMaterial }>素材使用管理</div>
                            <div className={ `${ styles['btn-opts-menu-item'] }` } onClick={ onPreview }>银幕画面效果预览</div>
                          </div>
                        )
                      }
                      
                    </div>
                  )
                }
              </div>

              {/*<!-- 只有待上刊状态 -->*/}
              {
                (ad.showPlanStatus === 'DELIVERY_WAIT' && !ad.showPlanStatusMore.includes('CONTENT_YES')) && (
                  <div className={ `${ styles['order-material-requirements'] }` } onClick={ onShowMaterial }>
                    <div>查看广告素材要求</div>
                    <div className={ `${ styles['btn-see-requirements'] } imediafont i-arrow-right` }></div>
                  </div>
                )
              }
            </div>
          )
        }
        
        <div className={ `${ styles['ad-page-cont-inner-r'] }` }>
          {
            (ad?.mediaDeliveryName) && (
              <div className={ `${ styles['flex'] }` }>
                <div className={ `${ styles['ad-tit-label'] }` }>广告计划名称</div>
                <div className={ `${ styles['delivery-name'] }` }>
                  {
                    isNameEditMode && (
                      <>
                      <Input 
                        className={ `${ styles['delivery-name-ipt'] }` } 
                        value={ mediaDeliveryName }
                        size='large'
                        ref= { inputRef }
                        onChange={ onDeliveryNameChange }
                        maxLength={ 32 }
                         />
                        <div className={ `${ styles['btn-checked'] }` } onClick={ onDeliveryNameBlur }>
                          <div className={ `imediafont i-checked` }></div>
                        </div>
                      </>
                    )
                  }

                  { !isNameEditMode && (
                    <div className={ `${ styles['text-deliveryName'] }` } onClick={ () => { toggleIptMode(true) } }>
                    { mediaDeliveryName || ad.mediaDeliveryName }
                    <div className={ `${ styles['btn-pencile'] }` }>
                      <div className={ `imediafont i-pencile-simple` }></div>
                    </div>
                    </div>
                  )
                  }
                </div>
              </div>
            )
          }

          <div className={ `${ styles['ad-form-wrap'] }` }>
            <div className={ `${ styles['ad-tit-label'] }` }>投放需求</div>
            {
              (isEditMode) && (
                <div className={ `${ styles['btn-edit'] }` } onClick={ showModal }><span className={ `${ styles['icon-pencile'] } imediafont i-pencile-simple` }></span>编辑投放需求</div>
              )
            }

            {/*订单详细数据*/}
            {
              ad?.timePeriods && (
                <div className={ `${ styles['ad-form'] }` }>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
                      投放周期：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      { util.tools.formatDateStr(ad.deliveryStart) }-{ util.tools.formatDateStr(ad.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
                      广告时长：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
                      {
                        (ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
                      播放时段：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        util.tools.convertTimePeriods(ad)
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
                      广告所属行业：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        getDeliveryIndustry(ad)
                      }
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>

      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } callBack={ onUpdateBaseInfo } />

      {/* 视频预览 */}
      <VideoPlayModal childRef={ videoPlayModalRef } />

      {/* 编辑投放需求 弹窗*/}
      <Modal 
        width={ 1104 }
        title="编辑投放需求"
        footer={ null }
        visible={ isModalVisible }
        onCancel={ onModalCancel }
        >
          {/* 表单 */}
          <CreateForm 
            form={ submitForm } 
            deliveryIndustryMore={ ad.deliveryIndustryMore } 
            mallOrderPlanId={ ad.mallOrderPlanId }
            reloadFn={ afterUpdateBaseInfo } />
      </Modal>

      {/*效果预览*/}
      {
        (getHasMenu()) && <PreviewEffectModal childRef={ previewEffectModalRef } imageUrl={ getImgUrl() } />
      }

      {/*广告素材制作要求*/}
      <ScreenEffectModal childRef={ screenEffectModalRef }  />
  	</>
  )
}