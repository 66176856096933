import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from './MainLayout.module.css'

import { useHistory } from 'react-router-dom'
import { Header, Footer, Sidebar } from '../../components'

import { renderRoutes } from 'react-router-config'

import { UPDATE_USER } from '../../store/actionTypes'
import { defaultState as defaultUserState } from '../../store/user/reducer'

import util from '../../libs/util'

export const MainLayout: React.FC = (props: any) => {
  const history = useHistory()
  const [height, setHeight] = useState<number>(600)

  useEffect(() => {
    setHeight(window.innerHeight - 132)
  })

  const dispatch = useDispatch()
  useEffect(() => {
    // 清除登录数据
    const clearLogData = () => {
      history.replace('/sign/signIn')
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
    util.bus.on('UNAUTH', clearLogData)

    return function() {
      util.bus.off('UNAUTH', clearLogData)
    }
  }, [])

  return (
    <div className={ styles.wrap }>
      <Header />
      <div className={ `${ styles.main }` }>
        <Sidebar/>
        <div className={ `${ styles['content'] }` }>
          <div className={ `${ styles['content-inner'] }` } style={{ minHeight: `${ height }px` }}>
            { renderRoutes(props.route.routes) }
            {/*{ props.children }*/}
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  )
}