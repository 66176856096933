import util from '../libs/util'

export const API = {}

// 根据当前级ID和类型获取所有下级行政(省级传空)
export const searchChildrenDistrict = async(data) => {
	const result = await util.request({
    method: 'GET',
    url: '/config/district/searchChildrenDistrict',
    data,
    params: data,
  })

  return result
}

// 根据一个CODE获取到省级市区级中文名称(定位回显用)
export const getFullDistrictById = async(data) => {
  const result = await util.request({
    method: 'GET',
    url: '/config/district/getFullDistrictById',
    data,
    params: data,
  })

  return result
}

// 查询点位主点位场所列表(分页)
export const searchOwnerPointEntityList = async(data) => {
	const result = await util.request({
    method: 'POST',
    url: `/resource/searchOwnerPointEntityList`,
    data,
  })

  return result
}

// 查询点位主点位列表(分页;WX和WEB)
export const searchOwnerPointList = async(data) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/searchOwnerPointList`,
    data,
  })

  return result
}

// 生成影院点位下载密钥
export const generateCinemaDownloadCode = async(data) => {
  const result = await util.request({
    method: 'GET',
    url: '/resource/device/generateCinemaDownloadCode',
    data,
    params: data,
  })

  return result.data
}

// 统计点位主设备（连接数和在线数）
export const getOwnerDeviceState = async(data) => {
  const result = await util.request({
    method: 'POST',
    url: '/resource/getOwnerDeviceState',
    data,
  })

  return result.data
}

// 获取企业认证信息（实名和身份）
export const getCompanyApply = async(data) => {
  const result = await util.request({
    method: 'GET',
    url: '/uc/getCompanyApply',
    data,
    params: data,
  })

  return result.data
}

// 查询我的菜单树
export const getMenuList = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/queryMyMenuTree',
    data,
    params: data,
  })

  return result.data
}

// 查询我的元素集合（根据菜单ID或KEY）
export const getMyElementList = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/queryMyElementList',
    data,
    params: data,
  })

  return result.data
}

// 获取个人信息
export const getUserInfo = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/getMyUserInfo',
    data,
    params: data,
  })

  return result.data
}

// 企业登录
// 获取手机号登录Code
export const getPhoneLoginCode = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/getPhoneLoginCode',
    method: 'GET',
    ...setting,
    headers: {
      userToken: '',
    },
    params: data,
  })

  return result
}

// 根据CODE获取当前端企业列表
export const getOrgUsersByCode = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/org/getOrgUsersByCode',
    method: 'GET',
    ...setting,
    headers: {
      userToken: '',
    },
    params: data,
  })

  return result
}

// 根据CODE换取登录token
export const codeLogin = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/codeLogin',
    method: 'POST',
    data,
    headers: {
      userToken: '',
    },
    ...setting,
  })

  return result
}

// 创建SKU时段管理
export const createSkuPeriods = async(data = {}, setting) => {
  const result = await util.request({
    method: 'POST',
    url: '/mall/sku_period/createSkuPeriods',
    data,
  })

  return result
}

// 获取字典表
export const findEnumItemList = async(data = {}, setting) => {
  const result = await util.request({
    url: `/config/enum/findEnumItemList`,
    method: 'GET',
    data,
    params: data,
  })

  return result
}

// 清空SKU时段管理
export const clearSkuPeriods = async(data = {}) => {
  const result = await util.request({
    method: 'DELETE',
    url: '/mall/sku_period/clearSkuPeriods',
    data,
  })

  return result
}

// 获取操作状态
export const getOperStatus = async(refreshId, setting) => {
  const result = await util.request({
    url: `/mall/sku_period/getOperStatus/${ refreshId }`,
    method: 'GET',
    ...setting,
  })

  return result
}

// 获取点位SKU列表
export const getPointSkuList = async(data = {}, setting) => {
  const result = await util.request({
    url: `/mall/point/sku/getPointSkuList`,
    method: 'GET',
    ...setting,
    params: data,
  })

  return result
}

// 获取点位SKU时段列表
export const getPointSkuPeriodList = async(data = {}, setting) => {
  const result = await util.request({
    url: `/mall/point/sku/getPointSkuPeriodList`,
    method: 'GET',
    ...setting,
    params: data,
  })

  return result
}

// 获取点位SPU信息
export const getPointSpu = async(data = {}, setting) => {
  const result = await util.request({
    url: `/mall/spu/getPointSpu`,
    method: 'POST',
    ...setting,
    data,
    params: data,
  })

  return result
}

// 查询被授权场所资源统计
export const getOrgEntityState = async(data = {}, setting) => {
  try {
    const result = await util.request({
      url: `/resource/entity/getOrgEntityState`,
      method: 'GET',
      ...setting,
      params: data,
    })

    return result
  } catch(e) {
    return e
  }
}

// 获取广告素材
export const getMineContent = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/content/searchMineContent`,
    data,
    params: data,
  })

  return result
}

// 获取存储空间
export const getStorageSpace = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/content/getStorageSpace`,
    data,
    params: data,
  })

  return result
}

// 根据MD5获取源文件记录
export const getContentAdByMd5 = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/getContentAdByMd5`,
    data,
    params: data,
  })

  if (result.code === 1 && result.data) {
    return true
  }

  return false
}

// 新建媒体源文件的记录
export const createOriginal = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/createOriginal`,
    data,
    params: data,
  })

  return result
}

// 删除文件
export const deleteContentAd = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/content/deleteContentAd`,
    data,
    params: data,
  })

  return result
}

// 提交审核
export const createContentApprove = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/content/createContentApprove`,
    data,
    params: data,
  })

  return result
}

// 生成预签名的 HTTPS GET URL
export const getPreSignedUrl = async(data = {}) => {
  const result = util.request({
    url: '/security/getPreSignedUrl',
    data,
    params: data,
    method: 'GET',
  })

  return result
}

// 获取上传文件Cos信息
export const getCosAuthInfo = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/security/getCosAuthInfo`,
    data,
    // params: data,
  })

  return result
}

// 获取媒体源文件的创建记录
export const getContentAdById = async(data = {}, setting) => {
  const result = await util.request({
    url: '/resource/content/getContentAdById',
    method: 'GET',
    params: data,
    ...setting,
  })

  return result
}

// 合成制作新视频
export const makeNewVideo = async(data = {}, setting) => {
  const result = await util.request({
    url: '/resource/content/makeNewVideo',
    method: 'POST',
    data,
    ...setting,
  })

  return result
}