import Cookies from 'js-cookie'

export const cookies = {}

const isLocalHost = window.location.host.includes('localhost')

/**
 * @description 存储 cookies 值
 * @param {string} key 
 * @param {any} value 
 */ 
cookies.set = function(key, value) {
  Cookies.set(key, value, { path: '/', domain: isLocalHost ? '' : '.remudooh.com' })
}

/**
 * @description 拿到 cookies 值
 * @param {String} key cookies key
 */ 
cookies.get = function(key) {
  return Cookies.get(key, { path: '/', domain: isLocalHost ? '' : '.remudooh.com' })
}

/**
 * @description 删除 cookies 值
 * @param {String} key cookies key
 */ 
cookies.remove = function(key) {
  Cookies.remove(key, { path: '/', domain: isLocalHost ? '' : '.remudooh.com' })
}