import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Welcome.module.css'
import { useHistory } from 'react-router-dom'

import { UPDATE_USER } from '../../../store/actionTypes'
import { defaultState as defaultUserState } from '../../../store/user/reducer'

import WelcomeImg from '../../../assets/images/common/welcome.png'
import MiniCode from '../../../assets/images/common/mini-code.jpg'

import util from '../../../libs/util'
import auth from '../signIn/auth'
import store from '../../../redux/store'
import { Header } from '../../../components'

import {
  getCompanyApply,
} from '../../../api'

interface PropsType {
  match: {
    isExact: boolean,
    params: {
      id: 'personal' | 'company',
      status: string
    },
    path: string,
    url: string
  }
}

const defaultMatch = {
  params: {
    id: '',
    status: ''
  }
}

const personalAuth = {
  noaccess: [`您 <i class="text-warning">暂无权限</i> 访问并使用，请联系企业主进行角色授权`],
  unauth: ['抱歉，您还<i class="text-warning">未完成</i>个人实名认证，请扫描扫描上方小程序二维码登录进行认证'],
  inreview: [`您的认证信息<i class="text-warning">审核中</i>，请耐心等待`],
  failed: [`认证审核<i class="text-warning">不通过</i>，请扫描上方小程序二维码进入“我的”页面重新提交认证`],
  mismatching: [`您的认证信息<i class="text-warning">与企业授权不匹配</i>，请联系您的企业`],
  rolepermissions: ['广告执行人员功能服务还在开发中，请扫描上方微信小程序码登录使用产品服务']
}

const defaultCompanyAuth = {
  registeredunauth: [`抱歉，您还 <i class="text-warning">未入驻</i> 媒体主，请扫描上方小程序二维码申请入驻`],
  inreview: ['您的企业认证信息已提交，请耐心等待平台审核'],
  companyfailed: ['企业认证审核不通过'],
  mediafailed: ['媒体主入驻审核不通过'],
  auth: ['广告执行人员功能服务还在开发中，请扫描上方微信小程序码登录使用产品服务']
}

export const Welcome: React.FC<PropsType> = (props) => {
  const history = useHistory()
  const [match, setMatch] = useState(defaultMatch)
  const status = match.params.status

  useEffect(() => {
    setMatch(props.match)
    getUserInfo()
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    // 清除登录数据
    const clearLogData = () => {
      history.replace('/sign/signIn')
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      util.storage.clear()
      util.Cookies.remove('userToken')
    }
    util.bus.on('UNAUTH', clearLogData)

    return function() {
      util.bus.off('UNAUTH', clearLogData)
    }
  }, [])

  const [companyAuth, setCompanyAuth] = useState({...defaultCompanyAuth})
  const [reason, setReason] = useState('')
  // 获取用户信息
  const getUserInfo = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'uc/getMyUserInfo'
    })

    const data = result.data
    
    const myAuth: any = auth(data)

    history.push({ 
      pathname: myAuth.url, 
      state: myAuth 
    })

    const companyAuth = data.companyAuth
    // 点位主审核不通过
    if (status === 'mediafailed' ) {
      setCompanyAuth(state => {
        return {
          ...state,
          mediafailed: [`企业认证审核不通过，原因：${companyAuth.approveOpinion.join(',')}，请扫描下方小程序二维码重新提交认证`],
        }
      })
    }
    // 企业认证审核不通过,调接口查原因
    if (status === 'companyfailed') {
      getReason()
    }

    store.dispatch({
      type: 'UPDATE_USER',
      payload: result.data
    })
  }

  const getReason = async() => {
    const data = await getCompanyApply()
    setCompanyAuth(state => {
      return {
        ...state,
        companyfailed: [`点位主入驻审核不通过, 原因: ${data.companyAuth.approveOpinion.join(',')}，请扫描下方小程序二维码重新提交认证`],
      }
    })
  }

  const hasMiniCode = () => {
    const isPersonal = match.params.id === 'personal'
    let result = false

    if (isPersonal) {
      if (status === 'unauth' || status === 'failed' || status === 'rolepermissions') {
        result = true
      }
    } else {
      if (status === 'registeredunauth' || status === 'companyfailed' || status === 'mediafailed' || status === 'auth') {
        result = true
      }
    }

    return result
  }

  return (
    <>
    <Header fixed={ false } />
    <div className={ styles['welcome-wrap'] }>
      <div className={ styles['welcome-cont'] }>
        {
          hasMiniCode() && <div className={ `icon ${ styles['mini-code'] }` }></div>
        }
        <div className={ styles['welcome-tit'] }>欢迎登录热幕精灵影院广告管家</div>
        {
          match.params.id === 'personal' ? 
          <React.Fragment>
            {
              personalAuth[status]?.map((value, index) => (
                <p className={ styles['welcome-tips'] } key={ index } dangerouslySetInnerHTML={{ __html: value }}></p>
              ))
            }
          </React.Fragment>
          :
          <React.Fragment>
            {
              companyAuth[status]?.map((value, index) => (
                <p className={ styles['welcome-tips'] } key={ index } dangerouslySetInnerHTML={{ __html: value }}></p>
              ))
            }
          </React.Fragment>
        }
        
      </div>
    </div>
    </>
  )
}

