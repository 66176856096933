import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Calendar, } from 'antd'
import moment from 'moment'
import util from '../../../../../libs/util'
import styles from './Sole.module.css'

const dateFormat = 'YYYY-MM-DD'

interface Props {
  childRef: any,
  isShowMode: boolean,
}

interface Point {
  resourcePointId: string,
  pointName: string,
  pointGrade: number | 0,
  entityName: string,
  cinemaDurationFrom: number | 0,
  cinemaDurationTo: number | 0,
  effectDate: string,
  effectDateEnd: string,
}
const defaultPoint: Point = {
  resourcePointId: '',
  pointName: '',
  pointGrade: 0,
  entityName: '',
  cinemaDurationFrom: 0,
  cinemaDurationTo: 0,
  effectDate: '',
  effectDateEnd: '',
}

export const RemoSoleModal: React.FC<Props> = ({ isShowMode, childRef }) => {
	useImperativeHandle(childRef, () => ({
    showModal: async(record) => {
      showModal()
      await getPointDetail(record.resourcePointId)
      await getManageList(record.resourcePointId)
    }
  }))

  const [currentDate, setCurrentDate] = useState('')
  const onPanelChange = (date, mode) => {
    if (mode === 'month') {
      const selectedDateStr = util.moment(date).format('YYYY-MM-DD')
      const selectedDate = new Date(selectedDateStr)
      const year = selectedDate.getFullYear()
      const month = selectedDate.getMonth()
      setCurrentDate(util.tools.formatDate(new Date(year, month, 1)))
    }
  }

  // 点位信息
  const [point, setPoint] = useState(defaultPoint)
  const getPointDetail = async(resourcePointId) => {
    const { code, data } = await util.request({
      method: 'POST',
      url: `/mall/spu/getPointSpu`,
      data: {
        resourcePointId,
        saleModel: 'SPLIT',
      },
    })

    if (code === 1) {
      setPoint(data)
      const currentDate = util.tools.formatDate(new Date(util.tools.formatDateYm(new Date())))
      const start = Math.max(+new Date(currentDate), +new Date(point.effectDate))
      const end = Math.max(+new Date(currentDate), +new Date(point.effectDateEnd))
      const newCurDate = +new Date(currentDate) < +new Date(point.effectDate) ? point.effectDate : (+new Date(currentDate) > +new Date(point.effectDateEnd) ? point.effectDateEnd : currentDate )
      setCurrentDate(newCurDate)
    }
  }

  const dateCellRender = (date) => {
    const dateStr = util.moment(date).format(dateFormat)
    const item = pointSkuList.find(i => i.pointDate === dateStr)
    const isStoped = manageList.find(i => +new Date(dateStr) >= +new Date(i.pointDateStart) && +new Date(dateStr) <= +new Date(i.pointDateEnd))

    return (<>
      { item ? `${ item.splitFreeDuration }s` : '' }
      { isStoped ? <div className={ styles['icon-halt'] }></div> : '' }
    </>)
  }
  const dateFullCellRender = (value) => {
    const dateStr = moment(value).format(dateFormat)
    const item = pointSkuList.find(i => i.pointDate === dateStr)
    const isStoped = manageList.find(i => +new Date(dateStr) >= +new Date(i.pointDateStart) && +new Date(dateStr) <= +new Date(i.pointDateEnd))
    const isToday = dateStr === moment().startOf('day').format(dateFormat)
    return (
      <div className={ `${ styles['calendar-cell'] } ${ isToday ? styles['is-today'] : '' }  ${ item ? styles['calendar-delivery-bg'] : '' } ` }>
        <div className={ `${ styles['calendar-date'] }` }>{ moment(value).date() }</div>
        <>
          { item ? `${ item.splitFreeDuration }s` : '' }
          { isStoped ? <div className={ styles['icon-halt'] }></div> : '' }
        </>
      </div>
    )
  }

  const convertDate = function(date) {
    return date?.split('-')[0] === '9999' ? '长期' : date?.split('-').join('.')
  }
  // YYYY-MM-DD to YYYY.MM.DD
  const formatDate = (dateStr) => {
    return dateStr ? dateStr.split('-').join('.') : ''
  }

  // 停售信息
  const [manageList, setManageList] = useState<any[]>([])
  const getManageList = async(resourcePointId) => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `/mall/sku_manage/getSkuManageListByPointId`,
      params: {
        resourcePointId,
      },
    })

    if (code === 1) {
      setManageList(data)
    }
  }

  // 出售信息
  const [pointSkuList, setPointSkuList] = useState<any[]>([])
  const getPointSkuList = async() => {
    const startMonth = new Date(currentDate)
    const startDate = currentDate
    startMonth.setMonth(startMonth.getMonth() + 1)
    const endDate = util.tools.formatDate(new Date(+startMonth - 24 * 60 * 60 * 1000))

    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `mall/point/sku/getPointSkuList`,
      params: {
        resourcePointId: point.resourcePointId,
        pointDateStart: startDate,
        pointDateEnd: endDate,
      },
    })

    if (code === 1) {
      setPointSkuList(data)
    }
  }
  useEffect(() => {
    currentDate && getPointSkuList()
  }, [currentDate])

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
    setCurrentDate('')
  }

  return (
  	<Modal 
	    width={ 1104 }
	    title="热幕点位库存"
	    visible={ isModalVisible }
	    footer={ null }
      onCancel={ onModalCancel }
	    >
      <div className={ styles['modal-top'] }>
        <div className={ styles['point-name'] }>
          <div className={ styles['point-name-text'] }>{ point.pointName }</div>
          {
            !isShowMode && (
              <div className={ styles['rate-list'] }>
                {
                  new Array(point.pointGrade).fill(1).map((item, index) => {
                    return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
                  })
                }
              </div>
            )
          }
        </div>
        <div className={ styles['entity-name'] }>{ point.entityName }</div>
      </div>

      <div className={ styles['stock-form'] }>
        <div className={ styles['stock-form-item'] }>
          <div className={ styles['stock-form-label'] }>每场电影银幕广告合作时长：</div>
          <div className={ styles['stock-form-value'] }>影片映前第{ point.cinemaDurationFrom + 1 }~{ point.cinemaDurationTo || 0 }分钟({ (point.cinemaDurationTo - point.cinemaDurationFrom) * 60 }s)</div>
        </div>

        <div className={ styles['stock-form-item'] }>
          <div className={ styles['stock-form-label'] }>合作期限：</div>
          <div className={ styles['stock-form-value'] }>{ formatDate(point.effectDate) }-{ convertDate(point.effectDateEnd) }</div>
          {
            manageList.length ? (
              <div className={ styles['halt-list'] }>
                {
                  manageList.map((item) => {
                    return (
                      <div 
                        className={ styles['halt-item'] }
                        key={ item?.mallSkuManageId }
                        >{ formatDate(item.pointDateStart) }-{ formatDate(item.pointDateEnd) } { item.operationType === 'CLOSED' ? '停售' : '' }：{ item.operationDesc }</div>
                    )
                  })
                }
              </div>
            ) : ''
          }
        </div>
      </div>

      <div>
        <Calendar 
          className={ styles['stock-calendar'] } 
          value={ currentDate ? moment(currentDate) : moment() }
          dateFullCellRender={ dateFullCellRender } 
          onPanelChange={ onPanelChange } />
      </div>
	  </Modal>
  )
}