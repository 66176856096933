import React, { useState, useEffect, useContext } from 'react'
import styles from './Desserts.module.css'

import { Button, message, Modal } from 'antd'
import { Icons } from '../../../../../components'
import { cinemaIssueContext, setCinemaIssueContext } from '../../CinemaIssue'
import util from '../../../../../libs/util'
import { defaultBtnStyle } from '../../guideDialog/cells'
import { taskReportStatus } from './cells'
import Loong from '../../../../../assets/images/media/loong.png'

const snowBtnStyle = {
  ...defaultBtnStyle,
  backgroundColor: '#fff',
  color: '#e2a45a'
}

const disabledBtnStyle = {
  ...defaultBtnStyle,
  opacity: .6
}

interface PointType {
  hallName: string,
  resourcePointId: string,
  selected?: boolean,
  disabled?: boolean,
  fileIds: string[],
  children: Array<any>
}

const defaultPoint = {
  hallName: '',
  resourcePointId: '',
  fileIds: [],
  children: []
}

export const Desserts: React.FC = () => {
  const value = useContext(cinemaIssueContext)
  const setValue = useContext(setCinemaIssueContext)
  const [desserts, setDesserts] = useState<PointType[]>([defaultPoint])
  const [taskList, setTaskList] = useState<any[]>([])
  const [currentAd, setCurrentAd] = useState<any>()
  const [RPIds, setRPIds] = useState<string[]>([])
  const [isMark, setIsMark] = useState<boolean>(false)

  const [visibleSumbitReportDialog, setVisibleSumbitReportDialog] = useState<boolean>(false)

  useEffect(() => {
    if(value.visibleIssueDialog) {
      getCinemaPointList()
    }
  }, [value.visibleIssueDialog])

  useEffect(() => {
    const list: any[] = desserts

    util.bus.on('selectDessertsItems', (item) => {
      setCurrentAd(item)

      const ids = list.map(record => {
        if(record.resourcePointId) {
          if(record.fileIds.indexOf(item.fileId) !== -1) {
            record.selected = true
            record.disabled = false
            return record.resourcePointId
          }else {
            record.selected = false
            record.disabled = true
          }
        }
      })

      setDesserts([...list])
      setRPIds([...ids])

      taskList.map(record => {
        if(record.number === item.number) {
          record.selected = true
        }else {
          record.selected = false
        }
      })

      setTaskList([...taskList])
    })
  }, [desserts, taskList])

  useEffect(() => {
    if(taskList.length) {
      const valid = taskList.every(item => {
        return item.taskReportStatus !== 'REPORT_WAIT'
      })

      if(valid) {
        setIsMark(true)
      }else {
        setIsMark(false)
      }
    }
  }, [taskList])

  useEffect(() => {
    console.log(currentAd)
  }, [currentAd])

  const getCinemaPointList = async () => {
    setDesserts([])
    setTaskList([])

    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/searchCinemaPointList',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      const list: any[] = result.data

      list.map(async (item) => {
        const result: any[] = await getCinemaPointTaskList(item.resourcePointId) || []
        setTaskList(taskList => [...taskList, ...result])

        item.fileIds = []

        result.map((record) => {
          if(item.resourcePointId === record.resourcePointId) {
            item.fileIds.push(record.fileId)
          }
        })
      })

      console.log(list)

      setDesserts(list)
    }
  }

  const getCinemaPointTaskList  = async (id) => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/getCinemaPointTaskList',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId,
        resourcePointId: [id]
      }
    })

    if(result.code === 1) { 
      let list: any[] = []
      list = result.data
      
      list.map((item, index) => {
        if(item.fileConvertCode === 0) {
          item.number = item.filePath.substr(item.filePath.lastIndexOf('\\') + 1) 
        }else {
          item.number = item.contentNumber
        }

        item.index = index
        item.pSelected = true
      })
      
      list.reverse()

      return list
    }
  }

  const onSuccess = () => {
    reportCinemaTaskStatus('DRAFT_SUCCESS')
  }

  const onFailure = () => {
    reportCinemaTaskStatus('DRAFT_FAILURE')
  }

  const onReport = () => {
    reportCinemaTaskStatus('REPORT')
  }

  const reportCinemaTaskStatus = async (status) => {
    
    if(currentAd) {
      const result = await util.request({
        method: 'POST',
        url: 'resource/delivery/cinema/reportCinemaTaskStatus',
        data: {
          deliveryDate: value.deliveryDate,
          orgEntityInfoId: value.orgEntityInfoId,
          taskReportStatus: status,
          fileId: currentAd.fileId,
          resourcePointId: RPIds
        }
      })

      if(result.code === 1) {
        if(status === 'DRAFT_SUCCESS' || status === 'DRAFT_FAILURE') {
          const list: any[] = taskList

          console.log(currentAd.fileId)

          let [reportSuccessCount, reportFailureCount] = [0, 0]

          list.map(record => {
            if(record.fileId === currentAd.fileId && record.pSelected) {
              record.taskReportStatus = status

              if(status === 'DRAFT_FAILURE') {
                record.reportSuccessCount = 0
                record.reportFailureCount = ++record.reportFailureCount
              }

              if(status === 'DRAFT_SUCCESS') {
                record.reportSuccessCount = ++record.reportSuccessCount
                record.reportFailureCount = 0
              }

              reportSuccessCount += record.reportSuccessCount
              reportFailureCount += record.reportFailureCount

              console.log(record)
            }

            if(record.fileId === currentAd.fileId && !record.pSelected) {
              reportSuccessCount = record.reportSuccessCount
              reportFailureCount = record.reportFailureCount
            }
          })

          console.log(reportSuccessCount, reportFailureCount)
          console.log(list)

          setTaskList([...list])
        }

        // 提交上刊执行结果 TODO
        if (status === 'REPORT') {
          message.success('提交成功')
          setVisibleSumbitReportDialog(!visibleSumbitReportDialog)
          if(setValue) {
            setValue(state => {
              return {
                ...state,
                visibleIssueDialog: !value.visibleIssueDialog
              }
            })
          }
        }

        util.bus.emit('handleReportCinemaTaskStatus')

        return
      }
    }
  }

  const onSelect = (item) => {

    if(item.fileIds.indexOf(currentAd.fileId) === -1) {
      return
    }

    if(currentAd?.taskReportStatus === 'REPORT_FAILURE' || currentAd?.taskReportStatus === 'REPORT_SUCCESS') {
      return
    }

    let _desserts: any[] = desserts
    let _taskList: any[] = taskList

    const ids = _desserts.map(record => {
      if(record.resourceCinemaHallId === item.resourceCinemaHallId) {
        record.selected = !record.selected
      }

      if(record.selected) {
        return record.resourcePointId
      }
    })

    _taskList.map(record => {
      if(item.selected) {
        if(item.resourcePointId === record.resourcePointId) {
          record.pSelected = true
        }else {
          record.pSelected = record.pSelected
        }
      }else {
        if(item.resourcePointId === record.resourcePointId) {
          record.pSelected = false
        }else {
          record.pSelected = record.pSelected
        }
      }
    })

    setDesserts([..._desserts])
    setTaskList([..._taskList])
    setRPIds([...ids])
  }

  const toggleReportDialog = () => {
    setVisibleSumbitReportDialog(!visibleSumbitReportDialog)
  }

  return (
    <div className={ styles.desserts }>
      <div className={ `${ styles.top } flex justify-between align-center px4 py4` }>
        <p className={ styles.path }>
          <i></i>
          <span>广告素材存放路径：{ currentAd?.filePath }</span>
        </p>

        <React.Fragment>
          {
            (currentAd?.taskReportStatus === 'REPORT_FAILURE' || currentAd?.taskReportStatus === 'REPORT_SUCCESS') ? '' 
            :
            (
              <div className={ styles.btn }>
                <span className="mr4">标记为</span>
                <Button 
                  className="mr4" 
                  style={ defaultBtnStyle } 
                  onClick={ onSuccess }
                  disabled={ currentAd?.fileConvertCode != 0 }
                >已上刊</Button>
                <Button 
                  style={ snowBtnStyle } 
                  onClick={ onFailure }
                >缺刊</Button>
              </div>
            )
          }
        </React.Fragment>
      </div>
      
      <div className={ styles.cards }>
        <ul className={ `${ styles.list } flex wrap px12` }>
          {
            desserts.map((item, index) => (
              <li 
                className={ 
                  `
                    ${ styles.card } 
                    ${ (currentAd?.taskReportStatus === 'REPORT_FAILURE' || currentAd?.taskReportStatus === 'REPORT_SUCCESS') 
                    ? 
                    styles['gray-selected'] 
                    : 
                    (!item.selected ? '' : styles.selected) }
                    pa2 mr4
                  ` 
                }
                key={ `desserts_item_${ index }` }
                onClick={ () => onSelect(item) }
              >
                <div className={ styles['card-content'] }>
                
                  <h3 className={ `${ styles.title } mb2` }>{ item.hallName }</h3>
                  <ul className={ `${ styles.content }` }>
                    {
                      <React.Fragment>
                        {
                          taskList.map((record, recordIndex) => {
                            if(record.resourcePointId === item.resourcePointId) {
                              return (
                                <li 
                                  className={ 
                                    `
                                      ${ !record.selected ? '' : styles.selected } 
                                      flex align-center py1
                                    ` 
                                  }
                                  key={ `desserts_item_record_${ index }_${ recordIndex }` }
                                >
                                  <i className={ `${ styles.index } mr2 tc fs12` }>{ record.index + 1 }</i>
                                  <span className={ `${ styles.num } fs12` }>{ record.number }</span>
                                  <span className={ `${ styles.sec } ml2 fs12` }>{ Math.round(record.contentDuration / 1000) }s</span>
                                  {
                                    record.selected ? 
                                    (<span className={ 
                                      `
                                        ${ styles.status } 
                                        ${ 
                                          (record.taskReportStatus === 'DRAFT_FAILURE' || record.taskReportStatus === 'REPORT_FAILURE') 
                                          ? 
                                          styles.dangers : '' 
                                        } 
                                        fs12 px2
                                      ` 
                                    }>
                                      { taskReportStatus(record.taskReportStatus) }
                                    </span>)
                                    :
                                    ''
                                  }
                                </li>
                              )
                            }
                          })
                        }

                        <li className="flex align-center" key="desserts_item_record_last">
                          <img width="24" src={ Loong } alt="" />
                          <span className="ml2">正片</span>
                        </li>
                      </React.Fragment>
                    }
                  </ul>
                  
                  {
                    (value.reportStatus.REPORT_WAIT > 0 || value.reportStatus.DRAFT_SUCCESS > 0 || value.reportStatus.DRAFT_FAILURE > 0) ?
                    <i className={ styles.check }>
                      {
                        (currentAd?.taskReportStatus === 'REPORT_FAILURE' || currentAd?.taskReportStatus === 'REPORT_SUCCESS') 
                          ? 
                          <Icons icon="check" color="#383C42" size="36" />
                          : 
                        (
                          !item.selected 
                          ? 
                          (
                            item.disabled 
                            ?
                            ''
                            :
                            <Icons icon="check" color="#383C42" size="36" />
                          )
                          : 
                          <Icons icon="check" color="#E2A45A" size="36" />
                        )
                      }
                    </i>: ''
                  }

                  {
                    (value.reportStatus.REPORT_WAIT > 0 || value.reportStatus.DRAFT_SUCCESS > 0 || value.reportStatus.DRAFT_FAILURE > 0) ?
                    ( item.disabled ? <span className={ styles.cover }></span> : '' ) : ''
                  }

                </div>
              </li>
            ))
          }
        </ul>
      </div>
      
      <React.Fragment>
        {
          (currentAd?.taskReportStatus === 'REPORT_FAILURE' || currentAd?.taskReportStatus === 'REPORT_SUCCESS') ? ''
          :
          (
            <div className={  `${ styles.bottom } flex justify-center align-center pt2 pb3`}>
              <Button 
                className="mr4" 
                style={ isMark ? defaultBtnStyle : disabledBtnStyle }  
                onClick={ toggleReportDialog }
                disabled={ !isMark }
              >提交上刊执行结果</Button>
            </div>
          )
        }
      </React.Fragment>

      <Modal 
        visible={ visibleSumbitReportDialog }
        closable={ false }
        footer={ null }
        width="744"
        bodyStyle={{ padding: '0', width: '744px' }}
        centered={ true }
      >
        <div className={ styles['submit-report-dialog'] }>
          <header className="flex justify-between align-center py3 px4">
            <div></div>

            <h3 className="fs16 fb">
              <span className="mr1">提交上刊执行结果</span>
            </h3>
            
            <i onClick={ toggleReportDialog }>
              <Icons color="#999" icon="close" />
            </i>
          </header>

          <div className={ `${ styles.main } tc py9` }>
            提交后不可修改上刊结果，确定提交？
          </div>

          <footer className="flex justify-center align-center py4">
            <Button 
              className="mr4" 
              style={ snowBtnStyle } 
              onClick={ toggleReportDialog }
            >取消</Button>

            <Button 
              style={ defaultBtnStyle } 
              onClick={ onReport }
            >确定</Button>
          </footer>
        </div>
      </Modal>
    </div>
  )
}