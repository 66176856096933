import React, { useState, useImperativeHandle, useRef, useEffect } from 'react'
import { Select } from 'antd'
import styles from './SelectWorkTime.module.css'

interface Props {
  // 间隔时间
  intervalHours?: number,
  childRef?: any,
  getValues?: any,
  disabled?: boolean,
}
const fixZero = (i) => {
  return i < 10 ? `0${ i }` : i
}
const getHour = (i) => {
  return i >= 24 ? i - 24 : i
}
const getMinutes = (i) => {
  return i === Math.round(i) ? '00' : '30'
}
const getFormatWorkTime = (value) => {
  if (value === '') {
    return
  }
  const hour = Math.floor(value)
  const isTotalData = value === hour
  return `${ fixZero(hour) }:${ isTotalData ? '00' : '30'}`
}
const getTimeNumber = (value) => {
  let h = value.split(':')
  return parseInt(h[0]) + (h[1] === '30' ? 0.5 : 0)
}

export const SelectWorkTime: React.FC<Props> = ({ childRef, intervalHours, disabled, getValues }) => {
  useImperativeHandle(childRef, () => ({
    setValues(start, end) {
      setWorkStart(start)
      setWorkEnd(end)
    }
  }))
  const hours = intervalHours || 6
  const [workStart, setWorkStart] = useState<any>('')
  const [workStartItems, setWorkStartItems] = useState<any>([])

  const [workEnd, setWorkEnd] = useState<any>('')
  const [workEndItems, setWorkEndItems] = useState<any>([])
  
  useEffect(() => {
    init()
  }, [])
  useEffect(() => {
    if (workStart === '') {
      return
    }
    setWorkEndItems(initSelectOptions('end'))
  }, [workStart])
  useEffect(() => {
    if (!workStart || !workEnd) {
      return
    }
    returnWorkTime()
  }, [workStart, workEnd])

  const init = () => {
    setWorkStartItems(initSelectOptions('start'))
  }

  const initSelectOptions = (type) => {
    let start = 0
    let end = 23
    let options:any = []
    let j = '00'
    const workS = getTimeNumber(workStart)
    if (type !== 'start') {
      start = workS + hours
      end = start == 6 ? 23.5 : workS - 1 + 24
    }
    for (let i = start; i <= end + 0.5; i += 0.5) {
      j = getMinutes(i)
      const hour = Math.floor(i)
      const value = getHour(i)
      const last = getHour(hour)
      const isZeroHour = type !== 'start' && last === 0 && i !== start && j === '00'
      const label = value < workS && !isZeroHour ? '次日' : ''
      const formatValue = `${ isZeroHour ? '24' : fixZero(getHour(hour)) }:${ j }`
      options.push({
        value: getFormatWorkTime(i),
        label: `${ label }${ formatValue }`,
      })
    }
    return options
  }

  const onWorkStartChange = (value) => {
    setWorkStart(value)
    const startNumber = getTimeNumber(value)
    const d = getTimeNumber(workEnd) - startNumber
    if (workEnd !== '' && d < hours) {
      const end = (startNumber + hours) % 24
      setWorkEnd(getFormatWorkTime(end))
    }
    setWorkEndItems(initSelectOptions('end'))
  }
  const onWorkEndChange = (value) => {
    setWorkEnd(value)
  }

  const returnWorkTime = () => {
    getValues && getValues({
      workStart,
      workEnd,
    })
  }

  return (
  	<div className={ styles['select-wrap'] }>
      <Select 
        className={ styles['select'] }
        placeholder="请选择"
        size='large'
        disabled={ disabled }
        value={ workStart }
        options={ workStartItems }
        onChange={ onWorkStartChange }>
      </Select>
      ~
      <Select 
        className={ styles['select'] }
        placeholder="请选择"
        size='large'
        disabled={ disabled }
        value={ workEnd }
        options={ workEndItems }
        onChange={ onWorkEndChange }>
      </Select>
    </div>
  )
}