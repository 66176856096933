import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Modal } from 'antd'
import styles from './SelectOrderType.module.css'
import { OrderTypeList } from '../../../../libs/util.constants'
import util from '../../../../libs/util'

interface Props {
  childRef: any,
  onConfirm?: any,
}

export const SelectOrderType: React.FC<Props> = ({ childRef, onConfirm }) => {
  useImperativeHandle(childRef, () => ({
    onToggle() {
      getUsableContractState()
      getFee()
      showModal()
    }
  }))
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const [curType, setCurType] = useState<any>()
  const [tabList, SetTabList] = useState<any>([])
  const toggleTab = (item) => {
    setCurType(item)
  }
  
  const getUsableContractState = async() => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/biz_entity/getUsableBizSaleEntityState`,
      params: {},
    })
    if (code === 1) {
      const { adCustomerTotal, mediaContractEntityTotal, mediaCustomerTotal } = data
      /**
       * 热幕显示：mediaContractEntityTotal == 0  && mediaCustomerTotal == 0
        媒体主自用投放显示：mediaContractEntityTotal > 0
        媒体主独家显示：mediaCustomerTotal > 0
        广告主独家显示：adCustomerTotal > 0
        VIP显示：vip接口返回>0
      */

      let list = JSON.parse(JSON.stringify(OrderTypeList))
      // 无 自用投放
      if (mediaContractEntityTotal <= 0) {
        const index = list.findIndex(i => i.id === 'SELF' && i.planAimExt == null)
        list.splice(index, 1)
      }
      // 无 独家
      if (mediaCustomerTotal <= 0) {
        const index = list.findIndex(i => i.id === 'EXCLUSIVE_MEDIA')
        list.splice(index, 1)
      }

      SetTabList(list)
      toggleTab(list[0])
    }
  }

  const findEnumItemList = async(key) => {
    const params = {
      groupKeyArray: key,
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
   	return data[key]
  }
  const [fee, setFee] = useState(0)
  const getFee = async () => {
    const item = await findEnumItemList('TECHNIC_FEE_OWN_CPT')
    setFee(item[0].itemValue)
  }

  const history = useHistory()
  const toCreatePage = () => {
    onConfirm && onConfirm(curType)
	}

  {/*选择执行单类型*/}
  return (
    <Modal 
      title="选择执行单类型" 
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) }}
      >
      <div className={ styles['modal-cont'] }>
        <div className={ styles['tab-list'] }>
          {
            tabList.map((item, index) => {
              return (
                <div key={ index } className={ `${ styles['tab-item'] } ${ item.id === curType?.id && item.planAimExt === curType?.planAimExt ? styles['is-selected'] : '' }` } onClick={ () => { toggleTab(item) } }>
                  { item.name }
                </div>
              )
            })
          }
        </div>
        <div className={ `${ styles['tab-cont'] }` }>
          <div className={ styles['service-list'] }>
            {
              curType?.serviceList.map((service) => {
                return (
                  <div className={ styles['service-item'] } key={ service.id }>
                    <div className={ styles['service-item-tit'] }>
                      <div className={ styles['service-item-tit-name'] }>{ service.name }
                        {
                          (!service.hasFee && service.id === 'other') && (<span className={ styles['service-item-tit-tips'] }>（无）</span>)
                        }
                      </div>
                      <div className={ styles['service-item-tit-tips'] }>
                        {
                          (service.hasFee) && (
                            <>
                            { 
                              (fee && service.id === 'other') && (<>{ fee }</>)
                            }{ service.tips }
                            </>
                          )
                        }
                        {
                          (!service.hasFee) && (
                            <>
                            {
                              (service.id === 'other') && '无费用'
                            }
                            {
                              (service.id !== 'other') && '免费'
                            }
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className={ styles['service-des-list'] }>
                      {
                        service?.list.map((item, index) => {
                          return (
                            <div className={ styles['service-des-item'] } key={ item }>
                              <div className={ styles['service-des-item-text'] }>{ index + 1 }. { item }</div>
                              <div className={ `${ styles['service-des-item-icon'] } imediafont i-check-solid ${ service.hasFee && service.id === 'other' || service.id !== 'other' ? styles['is-checked'] : '' }` }></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={ styles['modal-footer'] }>
        <button className={ styles['btn-confirm'] } onClick={ toCreatePage }>去投放</button>
      </div>
    </Modal>
  )
}