import React, { useState, useEffect } from 'react'
import styles from './Line.module.css'

interface Style {
  mt?: number,
  mr?: number,
  mb?: number,
  ml?: number,
  width?: number,
  height?: number,
  color?: string
}

const defaultLineStyle: Style = {
  mt: 0,
  mr: 16,
  mb: 0,
  ml: 16,
  width: 2,
  height: 12,
  color: '#999'
}

export const Line: React.FC<Style> = (props) => {
  const [style, setStyle] = useState(defaultLineStyle)

  useEffect(() => {
    setStyle({...style, ...props})
  }, [])

  return (
    <div className={ styles.line } style={{
      margin: `${ style.mt }px ${ style.mr }px ${ style.mb }px ${ style.ml }px`,
      width: style.width,
      height: style.height,
      backgroundColor: style.color
    }}></div>
  )
}