export * from './list'
export * from './create'
export * from './plan-detail'
export * from './media-detail'
export * from './content'
export * from './select-points'
export * from './select-hall'
export * from './confirm'
export * from './cashier'
export * from './report'

export interface AdExec {
  operatorType: string,
  deliveryCinemaModel: string,
  approveStatus: string,
  showPlanStatusMore: string,
  planAim: string,
  cancelOrderTime: string,
  contentUploadDateEnd: string,
  mediaDeliveryName: string,
  mallOrderMediaId: string,
  mallMediaApproveId?: string,
  mallOrderPlanId?: string,
  orderTotal: any,
  contentUrl?: string,
  showPlanStatus?: string,
  startBetweenDays?: number,
  mallOrderNumber?: string,
  payOrderTime?: string,
  payUser?: any,
  confirmUser?: any,
  hasDeliveryDays?: number,
  thumbnailUrl: string,
  contentApproveOpinion: string,
  contentDuration: number,
  deliveryContentDuration: number,
  timePeriods?: any[],
  deliveryStart?: string,
  deliveryEnd?: string,
  deliveryDays: number,
  deliveryModel?: string,
  deliveryIndustryMore?: {
    itemMap?: string,
  },
  planAmount?: number,
  confirmOrderTime?: string,
  administrativeAreaId?: any[],
  deliveryIndustry?: any[],
  customerId?: string,
  districtList?: any[],
  deliveryUser?: any,
  playSequence?: any,
  planAimExt?: any,
}
export const defaultAdExec: AdExec = {
  operatorType: '',
  deliveryCinemaModel: '',
  approveStatus: '',
  showPlanStatusMore: '',
  planAim: '',
  cancelOrderTime: '',
  contentUploadDateEnd: '',
  mediaDeliveryName: '',
  mallOrderMediaId: '',
  mallMediaApproveId: '',
  mallOrderPlanId: '',
  orderTotal: null,
  contentUrl: '',
  showPlanStatus: '',
  startBetweenDays: 0,
  mallOrderNumber: '',
  payOrderTime: '',
  payUser: {},
  confirmUser: {},
  hasDeliveryDays: 0,
  thumbnailUrl: '',
  contentApproveOpinion: '',
  contentDuration: 0,
  deliveryContentDuration: 0,
  timePeriods: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryDays: 0,
  deliveryModel: '',
  deliveryIndustryMore: {
    itemMap: '',
  },
  planAmount: 0,
  confirmOrderTime: '',
  administrativeAreaId: [],
  deliveryIndustry: [],
  customerId: '',
  districtList: [],
  deliveryUser: '',
  playSequence: 99,
  planAimExt: null,
}