import React, { useState, useEffect, useRef } from 'react'
import { Spin, Modal, message, Pagination } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'

import { NoRecord, Crumb } from '../../../components'

import styles from './SelectPoints.module.css'
import { FilterPointsPanel } from './filter/Filter'
import { Bottom } from './bottom'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
    },
  },
}

interface ContextValueType {
  administrativeAreaId: any[],
  cinemaChainName: any[],
  pointMinDays: string | number,
  entityLocation: any[],
  geoDistance: number | null,
  keyWord: string,
  mallOrderPlanId: string,
  usableSearch: boolean,
}

const defaultFilterContextValue: ContextValueType = {
  administrativeAreaId: [],
  cinemaChainName: [],
  pointMinDays: 1,
  entityLocation: [],
  geoDistance: null,
  keyWord: '',
  mallOrderPlanId: '',
  usableSearch: true,
}

interface Ad {
  operatorType: string,
  deliveryCinemaModel: string,
  approveStatus: string,
  showPlanStatusMore: string,
  planAim: string,
  cancelOrderTime: string,
  contentUploadDateEnd: string,
  mediaDeliveryName: string,
  mallOrderMediaId?: string,
  mallMediaApproveId?: string,
  mallOrderPlanId?: string,
  orderTotal: any,
  contentUrl?: string,
  showPlanStatus?: string,
  startBetweenDays?: number,
  mallOrderNumber?: string,
  payOrderTime?: string,
  payUser?: any,
  confirmUser?: any,
  hasDeliveryDays?: number,
  thumbnailUrl: string,
  contentApproveOpinion: string,
  contentDuration: number,
  deliveryContentDuration: number,
  timePeriods?: any[],
  deliveryStart?: string,
  deliveryEnd?: string,
  deliveryDays?: number,
  deliveryModel?: string,
  deliveryIndustryMore?: {
    itemMap?: string,
  },
  planAmount?: number,
  confirmOrderTime?: string,
}
const defaultAdExec: Ad = {
  operatorType: '',
  deliveryCinemaModel: '',
  approveStatus: '',
  showPlanStatusMore: '',
  planAim: '',
  cancelOrderTime: '',
  contentUploadDateEnd: '',
  mediaDeliveryName: '',
  mallOrderMediaId: '',
  mallMediaApproveId: '',
  mallOrderPlanId: '',
  orderTotal: null,
  contentUrl: '',
  showPlanStatus: '',
  startBetweenDays: 0,
  mallOrderNumber: '',
  payOrderTime: '',
  payUser: {},
  confirmUser: {},
  hasDeliveryDays: 0,
  thumbnailUrl: '',
  contentApproveOpinion: '',
  contentDuration: 0,
  deliveryContentDuration: 0,
  timePeriods: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryDays: 0,
  deliveryModel: '',
  deliveryIndustryMore: {
    itemMap: '',
  },
  planAmount: 0,
  confirmOrderTime: '',
}
export const filterPointsContext = React.createContext(defaultFilterContextValue)
export const setFilterPointsContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | any>(undefined)

export const AdExecSelectPoints: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const onBack = () => {
    history.go(-1)
  }

  const { mallOrderPlanId } = props.match.params
  // const [mallOrderPlanId, setMallOrderPlanId] = useState(props.match.params.mallOrderPlanId)
  const [filterData, setFilterData] = useState(defaultFilterContextValue)

  useEffect(() => {
    if (!filterData.administrativeAreaId || !filterData.administrativeAreaId.length) {
      return
    }
    getList(defaultCurrent)
    getMallState()
  }, [filterData])

  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrent] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  // useEffect(() => {
  //   getList(pageNum)
  // }, [pageNum])
  // useEffect(() => {
  //   setPageNum(defaultCurrent)
  // }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)

  useEffect(() => {
    getAdDetail()
    getOrderTotal()
  }, [])

  // 详情
  const [ad, setAd] = useState<any>(defaultAdExec)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1) {
      setAd(data)
      setFilterData((state) => {
        return {
          ...state,
          // pointMinDays: data.deliveryDays,
          administrativeAreaId: data.districtList.map(i => i.districtId),
        }
      })
    }
  }

  const getOrderTotal = async() => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      setAd(state => {
        return {
          ...state,
          orderTotal: data.find(i => i.mallOrderPlanId === mallOrderPlanId) || null,
        }
      })
    }
  }

  // 获取列表
  const getList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/point/searchMallEntityList?pageNum=${ pNum }&pageSize=${ pageSize }`,
      data: Object.assign(filterData, {
        mallOrderPlanId,
      }),
    })
    if (code === 1) {
      setList(data)
      setTotal(total)
      setIsSubmiting(false)
    }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
    getList(defaultCurrent)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
    setPageNum(defaultCurrent)
    getList(defaultCurrent)
  }

  const [entityUsableCount, setEntityUsableCount] = useState(0)
  const [usableCount, setUsableCount] = useState(0)
  // 点位统计
  const getMallState = async() => {
    const queryData = Object.assign({}, filterData, {
      usableSearch: true,
    })

    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/point/getMallState`,
      data: queryData,
    })

    if (code === 1) {
      const { entityUsableCount, usableCount } = data
      setEntityUsableCount(entityUsableCount)
      setUsableCount(usableCount)
    }
  }

  const goToDetailPage = () => {
    history.push(`/ad-exec/media-detail/${ ad.mallOrderPlanId }`)
  }

  const toggleToCart = async(entityIndex) => {
    let item = list[entityIndex]
    const type = item.hasChecked ? 'remove' : 'add'

    if (type === 'add' && item.hasChecked) {
      return
    }

    if (type === 'remove' && !item.hasChecked) {
      return
    }

    const { code, message: msg } = await toggleSelectPoints(type, item.resourceEntityInfoId)

    if (code === 1) {
      item.orderEntityState.checkedCount = type === 'add' ? item.usableCount : 0
      item.hasChecked = type === 'add' ? true : false
      setList(list)
      getOrderTotal()
    } else {
      message.error(msg || '操作失败')
    }
    
  }
  const toggleSelectPoints = async(type, resourceEntityInfoId) => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    let queryData = Object.assign({
      selectAll: '',
    }, {
      ...filterData,
      entityInfoList: resourceEntityInfoId ? [{
        selectModel: 'ALL',
        resourceEntityInfoId,
      }]: [],
    }, {
      usableSearch: true,
    })

    if (type === 'remove') {
      queryData.selectAll = resourceEntityInfoId ? 'NO' : 'YES'
    }

    const apiUrl = type === 'add' ? '/mall/order/selectMallPoint' : '/mall/order/removeOrderPoint'
    const result = await util.request({
      method: 'POST',
      url: apiUrl,
      data: queryData,
    })

    setIsSubmiting(false)

    console.log('toggleSelectPoints', result)
    return result
  }

  // 一键添加
  const addAllToCart = async(e) => {
    const type = 'add'

    await toggleSelectPoints('add', '')

    list.forEach(item => {
      item.orderEntityState.checkedCount = type === 'add' ? item.usableCount : 0
      item.hasChecked = type === 'add' ? true : false
    })
    setList(list)
    getOrderTotal()

    confirm({
      title: '提示',
      content: '已全部加入购物车',
      cancelText: '返回广告计划',
      okText: '继续选点',
      onOk() {
      },
      onCancel() {
        goToDetailPage()
      },
    })
  }

  const goToHallPage = (id) => {
    history.push(`/ad-exec/select-hall/${ mallOrderPlanId }/${ JSON.stringify(filterData) }/${ id }/${ ad.deliveryContentDuration }/${ ad.deliveryDays }/${ JSON.stringify(ad.timePeriods) }/${ ad.playSequence }/${ ad.showPlanStatus }/${ ad.deliveryModel }/${ ad.planAim }`)
  }

  const crumbList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '创建广告执行单',
      path: '/ad-exec/create',
    },
    {
      name: '广告执行单详情',
      path: `/ad-exec/media-detail/${ mallOrderPlanId }`,
    },
    {
      name: '选择广告点位',
      path: ``,
    }
  ]

  return (
    <>
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>选择广告点位</div>
        </div>
        
        <filterPointsContext.Provider value={ filterData }>
          <setFilterPointsContext.Provider value={ setFilterData }>
            <FilterPointsPanel ad={ ad } />
            {
              (list.length > 0) && (
                <div className={ `${ styles['list-cont'] }` }>
                  <div className={ `${ styles['list-top'] }` }>
                    <div className={ `${ styles['list-tips'] }` }>为你找到场所 { entityUsableCount } 家，可投点位 { usableCount } 个</div>
                    <div className={ `${ styles['btn-all'] }` } onClick={ addAllToCart }>一键添加</div>
                  </div>

                  <div className={ `${ styles['list-wrap'] }` }>
                    {
                      list.map((entity, entityIndex) => {
                        return (
                          <div 
                            className={ `${ styles['list-item'] } ${ entity.orderEntityState.checkedCount < entity.usableCount ? (entity.orderEntityState.checkedCount === 0 ? styles['is-optional'] : styles['is-part']) : styles['is-selected'] } ${ entity.usableCount === 0 ? styles['is-optional'] : '' }` } 
                            key={ entity.resourceEntityInfoId }
                            >
                            <div className={ `${ styles['list-item-l'] }` } onClick={ () => { goToHallPage(entity.resourceEntityInfoId) } }>
                              <img className={ `${ styles['entity-image'] }` } src={ `${ entity?.entityViewPath }?imageMogr2/thumbnail/x88` } />
                              <div className={ `${ styles['chain-name'] }` }>{ entity.cinemaChainName }</div>
                            </div>
                            <div className={ `${ styles['list-item-r'] }` } onClick={ () => { goToHallPage(entity.resourceEntityInfoId) } }>
                              <div className={ `${ styles['entity-tit'] }` }>{ entity.entityName }</div>
                              <div className={ `${ styles['entity-tips'] }` }>
                                {
                                  (entity?.geoDistance) && (<><span className={ `${ styles['entity-label'] }` }>{ entity?.geoDistance }km</span> | </>)
                                }<span className={ `${ styles['entity-label'] }` }>{ entity?.cityName }{ entity?.districtName }{ entity?.addressMore }</span>
                              </div>
                              <div className={ `${ styles['entity-tips'] }` }>已选点位<span className={ `${ styles['count-selected'] } ${ entity.orderEntityState.checkedCount > 0 ? 'success' : '' }` }>{ entity.orderEntityState.checkedCount || 0 }</span>个<span className={ `${ styles['btn-right'] } imediafont i-arrow-right` }></span></div>
                            </div>

                            <div className={ `${ styles['entity-points'] }` }>
                              <div className={ `${ styles['count-total'] }` }>{ entity.usableCount || 0 }</div>
                              <div>可选点位</div>
                            </div>

                            <div className={ `${ styles['btn-select'] }` } onClick={ () => { toggleToCart(entityIndex) } }><span className={ `${ styles['icon-select'] } ${ entity.orderEntityState.checkedCount === entity.usableCount && entity.usableCount > 0 ? styles['is-full'] : '' } imediafont i-check` }></span><span className={ `${ styles['text'] }` }>全选</span></div>
                          </div>
                        )
                      })
                    }
                  </div>

                  <div className={ `${ styles.paging } flex justify-end` }>
                    <Pagination 
                      showQuickJumper 
                      showSizeChanger
                      showTotal={ total => `共 ${total} 条`}
                      defaultCurrent={ defaultCurrent }
                      current={ pageNum }
                      pageSize={ pageSize }
                      pageSizeOptions={ pageSizeOptions }
                      total={ total } 
                      onChange={ onPageChange }
                      onShowSizeChange={ onShowSizeChange }
                       />
                  </div>
                </div>
              )
            }
            
            {
              list.length <= 0 && <NoRecord />
            }
          </setFilterPointsContext.Provider>
        </filterPointsContext.Provider>

      </div>
    </div>

    {/*底部操作*/}
    <Bottom ad={ ad } />
    </>
  )
}