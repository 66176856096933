import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { NoRecord, Crumb } from '../../../components'

import { BaseInfo } from './components/base-info'
import { EntityList } from '../components/entity-list'
import { OrderInfo } from '../components/order-info'
import { OrderBottom } from '../components/order-bottom'

import { AdExec, defaultAdExec } from '../../index'

import util from '../../../libs/util'

import styles from './AdPlanDetail.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderMediaId?: String,
    },
  },
}

// 热幕订单 mallOrderMediaId
export const AdExecPlanDetail: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const { mallOrderMediaId } = props.match.params

  const onBack = () => {
    history.push(`/ad-exec/list`)
  }

  useEffect(() => {
    getAdDetail()
  }, [])

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 详情
  const [ad, setAd] = useState<AdExec>(defaultAdExec)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getOrderMedia`,
      params: {
        mallOrderMediaId,
      },
    })
    if (code === 1) {
      setAd((state) => {
        return {
          ...state,
          ...data,
        }
      })
      // 是支付后的订单
      if (data.approveStatus !== 'DRAFT' && data.approveStatus !== 'SAVE') {
        await getOrderTotal(data.mallOrderPlanId)
      }
      // TODO 拿支付超时,素材提供超时 广告主接口
      if (data.approveStatus === 'DELIVERY_WAIT' || data.approveStatus === 'EXECUTING' || data.approveStatus === 'FINISHED') {
        getAdPlanDetail(data.mallOrderPlanId)
      }
      setIsSubmiting(false)
    }
  }

  const getAdPlanDetail = async(mallOrderPlanId) => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1 && data) {
      setAd((state) => {
        return {
          ...state,
          ...data,
          // 广告主端返回的是ms
          contentDuration: data.contentDuration / 1000,
        }
      })
    }
    setIsSubmiting(false)
  }

  const getOrderTotal = async(mallOrderPlanId) => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      setAd(state => {
        return {
          ...state,
          orderTotal: data.find(i => i.mallOrderPlanId === mallOrderPlanId) || null,
        }
      })
    }
  }

  const childRef: any = useRef()
  // const reloadFn = () => {
  //   getAdDetail()
  //   childRef.current.init()
  // }
  const crumbList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '广告执行单详情',
      path: '',
    }
  ]
  
  return (
    <div className={ `${ styles['ad-page'] } ${ (ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT' || ad?.showPlanStatus === 'DRAFT') ? styles['is-paywait'] : '' }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>广告执行单详情</div>
        </div>

        {/*投放基本信息*/}
        { ad.mallOrderPlanId && <BaseInfo ad={ ad } /> }
      </div>

      {/*投放点位*/}
      { ad.mallOrderPlanId && <EntityList childRef={ childRef } ad={ ad } /> }

      {/*底部操作*/}
      <OrderBottom ad={ ad } />
      

      {/*广告执行单信息*/}
      <OrderInfo ad={ ad } />
    </div>
  )
}