import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Select, Table, } from 'antd'
import util from '../../../../libs/util'
import styles from './Sole.module.css'

interface Props {
  resourcePointId: any,
}

export const Sole: React.FC<Props> = ({ resourcePointId }) => {
  useEffect(() => {
    init()
  }, [])

  const init = async() => {
    await getPointDetail()
    getManageList()
  }

  // 点位信息
  const [point, setPoint] = useState<any>({})
  const getPointDetail = async() => {
    const { code, data } = await util.request({
      method: 'POST',
      url: `/mall/spu/getPointSpu`,
      data: {
        resourcePointId,
        saleModel: 'SPLIT',
      },
    })

    if (code === 1) {
      setPoint(data)
      getCooperationPeriodList(data.resourceEntityInfoId, data.ownUcOrgId)
    }
  }

  const convertDate = function(date) {
    return date?.split('-')[0] === '9999' ? '长期' : date?.split('-').join('.')
  }
  // YYYY-MM-DD to YYYY.MM.DD
  const formatDate = (dateStr) => {
    return dateStr ? dateStr.split('-').join('.') : ''
  }

  const [cooperationPeriodList, setCooperationPeriodList] = useState<any>([])
  const getCooperationPeriodList = async(resourceEntityInfoId, mediaUcOrgId) => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `/mall/biz_entity/getBizEntityList`,
      params: {
        resourceEntityInfoId,
				mediaUcOrgId,
      },
    })

    if (code === 1) {
      setCooperationPeriodList(data)
    }
  }

  // 停售信息
  const [manageList, setManageList] = useState<any[]>([])
  const getManageList = async() => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `/mall/sku_manage/getSkuManageListByPointId`,
      params: {
        resourcePointId,
      },
    })

    if (code === 1) {
      setManageList(data)
    }
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return index + 1
      },
    },
    {
      title: '停售期间',
      dataIndex: 'pointDateStart',
      render(text, record, index) {
        return `${ record.pointDateStart }至${ record.pointDateEnd }`
      },
    },
    {
      title: '停售天数',
      dataIndex: 'totalDays',
      render(text, record, index) {
        const days = Math.round((+new Date(record.pointDateEnd) - (+new Date(record.pointDateStart))) / (24 * 60 * 60 * 1000))
        return `${ days }天`
      },
    },
    {
      title: '停售原因',
      dataIndex: 'operationDesc',
      render(text, record, index) {
        return `${ text }`
      },
    },
  ]

  return (
    <div className={ styles['stock-form'] }>
      {
        cooperationPeriodList.length > 0 && (
          <div className={ styles['stock-form-item'] }>
            <div className={ styles['stock-form-label'] }>合作期限：</div>
            <div className={ styles['stock-form-value'] }>
              <Select 
                style={{ width: 100 }}
                options={ cooperationPeriodList }
                >
              </Select>
            </div>
          </div>
        )
      }
      
      <div className={ styles['stock-form-item'] }>
        <div className={ styles['stock-form-label'] }>每场电影银幕广告合作时长：</div>
        <div className={ styles['stock-form-value'] }>影片映前第{ point.cinemaDurationFrom + 1 }~{ point.cinemaDurationTo || 10 }分钟({ (point.cinemaDurationTo - point.cinemaDurationFrom) * 60 }s)</div>
      </div>

      <div className={ styles['halt-list'] }>
        <div className={ styles['halt-list-tit'] }>停售记录</div>
        <Table 
          rowKey="mallSkuManageId"
          columns={ columns } 
          dataSource={ manageList }
          pagination={ false } />
      </div>
    </div>
  )
}