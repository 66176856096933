import React, { useState, useEffect, useRef, useContext } from 'react'
import { Tooltip } from 'antd'
import styles from './PointList.module.css'

import {
  searchOwnerPointList,
  generateCinemaDownloadCode,
  searchOwnerPointEntityList,
  getOwnerDeviceState,
  findEnumItemList,
} from '../../../../api'

import { Paging } from '../../../../components/pagination'
import { NoRecord } from '../../../../components/norecord'

import { ActiveModal } from './activeModal/index'
import { ConfirmModal } from './confirmModal/index'
import { ActiveConfirmModal } from './activeConfirmModal/index'
import { ServiceStartUpModal } from './serviceStartUpModal/index'
import { AwakenModal } from './awakenModal/index'
import { DownloadModal } from './downloadModal/index'
import { HelpModal } from './helpModal/index'

import { appContext } from '../../../../App.state'

import util from '../../../../libs/util'

interface Props {
  activate?: () => void,
  match: any,
  location: any,
}

interface Entity {
	entityName?: string,
	provinceName ?: string,
	cityName ?: string,
	districtName ?: string,
}
const defaultEntity = {
	entityName: '',
	administrativeAreaName: '',
}

const defaultForm = {
	// releaseStatus: 'RELEASE',
	operatorType: 'CINEMA',
	province: '',
	city: '',
	district: '',
	resourceOrgEntityId: '',
}
const defaultPageSize = 10

// 激活的地址
const baseActivePageUrl = 'http://localhost:12382/agent/dlcode'
// 设置存储路径的地址
const configPageUrl = 'http://localhost:12382/agent/config/cinema'
// 设置TMS参数的地址
const tmsConfigPageUrl = 'http://localhost:12382/agent/config/cinemaTms'

export const CinemaPointList: React.FC<Props> = (props) => {
	const appValue = useContext(appContext)

	// 点位激活弹窗
	const activeModalRef: any = useRef()
	// 显示点位激活弹窗
	const showActivateModal = () => {
		activeModalRef.current.toggle(true)
	}

	const activeConfirmModalRef: any = useRef()
	
	// 显示服务启动弹窗
	const serviceStartUpModalRef: any = useRef()
	const showServiceStartUpModal = () => {
		serviceStartUpModalRef.current.toggle(true)
	}

	// 显示唤醒点位弹窗
	const awakenModalRef: any = useRef()
	const showAwakenModal = () => {
		// 存在待激活点位时
		if (waitingPoints > 0) {
			return
		}
		awakenModalRef.current.toggle(true)
	}

	// 显示下载弹窗
	const downloadModalRef: any = useRef()
	const showDownloadModal = () => {
		downloadModalRef.current.toggle(true)
	}

	// 显示设置弹窗
	const configModalRef: any = useRef()
	const showConfigModal = () => {
		configModalRef.current.toggle(true)
	}
	const setLocation = () => {
		// 新开窗去设置
		const win: any = window.open(configPageUrl, '_blank')
  	win.focus()
	}

	const setTmsInfo = () => {
		// 新开窗去设置
		const win: any = window.open(tmsConfigPageUrl, '_blank')
  	win.focus()
	}

	// 显示Q&A弹窗
	const helpModalRef: any = useRef()
	const showHelpModal = () => {
		helpModalRef.current.toggle(true)
	}

	// 列表查询条件
	const [resourceOrgEntityId, setOrgEntityInfoId] = useState('')
	// 暂存
	const resourceOrgEntityIdRef:any = useRef('')
	useEffect(() => {
		const id = `${props.match.params.id}${props.location.hash}`
		setOrgEntityInfoId(id)
		resourceOrgEntityIdRef.current = id
		if (resourceOrgEntityId) {
			getPointList(1, defaultPageSize)
			getEntityList()
		}
	}, [resourceOrgEntityId])

	const [entity, setEntity] = useState<Entity>({...defaultEntity})
	const getEntityList = async () => {
		const { data: list } = await searchOwnerPointEntityList({
			operatorType: 'CINEMA',
			province: '',
			city: '',
			district: '',
			resourceOrgEntityId,
			pageNum: 1,
			pageSize: 1,
		})
		setEntity(list[0])
	}

	// 获取密钥
	const getSecretKey = async () => {
		if (!resourceOrgEntityId) {
			return
		}
		const secretKey = await generateCinemaDownloadCode({
			resourceOrgEntityId,
		})

		return secretKey
	}
	const [modeText, setModeText] = useState('激活')
	// 激活
	const activate = async () => {
		setModeText('激活')
		activateAction()
	}
	const [code, setCode] = useState('')
	const activateAction = async () => {
		// 当前显示是否成功的弹窗
		activeConfirmModalRef.current.toggle(true)
		// 获取密钥
		const secretKey = await getSecretKey()
		setCode(secretKey)
		setTimeout(() => {
			(document.getElementById('form') as any)?.submit()
		}, 0)

		// 新开窗去激活
		// const win: any = window.open(`${baseActivePageUrl}${secretKey}&entityName=${entity.entityName}`, '_blank')
  // 	win && win.focus()
	}
	// 唤醒
	const awaken = async () => {
		setModeText('唤醒')
		activateAction()
	}

	// 待激活状态的点位数
	const [waitingPoints, setWaitingPoints] = useState(0)
	// 是否所有点位都是待激活状态
	const [isAllWaiting, setIsAllWaiting] = useState(false)
	// 是否所有点位都在线
	const [isAllOnline, setIsAllOnline] = useState(false)
	// 是否所有点位都离线
	const [isAllOffline, setIsAllOffline] = useState(false)
	// 总点位数
	const [total, setTotal] = useState(0)

	// 列表数据
	const [pointList, setPointList] = useState<any[]>([])
	// 获取点位列表
	const getPointList = async (pageNum, pageSize) => {
		const { data: list, total } = await searchOwnerPointList(Object.assign({}, defaultForm, {
			pageNum,
			pageSize,
			resourceOrgEntityId,
		}))

		setPointList(list)
		setTotal(total)

		if (pageNum === 1) {
			getDeviceState(total)
		}

		if (total) {
			setOrgEntityInfoId(list[0].resourceOrgEntityId)
		}
	}

	// 统计点位主设备（连接数和在线数）
	const getDeviceState = async (total) => {
		const { deviceOfflineCount, deviceOnlineCount, mqttConnectedCount, mqttNotConnectedCount } = await getOwnerDeviceState(Object.assign({}, defaultForm, {
			resourceOrgEntityId,
		}))

		setWaitingPoints(mqttNotConnectedCount)
		setIsAllWaiting(mqttNotConnectedCount === total)
		setIsAllOnline(deviceOnlineCount === total)
		setIsAllOffline(deviceOfflineCount === total)
	}

	// 点位状态标签
	const getPointStatus = (point) => {
		const mqttConnected = point.mqttConnected
		const deviceOnline = point.deviceOnline === 'ONLINE'
		let statusCls
		let statusText
		// 已激活
		if (mqttConnected) {
			if (deviceOnline) {
				statusText = '在线'
				statusCls = 'online'
			} else {
				statusText = '离线'
				statusCls = 'offline'
			}
		} else {
			statusText = '未激活'
			statusCls = 'notactive'
		}

		return (
			<>
				<div className={ `icon ${ styles['point-status-label'] } ${ styles[statusCls] }` }></div>
				<div>{ statusText }</div>
			</>
		)
	}

	const onDownload = () => {
    const link = document.createElement('a')
    link.setAttribute('href', `${ appValue.rHost }/apps/RemuAgent_Cinema_1.1.0.zip`)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', 'jttmedia_agent')
    link.click()
  }

  const helpPrev = `https://${ util.tools.env }static.remudooh.com/cms/html/web/help/`
  const [helpPageUrl, setHelpPageUrl] = useState(helpPrev)
  const [helpUrls, setHelpUrls] = useState([])
	const getHelpPageUrl = async() => {
    const groupKey = 'CINEMA_HELP_PAGE_URL'
    const { code, data } = await findEnumItemList({
      groupKeyArray: groupKey,
    })

    if (code === 1) {
     setHelpPageUrl(`${ helpPrev }${ data[groupKey].find(i => i.itemKey === 'HELP_INDEX').itemValue }`)
     setHelpUrls(data[groupKey])
    }
  }
  useEffect(() => {
  	getHelpPageUrl()
  }, [])

  return (
    <div className={ styles['point-wrap'] }>
    	<form 
				action={ baseActivePageUrl }
				target="_blank"
				id="form"
				>
				<input type="hidden" name="code" value={ code } />
				<input type="hidden" name="entityName" value={ entity.entityName } />
			</form>
    	{/*顶部*/}
    	<div className={ styles['point-header-wrap'] }>
	    	<div className={ styles['point-header'] }>
	    		<a className={ styles['btn-help'] } href={ helpPageUrl } target="_blank"><i className={ `ifont i-help ${ styles['icon-config'] }` }></i>帮助中心</a>
	    		<div className={ styles['entity-info'] }>
	    			<div className={ styles['entity-name'] }>
	    				{ entity.entityName }
	    			</div>
	    			<div className={ styles['entity-address'] }>
	    				<i className={ `ifont i-location ${ styles['icon-location-thin'] }` }></i>
	    				{ entity.provinceName }
    					{ entity.cityName && <> | { entity.cityName } </> }
    					{ entity.districtName && <> | { entity.districtName } </> }
	    			</div>
	    		</div>
	    	</div>
	    	<div className={ styles['point-links-wrap']}>
		    	<div className={ styles['point-links'] }>	    		
		    		<div className={ styles['point-link-item'] } onClick={ showDownloadModal }>下载安装热幕精灵<Tooltip placement="top" title="完成下载安装，方可进行启动、激活、设置操作"><i className={ `ifont i-question ${ styles['icon-question'] }` }></i></Tooltip></div>	    		
		    		<div className={ styles['point-link-item'] } onClick={ showServiceStartUpModal }>启动热幕精灵<Tooltip placement="top" title="启动后，方可进行激活、设置操作"><i className={ `ifont i-question ${ styles['icon-question'] }` }></i></Tooltip></div>
			    	{
			    		waitingPoints > 0 ?
			    		<Tooltip placement="top" overlayStyle={{ zIndex: 100, }} defaultVisible visible title={ `发现${ waitingPoints }个点位待激活`} color="#FE8135">
			    			<div className={ styles['point-link-item'] } onClick={ showActivateModal }>激活银幕点位</div>
			    		</Tooltip>
			    		: 		    		
				    	<div className={ styles['point-link-item'] } onClick={ showAwakenModal }>唤醒银幕点位<Tooltip placement="top" title='若点位为离线状态，可将其唤醒为在线状态' ><i className={ `ifont i-question ${ styles['icon-question'] }` }></i></Tooltip></div>			    	
			    	}
			    	<div className={ styles['point-link-item'] } onClick={ setTmsInfo }>设置自动巡刊<Tooltip placement="top" title="设置前需确保热幕精灵已启动，设置后无需进行人工巡刊拍照回执"><i className={ `ifont i-question ${ styles['icon-question'] }` }></i></Tooltip></div>
	    			<div className={ styles['point-link-item'] } onClick={ showConfigModal }>设置广告素材DCP文件存放路径<Tooltip placement="top" title="设置前需确保热幕精灵已启动，有默认存放路径，若需变更路径可由此进入重新设置"><i className={ `ifont i-question ${ styles['icon-question'] }` }></i></Tooltip></div>
	    		</div>
    		</div>
    	</div>

    	{/*有记录*/}
	    { !!total && 
	    	<>
		    	<div className={ styles['point-cont'] }>
		    		{/*列表*/}
			    	<div className={ styles['point-list'] }>
				    	{
				    		pointList.map((point) => {
				    			return (
				    				<div 
					    				className={ styles['point-item'] }
					    				key={ point.resourcePointId }
				              onClick={ () => {} }
					    				>
					    			<div className={ styles['point-info'] }>
					    				<div className={ styles['point-name'] }>{ point.pointName }</div>
					    				<div className={ styles['point-number'] }>点位编码：<span className={ styles['point-number-count'] }>{ point.pointNumber }</span></div>
					    				<div className={ styles['point-status'] }>
					    					{ getPointStatus(point) }
					    				</div>
					    			</div>
					    		</div>
				    			)
				    		})
				    	}
				  	</div>

				  	{/*翻页*/}
		    		<Paging
	        		total={ total }
	        		getList= { getPointList }
	        		/>
			  	</div>
			  </>
			}

			{/*无记录*/}
    	{ !total && 
    		<NoRecord noRecordText="暂无点位资源~" />
	    }

	  	{/*弹窗*/}
	  	{/*激活 银幕广告点位*/}
	  	<ActiveModal 
	  		childRef={ activeModalRef }
	  		confirmFn={ activate } 
	  		helpUrls={ helpUrls }
	  		isAllWaiting={ isAllWaiting }
	  		 />
	  	{/*点位激活确认*/}
	  	<ActiveConfirmModal 
	  		childRef={ activeConfirmModalRef }
	  		modeText={ modeText }
	  		confirmFn={ () => { getPointList(1, defaultPageSize) } } 
	  		 />

	  	{/*启动 热幕精灵服务*/}
	  	<ServiceStartUpModal 
	  		childRef={ serviceStartUpModalRef }
	  		helpUrls={ helpUrls }
	  		 />

	  	{/*唤醒 银幕广告点位*/}
	  	<AwakenModal 
	  		childRef={ awakenModalRef }
	  		activateFn={ awaken } 
	  		isAllOnline={ isAllOnline }
	  		isAllOffline={ isAllOffline }
	  		hasWaiting={ waitingPoints > 0 }
	  		 />

	  	{/*下载热幕精灵*/}
	  	<DownloadModal 
	  		childRef={ downloadModalRef }
	  		confirmFn={ onDownload }
	  		helpUrls={ helpUrls }
	  		 />

	  	{/*设置广告素材路径*/}
	  	<ConfirmModal 
	  		childRef={ configModalRef }
	  		confirmFn={ setLocation }
	  		isSetMode={ true }
	  		helpUrls={ helpUrls }
	  		title="设置广告素材DCP文件存放路径"
	  		confirmText="设置路径"
	  		 />

	  	{/*Q&A*/}
	  	<HelpModal 
	  		childRef={ helpModalRef }
	  		 />
    </div>
  )
}