import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Tooltip, Calendar } from 'antd'
import type { Moment } from 'moment'
import moment from 'moment'
import styles from './PointPriceDetailModal.module.css'
import util from '../../../../libs/util'

interface Props {
  childRef: any,
}
const dateFormat = 'YYYY-MM-DD'
type RangeValue = [Moment, Moment] | undefined

export const PointPriceDetailModal: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    onToggle(queryForm) {
      setQueryForm(queryForm)
      showEffectModal()
      getList(queryForm)
    }
  }))
  const [queryForm, setQueryForm] = useState<any>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showEffectModal = () => {
    setIsModalVisible(true)
  }

  // 
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [point, setPoint] = useState<any>()
  const [priceList, setPriceList] = useState<any>()
  const getList = async(queryForm) => {
    setIsSubmiting(true)

    const status = queryForm?.showPlanStatus
    const isBeforePay = status === 'DRAFT' || status === 'SAVE'
    const apiName = isBeforePay ? 'getPointSku' : 'getOrderPointDetail'
    const params = {
      resourcePointId: queryForm?.resourcePointId || null,
      mallOrderPlanId: queryForm?.mallOrderPlanId || null,
      mallOrderMediaId: queryForm?.mallOrderMediaId || null,
    }

    const url = isBeforePay ? `/mall/point/sku/getPointSku` : `/mall/delivery/getOrderPointDetail`

    const { data, code } = await util.request({
      method: 'GET',
      url,
      params,
    })
    if (code === 1) {
      const list = data.priceList
      const betweenDate = [list[0].pointDate, list[list.length - 1].pointDate]
      const today = moment().startOf('day').format(dateFormat)
      setPoint(data)
      setValidRange([moment(betweenDate[0], dateFormat), moment(betweenDate[1], dateFormat)])
      setPriceList(list)
      setCalendarValue(moment(betweenDate[0], dateFormat))
      setIsSubmiting(false)
    }
  }

  // 设置可以显示的日期
  const [validRange, setValidRange] = useState<RangeValue>(undefined)
  // 展示日期
  const [calendarValue, setCalendarValue] = useState<Moment>()
  const dateFullCellRender = (value) => {
    const dateStr = moment(value).format(dateFormat)
    const hasThisDate:any = priceList?.find(i => i.pointDate === dateStr)
    const isToday = dateStr === moment().startOf('day').format(dateFormat)
    return (
      <div className={ `${ styles['calendar-cell'] } ${ isToday ? styles['is-today'] : '' }  ${ hasThisDate ? styles['calendar-delivery-bg'] : '' } ` }>
        <div className={ `${ styles['calendar-date'] }` }>{ moment(value).date() }</div>
      </div>
    )
  }
  // 
  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode)
  }

  return (
    <Modal 
      title="点位投放日期" 
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) } }
      >
      <div className={ `${ styles['point-top'] }` }>
        <div className={ `${ styles['point-name'] }` }>{ point?.pointDevice?.pointName }</div>
        <div className={ `${ styles['entity-name'] }` }>{ point?.pointDevice?.entityName }</div>
      </div>

      <div className={ `${ styles['ad-form'] }` }>
        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
            投放周期：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            { util.tools.formatDateStr(point?.deliveryStart) }-{ util.tools.formatDateStr(point?.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ queryForm?.deliveryDays }天)</span>
          </div>
        </div>

        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
            播放时段：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            {
              (point) && (<>{ util.tools.convertTimePeriods(point) }</>)
            }
          </div>
        </div>
      </div>

      <div className={ `${ styles['calendar-tips'] }` }>
        <div className={ `${ styles['icon-tips'] }` }></div>
        <div className={ `${ styles['durtaion-tips'] }` }>投放日期(<span className={ `${ styles['durtaion-tips-number'] } ${ queryForm?.deliveryDays != point?.usableDeliveryDays ? styles['important'] : '' }` }>{ point?.usableDeliveryDays }</span>天)</div>
      </div>

      <div className={ `${ styles['calendar-wrap'] }` }>
        <Calendar 
          onPanelChange={ onPanelChange }
          dateFullCellRender={ dateFullCellRender }
          value={ calendarValue ? moment(calendarValue) : moment() }
          validRange={ validRange }
           />
      </div>

    </Modal>
  )
}