import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { PersonalHome, CompanyHome } from './index'

export const MineHome: React.FC = (props) => {
	const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
	const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'MEDIA_OPERATION') : false
  })

	const [loginMode, setLoginMode] = useState<string>('')

	useEffect(() => {
		// 'COMPANY' | 'PERSONAL'
		if (userType) {
			setLoginMode(userType)
		}
  }, [userType])
 
	return (
		loginMode ? (loginMode === 'COMPANY' ? <CompanyHome /> : <PersonalHome />) : null
	)
}