import React, { useState, useEffect, useRef } from 'react'
import { DatePicker, Table, Spin, Pagination, Modal } from 'antd'
import type { Moment } from 'moment'

import moment from 'moment'

import util from '../../../libs/util'
import { GuideModal } from '../../../components'
import { transactionTypeNames, appIdItems, appProductItems, refundReasonItems } from '../../../libs/util.constants'
import miniCode from '../../../assets/images/common/mini-code.jpg'

import styles from './Home.module.css'

interface OrgAccount {
	totalAmount: string | number,
	creditAmount: string | number,
	balance: string | number,
}

const defaultOrgAccount:OrgAccount = {
	totalAmount: 0,
	creditAmount: 0,
	balance: 0,
}

interface Record {
	appUserId?: string,
	appUserName?: string,
	transactionType?: string,
	childReferList?: any,
}
const defaultRecord:Record = {
	appUserId: '',
	appUserName: '',
	transactionType: '',
	childReferList: [],
}

const { RangePicker } = DatePicker

export const WalletHome: React.FC = () => {
	const [isCompany, setIsCompany] = useState(false)
	const [orgAccount, setOrgAccount] = useState<OrgAccount>(defaultOrgAccount)
	const [currencyTypeId, setCurrencyTypeId] =  useState('YB')

	useEffect(() => {
		getUserInfo()
		getReferAccountInfo()
	}, [])

	// 获取用户信息
  const getUserInfo = async() => {
  	const { data, code } = await util.request({
      method: 'POST',
      url: `/uc/getMyUserInfo`,
    })
    if (code === 1) {
      setIsCompany(data.myUserInfo.userType === 'COMPANY')
    }
  }

	// 查询相关资金账户（个人与所在机构）
  const getReferAccountInfo = async() => {
  	const { data, code } = await util.request({
      method: 'POST',
      url: `/finance/account/getReferAccountInfo`,
      data: {
        currencyTypeId,
      },
    })
    if (code === 1) {
      const { orgAccount } = data
      setOrgAccount(orgAccount)
    }
  }

  // 充值 弹窗
	const childRef: any = useRef()
	const goToRecharge = () => {
		childRef.current.onToggle()
	}

	const [queryDate, setQueryDate] = useState([])
	const onDateChange = (dates, dateStrings) => {
	  if (dates) {
	    setQueryDate(dateStrings)
	  } else {
	  	setQueryDate([])
	  }
	}
	const columns = [
	  {
	    title: '序号',
	    dataIndex: 'index',
	    key: 'index',
	    render: (text, record, index) => {
	    	return `${ index + 1}`
	    },
	  },
	  {
	    title: '操作类型',
	    dataIndex: 'transactionType',
	    key: 'transactionType',
	    render: (text, record, index) => {
	    	return transactionTypeNames.find(i => i.itemKey === text)?.itemValue
	    },
	  },
	  {
	    title: '金额(元)',
	    dataIndex: 'transactionMoney',
	    key: 'transactionMoney',
	    render: (text, record, index) => {
	    	return <span className={ `${ styles['wallet-money'] } ${ record.transactionAction === 1 ? styles['is-positive']  : '' }` }>{ record.transactionAction === 1 ? '+' : '-' }{ util.tools.splitNum2String(text).int }{ util.tools.splitNum2String(text).dec }</span>
	    },
	  },
	  {
	    title: '操作时间',
	    key: 'transactionTime',
	    dataIndex: 'transactionTime',
	  },
	  {
	    title: '操作',
	    key: 'action',
	    render: (text, record) => (
	      <a className={ `${ styles['important'] }` } onClick={ () => { onClickDetail(record.transactionId) } }>查看详情</a>
	    ),
	  },
	]

	const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getList(pageNum)
  }, [pageNum, queryDate])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)

	const getList = async(num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/finance/trans/queryTrans?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: {
      	currencyTypeId,
      	startDate: queryDate.length ? queryDate[0] : '',
				endDate: queryDate.length ? queryDate[1] : '',
				pageNum,
				pageSize,
				queryType: 'COMPANY',
      },
    })
    if (code === 1) {
	    setList(data)
	    setTotal(total)
	    setIsSubmiting(false)
	  }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }


  // 详情弹窗
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const showDetailModal = () => {
    setIsDetailModalVisible(true)
  }
  const onDetailModalCancel = () => {
    setIsDetailModalVisible(false)
  }
	const onClickDetail = (id) => {
		getDetail(id)
		showDetailModal()
	}

	const [record, setRecord] = useState<any>()
	// 查询交易详情
  const getDetail = async(id) => {
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/finance/trans/getTrans`,
      params: {
      	transactionId: id,
      },
    })
    if (code === 1) {
    	setRecord(data)
    	if (data.transactionType === 'PAY') {
    		// 获取用户信息
      	getAppUserInfo(data.appUserId)
    	}
    }
  }
  // 获取用户信息
  const getAppUserInfo = async(appUserId) => {
    if (!appUserId) {
      return
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/uc/org/getOrgOrUserReal`,
      params: {
      	userInId: appUserId,
      },
    })
    if (code === 1) {
    	setRecord((state) => {
        return {
          ...state,
          appUserName: !data ? '未知' : (data.userType === 'PERSON' ? data.chinaName : data.companyAuthName),
        }
      })
    }
  }

  return (
    <div className={ `${ styles['wallet-page'] }` }>
      <div className={ `${ styles['wallet-page-tit'] }` }>
        <h3>我的钱包</h3>
      </div>

      <div className={ `${ styles['wallet-page-cont'] }` }>
        <div className={ `${ styles['wallet-page-top'] }` }>
        	<div className={ `${ styles['wallet-list'] }` }>
        		<div className={ `${ styles['wallet-item'] }` }>
			        <div className={ `${ styles['wallet-item-tit'] }` }>可用余额<span className={ `${ styles['wallet-unit'] }` }>(元)</span></div>
			        <div className={ `${ styles['wallet-item-amount'] }` }>
			          <span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(orgAccount.totalAmount).int }</span>
			          <span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(orgAccount.totalAmount).dec }</span>
			        </div>
			      </div>
			      <div className={ `${ styles['wallet-item'] }` }>
			        <div className={ `${ styles['wallet-item-tit'] }` }>钱包余额<span className={ `${ styles['wallet-unit'] }` }>(元)</span></div>
			        <div className={ `${ styles['wallet-item-amount'] }` }>
			          <span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(orgAccount.balance).int }</span>
			          <span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(orgAccount.balance).dec }</span>
			        </div>
			      </div>
			      <div className={ `${ styles['wallet-item'] }` }>
			        <div className={ `${ styles['wallet-item-tit'] }` }>授信额度<span className={ `${ styles['wallet-unit'] }` }>(元)</span></div>
			        <div className={ `${ styles['wallet-item-amount'] }` }>
			          <span className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(orgAccount.creditAmount).int }</span>
			          <span className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(orgAccount.creditAmount).dec }</span>
			        </div>
			      </div>
			    </div>

		    	{
		    		isCompany && <div>
		      	<div className={ `${ styles['btn-recharge'] }` } onClick={ goToRecharge }>充值</div></div>
		    	}
        </div>

        <div className={ `${ styles['wallet-date'] }` }>
	      	<RangePicker
	      		size='large'
			      ranges={{
			        '本月': [moment().startOf('month'), moment().endOf('month')],
			        '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
			      }}
			      onChange={ onDateChange }
			    />
	      </div>

	      <div className={ `${ styles['wallet-data'] }` }>
	      	<div className={ `${ styles['wallet-data-tit'] }` }>收支明细</div>
	      	<Spin spinning={ isSubmiting } tip="请稍候...">
		      	<div className={ styles['wallet-table']}>
			      	<Table 
			      		rowKey="transactionId"
			      		columns={ columns } 
			      		dataSource={ list }
			      		pagination={ false } />
			      </div>
        
		        {
		          // 翻页
		          (list.length > 0) && (
		            <div className={ `${ styles.paging } flex justify-end` }>
		              <Pagination 
		                showQuickJumper 
		                showSizeChanger
		                showTotal={ total => `共 ${total} 条`}
		                defaultCurrent={ defaultCurrent }
		                current={ pageNum }
		                pageSize={ pageSize }
		                pageSizeOptions={ pageSizeOptions }
		                total={ total } 
		                onChange={ onPageChange }
		                onShowSizeChange={ onShowSizeChange }
		                 />
		            </div>
		          )
		        }
		      </Spin>
	      </div>

      </div>
      
      {/* 充值提醒 弹窗*/}
      <GuideModal childRef={ childRef } />  

    	{/* 详情 弹窗*/}
      <Modal 
        width={ 480 }
        title="钱包交易明细详情"
        footer={ null }
        visible={ isDetailModalVisible }
        onCancel={ onDetailModalCancel }
        >
        <div className={ `${ styles['detail-modal-cont'] }` }>
	        <div className={ `${ styles['order-form'] }` }>
	        	<div className={ `${ styles['order-form-item'] }` }>
			        <div className={ `${ styles['order-form-label'] }` }>操作类型</div>
			        <div className={ `${ styles['order-form-value'] }` }>{ transactionTypeNames.find(i => i.itemKey === record?.transactionType)?.itemValue }</div>
			      </div>
			      <div className={ `${ styles['order-form-item'] }` }>
			        <div className={ `${ styles['order-form-label'] }` }>金额</div>
			        <div className={ `${ styles['order-form-value'] } ${ styles['wallet-money'] } ${ record?.transactionAction === 1 ? styles['is-positive']  : '' }` }>{ record?.transactionAction === 1 ? '+' : '-' }{ util.tools.splitNum2String(record?.transactionMoney).int }{ util.tools.splitNum2String(record?.transactionMoney).dec }</div>
			      </div>

				    {/*<!-- 支付/消费 -->*/}
				    {
				    	(record?.transactionType === 'PAY') && (
				    		<>
				    		<div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>支付时间</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionTime }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>支付方式</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.currencyTypeId === 'YB' ? '人民币' : '-' }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>支付用户</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.appUserName }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>订单编号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.appOrderId }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>交易流水号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionId }</div>
					      </div>
					      </>
				    	)
				    }
				    
				    {/*<!-- 充值 -->*/}
				    {
				    	(record?.transactionType === 'ADD') && (
				    		<>
				    		<div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>充值时间</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionTime }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>支付渠道</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ appIdItems.find(i => i.itemKey === record.appId)?.itemValue }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>充值方式</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ appProductItems.find(i => i.itemKey === record.appProduct)?.itemValue }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>交易流水号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionId || '-' }</div>
					      </div>
					      </>
				    	)
				    }

				    {/*<!-- 退款 -->*/}
				    {
				    	(record?.transactionType === 'REFUND') && (
				    		<>
				    		<div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>退款时间</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionTime }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>退款方式</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.currencyTypeId === 'YB' ? '人民币' : '-' }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>退款单号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.transactionId }</div>
					      </div>
					      <div className={ `${ styles['order-form-item'] }` }>
					        <div className={ `${ styles['order-form-label'] }` }>原订单编号</div>
					        <div className={ `${ styles['order-form-value'] }` }>{ record.appOrderId }</div>
					      </div>
					      </>
				    	)
				    }
				  </div>

				  {/*<!-- 退款 -->*/}
				  {
				  	(record?.childReferList.length > 0) && (
				  		<div className={ `${ styles['other-item'] }` }>
						    <div className={ `${ styles['other-tit'] }` }>退款</div>
						    
						    <div className={ `${ styles['other-info'] }` }>
						    	{
						    		record.childReferList.map(item => {
						    			return (
						    				<div 
									      	className={ `${ styles['record-item'] }` }
									        key={ item.transactionId }
									        >
									        <div className={ `${ styles['record-item-top'] }` }>
									          <div className={ `${ styles['record-reason'] }` }>{ refundReasonItems.find(i => i.itemKey === item.appReferChannel)?.itemValue }</div>
									          <div className={ `${ styles['record-item-top-r'] }` }>
										          <div className={ `${ styles['record-status'] }` }>已退款</div>
										          <div className={ `${ styles['record-money'] }` }>
										            <text className={ `${ styles['money-int'] }` }>{ util.tools.splitNum2String(item.transactionMoney).int }</text>
										            <text className={ `${ styles['money-dec'] }` }>{ util.tools.splitNum2String(item.transactionMoney).dec }</text>
										          </div>
									          </div>
									        </div>
									        <div className={ `${ styles['record-date'] }` }>{ item.transactionTime }</div>
									      </div>
						    			)
						    		})
						    	}
						    </div>
			        </div>
				  	)
				  }
        </div>
      </Modal>

    </div>
  )
}