import React, { useState } from 'react'
import util from './libs/util'

interface StateType {
  env: string,
  rHost: string,
  eHost: string,
  cinemaPageUrl: string,
  mediaPageUrl: string,
}

const location = window.location
const host = location.host
const env = util.tools.env
const cinemaHost = host.includes('localhost') ? host : `${ env }cinema.remudooh.com${ env ? ':8888' : '' }`
const protocol = location.protocol
const mediaHost = host.includes('localhost') ? host : `${ env }media.remudooh.com${ env ? ':8888' : '' }`

const defaultState: StateType = {
  env: util.tools.env,
  rHost: `https://${ util.tools.env }cdn.remudooh.com/`,
  eHost: `https://${ util.tools.env }cdn.remudooh.com/apps/热幕精灵（影院版）.zip`,
  cinemaPageUrl: `${ protocol }//${ cinemaHost }/#`,
  mediaPageUrl: `${ protocol }//${ mediaHost }/#`
}

export const appContext = React.createContext(defaultState)

export const AppStateProvider: React.FC = (props) => {
  const [state] = useState(defaultState)

  return (
    <appContext.Provider value={ state }>
      { props.children }
    </appContext.Provider>
  )
}