import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, Pagination, Table, Rate, Form, Input, Select, DatePicker, Popover, Tooltip, Slider, message,Spin } from 'antd'
import moment from 'moment'
import type { RangePickerProps } from 'antd/es/date-picker';
import { FireFilled, ExclamationCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { findEnumItemList, createSkuPeriods, clearSkuPeriods, getOperStatus } from '../../../../api'
import styles from './Sole.module.css'

interface Props {
  childRef: any,
  selectedRowKeys: any,
  resetData: any,
}

export const SoleModal: React.FC<Props> = ({ childRef, selectedRowKeys, resetData }) => {
	useImperativeHandle(childRef, () => ({
    showSetModal() {
      showSetModal()
    }
  }))

  const [soleForm] = Form.useForm()

  const [isSubmiting, setIsSubmiting] = useState(false)

  const [optId, setOptId] = useState('')
  useEffect(() => {
    if (!optId) {
      return
    }
    const timer = setInterval(() => {
      if (!optId) {
        clearInterval(timer)
        return
      }
      checkId(optId)
     }, 1000)

    return () => clearInterval(timer)
  }, [optId])
  
  const [customerList, setCustmerList] = useState<any[]>([])
  useEffect(() => {
    getCustomerList()
  }, [])

  // 独家库存设置弹窗
  const [isSetModalVisible, setIsSetModalVisible] = useState(false)
  
  const getCustomerList = async() => {
    const groupKey = 'CINEMA_CUSTOMER'
    const result = await findEnumItemList({
      groupKeyArray: groupKey,
    })

    if (result.code === 1) {
      setCustmerList([...result.data[groupKey], {
        itemKey: 'SELF',
        itemValue: '影院自营',
      }])
    }
  }
  const showSetModal = () => {
    resetForm()
    setIsSetModalVisible(true)
  }
  const onSetFinish = () => {
    console.log('adfa')
  }
  const onCustmerChange = (value) => {
    setForm(state => {
      return {
        ...state,
        customerId: value,
      }
    })
  }
  const defaultForm = {
    pointDateStart: '',
    pointDateEnd: '',
    timePeriods: [0, 10],
    customerId: '',
  }
  const [form, setForm] = useState({ ...defaultForm })
  const onStartDateChange = (v, value) => {
    setForm(state => {
      return {
        ...state,
        pointDateStart: value,
      }
    })
  }
  const onEndDateChange = (v, value) => {
    setForm(state => {
      return {
        ...state,
        pointDateEnd: value,
      }
    })
  }
  const onTimePeriodsChange = (value) => {
    let timePeriods:any = []
    for (let i = value[0]; i <= value[1]; i++) {
      timePeriods.push(i - 1)
    }
    setForm(state => {
      return {
        ...state,
        timePeriods,
      }
    })
  }
  const formatter = (value) => {
    return value + 1
  }
  const onSetModalCancel = () => {
    setIsSetModalVisible(false)
  }
  const onCheckForm = async () => {
    try {
      const values = await soleForm.validateFields();
      return values
    } catch (errorInfo) {
      return false
    }
  }
  const onSetModalConfirm = async() => {
    if (isSubmiting) {
      return
    }

    // 没选点位
    if (!selectedRowKeys.length) {
      message.warn('请选择点位')
      return
    }

    if (!await onCheckForm()) {
      return
    }

    setIsSubmiting(true)

    const result = await createSkuPeriods({
      resourcePointIds: selectedRowKeys,
      ...form,
    })
    if (result.code === 1) {
      // 查状态
      setOptId(result.data)
    } else {
      message.error(result.message || '操作失败，请重试~')
      setIsSubmiting(false)
    }
  }

  // 查状态是否操作成功
  const checkId = async(optId) => {
    setIsSubmiting(true)
    const result:any = await getOperStatus(optId)

    if (result.code === 1) {
      setOptId('')
      resetForm()
      resetData()
      setIsSetModalVisible(false)
      message.success('操作成功')
    }
    setIsSubmiting(false)
  }

  // 重置表单
  const resetForm = () => {
    setForm(state => {
      return {
        ...state,
        ...defaultForm,
      }
    })
    soleForm.resetFields()
  }

  const disabledStartDate = (current) => {
    // 不能选今天之前的日期
    return current && (current < moment().startOf('day') || current.isAfter(form.pointDateEnd, 'day'))
  }
  const disabledEndDate = (current) => {
    // 不能选今天之前的日期 和　起始日期前的
    return current && (current < moment().startOf('day') || current < moment(form.pointDateStart))
  }

  return (
  	<Modal 
	    width={ 780 }
	    title="独家库存设置"
	    visible={ isSetModalVisible }
	    footer={ null }
	    onCancel={ onSetModalCancel }
	    >
      <Spin spinning={ isSubmiting } tip="请稍候...">
  	    <Form
          form={ soleForm }
  	      name="form"
  	      labelCol={{ span: 8 }}
  	      wrapperCol={{ span: 12 }}
  	      onFinish={ onSetFinish }
  	      size="large"
  	      autoComplete="off"
  	      >
          <Form.Item
            label="独家代理商"
            name="customerId"
            rules={[{ required: true, message: '请选择独家代理商!' }]}
            >
            <Select
              value={ form.customerId }
              onChange={ onCustmerChange }
              >
              {
                customerList.map((record, index) => {
                  return (
                    <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="库存时段"
            name="timePeriods"
            required
            >
            <div className={ `${ styles['flex'] }` }>影片映前<Slider className={ `${ styles['slider'] }` } range onChange={ onTimePeriodsChange } min={ 1 } max={ 10 } />分钟</div>
          </Form.Item>
  	      <Form.Item
  	        label="起始日期"
  	        name="pointDateStart"
  	        rules={[{ required: true, message: '请选择起始日期!' }]}
  	        >
  	        <DatePicker value={ moment(form.pointDateStart) } style={{ width: '100%', }} disabledDate={ disabledStartDate } onChange={ onStartDateChange } />
  	      </Form.Item>
  	      <Form.Item
  	        label="结束日期"
  	        name="pointDateEnd"
  	        rules={[{ required: true, message: '请选择结束日期!' }]}
  	        >
  	        <DatePicker value={ moment(form.pointDateEnd) } style={{ width: '100%', }} disabledDate={ disabledEndDate } onChange={ onEndDateChange } />
  	      </Form.Item> 
  	    </Form>

        <div className={ styles['modal-tips'] }>
          <div className={ styles['modal-tips-tit'] }><ExclamationCircleFilled style={{ fontSize: '18px', color: '#FE8135', marginRight: '10px' }} />温馨提示：</div>
          <div>1. 媒体商家可在热幕平台管理以上合作时段，为共享售卖库存，接受所有广告商售卖</div>
          <div>2. 可针对以上时段设置某些库存时段的独家销售权，只可由某个独家代理商进行售卖，其他广告商不可售卖</div>
        </div>

  	    <div className={ styles['modal-footer'] }>
  	      <Button className={ `${ styles['modal-btn'] }` } onClick={ onSetModalCancel }>取消</Button>
  	      <Button className={ `${ styles['modal-btn'] } ${ styles['confirm'] }` } onClick={ onSetModalConfirm }>确认</Button>
  	    </div>
      </Spin>
	  </Modal>
  )
}