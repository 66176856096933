import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
	Checkbox,
	Popover,
} from 'antd'
import {
  CheckOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons'

import util from '../../../../../libs/util'
import {
  findEnumItemList,
} from '../../../../../api'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  confirmFn: any,
  childRef: any,
  isAllWaiting?: boolean,
  title?: String,
  confirmText?: String,
  isSetMode?: boolean,
  helpUrls?: any,
}

export const ConfirmModal: React.FC<Props> = ({ confirmFn, isAllWaiting=false, title='影院银幕广告点位激活指引', confirmText='立即激活点位', isSetMode, childRef, helpUrls }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isPopoverVisible, setIsPopoverVisible] = useState(true)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		confirmFn()
		toggle(false)
	}

	const handleCancel = () => {
		toggle(false)
	}

	const helpPrev = `https://${ util.tools.env }static.remudooh.com/cms/html/web/help/`
  const [helpPageUrl, setHelpPageUrl] = useState(helpPrev)
  const getHelpPageUrl = () => {
    setHelpPageUrl(`${ helpPrev }${ helpUrls?.find(i => i.itemKey === 'HOW_TO_STARTUP').itemValue }`)
  }
  useEffect(() => {
  	if (!helpUrls || !helpUrls.length) {
  		return
  	}
  	getHelpPageUrl()
  }, [helpUrls])

	return (
		<Modal 
			width={ 860 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>
			<div className={ stylesCommon['title'] }>{ title }</div>

			<div className={ `${ styles['tips'] }` }>
				<div className={ `ifont i-tips ${ styles['i-tips'] }` }></div>
				<div>请确保当前电脑<span className={ `${ styles['important'] }` }>已安装、已启动</span>【热幕精灵-设备端】应用程序</div>
			</div>
			
			<a className={ styles['steps-cont-fail-tips'] } href={ helpPageUrl } target="_blank">如何安装并启动【热幕精灵-设备端】？</a>

      <div className={ stylesCommon['footer'] }>
      	<button className={ stylesCommon['btn'] } onClick={ handleOk }>{ confirmText }</button>
      </div>
    </Modal>
	)
}