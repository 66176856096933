import { message } from 'antd'

import axios from 'axios'
import util from './util'

interface DefaultConfig {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'HEAD' | 'OPTIONS',
  baseURL: string,
  headers: object,
  data: any,
  timeout: number,
  returnWhat: 'DATA' | 'ALL'
}

/**
 * @description 创建请求实例
 */ 
function createService() {
  
  const service = axios.create()

  // 请求拦截
  service.interceptors.request.use(
    config => {
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // 响应拦截
  service.interceptors.response.use(
    (response: any) => {
      const { config, data } = response
      const { returnWhat } = config

      if (data.code === 993 || data.code === 992 || data.code === 3) {
        util.bus.emit('UNAUTH')
        return Promise.reject('UNAUTH')
      }

      if(data.code === 80201) {
        message.warn(data.message)
      }

      if(returnWhat === 'DATA') {
        return response.data
      }

      return response
    },
    error => {
      const { status, data } = error.response
      message.error(data.message)
      switch (status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = '请求地址不存在'; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
      return Promise.reject(error)
    }
  )

  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */ 
function createRequest(service) {
  return function(config) {
    const userToken= util.Cookies.get('userToken') || ''

    const headers = {
      userToken,
      'Content-Type': 'application/json;charset=UTF-8'
    }

    const defaultConfig: DefaultConfig = {
      method: 'GET',
      baseURL: `https://${ util.tools.env }api.remudooh.com/`,
      headers,
      data: {},
      timeout: 0,
      returnWhat: 'DATA'
    }

    const assignConfig = Object.assign(defaultConfig, config)

    return service(assignConfig)
  }
}

const service = createService()
const request = createRequest(service)

export default request