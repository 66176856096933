import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { NoRecord, Crumb } from '../../../../components'

import { BaseInfo } from '../components/base-info'
import { EntityList } from '../components/entity-list'
import { OrderInfo } from '../components/order-info'
import { OrderBottom } from '../components/order-bottom'

import util from '../../../../libs/util'

import styles from './AdDetail.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderMediaId?: String,
    },
  },
}

export interface Ad {
  operatorType: string,
  deliveryCinemaModel: string,
  approveStatus: string,
  showPlanStatusMore: string,
  planAim: string,
  cancelOrderTime: string,
  contentUploadDateEnd: string,
  mediaDeliveryName: string,
  mallOrderMediaId?: string,
  mallMediaApproveId?: string,
  mallOrderPlanId?: string,
  orderTotal: any,
  contentUrl?: string,
  showPlanStatus?: string,
  startBetweenDays?: number,
  mallOrderNumber?: string,
  payOrderTime?: string,
  payUser?: any,
  confirmUser?: any,
  hasDeliveryDays?: number,
  thumbnailUrl: string,
  contentApproveOpinion: string,
  contentDuration: number,
  deliveryContentDuration: number,
  timePeriods?: any[],
  deliveryStart?: string,
  deliveryEnd?: string,
  deliveryDays: number,
  deliveryModel?: string,
  deliveryIndustryMore?: {
    itemMap?: string,
  },
  planAmount?: number,
  confirmOrderTime?: string,
  administrativeAreaId?: any[],
  deliveryIndustry?: any[],
  customerId?: string,
  districtList?: any[],
}
export const defaultAd: Ad = {
  operatorType: '',
  deliveryCinemaModel: '',
  approveStatus: '',
  showPlanStatusMore: '',
  planAim: '',
  cancelOrderTime: '',
  contentUploadDateEnd: '',
  mediaDeliveryName: '',
  mallOrderMediaId: '',
  mallMediaApproveId: '',
  mallOrderPlanId: '',
  orderTotal: null,
  contentUrl: '',
  showPlanStatus: '',
  startBetweenDays: 0,
  mallOrderNumber: '',
  payOrderTime: '',
  payUser: {},
  confirmUser: {},
  hasDeliveryDays: 0,
  thumbnailUrl: '',
  contentApproveOpinion: '',
  contentDuration: 0,
  deliveryContentDuration: 0,
  timePeriods: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryDays: 0,
  deliveryModel: '',
  deliveryIndustryMore: {
    itemMap: '',
  },
  planAmount: 0,
  confirmOrderTime: '',
  administrativeAreaId: [],
  deliveryIndustry: [],
  customerId: '',
  districtList: [],
}


export const AdDetail: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const { mallOrderMediaId } = props.match.params

  const onBack = () => {
    history.push(`/remo-ad/list`)
  }

  useEffect(() => {
    getAdDetail()
  }, [])

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 详情
  const [ad, setAd] = useState<any>(defaultAd)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getOrderMedia`,
      params: {
        mallOrderMediaId,
      },
    })
    if (code === 1) {
      setAd(data)
      setIsSubmiting(false)
    }
  }

  const onUpdateBaseInfo = () => {
    getAdDetail()
  }

  const childRef: any = useRef()
  const reloadFn = () => {
    getAdDetail()
    childRef.current.init()
  }

  const crumbList = [
    {
      name: '热幕订单',
      path: '/remo-ad/list',
    },
    {
      name: '热幕订单详情',
      path: ``,
    }
  ]

  return (
    <div className={ `${ styles['ad-page'] } ${ (ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT' || ad?.showPlanStatus === 'DRAFT') ? styles['is-paywait'] : '' }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>热幕订单详情</div>
        </div>

        { ad.mallOrderPlanId && <BaseInfo ad={ ad } /> }
        
      </div>

      {/*底部操作*/}
      <OrderBottom ad={ ad } />

      {/*投放点位*/}
      { ad.mallOrderPlanId && <EntityList ad={ ad } /> }

      {/*热幕订单信息*/}
      <OrderInfo ad={ ad } />
      
    </div>
  )
}