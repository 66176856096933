export const taskReportStatus = function(value) {
  const map = new Map([
    ['REPORT_WAIT', ''],
    ['DRAFT_FAILURE', '缺刊'],
    ['DRAFT_SUCCESS', '已上刊'],
    ['REPORT_FAILURE', '缺刊'],
    ['REPORT_SUCCESS', '已上刊'],
  ])

  return map.get(value)
}