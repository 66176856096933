import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store'
import styles from './RemoPoint.module.css'

import util from '../../../../../libs/util'

import cinemaIcon from '../../../../../assets/images/icons/cinema-icon.png'
import houseIcon from '../../../../../assets/images/icons/house-icon.png'

export const RemoPoint: React.FC = () => {
  const [summary, setSummary] = useState(0)

  useEffect(() => {
    getOwnerPointSummary()
  }, [])

  const getOwnerPointSummary = async () => {
    const { code, data, total } = await util.request({
      method: 'POST',
      url: '/mall/spu/searchPointSpuList',
      data: {
        operatorType: 'CINEMA',
        showPointStatus: 'RELEASE',
        useScope: 'SKU_SPLIT',
        pageNum: 1,
        pageSize: 1,
      }
    })
    
    if (code === 1) {
      setSummary(total)
    }
  }

  return (
    <div className={ styles.point }>
      <h3 className={ `${ styles.title }` }>热幕资源</h3>
      {
        <div className={ `${ styles.panel }` }>
          <h4 className={ `${ styles['sub-title'] }` }>
            <span className={ `${ styles['icon-cinema'] } imediafont i-cinema` }></span>
            <span className="ml2">影院银幕广告点位</span>
          </h4>

          <div className="flex justify-around">
            <div className={ `${ styles.content } mt4 tc` }>
              <div className={ `${ styles.num }` }>{ summary || 0 }</div>
              <p className={ `${ styles.txt }` }>
                总数
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}