import React, { useState, useEffect, useRef } from 'react'
import styles from './Pagination.module.css'

import { Pagination } from 'antd'

interface Props {
	getList: (pageNum?: number, pageSize?: number) => void
  total: number,
}

export const Paging: React.FC<Props> = ({ total, getList }) => {
	const [defaultCurrent] = useState(1)
	const [pageSizeOptions] = useState(['10', '20', '30'])

  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const initRef = useRef(false)
  useEffect(() => {
    if (initRef.current) {
      getList(pageNum, pageSize)
    } else {
      initRef.current = true
    }
    // getList(pageNum, pageSize)
  }, [pageNum, pageSize])

  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageNum(1)
    setPageSize(size)
  }

  return (
  	<div className={ styles['page-cont'] }>
	    <Pagination 
		    showQuickJumper 
		    showSizeChanger
		    defaultCurrent={ defaultCurrent }
		    current={ pageNum }
		    pageSize={ pageSize }
		    pageSizeOptions={ pageSizeOptions }
		    total={ total } 
		    onChange={ onPageChange }
		    onShowSizeChange={ onShowSizeChange }
		     />
	  </div>
  )
}