import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { RootState } from '../../../store'
import { Line, Icons } from '../../../components'
import { roleList } from '../../../components/nail'
import { appContext } from '../../../App.state'
import { GuideModal } from '../../../components'
import util from '../../../libs/util'
import styles from './UserCenter.module.css'

export const UserCenter: React.FC = (props: any) => {
  const appValue = useContext(appContext)

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })

  const [isCompany, setIsCompany] = useState(false)
  const [myUserInfo, setMyUserInfo] = useState<any>({})
  const [bindingNumber, setBindingNumber] = useState('')
  const [companyAuth, setCompanyAuth] = useState<any>({})
  const [personAuth, setPersonAuth] = useState<any>({})
  const [roleLabel, setRoleLabel] = useState<any>()

  useEffect(() => {
    if (!userInfo.myUserInfo.userType) {
      return
    }
    const { myUserInfo, companyAuth, personAuth, userRoleList } = userInfo
    const company = myUserInfo.userType === 'COMPANY'
    setIsCompany(company)
    setMyUserInfo(myUserInfo)
    setCompanyAuth(companyAuth)
    setPersonAuth(personAuth)
    if (!company && userRoleList && userRoleList.length) {
      const role = userRoleList[0]
      setRoleLabel(roleList?.find(i => i.id === role?.roleItemKey)?.name)
    }

    const phoneNumber = userInfo.myUserInfo.phoneNumber || userInfo.myUserInfo?.bindingNumber.split('-')[1]
    setBindingNumber(util.tools.convertPhone(phoneNumber))

    console.log('user-page', userInfo)
  }, [userInfo])

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [hasBindWechat, setHasBindWechat] = useState(false)
  // 获取用户第三方账号信息
  const getByUserIdAndChannel = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/uc/third/getByUserIdAndChannel`,
      params: {
        productChannelId: 'MEDIA_WX',
        thirdPlatformId: 'THIRD_WX',
      },
    })
    if (code === 1) {
      setHasBindWechat(data && data.thirdOpenId)
    }
    setIsSubmiting(false)
  }
  useEffect(() => {
    getByUserIdAndChannel()
  }, [])

  const childRef: any = useRef()
  const onLink = () => {
    childRef.current.onToggle()
  }

  return (
    <div className={ `${ styles['user-page'] }` }>
      <div className={ `${ styles['crumb-list'] }` }><a className={ `${ styles['crumb-parent'] }` }  href={ `${ appValue.mediaPageUrl }/mine/home` }>我的主页</a> / <span className={ `${ styles['crumb-cur'] }` }>用户中心</span></div>

      {/*基本信息*/}
      <div className={ `${ styles['page-box'] }` }>
        <div className={ `${ styles['box-tit'] }` }>基本信息</div>

        <div className={ `${ styles['box-cont'] }` }>
          <div className={ `${ styles['form'] }` }>
            <div className={ `${ styles['form-item'] }` }>
              <div className={ `${ styles['form-item-label'] }` }>头像：</div>
              <div className={ `${ styles['form-item-value'] }` } onClick={ onLink }>
                <div className={ styles.avatar }>
                  {
                    myUserInfo?.avatarUrl ? 
                    <img width="112" src={ myUserInfo?.avatarUrl } alt="" />
                    :
                    <div className={ `${ styles['icon-avatar'] } imediafont i-building` }></div>
                  }
                  <div className={ `${ styles['btn-edit'] }` }><div className={ `${ styles['icon-edit'] } imediafont i-pencile-simple` }></div></div>
                </div>
              </div>
            </div>

            <div className={ `${ styles['form'] }` }>
              {/*<div className={ `${ styles['form-item'] }` }>
                <div className={ `${ styles['form-item-label'] }` }>热幕号：</div>
                <div className={ `${ styles['form-item-value'] }` }>{ myUserInfo?.userShowId }</div>
              </div>*/}
              {
                !isCompany && (
                  <>
                    <div className={ `${ styles['form-item'] }` }>
                      <div className={ `${ styles['form-item-label'] }` }>所属企业：</div>
                      <div className={ `${ styles['form-item-value'] }` }>{ companyAuth?.chinaName }</div>
                    </div>
                    <div className={ `${ styles['form-item'] }` }>
                      <div className={ `${ styles['form-item-label'] }` }>在该企业中的角色：</div>
                      <div className={ `${ styles['form-item-value'] }` }>{ roleLabel }</div>
                    </div>
                  </>
                )
              }
            </div>

          </div>
        </div>
      </div>

      <div className={ `${ styles['page-box'] }` }>
        <div className={ `${ styles['box-tit'] }` }>账号与安全</div>
        <div className={ `${ styles['box-cont'] }` }>
          <div className={ `${ styles['form'] }` }>
            <div className={ `${ styles['form-item'] }` }>
              <div className={ `${ styles['form-item-label'] }` }>热幕号：</div>
              <div className={ `${ styles['form-item-value'] }` }>{ myUserInfo?.userShowId }</div>
            </div>

            <div className={ `${ styles['form-item'] }` }>
              <div className={ `${ styles['form-item-label'] }` }>实名认证：</div>
              <div className={ `${ styles['form-item-value'] }` }>
                {
                  isCompany ? companyAuth?.chinaName : personAuth?.chinaName
                }
                <span className="ml1"><Icons color="#FE8135" icon={ isCompany ? 'company-auth' : 'personal-auth' } /></span>
                
              </div>
            </div>
          </div>
          <div className={ `${ styles['form'] }` }>
            <div className={ `${ styles['form-item'] }` }>
              <div className={ `${ styles['form-item-label'] }` }>绑定手机号：</div>
              <div className={ `${ styles['form-item-value'] }` }>
                { bindingNumber }
                <span className={ `${ styles['btn-link'] } ${ styles['ml'] }` } onClick={ onLink }>修改</span>
              </div>
            </div>

            <div className={ `${ styles['form-item'] }` }>
              <div className={ `${ styles['form-item-label'] }` }>微信：</div>
              <div className={ `${ styles['form-item-value'] }` }>
                {
                  hasBindWechat ? '微信用户' : '未关联'
                }
                <span className={ `${ styles['btn-link'] } ${ styles['ml'] }` } onClick={ onLink }>{ hasBindWechat ? '解除关联' : '去关联' }</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <GuideModal childRef={ childRef } />
    </div>
  )
}