import React, { useState, useEffect, useRef } from 'react'
import styles from './EntityList.module.css'
import util from '../../../libs/util'
import { Crumb } from '../../../components'

import { FilterPanel } from './filterPanel'
import { List } from './listInner'

interface ContextValueType {
  mediaResourceType: string | null,
  entityName: string | null,
  cinemaPropertyNumber: string | null,
  cinemaChainCompany: string | null,
  locationType: string | null,
  cinemaPlayerType: string | null,
  directStoreEnabled?: string | boolean | null,
}

export const convertItem = (list, text) => {
  const item = list.find(i => i.itemKey === text)
  return item ? item.itemValue : ''
}

export const defaultFilterContextValue: ContextValueType = {
  mediaResourceType: 'CINEMA_SCREEN',
  entityName: null,
	cinemaPropertyNumber: null,
  cinemaChainCompany: null,
  locationType: null,
  cinemaPlayerType: null,
  directStoreEnabled: null,
}

export const filterContext = React.createContext(defaultFilterContextValue)
export const setFilterContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | any>(undefined)

export const ResourceEntityList: React.FC = () => {
	const [filterData, setFilterData] = useState(defaultFilterContextValue)

  const listRef: any = useRef()
  const onSearch = () => {
    listRef.current.getEntityList(1)
  }

  useEffect(() => {
    findEnumItemList()
  }, [])

  // 院线
  const [cinemaChainCompanyItems, setCinemaChainCompanyItems] = useState([])
  // 位置类型
  const [locationTypeItems, setLocationTypeItems] = useState<any>([])
  // 影院TMS系统
  const [cinemaPlayerTypeItems, setCinemaPlayerTypeItems] = useState<any>([])
  const findEnumItemList = async() => {
  	const key = ['LOCATION_TYPE', 'CINEMA_PLAYER_TYPE', 'CINEMA_CHAIN']
    const params = {
      groupKeyArray: key.join(','),
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })

    setLocationTypeItems(data['LOCATION_TYPE'])
    setCinemaPlayerTypeItems(data['CINEMA_PLAYER_TYPE'])
    setCinemaChainCompanyItems(data['CINEMA_CHAIN'])
  }

  const crumbList = [
    {
      name: '媒体资源',
      path: '/entity/list',
    },
    {
      name: '影院',
      path: '',
    }
  ]
  return (
    <div className={ `${ styles['point-page'] }` }>
      <div className={ `${ styles['point-page-tit'] }` }>
        <Crumb list={ crumbList } />
      </div>
      <filterContext.Provider value={ filterData }>
      	<setFilterContext.Provider value={ setFilterData }>
		      <FilterPanel 
            onSearch={ onSearch } 
            locationTypeItems={ locationTypeItems  } 
            cinemaPlayerTypeItems={ cinemaPlayerTypeItems } 
            cinemaChainCompanyItems={ cinemaChainCompanyItems }
             />
		      <List 
            childRef={ listRef }
            locationTypeItems={ locationTypeItems  } 
            cinemaPlayerTypeItems={ cinemaPlayerTypeItems } 
            cinemaChainCompanyItems={ cinemaChainCompanyItems }
             />
	      </setFilterContext.Provider>
      </filterContext.Provider>

    </div>
  )
}