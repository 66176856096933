import { 
  CinemaIssue, 
} from '../pages'

const routes = [
  {
    path: '/delivery/list',
    component: CinemaIssue,
  },
]

export default routes