import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../../../libs/util.constants'
import { PriceModal } from '../price-modal'

import styles from './EntityList.module.css'

interface Props {
  ad: any,
}

interface Item {
  itemKey: string,
  itemValue: string,
}

export const EntityList: React.FC<Props> = ({ ad }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [mallOrderPlanId, setMallOrderPlanId] = useState(ad?.mallOrderPlanId)
  const [mallOrderMediaId, setMallOrderMediaId] = useState(ad?.mallOrderMediaId)
  const [mallMediaApproveId, setMallMediaApproveId] = useState(ad?.mallMediaApproveId)

  useEffect(() => {
    getEnumItems()
  }, [])

  // 场所列表
  const [entityList, setEntityList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getEntityList(pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)
  const getEntityList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/searchOrderMediaEntityList?pageNum=${pageNum}&pageSize=${pageSize}`,
      params: {
        mallOrderMediaId,
        mallMediaApproveId,
        pageNum,
        pageSize,
      },
    })
    if (code === 1) {
      // setEntityList(data)
      data.forEach((entity, index) => { 
        getPointList(data, index)
      })
      setTotal(total)
    }
  }

  // 点位列表
  const getPointList = async (list, entityIndex) => {
    let eList = [...list]
    const resourceEntityInfoId = eList[entityIndex].resourceEntityInfoId
    const { data = [], code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/searchOrderMediaPointList?pageNum=${1}&pageSize=${9999}`,
      params: {
        mallOrderMediaId,
        mallMediaApproveId,
        resourceEntityInfoId,
      },
    })

    if (code === 1) {
      eList[entityIndex].pointList = data
      setEntityList(eList)
      console.log('setEntityList', entityList, eList)
    }
  }

  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<Item[]>([])
  const [cinemaSoundTrackItems, setCinemaSoundTrackItems] = useState<Item[]>([])
  // 获取枚举值
  const getEnumItems = async () => {
    // CINEMA_SOUND_TRACK 声道
    // CINEMA_SCREEN_MATERIAL 影院银幕类型
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: ['CINEMA_SOUND_TRACK', 'CINEMA_SCREEN_MATERIAL'].join(','),
      },
    })
    if (code === 1) {
      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      setCinemaSoundTrackItems(data['CINEMA_SOUND_TRACK'] || [])
    }
  }

  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const priceModalRef: any = useRef()
  const showPriceModal = (point) => {
    const params = {
      resourcePointId: point.resourcePointId, 
      mallOrderPlanId,
      mallOrderMediaId,
      showPlanStatus: ad.showPlanStatus,
      deliveryDays: ad.deliveryDays,
      settleStandardType: ad.settleStandardType,
    }
    priceModalRef.current.showModal(params)
  }

  return (
  	<div className={ `${ styles['ad-page-cont'] }` }>
      <div className={ `${ styles['flex'] }` }>
        <div className={ `${ styles['ad-tit-label'] }` }>投放点位</div>
        <div className={ `${ styles['ad-tit-tips'] }` }>共 <span className={ `${ styles['important'] }` }>{ ad.pointCount }</span> 个点位，覆盖 <span className={ `${ styles['important'] }` }>{ total }</span> 个场所</div>
      </div>

      {
        (entityList.length > 0) && (
          <>
            {/* 场所列表 */}
            <div className={ `${ styles['table-head'] }` }>
              <div className={ `${ styles['table-td'] }` }>场所信息</div>
              <div className={ `${ styles['table-td'] }` }>点位名称</div>
              {
                ad.settleStandardType !== 'SHOW' && <div className={ `${ styles['table-td'] }` }>点位级别</div>
              }
              <div className={ `${ styles['table-td'] }` }>座位数</div>
              <div className={ `${ styles['table-td'] }` }>可投放天数</div>
              <div className={ `${ styles['table-td'] }` }>点位预估收益</div>
              {
                ad.settleStandardType === 'SHOW' && <div className={ `${ styles['table-td'] }` }>预估触达人次</div>
              }
              <div className={ `${ styles['table-td'] }` }>操作</div>
            </div>

            <div className={ `${ styles['entity-list'] }` }>
              {
                entityList.map(entity => {
                  return (
                    <div className={ `${ styles['entity-item'] }` } key={ entity.resourceEntityInfoId }>
                      <div className={ `${ styles['entity-info-wrap'] }` }>
                        <div className={ `${ styles['entity-image-cont'] }` }>
                          <img className={ `${ styles['entity-thumbnail'] }` } src={ `${ entity.entityViewPath }?imageMogr2/thumbnail/x112` } />
                          {
                            (entity.operatorEntityType === 'CINEMA') && (
                              <div className={ `${ styles['entity-label'] }` }>{ entity.cinemaChainName }</div>
                            )
                          }
                        </div>
                        <div className={ `${ styles['entity-info'] }` }>
                          <div className={ `${ styles['entity-tit'] }` }>{ entity.entityName }</div>
                          {
                            (entity?.cinemaUnifiedLink && entity?.cinemaUnifiedLink !== 'MANUAL_AGENT') && (
                              <div className={ `${ styles['entity-auto-check'] }` }>自动化上刊</div>
                            )
                          }
                          
                          <div className={ `${ styles['entity-dec'] }` }>
                            {/* { entity.cinemaAttendance }km
                            | &nbsp; */}
                            { entity.provinceName }
                            {
                              (entity.cityName) && (<> { entity.cityName } </>)
                            }
                            {
                              (entity.districtName) && (<> { entity.districtName } </>)
                            }
                            { entity.addressMore }
                          </div>
                          <div className={ `${ styles['entity-point-info'] }` }>已选点位<span className={ `${ styles['count-num'] }` }>{ entity.entityPointCount || 0 }</span>个
                          </div>
                          <div className={ `${ styles['entity-prirce-info'] }` }>小计 <span className={ `${ styles['entity-prirce-text'] }` }>￥<span className={ `${ styles['entity-prirce-int'] }` }>{ util.tools.splitNum2String(entity.realPayAmount).int }
                          </span>{ util.tools.splitNum2String(entity.realPayAmount).dec }</span></div>
                          
                        </div>
                      </div>

                      <div className={ `${ styles['point-list'] }` }>
                        {
                          (entity.pointList && entity.pointList.length > 0) && (
                            entity.pointList.map(point => {
                              return (
                                <div className={ `${ styles['point-item'] } ${ point.approveStatus === 'NO' ? styles['is-disabled'] : '' }` } key={ point.resourcePointId }>
                                  <div className={ `${ styles['table-td'] }` }>{ point.pointName }</div>

                                  {
                                    ad.settleStandardType !== 'SHOW' && (
                                      <div className={ `${ styles['table-td'] }` }>
                                        <div className={ styles['rate-list'] }>
                                          {
                                            new Array(point.pointGrade).fill(1).map((item, index) => {
                                              return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
                                            })
                                          }
                                        </div>
                                      </div>
                                    )
                                  }
                                  <div className={ `${ styles['table-td'] }` }>{ point.seatNumber }座</div>

                                  <div className={ `${ styles['table-td'] }` }><span className={ `${ styles['point-total-days'] } ${ point.innerTotal && point.innerTotal != ad.deliveryDays ? styles['special-number'] : '' }` }>{ point.innerTotal }</span>天</div>

                                  <div className={ `${ styles['table-td'] } ${ styles['special-number'] } ${ styles['text-price'] }` }>
                                    <span className={ `${ styles['text-price-unit'] }` }>￥</span>{ util.tools.splitNum2StringFixed(point.settlePrice) }
                                  </div>

                                  {
                                    ad.settleStandardType === 'SHOW' && <div className={ `${ styles['table-td'] }` }>{ point.flowPlanNum }人</div>
                                  }

                                  <div className={ `${ styles['table-td'] } ${ styles['table-link'] }` } onClick={ () => { showPriceModal(point) } }>预估收益详情</div>

                                  {
                                    (point.approveStatus === 'NO') && <div className={ `${ styles['point-refuse'] } icon ` }>谢绝投放</div>
                                  }
                                </div>
                              )
                            })
                          )
                        }
                        
                      </div>
                    </div>
                  )
                })
              }
            </div>

            {/*页码*/}
            <div className={ `${ styles.paging } flex justify-end` }>
              <Pagination 
                showQuickJumper 
                showSizeChanger
                showTotal={ total => `共 ${total} 条`}
                defaultCurrent={ defaultCurrent }
                current={ pageNum }
                pageSize={ pageSize }
                pageSizeOptions={ pageSizeOptions }
                total={ total } 
                onChange={ onPageChange }
                onShowSizeChange={ onShowSizeChange }
                 />
            </div>

            {/*点位预估收益*/}
            <PriceModal  childRef={ priceModalRef } />
          </>
        )
      }
    </div>
  )
}