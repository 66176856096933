import React, { useState, useContext, useEffect, useRef } from 'react'
import styles from './Thumbs.module.css'

import { Carousel } from 'antd'
import { Icons } from '../../../../../components/icons'
import { cinemaIssueContext } from '../../CinemaIssue'
import { appContext } from '../../../../../App.state'
import util from '../../../../../libs/util'

export const Thumbs: React.FC = () => {
  const value = useContext(cinemaIssueContext)
  const appValue = useContext(appContext)
  const ref: any = useRef()

  const [desserts, setDesserts] = useState<Array<any>>([])
  let [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)

  useEffect(() => {
    if(value.visibleIssueDialog) {
      getCinemaTaskFileList()
    }
  }, [value.visibleIssueDialog])

  useEffect(() => {
    if(desserts.length > 0) {
      util.bus.emit('selectDessertsItems', desserts[page - 1][0])
    }
  }, [page])

  useEffect(() => {
    if(desserts.length > 0) {
      util.bus.on('handleReportCinemaTaskStatus', () => {
        console.log(desserts)
        // getCinemaTaskFileList()
      })
    }
  }, [desserts])

  const getCinemaTaskFileList = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/getCinemaTaskFileList',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      const list: Array<any> = []
      let listChild: Array<any> = []

      if(result.data.length <= 5) {
        result.data.map((item) => {
          console.log(item)

          if(item.fileConvertCode === 0) {
            item.number = item.filePath.substr(item.filePath.lastIndexOf('\\') + 1) 
          }else {
            item.number = item.contentNumber
          }

          listChild.push(item)
        })

        list.push(listChild)
      }else {
        result.data.map((item) => {
          if(item.fileConvertCode === 0) {
            item.number = item.filePath.substr(item.filePath.lastIndexOf('\\') + 1) 
          }else {
            item.number = item.contentNumber
          }

          listChild.push(item)
          if(listChild.length === 5) {
            list.push(JSON.parse(JSON.stringify(listChild)))
            listChild.length = 0
          }
        })


        const lastLength = result.data.length % 5

        if(lastLength > 0) {
          listChild = result.data.slice(result.data.length - lastLength)
          list.push(JSON.parse(JSON.stringify(listChild)))
          listChild.length = 0
        }
      }

      list.map((arr) => {
        arr.map((item, index) => {
          if(index === 0) {
            item.selected = true
          }
        })
      })

      const timeout = setTimeout(() => {
        util.bus.emit('selectDessertsItems', list[0][0])
        clearTimeout(timeout)
      }, 300)

      setDesserts(list)
      setTotalPage(list.length)
    }
  }

  const onSelect = (record, index) => {
    let list: any[] = [...desserts]

    list[index].map(item => {
      if(item.fileId === record.fileId) {
        item.selected = true
        util.bus.emit('selectDessertsItems', item)
        // TODO
      }else {
        item.selected = false
      }
    })

    setDesserts(list)
  }

  const onUp = () => {
    util.tools.prevent(function() {
      --page
      if(page < 1) {
        // page = totalPage
        page = 1
        return
      }
      setPage(page)
      ref.current.prev()
    }, 1000)
  }

  const onDown = () => {
    util.tools.prevent(function() {
      ++page
      if(page > totalPage) {
        // page = 1
        page = totalPage
        return
      }
      setPage(page)
      ref.current.next()
    }, 1000)
  }

  return (
    <div className={ `${ styles.thumbs } flex justify-between` }>
      <div className={ styles.carousel }>
        <Carousel 
          ref={ ref } 
          dots={ false }
          dotPosition="right"
        >
          {
            desserts.map((item, index) => (
              <div className={ styles['carousel-item'] } key={ `desserts_item_${ index }` }>
                <div className={ `${ styles['carousel-item-content'] } flex align-center pl6` }>
                  {
                    item.map((record, recordIndex) => (
                      <div 
                        className={ `${ styles.card } ${ record.selected ? styles.selected : '' } mr4` } 
                        key={ `desserts_item_record_${ recordIndex }` }
                        onClick={ () => { onSelect(record, index) } }
                      >
                        <span className={ `${ styles.number } px2 fs12` }>{ record.number }</span>

                        <div className={ styles.cover }>
                          <img width="100%" src={ `${ appValue.rHost }${ record.thumbnailUrl[0] }` } alt="" />

                          {
                            record.fileConvertCode === 0 ? '' :
                            (
                              <span className={ `${ styles.unusual } px3` }>
                                <Icons icon="sigh" />
                                下载异常
                              </span>
                            )
                          }
                          
                        </div>

                        <ul className={ `${ styles.bottom } flex justify-around align-center` }>
                          {
                            (value.reportStatus.REPORT_WAIT > 0 || value.reportStatus.DRAFT_SUCCESS > 0 || value.reportStatus.DRAFT_FAILURE > 0) ? 
                            <li className="tc">
                              <span className="fb">{ record.reportWaitCount }</span>
                              <p>待上刊</p>
                            </li> : ''
                          }
                          
                          <li className="tc">
                            <span className="fb">{ record.reportSuccessCount }</span>
                            <p>已上刊</p>
                          </li>
                          <li className="tc">
                            <span className="fb">{ record.reportFailureCount }</span>
                            <p>缺刊</p>
                          </li>
                        </ul>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </Carousel>
      </div>

      <div className={ `${ styles.switch } flex justify-center align-center` }>
        <div className="tc">
          <div className={ styles.up } onClick={ onUp }>
            <Icons icon="up" color="#f2f2f2" size="16" />
          </div>
          <div className={ `${ styles.num } my4` }>{ page }/{ totalPage }</div>
          <div className={ styles.down } onClick={ onDown }>
            <Icons icon="down" color="#f2f2f2" size="16" />
          </div>
        </div>
      </div>
    </div>
  )
}