import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './CompanyHome.module.css'

import { useParams } from 'react-router-dom'

import { Line } from '../../../../components'
import { Profile }  from './profile'
import { Token }  from './token'
import { Point }  from './point'
import { RemoPoint }  from './remo-point'
import { Order }  from './order'
import { OrderExec } from './order-exec'
import { Staff }  from './staff'
import { Wallet } from './wallet'

export const CompanyHome: React.FC = (props: any) => {
  const history = useHistory()
  const goToPage = (path) => {
    history.push(path)
  }

  return (
    <div className={ `${ styles['company-home'] }` }>
      <div className={　styles['company-home-tit'] }>我的主页</div>
      <div className={ styles['company-cont-t'] }>
        <div className={ `${ styles['company-cont-l'] }` }>
          <Profile />
        </div>
        <div className={ styles['company-cont-r'] }>
          <Staff />
        </div>
      </div>
      
      <div className={ styles['company-cont'] }>
        <div className={ styles['company-cont-l'] }>
          <div className={ styles['company-cont-l-l'] }>
            {/*我的钱包*/}
            <div onClick={ () => { goToPage('/wallet/index') } }><Wallet /></div>
            {/*热幕收益*/}
            <div className={ styles['mt'] } onClick={ () => { goToPage('/remo-income/index') } }>
              <Token />
            </div>
          </div>
          <div className={ styles['company-cont-l-r'] }>
            {/*广告执行单*/}
            <OrderExec />
            {/*热幕订单*/}
            <div className={ styles['mt'] }>
              <Order />
            </div>
          </div>
        </div>
        <div className={ styles['company-cont-r'] }>
          {/*媒体资源*/}
          <div onClick={ () => { goToPage('/point/list') } }><Point /></div>
          {/*热幕资源*/}
          <div className={ styles['mt'] } onClick={ () => { goToPage('/remo-point/list') } }>
            <RemoPoint />
          </div>
        </div>
      </div>
    </div>
  )
}