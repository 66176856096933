import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { NoRecord, Crumb } from '../../../components'

import { BaseInfo } from '../components/base-info'
import { EntityList } from '../components/entity-list'
import { OrderInfo } from '../components/order-info'
import { OrderBottom } from '../components/order-bottom'

import { AdExec, defaultAdExec } from '../index'

import util from '../../../libs/util'

import styles from './AdMediaDetail.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
    },
  },
}

// 其他订单 mallOrderPlanId
export const AdExecMediaDetail: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const { mallOrderPlanId } = props.match.params

  const onBack = () => {
    history.push(`/ad-exec/list`)
  }

  useEffect(() => {
    getAdDetail()
  }, [])

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 详情
  const [ad, setAd] = useState<AdExec>(defaultAdExec)
  const [isEditMode, setIsEditMode] = useState(false)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1) {
      setAd((state) => {
        return {
          ...state,
          ...data,
        }
      })
      setIsEditMode(ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE')
      if (data?.planAim === 'SELF' || data?.planAim === 'EXCLUSIVE_MEDIA') {
        getOrderMediaByPlanId()
      }
      await getOrderTotal(data)
      setIsSubmiting(false)
    }
  }

  const getOrderTotal = async (list) => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      setAd(state => {
        return {
          ...state,
          orderTotal: data.find(i => i.mallOrderPlanId === mallOrderPlanId) || null,
        }
      })
    }
  }

  
  // 获取媒体主订单详情
  const getOrderMediaByPlanId = async() => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getOrderMediaByPlanId`,
      params: {
        mallOrderPlanId: mallOrderPlanId,
      },
    })

    if (code === 1) {
      setAd((state) => {
        return {
          ...state,
          ...data,
        }
      })
      console.log('getOrderMediaByPlanId', data)
    }
  }

  const childRef: any = useRef()
  const reloadFn = () => {
    getAdDetail()
    childRef.current.init()
  }

  const [needShowBottom, setNeedShowBottom] = useState(true)
  const toggleBottom = (show) => {
    setNeedShowBottom(show === undefined ? !needShowBottom : show)
  }

  const crumbList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '广告执行单详情',
      path: '',
    }
  ]

  return (
    <div className={ `${ styles['ad-page'] } ${ (ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT' || ad?.showPlanStatus === 'DRAFT') ? styles['is-paywait'] : '' }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>广告执行单详情</div>
        </div>

        {/*提交订单后*/}
        {
          ad.showPlanStatus && <BaseInfo ad={ ad } onUpdateBaseInfo={ reloadFn } />
        }
        
      </div>

      {/*投放点位*/}
      { ad.showPlanStatus && <EntityList childRef={ childRef } ad={ ad } toggleBottom={ toggleBottom } reloadFn={ reloadFn } /> }

      {/*底部操作*/}
      { ad.showPlanStatus && needShowBottom && <OrderBottom ad={ ad } isEditMode={ isEditMode } reloadFn={ reloadFn } isConfirmPage={ false } /> }

      {/*广告执行单信息*/}
      {
        (ad?.showPlanStatus !== 'DRAFT' && ad?.showPlanStatus !== 'SAVE') && (
          <OrderInfo ad={ ad } />
        )
      }
      
    </div>
  )
}