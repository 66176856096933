import React, { useState, useEffect } from 'react'
import { DatePicker, Cascader, Slider, Spin, message, Select } from 'antd'
import { useHistory } from 'react-router-dom'
import type { Moment } from 'moment'

import moment from 'moment'

import util from '../../../../libs/util'

import styles from './CreateForm.module.css'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const maxDayLen = 96

type RangeValue = [Moment | null, Moment | null] | null

interface Props {
  form?: any,
  deliveryIndustryMore?: any,
  mallOrderPlanId?: string,
  reloadFn?: Function,
}

export const CreateForm: React.FC<Props> = ({ form, deliveryIndustryMore, mallOrderPlanId, reloadFn }) => {
  const history = useHistory()
  
  const [submitForm, setSubmitForm] = useState<any>(Object.assign({}, form))
  const [isFormChecked, setIsFormChecked] = useState(false)
  const [isLongDuration, setIsLongDuration] = useState(true)
  const [durationList, setDurationList] = useState<any>([])

  useEffect(() => {
    setCustomerId(submitForm.customerId)
    submitForm.timePeriods.length && setOrder([submitForm.timePeriods.join(','), submitForm.playSequence].join(','))
    setPeriods(getPeriods(submitForm.timePeriods, submitForm.playSequence))
    // const iLong = submitForm.planAim === 'EXCLUSIVE_AD' || submitForm.planAim === 'EXCLUSIVE_MEDIA' || submitForm.planAim === 'SELF' && submitForm.planAimExt !== 0
    const iLong = true
    setIsLongDuration(iLong)
    if (iLong) {
      getDurationList()
    } else {
      setDurationList([15, 30, 60])
    }

    submitForm.deliveryStart && setSelectedDate([moment(submitForm.deliveryStart, dateFormat), moment(submitForm.deliveryEnd, dateFormat)])
    setIsFormChecked(true)
    
    getAdministrativeAreaId()
    getCalendarValidRange()
    initIndustryList()

    if (submitForm.planAim === 'EXCLUSIVE_MEDIA') {
  		getMediaCustomers()
      // 独家包销 播放时段 映前10分钟
      setSubmitForm((state) => {
        return {
          ...state,
          timePeriods: [10],
        }
      })
      checkForm('timePeriods')
  	}
    // 媒体主内宣投放
    if (submitForm.planAim === 'SELF' && submitForm.planAimExt === 0) {
      getAdTypeItems()
    }
  	setSubmitForm((state) => {
      return {
        ...state,
        planAim: submitForm.planAim,
        deliveryContentDuration: submitForm.deliveryContentDuration || null,
      }
    })

    if (deliveryIndustryMore) {
      const deliveryIndustry = Object.keys(deliveryIndustryMore.itemMap).join(',')
      setSelectedIndustry([deliveryIndustryMore.groupKey, deliveryIndustry])
    }
  }, [])

  // useEffect(() => {
  // 	if (form.planAim === 'EXCLUSIVE_MEDIA') {
  // 		getMediaCustomers()
  //     // 独家包销 播放时段 映前10分钟
  //     setSubmitForm((state) => {
  //       return {
  //         ...state,
  //         timePeriods: [10],
  //       }
  //     })
  //     checkForm('timePeriods')
  // 	}
  //   // 媒体主内宣投放
  //   if (form.planAim === 'SELF' && form.planAimExt === 0) {
  //     getAdTypeItems()
  //   }
  // 	setSubmitForm((state) => {
  //     return {
  //       ...state,
  //       planAim: form.planAim,
  //     }
  //   })
  // }, [form.planAim])

  const checkForm = (hasChecked?) => {
    let checkArr = ['deliveryStart', 'deliveryContentDuration', 'deliveryIndustry']

    if (form.planAim === 'EXCLUSIVE_MEDIA') {
    	checkArr.push('customerId')
    }
    const needCheckArr = checkArr.filter(i => i !== hasChecked)
    let isChecked = true
    for (let key in submitForm) {
      if (needCheckArr.includes(key) && !submitForm[key]) {
        isChecked = false
        break
      }
    }
    setIsFormChecked(isChecked)

    console.log(hasChecked, isChecked, isFormChecked)
  }

  const [customerId, setCustomerId] = useState()
  const [cinemaCustomerItems, setCinemaCustomerItems] = useState<any>([])
  const findEnumItemList = async(key) => {
    const params = {
      groupKeyArray: key,
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
   	return data[key]
  }
  const getMediaCustomers = async() => {
  	let items = await findEnumItemList('CINEMA_CUSTOMER')
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/biz_entity/getMediaCustomers`,
      data: {
        operatorType: 'CINEMA',
        city: '',
        district: '',
      },
    })
    if (code === 1) {
      items = items.filter(i => data.includes(i.itemKey))
      let res:any = []
      items.forEach(i => {
      	res.push({
      		label: i.itemValue,
        	value: i.itemKey,
      	})
      })
      setCinemaCustomerItems(res)
    }
  }
  const handleCustomerChange = (value) => {
  	setCustomerId(value)
  	setSubmitForm((state) => {
      return {
        ...state,
        customerId: value,
      }
    })
  	checkForm('customerId')
  }

  // 投放周期
  const [selectedDate, setSelectedDate] = useState<RangeValue>(null)
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const onDateChange = (date, dateString) => {
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryStart: dateString[0],
        deliveryEnd: dateString[1],
      }
    })
    if (!dateString[0]) {
      setIsFormChecked(false)
      setSelectedDate(null)
    } else {
      setSelectedDate(date)
      checkForm('deliveryStart')
    }
  }

  const disabledDate = (current) => {
    // 不能选最小日期之前的或最大日期之后的
    return current && (moment(current).valueOf() < moment(minDate).valueOf() || moment(current).valueOf() > moment(maxDate).valueOf())
  }

  // 广告时长
  const getDurationList = async() => {
    let durationItems = await findEnumItemList('TECHNIC_FEE_OWN_CPT')
    let tempList:any = []
    durationItems.forEach(i => {
      tempList.push({
        label: `${ i.itemKey }秒以内`,
        value: parseInt(i.itemKey),
      })
    })
    setDurationList(tempList)
  }
  const onToggleDuration = (value) => {
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryContentDuration: value,
      }
    })
    checkForm('deliveryContentDuration')
  }
  
  // 播放时段
  const periodArr = new Array(10).fill(1)
  const [order, setOrder] = useState<any>()
  // 映前5分钟内，第6－10分钟
  const orderList = [
    {
      value: [5, 99].join(','),
      label: '映前5分钟内',
    },
    {
      value: [6,7,8,9,10, 99].join(','),
      label: '映前6-10分钟',
    }, 
  ]
  const [periods, setPeriods] = useState<any>([])
  const onOrderChange = (tempValue) => {
    const value = tempValue.split(',')
    const len = value.length
    const timePeriods = len === 1 ? [value[0]] : value.slice(0, len - 1)
    const playSequence = value[len - 1]
    setOrder(tempValue)
    setSubmitForm((state) => {
      return {
        ...state,
        timePeriods,
        playSequence,
      }
    })
    setPeriods(getPeriods(timePeriods, playSequence))
    checkForm('timePeriods')
  }
  const getPeriods = (timePeriods, playSequence) => {
    let temPeriods:any = []
    if (timePeriods.length > 1) {
      timePeriods.forEach(i => {
        temPeriods.push(parseInt(i))
      })
    } else {
      if (playSequence == 0) {
        temPeriods = [1]
      } else {
        for (let i = 1; i <= timePeriods[0]; i++) {
          temPeriods.push(i)
        }
      }
    }
    return temPeriods
  }

  // 广告所属行业
  const [currentIndustry, setCurrentIndustry] = useState('INDUSTRY')
  const [industryOptions, setIndustryOptions] = useState<any>([])
  const [selectedIndustry, setSelectedIndustry] = useState<any>([])
  const loadData = async(selectedOptions) => {
    console.log('selectedOptions', selectedOptions, industryOptions)
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true
    const key = selectedOptions[0].itemKey
    setCurrentIndustry(key)
    const { code, data } = await getIndustryList(key)
    targetOption.loading = false
    let list = data[key]
    if (code === 1) {
      list.forEach(i => {
        i.value = i.itemKey
        i.label = i.itemValue
      })
      targetOption.children = list
      setIndustryOptions([...industryOptions])
    }
  }
  const onIndustryChange = (value, selectedOptions) => {
    const deliveryIndustry = value && value.length > 1 ? value[1] : ''
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryIndustry,
      }
    })
    setSelectedIndustry(value)
    if (!deliveryIndustry) {
      setIsFormChecked(false)
    } else {
      checkForm('deliveryIndustry')
    }
  }
  const getIndustryList = async(cIndustry) => {
    const params = {
      groupKeyArray: cIndustry,
      excludeItems: cIndustry === 'INDUSTRY' ? ['AGENT', 'PUBLIC-AD-TYPE'].join(',') : null, 
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    return { data, code }
  }
  const initIndustryList = async() => {
    const { code, data } = await getIndustryList(currentIndustry)
    let list = data[currentIndustry]
    if (code === 1) {
      list.forEach(i => {
        i.value = i.itemKey
        i.label = i.itemValue
        i.isLeaf = false
      })
      
      const result = await getIndustryList(list.map(i => i.itemKey).join(','))
      if (result.code === 1) {
        const childrenList = result.data
        list.forEach(i => {
          const itemKey = i.itemKey
          i.value = itemKey
          i.label = i.itemValue
          i.isLeaf = false
          i.children = childrenList[itemKey]
          i.children.forEach(j => {
            j.value = j.itemKey
            j.label = j.itemValue
            j.isLeaf = true
          })
        })
        setIndustryOptions([...list])
      }
    }
  }

  // 内容类型
  const [adTypeItems, setAdTypeItems] = useState<any>()
  const handleAdTypeChange = (value) => {
    setSelectedIndustry(value)
  	setSubmitForm((state) => {
      return {
        ...state,
        deliveryIndustry: value,
      }
    })
  	checkForm('deliveryIndustry')
  }
  const getAdTypeItems = async() => {
    let items = await findEnumItemList('PUBLIC-AD-TYPE')
    let res:any = []
    items.forEach(i => {
      res.push({
        label: i.itemValue,
        value: i.itemKey,
      })
    })
    setAdTypeItems(res)
  }

  // 获取地区id
  const getAdministrativeAreaId = async() => {
    const { data, code } = await util.request({
      method: 'POST',
      url: `/resource/entity/getOrgEntityDistrictGroup`,
      data: {
        operatorType: 'CINEMA',
        city: '',
        district: '',
      },
    })

    if (code === 1) {
      setSubmitForm((state) => {
        return {
          ...state,
          administrativeAreaId: data.map(i => i.city),
        }
      })
    }
  }

  // 获取日期可选区间
  const getCalendarValidRange = async() => {
    const { data, code } = await  util.request({
      method: 'GET',
      url: `/mall/tool/getDeliveryStartDay`,
      params: {
        operatorType: submitForm.operatorType,
      }
    })

    if (code === 1) {
      const minDate = new Date(data)
      const customMinDate = (Math.abs(+new Date(util.tools.formatDate(new Date())) - minDate.getTime()) / (24 * 60 * 60 * 1000))
      const maxDate = new Date(minDate.getTime() + (maxDayLen * (24 * 60 * 60 * 1000)))

      setMinDate(util.tools.formatDate(minDate))
      setMaxDate(util.tools.formatDate(maxDate))

      console.log('getCalendarValidRange', customMinDate, util.tools.formatDate(minDate), util.tools.formatDate(maxDate))
    }
  }

  const [isSpinning, setIsSpinning] = useState(false)
  // 创建广告执行单
  const onCreate = async() => {
    setIsSpinning(true)
    let params = { ...submitForm }
    if (params.planAimExt !== 0) {
      delete params.planAimExt
    }
    const { data, code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/createOrderPlan`,
      data: params,
    })

    setIsSpinning(false)
    if (code === 1) {
      message.success('操作成功！')
      history.push(`/ad-exec/media-detail/${ data.mallOrderPlanId }`)
    } else {
      message.error(msg || '操作失败！')
    }
  }

  // 更新
  const onUpdate = async() => {
  	if (isSpinning) {
      return
    }

    checkForm()
    if (!isFormChecked) {
      return
    }

    let form = { ...submitForm }
    if (submitForm.planAim !== 'EXCLUSIVE_MEDIA') {
      submitForm.customerId = null
    }

    // 创建订单时 deliveryModel不传了 目前都是SPLIT
    delete submitForm.deliveryModel

    setIsSpinning(true)

    const { data, code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
	      mallOrderPlanId,
	      ...submitForm,
	    },
    })

    if (submitForm.operatorType !== 'CINEMA') {
      delete submitForm.deliveryCinemaModel
    }

    setIsSpinning(false)

    if (code === 1) {
      reloadFn && reloadFn()
    } else {
    	message.error(msg || `操作失败`)
    }
  }

  return (
  	<Spin spinning={ isSpinning }>
    <div className={ `${ styles['ad-form'] }` }>
    	{
    		form.planAim === 'EXCLUSIVE_MEDIA' && (
    			<div className={ `${ styles['ad-form-item'] }` }>
		        <div className={ `${ styles['ad-form-label'] }` }>
		          <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
		          订单渠道：
		        </div>
		        <div className={ `${ styles['ad-form-value'] }` }>
		        	<Select 
		            className={ styles['ad-form-select'] }
		            placeholder="请选择"
		            size='large'
		            value={ customerId }
		            options={ cinemaCustomerItems }
		            onChange={ handleCustomerChange }>
		          </Select>
		        </div>
		      </div>
    		)
    	}

      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
          投放周期：
        </div>
        <div className={ `${ styles['ad-form-value'] }` }>
          <RangePicker
            size="large"
            format={ dateFormat }
            style={{ width: 365 }}
            value={ selectedDate }
            disabledDate={ disabledDate }
            onChange={ onDateChange }
          />
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
          广告时长：
        </div>
        <div className={ `${ styles['ad-form-value'] } ${ styles['flex'] }` }>
          {
            isLongDuration && (
              <Select 
		            className={ styles['ad-form-select'] }
		            placeholder="请选择"
		            size='large'
		            value={ submitForm.deliveryContentDuration }
		            options={ durationList }
		            onChange={ onToggleDuration }>
		          </Select>
            )
          }
          {
            !isLongDuration && durationList.map((duration, index) => {
              return (
                <div 
                  className={ `${ styles['duration-item'] } ${ submitForm.deliveryContentDuration === duration ? styles['is-selected'] : '' }` } 
                  key={ index }
                  onClick={ () => onToggleDuration(duration) }
                  >
                  { duration }秒
                </div>
              )
            })
          }
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] } ${ styles['baseline'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
          播放时段：
        </div>
        {
          form.planAim === 'EXCLUSIVE_MEDIA' && (
            <div className={ `${ styles['ad-form-value'] }` }>影片映前10分钟内</div>
          )
        }
        {
          form.planAim !== 'EXCLUSIVE_MEDIA' && (
            <div className={ `${ styles['ad-form-value'] } ${ styles['col'] }` }>
              <div className={ `${ styles['ad-form-value'] }` }>
              {
                orderList.map((item, index) => {
                  return (
                    <div 
                      className={ `${ styles['duration-item'] } ${ order === item.value ? styles['is-selected'] : '' }` } 
                      key={ index }
                      onClick={ () => onOrderChange(item.value) }
                      >
                      { item.label }
                    </div>
                  )
                })
              }
              </div>
              
              <div className={ `${ styles['periods'] }` }>
                <div className={ `${ styles['periods-label'] }` }>正片<br/>放映</div>
                <div className={ `${ styles['periods-list-wrap'] }` }>
                  <div className={ `${ styles['periods-list-label'] }` }>银幕广告时间</div>
                  <div className={ `${ styles['periods-list'] }` }>
                  {
                    periodArr.map((item, index) => {
                      return (
                        <div className={ `${ styles['periods-item'] }` } key={ index }>
                          <div className={ `${ styles['periods-item-label'] } ${ periods.includes(index + 1) ? styles['is-selected'] : '' }` }></div>
                          <div className={ `${ styles['periods-item-value'] }` }>{ index + 1 }</div>
                        </div>
                      )
                    })
                  }
                  </div>
                </div>
                <div className={ `${ styles['periods-label'] }` }>检票<br/>时间</div>
              </div>
            </div>
          )
        }
        
      </div>
      {
    		(form.planAim === 'SELF' && form.planAimExt === 0) && (
          <div className={ `${ styles['ad-form-item'] }` }>
            <div className={ `${ styles['ad-form-label'] }` }>
              <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
              内容类型：
            </div>
            <div className={ `${ styles['ad-form-value'] }` }>
              <Select 
		            className={ styles['ad-form-select'] }
		            placeholder="请选择"
		            size='large'
		            value={ submitForm.deliveryIndustry }
		            options={ adTypeItems }
		            onChange={ handleAdTypeChange }>
		          </Select>
            </div>
          </div>
        )
      }
      {
    		(form.planAim !== 'SELF' || form.planAimExt !== 0) && (
          <div className={ `${ styles['ad-form-item'] }` }>
            <div className={ `${ styles['ad-form-label'] }` }>
              <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
              广告所属行业：
            </div>
            <div className={ `${ styles['ad-form-value'] }` }>
              <Cascader
                options={ industryOptions }               
                onChange={ onIndustryChange }
                value={ selectedIndustry }
                changeOnSelect
                size="large"
                style={{ width: 365 }}
              />
            </div>
          </div>
        )
      }
      
      <div className={ `${ styles['ad-form-item'] } ${ styles['flex-end'] }` }>
      	{
      		!mallOrderPlanId && <button className={ `${ styles['btn-create'] }` } disabled={ !isFormChecked } onClick={ onCreate }>创建广告执行单</button>
      	}
      	{
      		mallOrderPlanId && <button className={ `${ styles['btn-create'] }` } disabled={ !isFormChecked } onClick={ onUpdate }>完成</button>
      	}
      </div>
    </div>
    </Spin>
  )
}