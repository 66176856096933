import React, { useState, useContext, useEffect } from 'react'
import styles from './List.module.css'

import { Table } from 'antd'
import { cinemaIssueContext, setCinemaIssueContext } from '../CinemaIssue'
import util from '../../../../libs/util'
import { DessertsType, columns1, columns2, defaultDesserts, deviceOnlineText } from './cells'

export const List: React.FC = () => {
  const value = useContext(cinemaIssueContext)
  const setValue = useContext(setCinemaIssueContext)
  const [desserts, setDesserts] = useState<DessertsType[]>(defaultDesserts)
  const [columns, setColumns] = useState(columns1)
  useEffect(() => {
    if(value.orgEntityInfoId) {
      getCinemaPointList()
    }
  }, [value.orgEntityInfoId])

  useEffect(() => {
    if(!value.visibleIssueDialog) {
      getCinemaPointList()
    }
  }, [value.visibleIssueDialog])

  useEffect(() => {
    if(value.currStep === 3) {
      setColumns(columns2)
    }
  }, [value.currStep])

  const getCinemaPointList = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/searchCinemaPointList',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      const list: DessertsType[] = []

      result.data.map((item, index) => {
        list.push({
          key: `list_item_${ index }`,
          hallName: item.hallName,
          deviceOnline: deviceOnlineText(item.deviceOnline),
          deliveryTaskCount: item.deliveryTaskCount,
          succeed: item.execSuccessCount,
          failed: item.deliveryTaskCount - item.execSuccessCount,
          situation: `${ item.reportCount }/${ item.deliveryTaskCount }`
        })
      })

      if(setValue) {
        setValue(state => {
          return {
            ...state,
            hasList: !!result.data.length ? true : false
          }
        })
      }

      setDesserts(list)
    }
  }

  return (
    <div className={ `${ styles.list } mt8` }>
      {
        (desserts.length > 0) && (
        <Table
          columns={ columns }
          dataSource={ desserts }
          pagination={ false }
        ></Table>
        )
      }
      {
        (desserts.length <= 0) && (
          <div className={ styles['no-record'] }>
            <div className={ styles['bg-no-record'] }></div>
            <div>无影院银幕广告上刊任务</div>
          </div>
        )
      }
    </div>
  )
}