import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Modal, Button, Form, Input, Select, Radio, Upload, TimePicker, message, DatePicker, Cascader, Switch } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import styles from './Detail.module.css'
import { directStoreEnabledItems } from '../list/listInner'
import util from '../../../libs/util'
import { Crumb, SelectWorkTime, PreviewImg } from '../../../components'
import { uploadMedia } from '../../../libs/util.upload'
import { Industry } from './industry'

const { TextArea } = Input
const { RangePicker } = TimePicker

interface Props {
  match: {
    params: {
      id?: String,
    },
  },
}

export const EntityDetail: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
	const history = useHistory()
  const { id } = props.match.params

  const crumbList = [
    {
      name: '媒体资源',
      path: '/entity/list',
    },
    {
      name: '影院',
      path: '/entity/list',
    },
    {
      name: '编辑影院信息',
      path: '',
    }
  ]

  useEffect(() => { 
    init()
  }, [])

  const init = async() => {
    findEnumItemList()
    await getEntityDraftHisList()
    initIndustryList()
  }

  // 院线
  const [cinemaChainIdItems, setCinemaChainIdItems] = useState<any>([])
  // 位置类型
  const [locationTypeItems, setLocationTypeItems] = useState<any>([])
  // 影院TMS系统
  const [cinemaPlayerTypeItems, setCinemaPlayerTypeItems] = useState<any>([])
  const findEnumItemList = async() => {
  	const key = ['LOCATION_TYPE', 'CINEMA_PLAYER_TYPE', 'CINEMA_CHAIN', 'INDUSTRY']
    const params = {
      groupKeyArray: key.join(','),
      excludeItems: ['AGENT', 'PUBLIC-AD-TYPE'].join(','),
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })

    setLocationTypeItems(data['LOCATION_TYPE'])
    setCinemaPlayerTypeItems(data['CINEMA_PLAYER_TYPE'])
    setCinemaChainIdItems(data['CINEMA_CHAIN'])
  }

  // 广告所属行业
  const [currentIndustry, setCurrentIndustry] = useState('INDUSTRY')
  const [industryOptions, setIndustryOptions] = useState<any>([])
  const [selectedIndustry, setSelectedIndustry] = useState<any>([])
  const loadData = async(selectedOptions) => {
    console.log('selectedOptions', selectedOptions, industryOptions)
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true
    const key = selectedOptions[0].itemKey
    setCurrentIndustry(key)
    const { code, data } = await getIndustryList(key)
    targetOption.loading = false
    let list = data[key]
    if (code === 1) {
      list.forEach(i => {
        i.value = i.itemKey
        i.label = i.itemValue
      })
      targetOption.children = list
      setIndustryOptions([...industryOptions])
    }
  }
  const getIndustryList = async(cIndustry) => {
    const params = {
      groupKeyArray: cIndustry,
      excludeItems: cIndustry === 'INDUSTRY' ? ['AGENT', 'PUBLIC-AD-TYPE'].join(',') : null, 
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    return { data, code }
  }
  const initIndustryList = async() => {
    const { code, data } = await getIndustryList(currentIndustry)
    let list = data[currentIndustry]
    if (code === 1) {
      list.forEach(i => {
        i.value = i.itemKey
        i.label = i.itemValue
        i.isLeaf = false
      })
      setIndustryOptions([...list])
    }
  }

  const imgTypeNames = ['entityViewPath', 'entityViewPathOne', 'entityViewPathTwo']
  const [proImages, setProImages] = useState([
    {
      type: 'entityViewPath',
      imgUrl: '',
      uploadUrl: '',
    },
    {
      type: 'entityViewPathOne',
      imgUrl: '',
      uploadUrl: '',
    },
    {
      type: 'entityViewPathTwo',
      imgUrl: '',
      uploadUrl: '',
    }
  ])
  const [curFileType, setCurFileType] = useState('')
  const [canEdit, setCanEdit] = useState(false)
  const [resourceEntityDraftId, setResourceEntityDraftId] = useState()
  const getEntityDraftHisList = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/resource/entity_draft_his/getEntityDraftHisList`,
      params: {
        resourceEntityInfoIds: id,
      },
    })

    if (code === 1) {
      if (data && data.length) {
        const detail = data[0]
        // 若获取的数据为null或审核通过的则通过”获取场所信息“接口回显详情页（可提交申请）
        if (detail.approveStatus === 'YES') {
          setCanEdit(true)
          getEntityInfoById()
        } else if (detail.approveStatus === 'WAIT' || detail.approveStatus === 'NO') {
        // 若获取的数据为”审核中“或”不通过“的则直接回显（审核中的不能再提交，审核不通过的可以提交申请）
          setDetail(detail)
          setCanEdit(detail.approveStatus === 'NO')
          // getEntityDraftHisList 中的图片不带域名地址(好像现在是带的？)
          initForm(detail)
        }
        setResourceEntityDraftId(detail.resourceEntityDraftId || '')
      } else {
        // 若获取的数据为null或审核通过的则通过”获取场所信息“接口回显详情页（可提交申请）
        setCanEdit(true)
        getEntityInfoById()
      }
    }
  }
  const [detail, setDetail] = useState<any>()
  const workTimeRef: any = useRef()
  const initForm = (data) => {
    const defaultArr = ['openDate', 'locationType', 'workStart', 'workEnd', 'cinemaPlayerType', 'description', 'directStoreEnabled']
    // 按产品原型部分字段要直接显示为空
    const needDefaultValue = data.initSource === 'SYSTEM'
    let tempStart = needDefaultValue ? '' : data.workStart
    let tempEnd = needDefaultValue ? '' : data.workEnd
    detailForm.setFieldsValue({
      entityName: data.entityName,
      cinemaPropertyNumber: data.cinemaPropertyNumber,
      directStoreEnabled: needDefaultValue ? '' : data.directStoreEnabled?.toString(),
      cinemaChainId: data.cinemaChainId,
      openDate: needDefaultValue ? null : (data.openDate ? moment(data.openDate) : null),
      address: `${ data.provinceName }-${ data.cityName }-${ data.districtName }`,
      addressMore: data.addressMore,
      locationType: needDefaultValue ? '' : data.locationType,
      // worktime: needDefaultValue ? [] : [moment(data.workStart, worktimeFormat), moment(data.workEnd, worktimeFormat)],
      workStart: tempStart,
      workEnd: tempEnd,
      cinemaPlayerType: needDefaultValue ? '' : data.cinemaPlayerType,
      description: needDefaultValue ? '' : data.description,
      // antiIndustry: data.antiIndustry
    })
    workTimeRef.current.setValues(tempStart, tempEnd)
    const notNeedSplit = data.approveStatus === 'WAIT' || data.approveStatus === 'NO'
    // 去掉前缀
    const deletePreUrl = (value) => {
      let arr
      arr = value.split('//')[1].split('/')
      arr.shift()
      arr = `/${ arr.join('/') }`
      return arr
    }
    let imgList = [...proImages]
    imgList.forEach(item => {
      item.uploadUrl = !needDefaultValue ? (notNeedSplit ? data[item.type] : deletePreUrl(data[item.type])) : ''
      item.imgUrl = !needDefaultValue ? (notNeedSplit ? `${ baseURL }${ data[item.type] }` : data[item.type]) : ''
    })
    setProImages(imgList)

    let antiList:any = []
    data?.antiIndustryList.forEach(perant => {
      let item:any = {}
      item = {
        itemKey: perant.groupKey,
        itemValue: perant.groupValue,
        children: []
      }
      for (let i in perant.itemMap) {
        item.children.push({
          itemKey: i,
          itemValue: perant.itemMap[i],
        })
      }
      antiList.push(item)
    })
    setAntiIndustryArr(antiList)
    setIsAntiChecked(!!data?.antiIndustryList.length)
  }
  const [needDefaultValue, setNeedDefaultValue] = useState(false)
  const getEntityInfoById = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/resource/getEntityInfoById`,
      params: {
        resourceEntityInfoId: id,
      },
    })

    if (code === 1) {
      // 按产品原型部分字段要直接显示为空
      // setNeedDefaultValue(data.initSource === 'SYSTEM')
      setDetail(data)
      initForm(data)
    }
  }

  const [detailForm] = Form.useForm()
  const inputStyle = {
    width: '480px',
    borderRadius: '4px', 
    borderColor: '#E6E6E6'
  }
  const [form, setForm] = useState({})
  const onValuesChange = (values, allValues) => {
    setForm(state => {
      return {
        ...state,
        ...allValues,
      }
    })
  }
  const openDateFormat = 'YYYY-MM-DD'
  const checkForm = (submitForm) => {
    let isChecked = true
    let errorTips = '请完善信息'
    let checkFormArr = [
      {
        key: 'entityName',
        type: 'string',
        errorTips: '请输入影院名称',
      },
      {
        key: 'cinemaPropertyNumber',
        type: 'number',
        errorTips: '请输入专资编码',
      },
      {
        key: 'cinemaChainId',
        type: 'string',
        errorTips: '请选择院线名称',
      },
      {
        key: 'directStoreEnabled',
        type: 'boolean',
        errorTips: '请选择是否为影管公司直营影院',
      },
      {
        key: 'openDate',
        type: 'string',
        errorTips: '请选择开业时间',
      },
      {
        key: 'locationType',
        type: 'string',
        errorTips: '请选择位置类型',
      },
      {
        key: 'workStart',
        type: 'string',
        errorTips: '请选择营业时间',
      },
      {
        key: 'cinemaPlayerType',
        type: 'string',
        errorTips: '请选择影院TMS系统',
      },
      {
        key: 'description',
        type: 'string',
        errorTips: '请填写影院简介',
      },
      {
        key: 'entityViewPath',
        type: 'string',
        errorTips: '请上传影院环境图1',
      },
      {
        key: 'entityViewPathOne',
        type: 'string',
        errorTips: '请上传影院环境图2',
      },
      {
        key: 'entityViewPathTwo',
        type: 'string',
        errorTips: '请上传影院环境图3',
      },
    ];

    for (let index = 0; index < checkFormArr.length; index++) {
      const item = checkFormArr[index];
      if (item.type === 'array' && submitForm[item.key].length < 1
        || (item.type === 'string' || item.type === 'number') && !submitForm[item.key] || (item.type === 'boolean' && (submitForm[item.key] === '' || submitForm[item.key] === null))) {
        errorTips = item.errorTips || errorTips;
        isChecked = false
        break;
      }
    }

    return {
      isChecked,
      errorTips,
    }
  }
  const onConfirm = async() => {
    let params = { ...detailForm.getFieldsValue() }
    params.directStoreEnabled = params.directStoreEnabled === '' ? '' : params.directStoreEnabled === 'true'
    params.openDate = params.openDate ? moment(params.openDate).format(openDateFormat) : ''
    params[proImages[0].type] = proImages[0].uploadUrl
    params[proImages[1].type] = proImages[1].uploadUrl
    params[proImages[2].type] = proImages[2].uploadUrl

    const checkFormData = checkForm(params)
    if (!checkFormData.isChecked) {
      message.warning(checkFormData.errorTips)
      return
    }

    const { code, data } = await util.request({
      method: 'POST',
      url: `/resource/entity_draft/applyEntityDraft`,
      data: {
        resourceEntityInfoId: detail.resourceEntityInfoId,
        resourceEntityDraftId: detail.resourceEntityDraftId,
        operatorEntityType: detail.operatorEntityType,
        entityType: detail.entityType,
        province: detail.province,
        city: detail.city,
        country: detail.country,
        district: detail.district,
        ...params,
      },
    })

    if (code === 1) {
      message.success('操作成功')
      setTimeout(() => {
        history.goBack()
      }, 1000)
    }
  }

  const uploadProps = {
    maxCount: 1,
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async(file, fileList) => {
      const { type } = file
      const isImgTypeChecked = type.includes('image/jpeg') || type.includes('image/jpg') || type.includes('image/png')
      // 文件类型
      if (!isImgTypeChecked) {
        Modal.warning({
          title: '仅上传正确的图片格式',
          content: ``,
        })
        return false
      }
      return true
    },
    customRequest: async(res) => {
      // 上传中
      const type = 'place'
      try {
        const result = await uploadMedia({
          file: res.file,
          type,
          callBack: (percent) => {
          }
        })
        const index = proImages.findIndex(i => i.type === curFileType)
        const imgUrl = result.location
        proImages[index].imgUrl = `${ baseURL }${ imgUrl }`
        proImages[index].uploadUrl = imgUrl
        setProImages(JSON.parse(JSON.stringify(proImages)))
      } catch(e:any) {
        message.error(e.message)
      }
    },
  }

  const delEnvironmentFile = (type) => {
    const index = proImages.findIndex(i => i.type === type)
    proImages[index].imgUrl = ''
    proImages[index].uploadUrl = ''
    setProImages(JSON.parse(JSON.stringify(proImages)))

    detailForm.setFieldsValue({
      [type]: '',
    })
  }

  const onWorktimeChange = (v, value) => {
    detailForm.setFieldsValue({
      worktime: v,
    })
  }

  const worktimeFormat = 'HH:mm'

  const setWorkTime = (value) => {
    detailForm.setFieldsValue({
      workStart: value.workStart,
      workEnd: value.workEnd,
    })
    console.log('setWorkTime', value)
  }

  const [isAntiChecked, setIsAntiChecked] = useState(false)
  const onSwitchChange = (value) => {
    setIsAntiChecked(value)
    if (!value) {
      detailForm.setFieldsValue({
        antiIndustry: [],
      })
    }
  }

  const [antiIndustryArr, setAntiIndustryArr] = useState<any>([])
  const onIndustryConfirm = (value) => {
    detailForm.setFieldsValue({
      antiIndustry: value,
    })
  }

  const previewImgRef: any = useRef()
  const previewImage = (url) => {
    previewImgRef.current.onToggle(url)
  }

  return (
  	<div className={ `${ styles['detail-page'] }` }>
	  	<Crumb list={ crumbList } />
      <div className={ `${ styles['detail-cont'] }` }>
        <div className={ `${ styles['detail-page-tit'] }` }>编辑影院信息</div>

        <Form 
          form={ detailForm }
          name="detailForm" 
          onValuesChange={ onValuesChange }
          onFinish={ onConfirm }
          autoComplete="off"
          size="large"
          layout='vertical'
          >
          <Form.Item
            label="影院名称"
            name="entityName"
            >
            <Input 
              disabled={ !canEdit }
              style={ inputStyle }
              placeholder="请输入" 
            />
          </Form.Item>
          <Form.Item
            label="专资编码"
            name="cinemaPropertyNumber"
            >
            <Input 
              disabled
              style={ inputStyle }
              placeholder="请输入" 
            />
          </Form.Item>
          <Form.Item 
            label="是否为影管公司直营影院"
            name="directStoreEnabled"
            >
            <Radio.Group disabled={ !canEdit }>
              {
                directStoreEnabledItems.map((record, index) => {
                  return (
                    <Radio key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Radio>
                  )
                })
              }
            </Radio.Group>
          </Form.Item>
          <Form.Item 
            label="院线"
            name="cinemaChainId"
            >
            <Select
              disabled={ !canEdit }
              style={ inputStyle }
              allowClear
              >
              {
                cinemaChainIdItems.map((record, index) => {
                  return (
                    <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item 
            label="开业时间"
            name="openDate"
            >
            <DatePicker 
              disabled={ !canEdit }
              allowClear={ false } 
              locale={ locale } /> 
          </Form.Item>
          <Form.Item 
            label="省/市/区"
            name="address"
            >
            <Input 
              disabled
              style={ inputStyle }
              placeholder="请输入" 
            />
          </Form.Item>
          <Form.Item 
            label="详细位置"
            name="addressMore"
            >
            <Input 
              disabled
              style={ inputStyle }
              placeholder="请输入" 
            />
          </Form.Item>
          <Form.Item 
            label="位置类型"
            name="locationType"
            >
            <Select
              disabled={ !canEdit }
              style={ inputStyle }
              allowClear
              >
              {
                locationTypeItems.map((record, index) => {
                  return (
                    <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item 
            label="营业时间"
            name="workStart"
            >
            <SelectWorkTime 
              childRef={ workTimeRef }
              disabled={ !canEdit }
              getValues={ setWorkTime } 
                />
            {/* <RangePicker 
              disabled={ !canEdit }
              bordered={ true } 
              minuteStep={ 30 }
              onChange={ onWorktimeChange }
              locale={ locale }
              format={ worktimeFormat } /> */}
          </Form.Item>
          <Form.Item 
            hidden
            label=""
            name="workEnd"
            ></Form.Item>
          <Form.Item 
            label="影院TMS系统"
            name="cinemaPlayerType"
            >
            <Select
              disabled={ !canEdit }
              style={ inputStyle }
              allowClear
              >
              {
                cinemaPlayerTypeItems.map((record, index) => {
                  return (
                    <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item 
            label="影院简介"
            name="description"
            >
            <TextArea 
              rows={ 4 }
              disabled={ !canEdit }
              style={ inputStyle }
              placeholder="请输入" 
            />
          </Form.Item>
          <Form.Item>
            <div className={ styles['form-item-tit'] }>影院环境图</div>
            <div className={ styles['form-item-tips'] }>1. 为了展示效果，请尽可能上传横版的图片</div>
            <div className={ styles['form-item-tips'] }>2. 可拍摄影院所在商业体的整体外观图、影院门面图、影院大堂图、影院过道走廊等</div>
            <div className={ styles['img-list'] }>
              {
                proImages.map((item, index) => {
                  return (
                    <div 
                      className={ `${ styles['img-item'] } ${ styles['img-item-bg'] }`} 
                      key = { index }
                      style={{
                        background: `${ item.imgUrl ? '#000' : '' }`,
                      }}
                      >
                      {
                        (item.imgUrl) && (
                          <>
                            {
                              canEdit && (<div className={ styles['btn-del'] } onClick={ () => { delEnvironmentFile(item.type) } }><div className={ `${ styles['icon-del'] } imediafont i-delete` }></div></div>)
                            }
                            <img src={ item.imgUrl } onClick={ () => { previewImage(item.imgUrl) } } />
                          </>
                        )
                      }
                      {
                        (!item.imgUrl && canEdit) && (
                          <div className={ `${ styles['img-item'] } ${ styles['btn-upload'] }` }>
                            <Upload { ...uploadProps }>
                              <div className={ `${ styles['btn-add'] }` } onClick={ () => { setCurFileType(item.type) } }><div className={ `${ styles['icon-add'] } imediafont i-plus` }></div></div>
                            </Upload>
                          </div>
                        )
                      }
                    </div>
                  )
                })
              }
            </div>
          </Form.Item>
          <Switch 
            className={ styles['form-switch'] } 
            checked={ isAntiChecked } 
            onChange={ onSwitchChange } 
            disabled={ !canEdit }
            unCheckedChildren="不限" />
          <Form.Item 
            label="禁投行业"
            name="antiIndustry"
            >
            { isAntiChecked && <Industry disabled={ !canEdit } onIndustryConfirm={ onIndustryConfirm } selectedArr={ antiIndustryArr } /> }
          </Form.Item>
          {
            canEdit && (
              <Form.Item>
                <Button 
                  style={{
                    marginRight: '20px',
                    backgroundColor: '#E2A45A',
                    borderColor: '#E2A45A',
                    borderRadius: '4px',
                  }}
                  type="primary" 
                  htmlType="submit">
                  确认并提交
                </Button>
              </Form.Item>
            )
          }
          {
            !canEdit && (
              <div className={ `${ styles['page-tips'] }` }><span className={ `${ styles['icon-tips'] } ifont i-tips` }></span>修改信息<span className={ `${ styles['important'] }` }>正在审核中</span>，审核通过后立即生效</div>
            )
          }
          
        </Form>
      </div>

      <PreviewImg childRef={ previewImgRef } />
	  </div>
  )
}