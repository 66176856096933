import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { Modal, Button, Form, Input, Select, DatePicker, Popover, Tooltip, Slider, message, Spin } from 'antd'
import { FireFilled, ExclamationCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import styles from './StockList.module.css'
import { clearSkuPeriods, getOperStatus, getPointSkuList, getPointSkuPeriodList, findEnumItemList, getPointSpu } from '../../../../api'
import util from '../../../../libs/util'
import { RootState } from '../../../../store'
import { appContext } from '../../../../App.state'

interface PropsType {
  id: string,
}

interface ItemType {
  pointDate: string,
  soldDuration: number | string,
  saleDuration: number | string,
  periodList: Array<any>,
}

export const StockList: React.FC<PropsType> = ({ id }) => {
  const appValue = useContext(appContext)

	const history = useHistory()
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const now = new Date()
  let month = now.getMonth() + 1
  const monthStr = month >= 10 ? month :  `0${ month }`
  const [selectedDate, setSelectedDate] = useState(`${ now.getFullYear() }-${ monthStr }`)
  const monthFormat = 'YYYY-MM'

  const times = [1,1,1,1,1,1,1,1,1,1]
  const step = [1,2,3,4]

  const minuteLen = 10
  const stepLen = 4
  const [list, setList] = useState<ItemType[]>([])

  const dataRef = useRef<ItemType[]>([])
  useEffect(() => {
    dataRef.current = list
  }, [list])

  useEffect(() => {
    id && init()
  }, [selectedDate])

  const [optId, setOptId] = useState('')
  useEffect(() => {
    if (!optId) {
      return
    }
    const timer = setInterval(() => {
      if (!optId) {
        clearInterval(timer)
        return
      }
      checkId(optId)
     }, 1000)

    return () => clearInterval(timer)
  }, [optId])

  const init = async() => {
    setSelectedRowKeys([id])
    getPointDetail()
    await getList()
    await getPeriodList()
    getManageList()
  }

  const soleModalRef: any = useRef()
  const showSetModal = () => {
    soleModalRef.current.showSetModal()
  }

  const onDateChange = (e, value) => {
  	setSelectedDate(value)
  }

  const getList = async() => {
  	const sDate = new Date(selectedDate)
  	sDate.setMonth(sDate.getMonth() + 1)
  	const endDate = new Date(sDate.getTime() - 24 * 60 * 60 * 1000)
  	endDate.getDate()
  	const result = await getPointSkuList({
  		resourcePointId: id,
  		pointDateStart: `${ selectedDate }-01`,
  		pointDateEnd: `${ selectedDate }-${ endDate.getDate() }`,
  	})

    if (result.code === 1) {
      setList(result.data || [])
    }
  }

  const getPeriodList = async() => {
    const sDate = new Date(selectedDate)
    sDate.setMonth(sDate.getMonth() + 1)
    const endDate = new Date(sDate.getTime() - 24 * 60 * 60 * 1000)
    endDate.getDate()
    const result = await getPointSkuPeriodList({
      resourcePointId: id,
      pointDateStart: `${ selectedDate }-01`,
      pointDateEnd: `${ selectedDate }-${ endDate.getDate() }`,
    })

    if (result.code === 1) {
      const periodList = result.data
      let copiedArray = Array.from(dataRef.current)
      copiedArray.forEach((item, index) => {
        item.periodList = periodList.filter(i => i.pointDate === item.pointDate).sort((a, b) => a.timePeriod - b.timePeriod)
        times.forEach((i, timesIndex) => {

        })
      })
      setList([...copiedArray])
    }
  }

  const [pointDetail, setPointDetail] = useState({
    pointName: '',
    entityName: '',
    ownUcOrgId: '',
  })
  const getPointDetail = async() => {
    const result = await getPointSpu({
      resourcePointId: id,
      saleModel: 'SPLIT',
    })

    if (result.code === 1) {
      setPointDetail(result.data)
    }
  }

  const getSoldPercent = (record) => {
    return (record.soldDuration / record.saleDuration * 100).toFixed(2)
  }

  // 是否已售 第几分钟的第几个15秒
  const getSoled = (periodList, periodIndex, stepIndex) => {
    return periodList && periodList.find(i => i.timePeriod === periodIndex && Math.round(i.soldDuration / 15) - 1 >= stepIndex)
  }
  
  // 是否独家 第几分钟的第几个15秒
  const getClsName = (periodList, periodIndex) => {
    if (!periodList) {
      return 'is-self'
    }
    if (periodList.find(i => i.timePeriod === periodIndex && i.customerId === 'REMU')) {
      return 'is-remo'
    } else if (periodList.find(i => i.timePeriod === periodIndex && i.customerId === 'SELF')) {
      return 'is-self'
    } else if (periodList.find(i => i.timePeriod === periodIndex && i.customerId)) {
      return 'is-only'
    }
  }

  const [customerList, setCustmerList] = useState<any[]>([])
  useEffect(() => {
    getCustomerList()
  }, [])
  const getCustomerList = async() => {
    const groupKey = 'CINEMA_CUSTOMER'
    const result = await findEnumItemList({
      groupKeyArray: groupKey,
    })

    if (result.code === 1) {
      setCustmerList([...result.data[groupKey], {
        itemKey: 'SELF',
        itemValue: '影院',
      }])
    }
  }
  const getTypeName = (periodList, periodIndex) => {
    if (!customerList.length || !periodList) {
      return
    }
    const thisObj = periodList ? periodList.find(i => i.timePeriod === periodIndex) : null
    const customerId = thisObj ? thisObj.customerId : null
    const isOnly = thisObj && customerId

    if (customerId === 'SELF') {
      return '影院'
    } else if (customerId === 'REMU') {
      return '热幕'
    }
    return isOnly && customerList.find(i => i.itemKey === customerId).itemValue
  }

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 清空独家库存设置
  const { confirm } = Modal
  const showDelConfirm = () => {
    confirm({
      title: '清空后将取消所有独家库存的设置，恢复所有库存为共享库存',
      content: '是否确定继续操作？',
      icon: <ExclamationCircleFilled />,
      onOk: async() => {
        const result = await clearSkuPeriods({
          resourcePointIds: selectedRowKeys,
        })

        if (result.code === 1) {
          setIsSubmiting(true)
          // 查状态
          setOptId(result.data)
        } else {
          setIsSubmiting(false)
          message.error(result.message || '操作失败，请重试~')
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const resetData = async() => {
    getPointDetail()
    await getList()
    await getPeriodList()
  }

  // 查状态是否操作成功
  const checkId = async(optId) => {
    setIsSubmiting(true)
    const result:any = await getOperStatus(optId)

    if (result.code === 1) {
      setOptId('')
      resetData()
      message.success('操作成功')
    }
    setIsSubmiting(false)
  }

  const getDateLabel = (date) => {
    const now = new Date()
    const today = +new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const pointDate = +new Date(date.split('-').join('/'))
    let result = 'before'
    if (pointDate > today) {
      result = 'future'
    } else if (pointDate === today) {
      result = 'today'
    }
    return result
  }

  // 停售信息
  const [stopList, setStopList] = useState<any[]>([])
  const getManageList = async() => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `/mall/sku_manage/getSkuManageListByPointId`,
      params: {
        resourcePointId: id,
      },
    })

    if (code === 1) {
      let manageList = data
      let result:any = []
      for (let i = 0, len = manageList.length; i < len; i++) {
        const item = manageList[i]
        const startDate = +new Date(item.pointDateStart)
        const endDate = +new Date(item.pointDateEnd)
        for (let j = startDate; j <= endDate; j += 24 * 60 * 60 * 1000) {
          result.push({
            pointDate: util.tools.formatDate(new Date(j)),
            ...item,
          })
        }
      }
      setStopList(result)
    }
  }

  const isStopDay = (pointDate) => {
    return stopList.find(i => i.pointDate === pointDate)
  }

  return (
  	<div className={ `${ styles['stoke-page'] }` }>
	  	<div className={ `${ styles['stoke-page-cont'] }` }>
        <div className={ `${ styles['mpoint-top'] } ${ styles['flex'] }` }>
        	<div className={ `${ styles['stoke-top-l'] } ${ styles['flex'] }` }>
	        	<DatePicker allowClear={ false } locale={ locale } onChange={ onDateChange } defaultValue={ moment(selectedDate, monthFormat) } format={ monthFormat } picker="month" />
	          {/* <div className={ `${ styles['mpoint-top-l'] } ${ styles['flex'] }` }>
	            <Button className={ `${ styles['point-opts-btn'] } }` } type="primary" onClick={ showSetModal }>独家库存设置</Button>
	            <Button className={ `${ styles['point-opts-btn'] } ${ styles['default'] }` } onClick={ showDelConfirm }>清空独家库存</Button>
	          </div> */}
          </div>
          <div className={ `${ styles['mpoint-top-r'] } ${ styles['flex'] }` }>
            <div className={ `${ styles['mpoint-tips'] } ${ styles['flex'] }` }>
              <div className={ styles['mpoint-tips-label'] }>库存类型图示：</div>
              <div className={ `${ styles['mpoint-tips-value'] } ${ styles['flex'] }` }>
                <div className={ `${ styles['mpoint-tips-value-item'] } ${ styles['flex'] }` }>
                  <span className={ `${ styles['mpoint-tips-icon'] } ${ styles['is-self'] }` }></span>
                  影院
                  {/* <Tooltip title="各渠道共同售卖的库存">
                    <QuestionCircleOutlined className={ styles['mpoint-tips-icon-2'] } />
                  </Tooltip> */}
                </div>
                <div className={ `${ styles['mpoint-tips-value-item'] } ${ styles['flex'] }` }>
                  <span className={ `${ styles['mpoint-tips-icon'] } ${ styles['is-remo'] }` }></span>
                  热幕
                  {/* <Tooltip title="各渠道共同售卖的库存">
                    <QuestionCircleOutlined className={ styles['mpoint-tips-icon-2'] } />
                  </Tooltip> */}
                </div>
                <div className={ `${ styles['mpoint-tips-value-item'] } ${ styles['flex'] }` }>
                  <span className={ `${ styles['mpoint-tips-icon'] } ${ styles['is-only'] }` }></span>
                  独家
                  <Tooltip title="代理商独家代理的库存">
                    <QuestionCircleOutlined className={ styles['mpoint-tips-icon-2'] } />
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className={ `${ styles['mpoint-tips'] } ${ styles['flex'] }` }>
              <div className={ styles['mpoint-tips-label'] }>点位库存售卖情况图示：</div>
              <div className={ `${ styles['mpoint-tips-value'] } ${ styles['flex'] }` }>
                <div className={ `${ styles['mpoint-tips-value-item'] } ${ styles['flex'] }` }>
                  <span className={ `${ styles['mpoint-tips-icon'] } ${ styles['is-in'] }` }></span>
                  未出售
                </div>
                <div className={ `${ styles['mpoint-tips-value-item'] } ${ styles['flex'] }` }>
                  <span className={ `${ styles['mpoint-tips-icon'] } ${ styles['is-soled'] }` }></span>
                  已出售
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={ `${ styles['date-list'] } ${ !list.length ? styles['no-record'] : '' }` }>
          {
            list.map((item, index) => {
              return (
                <div className={ `${ styles['date-item'] }` } key={ item.pointDate }>
                  <div className={ `${ styles['date-item-label'] } ${ getDateLabel(item.pointDate) === 'today' ? styles['today'] : getDateLabel(item.pointDate) === 'before' ? styles['before'] : styles['future'] }` }>
                    { getDateLabel(item.pointDate) === 'today' && '今' }
                  </div>
                  <div className={ `${ styles['date-item-date'] } ${ getDateLabel(item.pointDate) === 'before' ? styles['before'] : '' }` }>{ item.pointDate }</div>
                  <div className={ `${ styles['date-item-result'] }` }>已出售 <span className={ `${ styles['sale-out-percent'] }` }>{ getSoldPercent(item) }%</span></div>
                  <div className={ `${ styles['date-item-data'] }` }>
                    <div className={ `${ styles['date-item-cont'] }` }>
                      <div className={ `${ styles['minute-list'] }` }>
                        {
                          times.map((period, periodIndex) => {
                            return (
                              <div className={ `${ styles['minute-item'] } ${ styles['type-step-item'] } ${ getClsName(item.periodList, periodIndex + 1) === 'is-remo' ? styles['is-remo'] : (getClsName(item.periodList, periodIndex + 1) === 'is-self' ? styles['is-self'] : styles['is-only'])}` } key={ periodIndex }>
                                <div className={ `${ styles['type-text'] }` }>{ getTypeName(item.periodList, periodIndex + 1) }</div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className={ `${ styles['minute-list'] }` }>
                        {
                          times.map((period, periodIndex) => {
                            return (
                              <div className={ `${ styles['minute-item'] }` } key={ periodIndex }>
                                {
                                  step.map((step, stepIndex) => {
                                    return (
                                      <div className={ `${ styles['step-item'] } ${ getSoled(item.periodList, periodIndex + 1, stepIndex) ? styles['is-soled'] : styles['is-in']}` } key={ stepIndex }></div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={ `${ styles['degree-list'] }` }>
                      <div className={ `${ styles['degree-list-start'] }` }>映前第</div>
                      <div className={ `${ styles['degree-list-end'] }` }>分钟</div>
                      {
                        times.map((record, index) => {
                          return (
                            <div className={ `${ styles['degree-item'] }` } key={ index }>{ index + 1 }</div>
                          )
                        })
                      }
                    </div>
                  </div>
                  
                  {
                    (isStopDay(item.pointDate)) && (<div className={ `${ styles['date-stop-label'] }` }>停售</div>)
                  }
                </div>
              )
            })
          }

          {
            !list.length && '暂无数据~'
          }
        	
        </div>

      </div>
      {/*独家库存设置*/}
      {/* <SoleModal childRef={ soleModalRef } selectedRowKeys={ selectedRowKeys } resetData={ resetData } /> */}
	  </div>
  )
}