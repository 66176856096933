import React, { useState, useContext, useEffect } from 'react'
import styles from './Elf.module.css'

import { cinemaIssueContext, setCinemaIssueContext } from '../CinemaIssue'
import { Button } from 'antd'
import { GuideDialog } from '../guideDialog'
import { IssueDialog } from '../issueDialog'
import { defaultBtnStyle, snowBtnStyle } from '../guideDialog/cells'

import util from '../../../../libs/util'

import step1 from '../../../../assets/images/media/step1.png'
import step2 from '../../../../assets/images/media/step2.png'
import step3 from '../../../../assets/images/media/step3.png'

interface Step {
  step: number,
  icon: string,
  name: string
}

const btnStyle = {
  ...defaultBtnStyle,
  ...snowBtnStyle
}

const defaultSteps: Step[] = [
  {
    step: 1,
    icon: step1,
    name: '热幕精灵服务器检测'
  },
  {
    step: 2,
    icon: step2,
    name: '接收广告素材'
  },
  {
    step: 3,
    icon: step3,
    name: '执行上刊并提交结果'
  }
]

export const Elf: React.FC = () => {
  const value = useContext(cinemaIssueContext)
  const setValue = useContext(setCinemaIssueContext)
  const [steps, setSteps] = useState<Step[]>(defaultSteps)
  let [currStep, setCurrStep] = useState<number>(1)
  const [cinemaStatus, setCinemaStatus] = useState<string>('ONLINE')
  const [deliverStatus, setDeliverStatus] = useState<number>(-1)
  const [reportStatus, setReportStatus] = useState<any>(-1)
  const [downArrowStyl, setDownArrowStyl] = useState<object>({})
  const [checking, setChecking] = useState<boolean>(false)
  let [countdown, setCountdown] = useState<number>(10)

  const [waterWidth, setWaterWidth] = useState<number>(0)

  useEffect(() => {
    if(currStep == 1) {
      setWaterWidth(0)
    }

    if(currStep == 2) {
      setWaterWidth(275)
    }

    if(currStep == 3) {
      setWaterWidth(555)
    }
  }, [currStep])

  useEffect(() => {
    util.bus.on('getCinemaExecState', () => {
      getCinemaExecState()
    })

    util.bus.on('handleCountDown', handleCountDown)
  }, [])

  useEffect(() => {
    if(value.orgEntityInfoId) {
      checkCinemaOnline()
    }
  }, [value.orgEntityInfoId])

  useEffect(() => {
    if(!value.visibleIssueDialog) {
      checkCinemaOnline()
    }
  }, [value.visibleIssueDialog])

  useEffect(() => {
    if(reportStatus !== -1) {
      setCurrStep(3)
      if(setValue) {
        setValue(state => ({
          ...state,
          currStep: 3
        }))
      }
      setDownArrowStyl({ left: '730px' })
    }

    if(deliverStatus > 0) {
      setCurrStep(2)
      if(setValue) {
        setValue(state => ({
          ...state,
          currStep: 2
        }))
      }
      setDownArrowStyl({ left: '455px' })
    }

    if(cinemaStatus === 'OFFLINE') {
      setCurrStep(1)
      if(setValue) {
        setValue(state => ({
          ...state,
          currStep: 1
        }))
      }
      setDownArrowStyl({ left: '155px' })
    }
  }, [cinemaStatus, deliverStatus, reportStatus])

  const checkCinemaOnline = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/checkCinemaOnline',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      setCinemaStatus(result.data)
      if(result.data === 'ONLINE') {
        getCinemaExecState()
      }
    }
  }

  const getCinemaExecState = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/getCinemaExecState',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      const deliverySuccess = result.data.DELIVER_SUCCESS
      setDeliverStatus(deliverySuccess)
      if(deliverySuccess === 0) {
        getCinemaReportState()
      }
    }
  }

  const getCinemaReportState = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/delivery/cinema/getCinemaReportState',
      data: {
        deliveryDate: value.deliveryDate,
        orgEntityInfoId: value.orgEntityInfoId
      }
    })

    if(result.code === 1) {
      setReportStatus(result.data)
      if(setValue) {
        setValue(value => ({
          ...value,
          reportStatus: result.data
        }))
      }
    }
  }

  const handleCountDown = () => {
    let countdown = 10
    setChecking(true)
    const timer = setInterval(() => {
      if(currStep === 1) checkCinemaOnline()
      if(currStep === 2) getCinemaExecState()
      if(countdown <= 0) {
        countdown = 10
        setChecking(false)
        clearInterval(timer)
      }
      setCountdown(countdown--)
    }, 1000)
  }

  const onFire = () => {
    util.bus.emit('showGuideDialog')
  }

  const onIssue = () => {
    util.bus.emit('showIssueDialog')
  }

  return (
    <div className={ `${ styles.elf } mt8` }>
      <div className={ styles.top }>
        <ul className={ `${ styles.steps } flex justify-between align-center` }>
          {
            steps.map((item, index) => {
              return (
                <li className="tc" key={ `steps_${ index }` }>
                  <i className={ `${ styles.icon } ${ currStep >= item.step ? styles.active : '' } mb4` }>
                    <img className="mt3" width="26" src={ item.icon } alt="" />
                  </i>
                  <p>
                    <i className={ `${ styles.circle } tc fb` }>{ item.step }</i>
                    <span className="ml1">{ item.name }</span>
                  </p>
                </li>
              )
            })
          }
          <span className={ styles.line }>
            <span className={ styles.water } style={{ width: `${ waterWidth }px` }}></span>
          </span>
        </ul>
      </div>
      <div className={ `${ styles.bottom } flex` }>
        {
          (reportStatus.REPORT_WAIT > 0 || reportStatus.DRAFT_SUCCESS > 0 || reportStatus.DRAFT_FAILURE > 0) 
          ?
          <div className={ styles['down-arrow'] } style={ downArrowStyl }></div>
          :
          ''
        }

        <div className={ `${ styles.status } flex align-center justify-between` }>
          <div className={ `${ styles.step1 } flex justify-center align-center` }>
            {  
              (currStep === 1) && (
                (checking) ? 
                <span className="mr2">服务器检测中（{ countdown }s）</span> 
                :
                <React.Fragment>
                  <span className="mr2">{ cinemaStatus === 'ONLINE' ? '正常' : '服务器未正常运行' }</span>
                  {
                    cinemaStatus === 'ONLINE' ? '' : <Button style={ btnStyle } onClick={ onFire }>启动热幕精灵</Button>
                  }
                </React.Fragment>
              )
            }
          </div>
          <div className={ `${ styles.step2 } flex justify-center align-center` }>
            {
              (currStep == 2) && (
                deliverStatus !== -1 ? (deliverStatus > 0 ? '下载中，请耐心等待...' : '下载完成') : ''
              )
            }
          </div>
          {
            (currStep == 3) && (
              reportStatus !== -1 ?
              (
                (reportStatus.REPORT_WAIT > 0 || reportStatus.DRAFT_SUCCESS > 0 || reportStatus.DRAFT_FAILURE > 0) ?
                (
                  <div className={ `${ styles.step3 } flex justify-center align-center` }>
                    <span className="mr2">待完成</span>
                    <Button style={ btnStyle } onClick={ onIssue }>执行上刊</Button>
                  </div>
                ) :
                (
                  <div className={ `${ styles.step3 } flex justify-center align-center` }>
                    <span className="mr2">完成</span>
                    <Button style={ btnStyle } onClick={ onIssue }>查看上刊结果</Button>
                  </div>
                )
              ) : ''
            )
          }
          
        </div>
      </div>

      <GuideDialog />

      <IssueDialog />
    </div>
  )
}