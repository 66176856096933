import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, Pagination, Table, message,Spin } from 'antd'
import { FireFilled, ExclamationCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store'
import { findEnumItemList, clearSkuPeriods, getOperStatus, getOrgEntityState } from '../../../../api'
import util from '../../../../libs/util'
import styles from './List.module.css'

import { filterContext, setFilterContext } from '../PointList'
import { cinemaPointStatus, normalPointStatus, pointStatusItems, mediaApproveStatusItems, approveStatusItems } from '../filterPanel/FilterPanel'
import { SoleModal } from '../soleModal/index'
import { appContext } from '../../../../App.state'

const { Column, ColumnGroup } = Table

// 
export const operatorTypeList = [
  {
    itemKey: 'CINEMA',
    itemValue: '银幕',
  },
  {
    itemKey: 'HALL_NAME',
    itemValue: '厅冠名',
  },
  {
    itemKey: 'LIGHTBOX',
    itemValue: '灯箱',
  },
  {
    itemKey: 'DIGITAL',
    itemValue: '数字屏',
  },
  // {
  //   itemKey: 'LCD',
  //   itemValue: 'LCD',
  // },
]
export const deviceOnlineItems = [
  {
    itemKey: 'ONLINE',
    itemValue: '在线',
    className: 'success',
  },
  {
    itemKey: 'OFFLINE',
    itemValue: '离线',
    className: 'error'
  },
]

interface Props {
  childRef: any,
}

export const List: React.FC<Props> = ({ childRef }) => {
  const appValue = useContext(appContext)
  useImperativeHandle(childRef, () => {
    return {
      getPointList,
    }
  })

  const soleModalRef: any = useRef()
  const showSetModal = () => {
    soleModalRef.current.showSetModal()
  }

  const [optId, setOptId] = useState('')
  useEffect(() => {
    if (!optId) {
      return
    }
    const timer = setInterval(() => {
      if (!optId) {
        clearInterval(timer)
        return
      }
      checkId(optId)
     }, 1000)

    return () => clearInterval(timer)
  }, [optId])

  const filter = useContext(filterContext)
  const setFilter = useContext(setFilterContext)

  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getPointList(pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 获取点位列表
  const getPointList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    let params = {
      city: filter.city,
      district: filter.district,
      operatorType: filter.operatorType,
      entityName: filter.entityName,
      pointNumber: filter.pointNumber,
      pointName: filter.pointName,
      releaseStatus: filter.releaseStatus,
      pointStatus: filter.pointStatus,
    }
    // delete params.searchType
    const result = await util.request({
      method: 'POST',
      url: `/mall/spu/searchPointSpuList?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: filter,
    })
    const data = result.data || []
    const total = result.total || 0
    setList(data)
    setTotal(total)
    setIsSubmiting(false)
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const [tabList, setTabList] = useState<any>(operatorTypeList)

  useEffect(() => {
    getPointList(1)
    setColumnFn()
  }, [filter.operatorType])

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 清空独家库存设置
  const { confirm } = Modal
  const showDelConfirm = () => {
    confirm({
      title: '清空后将取消所有独家库存的设置，恢复所有库存为共享库存',
      content: '是否确定继续操作？',
      icon: <ExclamationCircleFilled />,
      onOk: async() => {
        const result = await clearSkuPeriods({
          resourcePointIds: selectedRowKeys,
        })

        if (result.code === 1) {
          setIsSubmiting(true)
          // 查状态
          setOptId(result.data)
        } else {
          setIsSubmiting(false)
          message.error(result.message || '操作失败，请重试~')
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const history = useHistory()
  // 点位库存
  const showPointModal = (record) => {
    history.push(`/point/stock/${ record.resourcePointId }`)
    const url = `/point/stock/${ record.resourcePointId }`
    // 新开窗
    const win: any = window.open(url, '_blank')
    win.focus()
  }
  // 点位详情
  const goToDetail = (record) => {
    history.push(`/point/detail/${ record.resourcePointId }/false`)
  }

  const [showPointStatusItems, setShowPointStatusItems] = useState<any>(cinemaPointStatus)
  // 切换tab
  const toggleTab = (id) => {
    if (id === 'DIGITAL') {
      setFilter(state => {
        return {
          ...state,
          operatorTypes: ['LCD', 'LED'],
          operatorType: null,
        }
      })
      return
    }
    setFilter(state => {
      return {
        ...state,
        operatorTypes: [],
        operatorType: id,
      }
    })
  }

  const elemList = useSelector((state: RootState) => {
    return state.menu.elemList
  })
  useEffect(() => {
    setBtnAuth()
  }, [elemList])
  
  const [hasAuth, setHasAuth] = useState(false)
  const setBtnAuth = () => {
    const haskey = (key) => {
      return !!elemList.find(i => i.elementItemKey === key && i.authType === 'ENABLED')
    }
    // 审核
    setHasAuth(haskey('ShenHe_MEDIA_WEB_DianWei'))
  }
  
  useEffect(() => {
    setColumnFn()
  }, [hasAuth])
  const [columns, setColumns] = useState<any[]>([])
  const setColumnFn = () => {
    const indexItem = {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return index + 1
      },
    }
    const typeItem = {
      title: '数字屏类型',
      dataIndex: 'operatorType',
      render(text, record, index) {
        return text
      },
    }
    const pointNumberItem = {
      title: '点位编号',
      dataIndex: 'pointNumber',
    }
    const provinceNameItem = {
      title: '省',
      dataIndex: 'provinceName',
      render(text, record, index) {
        return record.entityInfo.provinceName
      },
    }
    const cityNameItem = {
      title: '市',
      dataIndex: 'cityName',
      render(text, record, index) {
        return record.entityInfo.cityName
      },
    }
    const entityNameItem = {
      title: '场所',
      dataIndex: 'entityName',
    }
    const pointNameItem = {
      title: '点位名称',
      dataIndex: 'pointName',
    }
    const lightboxLocationInfoItem = {
      title: '位置',
      dataIndex: 'lightboxLocationInfo',
    }
    const pointSrcItem = {
      title: '点位图片',
      dataIndex: 'pointSrc',
      render(text, record, index) {
        return (
          <span className={ `${ styles['point-image-wrap'] }  ${ styles[record?.operatorType] }`}>
            {
              (record.environmentFilePath || record.environmentFilePathOne || record.environmentFilePathTwo) && (<img src={ `${ appValue.rHost }${ record.environmentFilePath || record.environmentFilePathOne || record.environmentFilePathTwo }?imageMogr2/thumbnail/x41` } className={ styles['point-image'] } />)
            }
          </span>
        )
      },
    }
    const lightboxFrameItem = {
      title: '外框尺寸(cm)',
      dataIndex: 'lightboxFrame',
      render(text, record, index) {
        return (
          <>{ util.tools.splitNum2StringFixed(record.lightboxFrameWidth) } × { util.tools.splitNum2StringFixed(record.lightboxFrameHeight) }</>
        )
      },
    }
    const lightboxContentItem = {
      title: '有效尺寸(cm)',
      dataIndex: 'lightboxContent',
      render(text, record, index) {
        return (
          <>{ util.tools.splitNum2StringFixed(record.lightboxContentWidth) } × { util.tools.splitNum2StringFixed(record.lightboxContentHeight) }</>
        )
      },
    }
    const lightboxMaterialItem = {
      title: '上刊画面材质',
      dataIndex: 'lightboxMaterial',
      render(text, record, index) {
        const obj = text ? lightBoxMaterialItems.filter(i => text.includes(i.itemKey)).map(i => i.itemValue) : []
        return (
          <div className={ styles['flex'] }>
            {
              obj.length ? <>{ obj.join(',') }</> : ''
            }
          </div>
        )
      },
    }
    const hallNameLabelItem = {
      title: '厅号牌尺寸',
      dataIndex: 'hallNameLabelSize',
      render(text, record, index) {
        return record.hallNameLabelSize
      },
    }
    const hallNameContentItem = {
      title: '制作尺寸',
      dataIndex: 'hallNameContentSize',
      render(text, record, index) {
        return record.hallNameContentSize
      },
    }
    const seatNumberItem = {
      title: '座位数',
      dataIndex: filter.operatorType === 'CINEMA' ? 'seatNumber' : 'hallNameSeatNumber',
    }
    const cinemaScreenMaterialItem = {
      title: '银幕类型',
      dataIndex: 'cinemaScreenMaterial',
      render(text, record, index) {
        const obj = cinemaScreenMaterialItems.find(i => i.itemKey === text)
        return obj ? obj.itemValue : ''
      },
    }
    const lcdDeviceTypeItem = {
      title: '设备类型',
      dataIndex: 'lcdDeviceType',
      render(text, record, index) {
        const obj = lcdDeviceTypeItems.find(i => i.itemKey === record.lcdDeviceType || i.itemKey === record.ledDeviceType)
        return obj ? obj.itemValue : ''
      },
    }
    const lcdScreenTypeItem = {
      title: '显示方向',
      dataIndex: 'lcdScreenType',
      render(text, record, index) {
        const obj = lcdScreenTypeItems.find(i => i.itemKey === record.lcdScreenType || i.itemKey === record.ledScreenType)
        return obj ? obj.itemValue : ''
      },
    }
    const lcdScreenSizeItem = {
      title: '设备尺寸',
      dataIndex: 'lcdScreenSize',
      render(text, record, index) {
        return record.lcdScreenSize || record.ledScreenSize ? `${ record.lcdScreenSize || record.ledScreenSize }寸` : ''
      },
    }
    const releaseStatusItem = {
      title: '点位状态',
      dataIndex: 'releaseStatus',
      render(text, record, index) {
        const obj = showPointStatusItems.find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? <><span className={ `${ styles['icon-dot'] } ${ obj ? styles[obj.className] : '' }` }></span>{ obj.itemValue }</> : ''
            }
          </div>
        )
      },
    }
    const mediaApproveStatusItem = {
      title: filter.operatorType === 'CINEMA' ? '企业审核状态' : '审核状态',
      dataIndex: 'mediaApproveStatus',
      render(text, record, index) {
        const obj = mediaApproveStatusItems.find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? <><span className={ `${ styles['icon-dot'] } ${ obj ? styles[obj.className] : '' }` }></span>{ obj.itemValue }</> : ''
            }
          </div>
        )
      },
    }
    const approveStatusItem = {
      title: '平台审核状态',
      dataIndex: 'approveStatus',
      render(text, record, index) {
        const obj = approveStatusItems.find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? <><span className={ `${ styles['icon-dot'] } ${ obj ? styles[obj.className] : '' }` }></span>{ obj.itemValue }</> : ''
            }
          </div>
        )
      },
    }
    const pointStatusItem = {
      title: '是否已激活',
      dataIndex: 'pointStatus',
      render(text, record, index) {
        const obj = (pointStatusItems || []).find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? obj.itemDesc : ''
            }
          </div>
        )
      },
    }
    const deviceOnlineItem = {
      title: '设备状态',
      dataIndex: 'deviceOnline',
      render(text, record, index) {
        const obj = deviceOnlineItems.find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? <><span className={ `${ styles['icon-dot'] } ${ obj ? styles[obj.className] : '' }` }></span>{ obj.itemValue }</> : ''
            }
          </div>
        )
      },
    }
    const optionsItem = {
      title: '操作',
      dataIndex: 'options',
      render: (_, record) => (
        <>
          {
            ((record.mediaApproveStatus === 'WAIT') && hasAuth) && (<span className={ styles['btn-table-link'] } onClick={ () => { onAudit(record) } }>审核</span>)
          }
          {
            record.mediaApproveStatus !== 'WAIT' && (<span className={ styles['btn-table-link'] } onClick={ onEdit }>编辑</span>)
          }
          <span className={ styles['btn-table-link'] } onClick={ () => { goToDetail(record) } }>查看详情</span>
          {
            (filter.operatorType === 'CINEMA') && (<span className={ styles['btn-table-link'] } onClick={ () => { goToDetail(record) } }>查看库存</span>)
          }
        </>
      ),
    }
    const cinemaColumns = [
      indexItem,
      pointNumberItem, 
      provinceNameItem,
      cityNameItem,
      entityNameItem,
      pointNameItem,
      pointSrcItem,
      seatNumberItem,
      cinemaScreenMaterialItem,
      releaseStatusItem,
      mediaApproveStatusItem,
      approveStatusItem,
      pointStatusItem,
      deviceOnlineItem,
      optionsItem,
    ]
    const lightBoxColumns = [
      indexItem,
      pointNumberItem, 
      provinceNameItem,
      cityNameItem,
      entityNameItem,
      pointNameItem,
      lightboxLocationInfoItem,
      pointSrcItem,
      lightboxFrameItem,
      lightboxContentItem,
      lightboxMaterialItem,
      releaseStatusItem,
      mediaApproveStatusItem,
      optionsItem,
    ]
    const hallNameColumns = [
      indexItem,
      pointNumberItem, 
      provinceNameItem,
      cityNameItem,
      entityNameItem,
      pointNameItem,
      pointSrcItem,
      seatNumberItem,
      hallNameLabelItem,
      hallNameContentItem,
      releaseStatusItem,
      mediaApproveStatusItem,
      optionsItem,
    ]
    const digitalColumns = [
      indexItem,
      typeItem,
      pointNumberItem, 
      provinceNameItem,
      cityNameItem,
      entityNameItem,
      pointNameItem,
      pointSrcItem,
      // lcdDeviceTypeItem,
      // lcdScreenTypeItem,
      lcdScreenSizeItem,
      releaseStatusItem,
      mediaApproveStatusItem,
      optionsItem,
    ]
    const operatorType = filter.operatorType
    if (operatorType === 'CINEMA') {
      setColumns(cinemaColumns)
    } else if (operatorType === 'LIGHTBOX') {
      setColumns(lightBoxColumns)
    } else if (operatorType === 'HALL_NAME') {
      setColumns(hallNameColumns)
    } else {
      setColumns(digitalColumns)
    }
  }
  useEffect(() => {
    getEnumItems()
  }, [])
  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<any[]>([])
  const [lightBoxMaterialItems, setLightBoxMaterialItems] = useState<any[]>([])
  const [lcdDeviceTypeItems, setLcdDeviceTypeItems] = useState<any[]>([])
  const [lcdScreenTypeItems, setLcdScreenTypeItems] = useState<any[]>([])
  
  // 获取枚举值
  const getEnumItems = async () => {
    // CINEMA_SOUND_TRACK 声道
    // CINEMA_SCREEN_MATERIAL 影院银幕类型
    // LIGHTBOX_MATERIAL 画面材质
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`, 
      params: {
        groupKeyArray: ['LIGHTBOX_MATERIAL', 'CINEMA_SCREEN_MATERIAL', 'SCREEN_TYPE', 'DEVICE_TYPE'].join(','),
      },
    })
    if (code === 1) {
      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      setLightBoxMaterialItems(data['LIGHTBOX_MATERIAL'] || [])
      setLcdDeviceTypeItems(data['DEVICE_TYPE'] || [])
      setLcdScreenTypeItems(data['SCREEN_TYPE'] || [])
    }
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false)
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.releaseStatus === 'NOT_RELEASE',
    }),
  }
  
  const resetData = () => {
    setSelectedRowKeys([])
    getPointList(1)
  }

  // 查状态是否操作成功
  const checkId = async(optId) => {
    setIsSubmiting(true)
    const result:any = await getOperStatus(optId)

    if (result.code === 1) {
      setOptId('')
      resetData()
      message.success('操作成功')
    }
    setIsSubmiting(false)
  }

  // 打印已入驻点位二维码
  const togglePrintTips = () => {
    Modal.confirm({
      title: '是否确认打印？',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: onPrint,
    })
  }
  const onPrint = async() => {
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/spu/createPdfByPointNumbers`,
      data: selectedRowKeys,
    })
    if (code === 1) {
      console.log('onPrint', data)
      // 新开窗
      const win: any = window.open(data, '_blank')
      win.focus()
    }
  }
  const onEdit = () => {
    Modal.warn({
      title: '请前往热幕精灵小程序操作',
      content: '',
      okText: '我知道了',
    })
  }
  const onAudit = (record) => {
    history.push(`/point/detail/${ record.resourcePointId }/true`)
  }
  return(
    <div className={ styles['point-cont'] }>
      <div className={ styles['point-tabs'] }>
        {
          tabList.map((record, index) => {
            return (
              <div 
                className={ `${ styles['point-tab'] } ${ filter.operatorType === record.itemKey || !filter.operatorType && record.itemKey === 'DIGITAL' ? styles['is-selected'] : '' }` }
                key={ record.itemKey }
                onClick={ () => toggleTab(record.itemKey) }
                >{ record.itemValue }</div>
            )
          })
        }
      </div>

      {/* <div className={ styles['point-opts'] }>
        <Button className={ styles['point-opts-btn'] } type="primary" disabled={ !selectedRowKeys.length } onClick={ showSetModal }>独家库存设置</Button>
        <Button className={ `${ styles['point-opts-btn'] } ${ styles['default'] }` } disabled={ !selectedRowKeys.length } onClick={ showDelConfirm }>清空独家库存</Button>
      </div> */}

      <Spin spinning={ isSubmiting } tip="请稍候...">
        {
          (filter.operatorType === 'LIGHTBOX') && (<div className={ styles['point-opts'] }><Button type="primary" className={ `${ styles['btn'] } ${ styles['point-opts-btn'] }` } disabled={ selectedRowKeys.length <= 0 } onClick={ togglePrintTips }>打印已入驻点位二维码</Button></div>)
        }
        <div className={ styles['point-table']}>
          {
            filter.operatorType === 'LIGHTBOX' && (
              <Table 
                rowKey="pointNumber"
                columns={ columns } 
                dataSource={ list }
                rowSelection={ rowSelection }
                pagination={ false } />
            )
          }

          {
            filter.operatorType !== 'LIGHTBOX' && (
              <Table 
                rowKey="resourcePointId"
                columns={ columns } 
                dataSource={ list }
                pagination={ false } >
                {/* <Column
                  title="options"
                  dataIndex="options"
                  key="options"
                  render={(options) => (
                    <>
                      afdadfadfadf
                    </>
                  )}
                /> */}
              </Table>
            )
          }
          
        </div>
        
        {
          // 翻页
          (list.length > 0) && (
            <div className={ `${ styles.paging } flex justify-end` }>
              <Pagination 
                showQuickJumper 
                showSizeChanger
                defaultCurrent={ defaultCurrent }
                current={ pageNum }
                pageSize={ pageSize }
                pageSizeOptions={ pageSizeOptions }
                total={ total } 
                onChange={ onPageChange }
                onShowSizeChange={ onShowSizeChange }
                 />
            </div>
          )
        }
      </Spin>

      {/*独家库存设置*/}
      <SoleModal childRef={ soleModalRef } selectedRowKeys={ selectedRowKeys } resetData={ resetData } />

    </div>
  )
}