import { 
  WalletHome,
} from '../pages'

const routes = [
	{
    path: '/wallet/index',
    component: WalletHome,
  },
]

export default routes