import React, { useContext } from 'react'

import { appContext } from '../../App.state'

import styles from './Crumb.module.css'

interface Props {
  list?: any,
}

export const Crumb: React.FC<Props> = ({ list }) => {
	const appValue = useContext(appContext)

  return (
    <div className={ `${ styles['crumb-list'] }` }>
    	{
    		list.map((item, index) => {
    			return (
    				<span key={ index }>
    				{/*最后一个*/}
    				{
    					(index >= list.length - 1 ) && <span className={ `${ styles['crumb-cur'] }` }>{ item.name }</span>
    				}
    				{
    					(index < list.length - 1 ) && <><a className={ `${ styles['crumb-parent'] }` } href={ `${ appValue.mediaPageUrl }${ item.path }` }>{ item.name }</a> / </>
    				}
    				
    				</span>
    			)
    		})
    	}
    </div>
  )
}