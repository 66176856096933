import React, { useState, useRef, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Popconfirm, message } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { RootState } from '../../store'
import store from '../../redux/store'
import { getUserInfoAction } from '../../store/user/action'
import styles from './Header.module.css'

import { useLocation, useHistory } from 'react-router-dom'

import { appContext } from '../../App.state'

import logo from '../../assets/logo.png'
import miniCode from '../../assets/images/common/mini-code.jpg'

import util from '../../libs/util'
import { Line } from '../../components'
import { SignUpGuideModal } from './signUpGuideModal'
import { UserInfoModal } from './userInfoModal'
import auth from '../../pages/assistant/login/loginCont/auth'

interface PropsType {
  fixed?: boolean,
}

interface AuthInfoType {
  chinaName: string
}

interface MyUserInfoType {
  userType: 'COMPANY' | 'PERSONAL',
  productId: 'MEDIA' | string,
}

const defaultAuthInfo: AuthInfoType = {
  chinaName: ''
}

const defaultMyUserInfo: MyUserInfoType = {
  userType: 'COMPANY',
  productId: 'MEDIA'
}

export const Header: React.FC<PropsType> = (props) => {
  const appValue = useContext(appContext)

  const [fixed] = useState(props.fixed === undefined ? true : props.fixed)
  const location = useLocation()
  const history = useHistory()
  const childRef: any = useRef()

  const [pathname, setPathname] = useState<string>('')
  

  const [authInfo, setAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [unAuthInfo, setUnAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [myUserInfo, setMyUserInfo] = useState<MyUserInfoType>(defaultMyUserInfo)
  const [userRoleList, setUserRoleList] = useState<any[]>([])

  const [signedIn, setSignedIn] = useState(false)
  useEffect(() => {
    const userToken = util.Cookies.get('userToken')
    userToken ? setSignedIn(true) : setSignedIn(false)
    if (userToken) {
      dispatch(getUserInfoAction({}))
    }
  }, [])

  useEffect(() => {
    getPathname()
  }, [signedIn])

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  const dispatch = useDispatch()

  useEffect(() => {
    setUserInfo()
  }, [userInfo])

  const getPathname = () => {
    const pathname = location.pathname
    setPathname(pathname)
    if (pathname === '/home' || pathname === '/sign/signIn') {
      util.Cookies.remove('userToken')
      setSignedIn(false)
    }
  }

  const onSignUpGuide = () => {
    childRef.current.onToggle()
  }

  const setUserInfo = () => {
    const { companyAuth, personAuth, myUserInfo, userRoleList } = userInfo

    if (myUserInfo.userType === 'PERSON') {
      setAuthInfo(personAuth)
      setUnAuthInfo(companyAuth)
      setUserRoleList(userRoleList)
    } else {
      setAuthInfo(companyAuth)
      setUnAuthInfo(personAuth)
    }

    setMyUserInfo(myUserInfo)
  }

  const onLogout = async () => {
    const result = await util.request({
      method: 'POST',
      url: '/uc/userLogout'
    })

    if(result.code === 1) {
      setSignedIn(false)
      util.storage.clear()
      util.Cookies.remove('userToken')
      history.replace('/sign/signIn')
    }
  }

  const goToCinemaPage = () => {
    const url = `${ appValue.cinemaPageUrl }/assistant/home`
    console.log('goToCinemaPage', url)

    // 新开窗
    const win: any = window.open(url, '_blank')
    win.focus()
  }

  const goToMediaPage = () => {
    console.log('去媒体主端官网')
  }

  const goToSignin = () => {
    history.replace('/sign/signIn')
  }

  const [isCinemaTipOpen, setIsCinemaTipOpen] = useState(false)
  const handleOpenCinemaConfirm = () => {
    goToCinemaPage()
    setTimeout(() => {
      setIsCinemaTipOpen(false)
    }, 1000)
  }
  const handleOpenCinemaCancel = () => {
    setIsCinemaTipOpen(false)
  }

  const showCinema = async () => {
    // 如果点位不在线，则显示提示面板
    if (isOffline) {
      setIsCinemaTipOpen(true)
      return
    }

    const result = await util.request({
      method: 'POST',
      url: 'uc/getMyUserInfo'
    })

    if (result.data.permissionDenied) {
      message.error('当前账号尚未开通热幕影院银幕广告助手服务！')

      // 调退出登录
      onLogout()

      return
    }

    util.storage.set('myUserInfo', result.data.myUserInfo)

    const myAuth: any = auth(result.data)
    console.log('login get ', myAuth)
    
    const url = `${ appValue.cinemaPageUrl }${myAuth.url}`
    console.log('goToCinemaPage', url)

    // 新开窗
    const win: any = window.open(url, '_blank')
    win.focus()

    // history.push({ 
    //   pathname: myAuth.url, 
    //   state: myAuth 
    // })

    store.dispatch({
      type: 'UPDATE_USER',
      payload: result.data
    })
  }

  const [isOffline, setIsOffline] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [queryTimes, setQueryTimes] = useState(0)
  // 30分钟查询一次
  const queryIntervalTime = 30 * (60 * 1000)
  useEffect(() => {
    const timer = setInterval(() => {
      if (!queryTimes || queryTimes < 0) {
        clearInterval(timer)
        return
      }
      getIsOffline()
     }, queryIntervalTime)

    return () => clearInterval(timer)
  }, [queryTimes])
  useEffect(() => {
    signedIn && getIsOffline()
  }, [signedIn])
  const getIsOffline = async() => {
    setQueryTimes(queryTimes + 1)
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/resource/getMyEntityDeviceOnline`,
      params: {
        operatorType: 'CINEMA',
      },
    })
    setIsSubmiting(false)
    if (code === 1) {
      // 用户可以有多家影院
      // deviceOnlineCount 在线数量
      // deviceOfflineCount 离线数量
      const hasOfflineDevice = data.map(i => i.deviceOfflineCount).reduce((prev, value) => { return prev + value }, 0) > 0
      console.log('getIsOffline', hasOfflineDevice)
      setIsOffline(hasOfflineDevice)
      setIsCinemaTipOpen(hasOfflineDevice)
    }
  }

  return (
    <header className={ `${ styles.header } flex justify-between align-center ${ fixed ? '' : styles['static']}` }>
      <div className={ `${ styles.left }` }>
        <div className={ `${ styles.logo }` }>
          <img width="56" src={ logo } alt="" />
        </div>
        <div className={ `${ styles['text-logo'] } `}>影院广告管家</div>
      </div>

      <div className={ `${ styles.right } flex justify-start align-center` }>
        <div className={ `flex align-center` }>
         {
            signedIn && pathname !== '/choose' && 
            <div className={ `${ styles['assistant-wrap'] }` }>
              <Popconfirm
                title="有点位离线，请及时处理"
                okText="立即处理"
                placement="topRight"
                cancelText="暂不处理"
                visible={ isCinemaTipOpen }
                onConfirm={ handleOpenCinemaConfirm }
                onCancel={ handleOpenCinemaCancel }
                
              >
                {/* zIndex={ 1000 } */}
                <div className={ `${ styles['assistant'] }` } onClick={ showCinema }>
                  <i className={ `icon ${ styles['icon-robot'] } ${ isOffline ? styles['is-disabled'] : '' }` }></i>
                  映前广告助手
                  {
                    isOffline && <div className={ `${ styles['icon-assistant-tips'] }` }><ExclamationCircleFilled style={{ color: '#f00', 'marginLeft': '10px' }} /></div>
                  }
                </div>
              </Popconfirm>
              {/*<SyncOutlined style={{ color: '#666', }} spin={ isSubmiting }  onClick={ getIsOffline } />*/}
            </div>
            
          }

          <div className={ `${ styles.miniprogram } ${ signedIn ? styles.hasborder: '' }` } onClick={ goToMediaPage }>
            <div className={ `flex justify-start align-center` }>
              <i className={ `imediafont i-minicode ${ styles['icon-mini'] }` }></i>
              <div className={ `${ styles.name }` }>微信小程序</div>
            </div>

            <div className={ `${ styles['mini-code'] } tc` }>
              <img width="180" src={ miniCode } alt="" />
              <h3 className={ `pt2 tc` }>微信扫描小程序码</h3>
            </div>
          </div>
        </div>

        {
          (pathname !== '/sign/signIn' && pathname !== '/choose' && signedIn === false) ?
          (
            <div className={ `${ styles.user } flex align-center` }>
              <i className={ `ifont i-user ${ styles['icon-user'] }` }></i>
              <div className={ `${ styles['sign-in'] }` } onClick={ goToSignin }>登录</div>
              <Line />
              <span className={ styles['btn-register'] } onClick={ onSignUpGuide }>注册</span>
            </div>
          ) 
          : 
          (
            signedIn && 
            <UserInfoModal
              authInfo={ authInfo }
              unAuthInfo={ unAuthInfo }
              myUserInfo={ myUserInfo }
              userRoleList={ userRoleList }
              onLogout={ onLogout }
               />
          )
        }
      </div>

      <SignUpGuideModal childRef={ childRef } />
    </header>
  )
}