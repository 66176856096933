import { 
  AdExecList, 
  AdExecCreate,
  AdExecPlanDetail,
  AdExecMediaDetail,
  AdExecManageContent,
  AdExecSelectPoints,
  AdExecSelectHall,
  AdExecConfirm,
  AdExecCashier,
  AdExecReportHome,
  AdExecReportList,
} from '../pages'

const routes = [
	{
    path: '/ad-exec/list',
    component: AdExecList,
  },
  {
    path: '/ad-exec/create/:planAim/:planAimExt',
    component: AdExecCreate,
  },
  // 热幕订单 媒体主详情接口 /mall/media/order/getOrderMedia 传参 mallOrderMediaId
  {
    path: '/ad-exec/plan-detail/:mallOrderMediaId',
    component: AdExecPlanDetail,
  },
  // 影院线下自营 代理商独家包销(使用原广告主接口) /mall/order/getOrderPlanDetail 传参 mallOrderPlanId
  {
    path: '/ad-exec/media-detail/:mallOrderPlanId',
    component: AdExecMediaDetail,
  },
  {
    path: '/ad-exec/content/list/:mallOrderPlanId',
    component: AdExecManageContent,
  },
  {
    path: '/ad-exec/select-points/:mallOrderPlanId',
    component: AdExecSelectPoints,
  },
  {
    path: '/ad-exec/select-hall/:mallOrderPlanId/:filterData/:id/:deliveryContentDuration/:deliveryDays/:timePeriods/:playSequence/:showPlanStatus/:deliveryModel/:planAim',
    component: AdExecSelectHall,
  },
  {
    path: '/ad-exec/confirm/:mallOrderPlanId',
    component: AdExecConfirm,
  },
  {
    path: '/ad-exec/cashier/:mallOrderPlanId/:isFromConfirmPage',
    component: AdExecCashier,
  },
  {
    path: '/ad-exec/report/home/:mallOrderMediaId',
    component: AdExecReportHome,
  },
  {
    path: '/ad-exec/report/list/:mallOrderMediaId/:query',
    component: AdExecReportList,
  },
]

export default routes