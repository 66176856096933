import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
	Checkbox,
} from 'antd'
import {
  CheckOutlined,
} from '@ant-design/icons'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  activateFn: any,
  childRef: any,
  isAllOnline: boolean,
  isAllOffline: boolean,
  hasWaiting?: boolean,
}

export const AwakenModal: React.FC<Props> = ({ activateFn, isAllOnline, isAllOffline, hasWaiting, childRef }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		activateFn()
		toggle(false)
		setIsConfirmed(false)
	}

	const handleCancel = () => {
		toggle(false)
		setIsConfirmed(false)
	}

	const [isConfirmed, setIsConfirmed] = useState(false)
	const onCheckboxChange = () => {
		setIsConfirmed(!isConfirmed)
	}

	return (
		<Modal 
			width={ isAllOnline ? 660 : 860 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>

			<div className={ stylesCommon['title'] }>{  isAllOnline ? '提示' : '银幕广告点位唤醒' }</div>

			{ isAllOnline && 
				<div className={ styles['content'] }>
					<div className={ stylesCommon['content-subtit'] }>点位在线，无需唤醒 ！</div>
					<div className={ stylesCommon['content-pa'] }>若热幕精灵服务已启动，点位仍离线，则需唤醒点位</div>
				</div>
			}
			{/*全部离线或部分在线并无未激活*/}
			{ (isAllOffline || !hasWaiting && !isAllOnline && !isAllOffline) && 
				<>
					<div className={ styles['pic-step-2-wrap'] }>
						<div className={ `icon ${ styles['pic-step-2'] }` }>请确保 已启动热幕精灵服务</div>
						<div className={ `${ styles['tips'] }` }>
							<p>我是</p>
    					<p>接收广告素材的PC机</p>
						</div>
					</div>

					<div className={ styles['checkbox-bar-wrap'] }>
		        <div className={ styles['checkbox-bar'] } onClick={ onCheckboxChange }>
			        <div className={ `${styles['checkbox-my-wrap']}` }>
			        	<div className={ `${styles['checkbox-my']} ${ isConfirmed ? styles['is-checked'] : '' }` }></div>
			        	{ isConfirmed && <CheckOutlined className={ styles['checkbox-icon'] } /> }
			        	<input type="checkbox" className={ styles['hide'] } />
			        </div>
		        	热幕精灵服务已启动
		        </div>
		      </div>
				</>
			}

      <div className={ stylesCommon['footer'] }>
      	{ isAllOnline && <button className={ stylesCommon['btn'] } onClick={ handleCancel }>确定</button> }
      	
      	{/*全部离线或部分在线并无未激活*/}
      	{ (isAllOffline || !hasWaiting && !isAllOnline && !isAllOffline) && <button className={ stylesCommon['btn'] } onClick={ handleOk } disabled={ !isConfirmed }>点位唤醒</button> }
      </div>
    </Modal>
	)
}