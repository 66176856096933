import React, { useState, useRef, useEffect } from 'react'
import styles from './Header.module.css'

import { useLocation, useHistory } from 'react-router-dom'

import util from '../../libs/util'
import { SignUpGuideModal } from '../../components/header/signUpGuideModal'
import { UserInfoModal } from '../../components/header/userInfoModal'

interface AuthInfoType {
  chinaName: string
}

interface MyUserInfoType {
  userType: 'COMPANY' | 'PERSONAL',
  productId: 'MEDIA' | string,
}

const defaultAuthInfo: AuthInfoType = {
  chinaName: ''
}

const defaultMyUserInfo: MyUserInfoType = {
  userType: 'COMPANY',
  productId: 'MEDIA'
}

const loginPageUrl = '/assistant/login'

export const AssistantHeader: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const childRef: any = useRef()

  const [pathname, setPathname] = useState<string>('')
  const [signedIn, setSignedIn] = useState(false)

  const [authInfo, setAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [unAuthInfo, setUnAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [myUserInfo, setMyUserInfo] = useState<MyUserInfoType>(defaultMyUserInfo)
  const [userRoleList, setUserRoleList] = useState<any[]>([])

  useEffect(() => {
    const userToken = util.Cookies.get('userToken') || ''
    userToken ? setSignedIn(true) : setSignedIn(false)
  }, [])

  useEffect(() => {
    getPathname()

    if (signedIn) {
      getUserInfo()
    }
  }, [signedIn])

  const getPathname = () => {
    const pathname = location.pathname
    console.log('as getPathname', location)
    setPathname(pathname)
    if (pathname === loginPageUrl) {
      util.Cookies.remove('userToken')
      setSignedIn(false)
    }
  }

  const getUserInfo = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'uc/getMyUserInfo'
    })

    const { companyAuth, personAuth, myUserInfo, userRoleList } = result.data

    util.storage.set('myUserInfo', myUserInfo)

    if (myUserInfo.userType === 'PERSON') {
      setAuthInfo(personAuth)
      setUnAuthInfo(companyAuth)
      setUserRoleList(userRoleList)
    } else {
      setAuthInfo(companyAuth)
      setUnAuthInfo(personAuth)
    }

    setMyUserInfo(myUserInfo)
  }

  const onLogout = async () => {
    const result = await util.request({
      method: 'POST',
      url: '/uc/userLogout'
    })

    if(result.code === 1) {
      setSignedIn(false)
      util.storage.clear()
      history.replace(loginPageUrl)
    }
  }

  return (
    <header className={ `${ styles.header }` }>
      <div className={ `${ styles['header-inner'] }` }>
        <div className={ `${ styles['header-left'] }` }>
          <div className={ `icon ${ styles['logo'] } `}></div>
          <div className={ `${ styles['logo-text'] } `}>映前广告助手</div>
        </div>

        {
          signedIn && pathname !== '/choose' && 
          <UserInfoModal
            authInfo={ authInfo }
            unAuthInfo={ unAuthInfo }
            myUserInfo={ myUserInfo }
            userRoleList={ userRoleList }
            onLogout={ onLogout }
             />
        }
        
        <SignUpGuideModal childRef={ childRef } />
      </div>
    </header>
  )
}