import React, { useState, useEffect } from 'react'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import styles from './Token.module.css'

import util from '../../../../../libs/util'
interface Props {
}
export const Token: React.FC<Props> = () => {
  const [balance, setBalance] = useState(0)
  const [sellerRecentAmount, setSellerRecentAmount] = useState(0)
  const [sellerIncomeAmount, setSellerIncomeAmount] = useState(0)
  const [isShow, setIsShow] = useState(true)

  useEffect(() => {
    getBalance()
    getSellerAmount()
  }, [])

  const toggleShow = () => {
    setIsShow(!isShow)
  }

  const getBalance = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'finance/account/getReferAccountInfo',
      data: {
        currencyTypeId: 'DJ'
      }
    })

    if (result.code === 1) {
      setBalance(result.data.orgAccount.balance)
    }
  }

  // 查询收益汇总
  const getSellerAmount = async () => {
    const result = await util.request({
      method: 'GET',
      url: '/finance/trans/getSellerAmount',
      data: {}
    })

    if (result.code === 1) {
      setSellerRecentAmount(result.data.sellerRecentAmount)
      setSellerIncomeAmount(result.data.sellerIncomeAmount)
    }
  }

  return (
    <div className={ `${ styles.token }` }>
      <h3 className={ styles.title }>热幕收益
        <span className={ styles['token-icon-toggle'] } onClick={ toggleShow }>{ isShow && <EyeOutlined /> }{ !isShow && <EyeInvisibleOutlined />}</span>
      </h3>

      <div className={ styles['token-cont'] }>
        <div className={ styles['token-item'] }>
          <div className={ styles['token-tit'] }>余额<span className={ styles['token-tit-unit'] }>（元)</span></div>
          <div className={ styles['token-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.cut(util.tools.moneyFormat(balance)).int }</span>
              <span className={ styles.dec }>{ util.tools.cut(util.tools.moneyFormat(balance)).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>

        <div className={ styles['token-item'] }>
          <div className={ styles['token-tit'] }>最近日收益<span className={ styles['token-tit-unit'] }>（元)</span></div>
          <div className={ styles['token-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.cut(util.tools.moneyFormat(sellerRecentAmount)).int }</span>
              <span className={ styles.dec }>{ util.tools.cut(util.tools.moneyFormat(sellerRecentAmount)).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>

        <div className={ styles['token-item'] }>
          <div className={ styles['token-tit'] }>累计收益<span className={ styles['token-tit-unit'] }>（元)</span></div>
          <div className={ styles['token-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.cut(util.tools.moneyFormat(sellerIncomeAmount)).int }</span>
              <span className={ styles.dec }>{ util.tools.cut(util.tools.moneyFormat(sellerIncomeAmount)).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>
      </div>
    </div>
  )
}