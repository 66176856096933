import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
	Checkbox,
	Popover,
} from 'antd'
import {
  CheckOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons'

import util from '../../../../../libs/util'
import {
  findEnumItemList,
} from '../../../../../api'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  confirmFn: any,
  childRef: any,
  isAllWaiting?: boolean,
  title?: String,
  confirmText?: String,
  isSetMode?: boolean,
  helpUrls?: any,
}

export const ActiveModal: React.FC<Props> = ({ confirmFn, isAllWaiting=false, title='银幕点位激活', confirmText='我知道了', isSetMode, childRef, helpUrls }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isPopoverVisible, setIsPopoverVisible] = useState(true)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		confirmFn()
		toggle(false)
	}

	const handleConfirm = () => {
		toggle(false)
	}
	const handleCancel = () => {
		toggle(false)
	}

	const helpPrev = `https://${ util.tools.env }static.remudooh.com/cms/html/web/help/`
  const [helpPageUrl, setHelpPageUrl] = useState(helpPrev)
  const [helpStartPageUrl, setHelpStartPageUrl] = useState(helpPrev)

	const getHelpPageUrl = () => {
    setHelpPageUrl(`${ helpPrev }${ helpUrls?.find(i => i.itemKey === 'ACTIVE_FAIL').itemValue }`)
    setHelpStartPageUrl(`${ helpPrev }${ helpUrls?.find(i => i.itemKey === 'HOW_TO_STARTUP').itemValue }`)
  }
  useEffect(() => {
  	if (!helpUrls || !helpUrls.length) {
  		return
  	}
  	getHelpPageUrl()
  }, [helpUrls])

	return (
		<Modal 
			width={ 1110 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>
			<div className={ stylesCommon['title'] }>{ title }</div>
			<div className={ styles['steps-cont'] }>
				<div className={ styles['steps-cont-tips'] }>激活点位前，请确保【热幕精灵】服务程序已安装并启动<a className={ styles['steps-cont-tips-btn'] } href={ helpStartPageUrl } target="_blank">如何安装、启动热幕精灵？</a></div>

				<div className={ styles['steps-cont-btn'] }><button className={ stylesCommon['btn'] } onClick={ handleOk }>立即激活点位</button></div>

				<div className={ styles['steps-cont-item-text'] }>
					<div className={ styles['steps-cont-item-pic'] }>
						<div className={ `icon ${ styles['pic-active'] } ${ styles['pic-active-1'] }` }></div>
						<div className={ styles['steps-cont-item-pic-text'] }>热幕精灵激活页面</div>
					</div>
				</div>

				<a className={ styles['steps-cont-fail-tips'] } href={ helpPageUrl } target="_blank">激活页面无法打开怎么办？</a>
			</div>

      <div className={ stylesCommon['footer'] }>
      	<button className={ stylesCommon['btn'] } onClick={ handleConfirm }>我知道了</button>
      </div>
    </Modal>
	)
}