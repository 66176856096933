import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, } from 'antd'
import util from '../../../../../libs/util'
import styles from './PointGradeIntroModal.module.css'

interface Props {
  childRef: any,
}

export const PointGradeIntroModal: React.FC<Props> = ({ childRef }) => {
	useImperativeHandle(childRef, () => ({
    showModal: () => {
      showModal()
      getList()
    }
  }))

  // 
  const [list, setList] = useState<any>([])
  const getList = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/point_grade_standard/searchPointGradeStandard`,
      params: {},
    })

    if (code === 1) {
      data.sort((a, b) => {
        if (a.citiLevel === b.citiLevel) {
          return b.pointLevel - a.pointLevel
        }
        return a.citiLevel - b.citiLevel
      })
      setList(data)
    }
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  return (
  	<Modal 
	    width={ 1104 }
	    title="点位评级"
	    visible={ isModalVisible }
	    footer={ null }
      onCancel={ onModalCancel }
	    >
      <div className={ `${ styles['intro-page'] }` } >
        <div className={ `${ styles['intro-cont'] }` }>
          <div className={ `${ styles['intro-tit'] }` }>规则说明</div>
          <div className={ `${ styles['intro-tips'] }` }>
            <div className={ `${ styles['intro-p'] }` }>1. 每季度首日自动更新所有点位的级别。</div>
            <div className={ `${ styles['intro-p'] }` }>2. 根据影院所在城市的级别和影厅日均观影人次对银幕进行点位评级。</div>
          </div>
        </div>

        <div className={ `${ styles['intro'] }` }>
          <div className={ `${ styles['intro-tit'] }` }>评级规则</div>
          <div className={ `${ styles['intro-table'] }` }>
            <div className={ `${ styles['intro-th'] }` }>
              <div className={ `${ styles['intro-td'] }` }>
                点位级别
              </div>

              <div className={ `${ styles['intro-td'] }` }>
                城市级别
              </div>

              <div className={ `${ styles['intro-td'] }` }>
                前四季度日均观影人次
              </div>
            </div>

            <div className={ `${ styles['intro-tbody'] }` }>
              {
                list?.map((item, index) => {
                  return (
                    <div
                      className={ `${ styles['intro-tr'] }` } 
                      key={ index }
                      >
                      <div className={ `${ styles['intro-td'] } ${ (index) % 2 === 0 ? '' : styles['small'] }` }>
                        {
                          (index % 2 === 0) && (
                            <div className={ styles['rate-list'] }>
                              {
                                new Array(item?.cityGrade).fill(1).map((item, index) => {
                                  return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
                                })
                              }
                            </div>
                          )
                        }
                      </div>
                      <div className={ `${ styles['intro-td'] }` }>
                        <div className={ `icon L L${ item.cityGrade } }` }></div>
                      </div>

                      <div className={ `${ styles['intro-td'] }` }>{ item.trafficSymbol === 'GE' ? '>=' : '<=' }{ item.dailyTraffic }人/天</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
	  </Modal>
  )
}