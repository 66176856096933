import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import MediaInfoFactory from 'mediainfo.js'

import { CinemaScreenTypeItems } from '../../../../libs/util.constants'
import util from '../../../../libs/util'

import styles from './PreviewEffectModal.module.css'

interface Props {
  childRef?: any,
  videoRatio?: string | number,
  imageUrl?: string,
}

export const PreviewEffectModal: React.FC<Props> = ({ childRef, videoRatio, imageUrl }) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

  useImperativeHandle(childRef, () => ({
    showModal() {
      showModal()
    }
  }))

	// 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [cinemaScreenTypeItems, setCinemaScreenTypeItems] = useState<any>([])
  const [ratio, setRatio] = useState(0)
  const showModal = async() => {
    const result:any = await getImageSizeByUrl(imageUrl)
    console.log('result', result)
    
    let items = JSON.parse(JSON.stringify(CinemaScreenTypeItems))
    const videoRatio = parseFloat((Math.ceil((result.width / result.height) * 100) / 100).toFixed(2))
    setRatio(videoRatio)
    items.forEach(item => {
      const ratio = parseFloat(item.tips.split(':')[0])
      const type = item.itemKey
      if (videoRatio > ratio) {
        item.text = '上下黑边'
        item.type = 'vertical'
      } else if (videoRatio == ratio) {
        item.text = '全屏铺满'
        item.type = 'full'
      } else {
        item.text = '左右黑边'
        item.type = 'horizontal'
      }
    })
    setCinemaScreenTypeItems(items)

    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  
  const getImageSizeByUrl = (url) => {
    return new Promise(function (resolve, reject) {
      let image = new Image()
      console.log('url', url)
      image.onload = function () {
        resolve({
          width: image.width,
          height: image.height
        })
      };
      image.onerror = function () {
        reject(new Error('error'))
      };
      image.src = url
    })
  }
  
  return (
  	<Modal 
      width={ 1104 }
      title="银幕画面效果预览"
      footer={ null }
      visible={ isModalVisible }
      onCancel={ onModalCancel }
      >
      <div className={ `${ styles['preview-cont'] }` }>
        <div className={ `${ styles['preview-tit'] }` }>该广告（比例为{ ratio }:1）在不同银幕画幅上的效果预览：</div>
        <div className={ `${ styles['preview-list'] }` }>
          {
            cinemaScreenTypeItems.map(item => {
              return (
                <div 
                  className={ `${ styles['preview-item'] }` }
                  key={ item.itemKey }
                  >
                  <div className={ `${ styles['preview-item-top'] }` }>
                    <div className={ `${ styles['preview-item-label'] }` }>{ item.itemValue } { item.tips }</div>
                    {/*{
                      imageUrl && <img src={ imageUrl } className={ `${ styles['preview-item-image'] } styles[${ item.type }]` } />
                    }*/}
                    {
                      imageUrl && <div style={{ backgroundImage: `url(${ imageUrl })` }} className={ `${ styles['preview-item-image'] } icon` }></div>
                    }
                  </div>
                  <div className={ `${ styles['preview-item-text'] }` }>{ item.text }</div>
                </div>
              )
            })
          }
        </div>
       </div>
    </Modal>  
  )
}