import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../../libs/util'

import styles from './OrderInfo.module.css'

interface Props {
  ad: any,
}

export const OrderInfo: React.FC<Props> = ({ ad }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const copyText = () => {
    navigator.clipboard.writeText(ad?.mallOrderMediaNumber || '').then(() => {
      message.success('复制成功')
    })
  }
  
  return (
  	<div className={ `${ styles['ad-page-cont'] }` }>
      <div className={ `${ styles['ad-tit-label'] }` }>广告订单信息</div>

      <div className={ `${ styles['ad-info-form'] }` }>
        <div className={ `${ styles['ad-info-form-item'] }` }>
          <div className={ `${ styles['ad-info-form-label'] }` }>订单编号</div>
          <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.mallOrderMediaNumber }<span className={ `${ styles['btn-copy'] }` } onClick={ copyText }>复制</span></div>
        </div>
        <div className={ `${ styles['ad-info-form-item'] }` }>
          <div className={ `${ styles['ad-info-form-label'] }` }>广告主</div>
          <div className={ `${ styles['ad-info-form-value'] }` }>
            { ad.deliveryUser?.operatorName || '-' }
          </div>
        </div>
        <div className={ `${ styles['ad-info-form-item'] }` }>
          <div className={ `${ styles['ad-info-form-label'] }` }>提交时间</div>
          <div className={ `${ styles['ad-info-form-value'] }` }>{ ad?.confirmOrderTime }</div>
        </div>
      </div>
    </div>
  )
}