import React, { useState, useContext, useEffect } from 'react'
import styles from './IssueDialog.module.css'

import { Modal } from 'antd'

import { bodyStyle } from './cells'
import util from '../../../../libs/util'
import { Icons } from '../../../../components'
import { Thumbs } from './thumbs'
import { Desserts } from './desserts'
import { cinemaIssueContext, setCinemaIssueContext } from '../CinemaIssue'

const tips = {
  title: '上刊指引',
  list: [
    '1、选中广告；',
    '2、根据指示的接收路径，找到广告素材文件，按投放点位和刊播顺序将其加入TMS系统的播放队列；',
    '3、选择上刊的影厅，标记为“上刊”或“缺刊”；',
    '4、所有影厅都标记完成后，即可“提交上刊执行结果”；'
  ]
}

export const IssueDialog: React.FC = () => {
  const value = useContext(cinemaIssueContext)
  const setValue = useContext(setCinemaIssueContext)

  useEffect(() => {
    util.bus.on('showIssueDialog', toggleDialog)
  }, [])

  const toggleDialog = () => {
    if(setValue) {
      setValue(state => {
        return {
          ...state,
          visibleIssueDialog: !value.visibleIssueDialog
        }
      })
    }
  }

  return (
    <Modal 
      visible={ value.visibleIssueDialog }
      closable={ false }
      footer={ null }
      width="862"
      centered={ true }
      bodyStyle={ bodyStyle }
      onCancel={ toggleDialog }
    >
      <div className={ styles['issue-dialog'] }>
        <header className="flex justify-between align-center px4">
          <h3 className="fs16 fb">
            <span className="mr1">
              {
                (value.reportStatus.REPORT_WAIT > 0 || value.reportStatus.DRAFT_SUCCESS > 0 || value.reportStatus.DRAFT_FAILURE > 0) ?
                '执行上刊任务并提交上刊结果' : '上刊执行结果'
              }
              
            </span>
            <span className={ styles.tips }>
              <Icons color="#999" icon="question" />

              <ul className={ `${ styles['tips-ul'] } pa5` }>
                <h3>{ tips.title }</h3>
                {
                  tips.list.map((value, index) => (
                    <li key={ `${ index }` }>{ value }</li>
                  ))
                }
              </ul>
            </span>
          </h3>
          
          <i onClick={ toggleDialog }>
            <Icons color="#999" icon="close" />
          </i>
        </header>

        <div className={ `${ styles.main } pt4 px8` }>
          <div className={ `${ styles.top } flex justify-end align-center mb2` }>
            <p className={ styles.date }>
              <span>上刊日期：</span>
              <span>{ value.deliveryDate }</span>
            </p>
            <p className={ `${ styles.cinema } ml12` }>
              <span>上刊影院：</span>
              <span>{ value.entityName }</span>
            </p>
          </div>

          <Thumbs />

          <Desserts />
        </div>
      </div>
    </Modal>
  )
}