import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Button, Select, Row, Col, Radio } from 'antd'
import util from '../../../../libs/util'
import styles from './FilterPanel.module.css'
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { filterContext, setFilterContext, defaultFilterContextValue } from '../EntityList'
import { directStoreEnabledItems } from '../listInner'

interface Props {
  onSearch?: any,
  locationTypeItems: Array<any>,
  cinemaPlayerTypeItems: Array<any>,
  cinemaChainCompanyItems: Array<any>,
}

export const FilterPanel: React.FC<Props> = ({ onSearch, locationTypeItems, cinemaPlayerTypeItems, cinemaChainCompanyItems }) => {
  const [entityFilter] = Form.useForm()

  const filter = useContext(filterContext)
  const setFilter = useContext(setFilterContext)

  const inputStyle = {
    borderRadius: '4px', 
    borderColor: '#E6E6E6'
  }
  const onValuesChange = (values, allValues) => {
    setFilter(state => {
      return {
        ...state,
        ...allValues,
      }
    })
  }
  const onFilterSearch = () => {
    onSearch()
  }
  const onReset = () => {
    setFilter(state => {
      return {
        ...state,
        ...defaultFilterContextValue,
      }
    })
    entityFilter.resetFields()
  }

  return (
    <div className={ styles['filter-panel'] }>
      <Form 
        form={ entityFilter }
        name="entityFilter" 
        onValuesChange={ onValuesChange }
        onFinish={ onFilterSearch }
        autoComplete="off"
        size="large"
        >
        <Row gutter={ 24 }>
          <Col span={ 6 }>
            <Form.Item
              label="影院名称"
              name="entityName"
              >
              <Input 
                style={ inputStyle }
                placeholder="请输入" 
              />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item
              label="专资编码"
              name="cinemaPropertyNumber"
              >
              <Input 
                style={ inputStyle }
                placeholder="请输入" 
              />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="院线"
              name="cinemaChainCompany"
              >
              <Select
                style={ inputStyle }
                allowClear
                >
                {
                  cinemaChainCompanyItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="位置类型"
              name="locationType"
              >
              <Select
                style={ inputStyle }
                allowClear
                >
                {
                  locationTypeItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          
        </Row>
        <Row gutter={ 24 }>
          <Col span={ 6 }>
            <Form.Item 
              label="影院TMS系统"
              name="cinemaPlayerType"
              >
              <Select
                style={ inputStyle }
                allowClear
                >
                {
                  cinemaPlayerTypeItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
                
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="是否为影管公司直营影院"
              name="directStoreEnabled"
              >
              <Radio.Group>
                {
                  directStoreEnabledItems.map((record, index) => {
                    return (
                      <Radio key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Radio>
                    )
                  })
                }
				      </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item>
              <Button 
                style={{
                  marginRight: '20px',
                  backgroundColor: '#E2A45A',
                  borderColor: '#E2A45A',
                  borderRadius: '4px',
                }}
                type="primary" 
                htmlType="submit">
                查询
              </Button>
              <Button 
                htmlType="button" 
                style={{
                  borderColor: '#EDEDED',
                  borderRadius: '4px',
                }}
                onClick={ onReset }>
                重置
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}