import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store'
import styles from './Point.module.css'

import util from '../../../../../libs/util'

import cinemaIcon from '../../../../../assets/images/icons/cinema-icon.png'
import houseIcon from '../../../../../assets/images/icons/house-icon.png'

export const Point: React.FC = () => {
  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  const [summary, setSummary] = useState<any>({})

  useEffect(() => {
    if (userInfo.myUserInfo.userType) {
      getOwnerPointSummary(userInfo.myUserInfo.userType === 'PERSON')
    }
  }, [userInfo])

  const getOwnerPointSummary = async (isPersonal) => {
    // const apiUrl = isPersonal ? '/mall/point/own/getPersonalPointTotal' : 'resource/getOwnerPointSummary'
    const apiUrl = 'resource/getOwnerPointSummary'
    const result = await util.request({
      method: 'POST',
      url: apiUrl,
    })

    if (result.code === 1) {
      setSummary(result.data)
    }
  }

  return (
    <div className={ styles.point }>
      <h3 className={ `${ styles.title }` }>媒体资源</h3>

      <div className={ `${ styles.panel }` }>
        <h4 className={ `${ styles['sub-title'] }` }>
          <span className={ `${ styles['icon-cinema'] } imediafont i-cinema` }></span>
          <span className="ml2">影院银幕广告点位</span>
        </h4>

        <div className="flex justify-around">
          <div className={ `${ styles.content } mt4 tc` }>
            <strong className={ `${ styles.num }` }>{ summary.notReleaseCinemaCount　+　summary.releaseCinemaCount || 0 }</strong>
            <p className={ `${ styles.txt }` }>
              总数
            </p>
          </div>

          <div className={ `${ styles.content } mt4 tc` }>
            <strong className={ `${ styles.num } ${ styles['no-release'] }` }>{ summary.notReleaseCinemaCount || 0 }</strong>
            <p className={ `${ styles.txt }` }>待入驻</p>
          </div>

          <div className={ `${ styles.content } mt4 tc` }>
            <strong className={ `${ styles.num } ${ styles['is-success'] }` }>{ summary.releaseCinemaCount || 0 }</strong>
            <p className={ `${ styles.txt }` }>
              已入驻
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}