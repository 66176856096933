import React, { useState, useEffect, useRef } from 'react'
import styles from './PointList.module.css'
import { Crumb } from '../../../components'

import { FilterPanel, List } from './index'

interface ContextValueType {
  city: string | null,
  district: string | null,
  operatorType: string | 'CINEMA',
  entityName: string | null,
  pointNumber: string,
  pointName: string | null,
  releaseStatus: string | null,
  pointStatus: string | null,
  mediaApproveStatus: string | null,
  approveStatus: string | null,
  operatorTypes: [],
}

export const defaultFilterContextValue: ContextValueType = {
	city: null,
  district: null,
  operatorType: 'CINEMA',
  entityName: null,
  pointNumber: '',
  pointName: null,
  releaseStatus: null,
  pointStatus: null,
  mediaApproveStatus: null,
  approveStatus: null,
  operatorTypes: [],
}

export const filterContext = React.createContext(defaultFilterContextValue)
export const setFilterContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | any>(undefined)

export const PointList: React.FC = () => {
	const [filterData, setFilterData] = useState(defaultFilterContextValue)

  const listRef: any = useRef()
  const onSearch = () => {
    listRef.current.getPointList(1)
  }

  const crumbList = [
    {
      name: '媒体资源',
      path: '/entity/list',
    },
    {
      name: '点位',
      path: '',
    }
  ]

  return (
    <div className={ `${ styles['point-page'] }` }>
      <div className={ `${ styles['point-page-tit'] }` }>
        <Crumb list={ crumbList } />
      </div>
      <filterContext.Provider value={ filterData }>
      	<setFilterContext.Provider value={ setFilterData }>
		      <FilterPanel onSearch={ onSearch } />
		      <List childRef={ listRef } />
	      </setFilterContext.Provider>
      </filterContext.Provider>

    </div>
  )
}