import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
} from 'antd'
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons'

import util from '../../../../../libs/util'

import {
  findEnumItemList,
} from '../../../../../api'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  childRef: any,
  helpUrls?: any,
}

export const ServiceStartUpModal: React.FC<Props> = ({ childRef, helpUrls }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		toggle(false)
	}

	const handleCancel = () => {
		toggle(false)
	}

	const helpPrev = `https://${ util.tools.env }static.remudooh.com/cms/html/web/help/`
  const [helpPageUrl, setHelpPageUrl] = useState(helpPrev)
  const getHelpPageUrl = () => {
    setHelpPageUrl(`${ helpPrev }${ helpUrls?.find(i => i.itemKey === 'STARTUP_FAIL').itemValue }`)
  }
  useEffect(() => {
  	if (!helpUrls || !helpUrls.length) {
  		return
  	}
  	getHelpPageUrl()
  }, [helpUrls])

	return (
		<Modal 
			width={ 1110 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>
			<div className={ stylesCommon['title'] }>热幕精灵启动</div>
			<div className={ styles['steps-cont'] }>
				<div className={ styles['steps-cont-tips'] }><i className={ `ifont i-tips ${ styles['i-tips'] }` }></i>启动前<span className={ styles['important'] }>必须先关闭</span>“360、电脑管家”等杀毒软件的所有进程</div>

	      <div className={ styles['steps-cont-item-text'] }>在解压缩后的文件包“<span className={ styles['bold'] }>...\RemuAgent_Cinema</span>”里面，按下方左图进行安装操作：</div>
				<div className={ styles['steps-cont-item-text'] }>
					<div className={ styles['steps-cont-item-pic'] }>
						<div className={ `icon ${ styles['pic-startup'] } ${ styles['pic-startup-1'] }` }></div>
						<div className={ styles['steps-cont-item-pic-text'] }>启动热幕精灵的操作方法</div>
					</div>
					<div className={ styles['steps-cont-item-pic'] }>
						<div className={ `icon ${ styles['pic-startup'] } ${ styles['pic-startup-2'] }` }></div>
						<div className={ styles['steps-cont-item-pic-text'] }>热幕精灵启动界面</div>
					</div>
				</div>

				<a className={ styles['steps-cont-fail-tips'] } href={ helpPageUrl } target="_blank">启动失败怎么办？</a>
			</div>

      <div className={ stylesCommon['footer'] }>
      	<button className={ stylesCommon['btn'] } onClick={ handleOk }>我知道了</button>
      </div>
    </Modal>
	)
}