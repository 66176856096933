let welcomeUrl = '/assistant/welcome/personal/'

const mineHomeUrl = '/assistant/home'

const personalAuth = (user) => {
  const { companyAuth, personAuth, userRoleList } = user
  // 已企业认证
  const hasCompanyAuth = companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'YES'
  // 已个人认证
  const hasPersonAuth = personAuth && personAuth.status === 'YES'

  let status = 'unauth'
  let reason = '未认证'

  // 只有个人认证通过后，才企业授权
  // 未个人认证
  if (!personAuth) {
    status = 'unauth'
    reason = '未认证'
  } else {
  // 已个人认证
    if (personAuth.status === 'WAIT') {
    // 认证审核中
      status = 'inreview'
      reason = '认证审核中'
    } else if (personAuth.status === 'NO') {
    // 认证审核不通过
      status = 'failed'
      reason = '认证审核不通过'
    } else if (personAuth.status === 'YES' && (!companyAuth || (companyAuth.status !== 'YES' || companyAuth.roleStatus !== 'YES'))) {
    // 无权限(认证通过　但企业未通过认证)
      status = 'noaccess'
    } else if (hasPersonAuth && hasCompanyAuth) {
      // 已入企业且个人认证成功

      // 跳转至个人我的点位主端主页
      return {
        url: mineHomeUrl
      }

      // // 是 上刊人员 或 店长 或　机务人员
      // const hasAuth = userRoleList.map(i => i.roleItemKey).includes('MEDIA_PUBLISH') || userRoleList.map(i => i.roleItemKey).includes('MEDIA_MANAGER') || userRoleList.map(i => i.roleItemKey).includes('MEDIA_MECHANIC')
      
      // if (hasAuth) {
      //   // 跳转至个人我的点位主端主页
      //   return {
      //     url: mineHomeUrl
      //   }
      // } else {
      //   status = 'noaccess'
      //   reason = '未获得角色权限'
      // }
    }
  }

  return {
    url: welcomeUrl + status,
    status,
    reason,
  }
}

const companyAuth = (user) => {
  const { companyAuth } = user
  let status
  let reason

  if (!companyAuth || companyAuth && (companyAuth.status == 'DRAFT' || !companyAuth.roleStatus)) {
    // 企业用户已注册，未提交认证
    status = 'registeredunauth'
    reason = '企业用户已注册，未提交认证'
  }
  else if (companyAuth && (companyAuth.status === 'WAIT' || companyAuth.roleStatus === 'WAIT')) {
    // 企业认证审核中 
    status = 'inreview'
    reason = '企业认证审核中'  
  }
  else if (companyAuth && companyAuth.status === 'NO') {
    // 企业认证审核不通过
    // 审核不通过原因 /uc/getCompanyApply companyAuth.approveOpinion[]
    status = 'companyfailed'
    reason = '企业认证审核不通过'
  }
  else if(companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'NO') {
    // 点位主入驻审核不通过
    // 审核不通过原因 /uc/getCompanyApply mediaApply.approveOpinion[]
    status = 'mediafailed'
    reason = '点位主入驻审核不通过'
  }
  else if(companyAuth && companyAuth.status === 'YES' && companyAuth.roleStatus === 'YES') {
    // 跳转至企业我的点位主端主页
    return {
      url: mineHomeUrl
    }
  }

  return {
    url: welcomeUrl + status,
    status,
    reason,
  }
}

export default (user) => {
  const { myUserInfo: { userType } } = user

  switch (userType) {
    case 'PERSON':
      welcomeUrl = '/assistant/welcome/personal/'
      return personalAuth(user)
    default:
      welcomeUrl = '/assistant/welcome/company/'
      return companyAuth(user)
  }
}

