import React, { useState, useEffect } from 'react'
import { Checkbox, Button, Modal } from 'antd'
import util from '../../../../libs/util'
import styles from './Industry.module.css'
const CheckboxGroup = Checkbox.Group

interface Props {
  selectedArr?: any,
  onIndustryConfirm?: any,
  disabled?: boolean,
}

export const Industry: React.FC<Props> = ({ selectedArr, onIndustryConfirm, disabled }) => {
  useEffect(() => {
    setSelectedIndustry(JSON.parse(JSON.stringify(selectedArr)))
    init()
  }, [])

  const init = () => {
    initIndustryList()
  }

  // 广告所属行业
  const [currentIndustry, setCurrentIndustry] = useState<any>()
  const [industryOptions, setIndustryOptions] = useState<any>([])
  const [selectedIndustry, setSelectedIndustry] = useState<any>([])
  const [childOptions, setChildOptions] = useState<any>([])
  const initIndustryList = async() => {
    const key = 'INDUSTRY'
    const { code, data } = await getIndustryList(key)
    if (code === 1) {
      let list = data[key]
      list.forEach(i => {
        i.children = []
        i.checkAll = false
        i.checkCount = 0
      })
      setIndustryOptions(list)
      // list.length && getChild(list[0], 0)
    }
  }
  const getChild = async(parentItem, index) => {
    setCurrentIndustry({
      ...parentItem,
      index,
    })
    const key = parentItem.itemKey
    let parentList = [...industryOptions]
    // 先找有没有查过 不用查多次
    let item = parentList.find(i => i.itemKey === key)

    const { code, data } = await getIndustryList(key)
    if (code === 1) {
      let list = data[key]
      // 检查是否是在selectedArr里的
      const selectedParent = selectedArr.find(i => i.itemKey === key)
      if (selectedParent) {
        const selectedParentChildList = selectedParent.children.map(i => i.itemKey)
        list.forEach(i => {
          i.isChecked = selectedParentChildList.includes(i.itemKey)
        })

        let r = parentList.find(i => i.itemKey === key)
        r.checkCount = selectedParentChildList.length
        r.checkAll = list.length === selectedParentChildList.length
      }
      item.children = list
      setIndustryOptions(parentList)
      setChildOptions(list)
    }
  }
  const getIndustryList = async(cIndustry) => {
    const params = {
      groupKeyArray: cIndustry,
      excludeItems: cIndustry === 'INDUSTRY' ? ['AGENT', 'PUBLIC-AD-TYPE'].join(',') : null, 
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    return { data, code }
  }

  const onCheckAllChange = ({ target }) => {
    let list = [...industryOptions]
    const checked = target.checked
    let item = list.find(i => i.itemKey === currentIndustry?.itemKey)
    item.checkAll = checked
    item.checkCount = checked ? item.children.length : 0
    item.children.forEach(i => {
      i.isChecked = checked
    })
    setIndustryOptions(list)
  }
  const onCheckChildChange = (e, childIndex) => {
    const checked = e.target.checked
    let list = [...industryOptions]
    let parent = list.find(i => i.itemKey === currentIndustry?.itemKey)
    let item = parent.children[childIndex]
    item.isChecked = checked
    parent.checkCount = checked ? parent.checkCount + 1 : parent.checkCount - 1
    parent.checkAll = parent.checkCount === parent.children.length
    setIndustryOptions(list)
  }

  const onReset = () => {
    let options = [...industryOptions]
    options.forEach(parent => {
      // 检查是否是在selectedArr里的
      const selectedParent = selectedArr.find(i => i.itemKey === parent.itemKey)
      if (selectedParent) {
        const selectedParentChildList = selectedParent.children.map(i => i.itemKey)
        parent.children.forEach(i => {
          i.isChecked = selectedParentChildList.includes(i.itemKey)
        })
        parent.checkCount = selectedParentChildList.length
        parent.checkAll = parent.children.length === selectedParentChildList.length
      } else {
        parent.children.forEach(i => {
          i.isChecked = false
        })
        parent.checkCount = 0
        parent.checkAll = false
      }
    })
    setIndustryOptions(options)
  }

  const onConfirm = () => {
    let result:any = []
    let tempList = JSON.parse(JSON.stringify(industryOptions))
    tempList.forEach(i => {
      if (i.checkCount > 0) {
        let item = i
        item.children = i.children.filter(j => j.isChecked)
        result.push(item)
      }
    })
    setSelectedIndustry(result)
    if (onIndustryConfirm) {
      let list:any = []
      result.forEach(i => {
        if (i.checkCount > 0) {
          list.push(...i.children.map(j => j.itemKey))
        }
      })
      onIndustryConfirm(list)
    }
    toggle()
  }

  const returnResult = () => {
    let result:any = []
    selectedIndustry.forEach(i => {
      if (i.checkCount > 0) {
        result.push(...i.children.map(j => j.itemKey))
      }
    })
    return result
  }
  const [isPannelShow, setIsPannelShow] = useState(false)
  const toggle = () => {
    if (disabled) {
      return
    }
    setIsPannelShow(!isPannelShow)
  }

  return (
    <>
      <div className={ styles['industry-wrap'] } onClick={ toggle }>
        {
          selectedIndustry.map(parent => {
            return (
              <div 
                className={ styles['point-industry-item'] }
                key={ parent.itemKey }
                >
                <div className={ styles['point-industry-label'] }>{ parent.itemValue }</div>
                {
                  (parent?.children) && (
                    <div className={ styles['point-industry-values'] }>
                      {
                        parent?.children.map(item => {
                          return (
                            <div className={ styles['point-industry-value'] } key={ item.itemKey }>{ item.itemValue }</div>
                          )
                        })
                      }
                    </div>
                  )
                }
              </div>
            )
          })
        }
        {
          selectedIndustry.length <= 0 && (<div className={ styles['btn-set'] }>
          <div className={ `imediafont i-click ${ styles['btn-click'] }` }></div>点击设置禁投行业
        </div>)
        }
      </div>
      <Modal 
        width={ 800 }
        title="选择禁投行业"
        visible={ isPannelShow }
        footer={ null }
        onCancel={ toggle }
        >
        <div className={ `${ styles['industry-pannel-wrap'] }` }>
          <div className={ styles['industry-pannel'] }>
            <div className={ styles['parent-list'] }>
              {
                industryOptions.map((parent, parentIndex) => {
                  return (
                    <div 
                      className={ `${ styles['parent-item'] } ${ currentIndustry?.itemKey === parent.itemKey ? styles['is-selected'] : '' }`}
                      key={ parent.itemKey }
                      onClick={ () =>{ getChild(parent, parentIndex) } }
                      >
                      { parent.itemValue }
                      {
                        parent.checkCount > 0 && (<div className={ `${ styles['count'] }` }>{ parent.checkCount }</div>)
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className={ styles['child-list'] }>
              <Checkbox className={ styles['child-checkall'] } onChange={ onCheckAllChange } checked={ industryOptions[currentIndustry?.index]?.checkAll }>
              全部
              </Checkbox>
              {
                childOptions.map((child, childIndex) => {
                  return (
                    <div 
                      className={ styles['child-item'] }
                      key={ child.itemKey }
                      >
                        <Checkbox onChange={ (e) => { onCheckChildChange(e, childIndex) } } checked={ industryOptions[currentIndustry?.index]?.children && industryOptions[currentIndustry?.index]?.children[childIndex]?.isChecked }>{ child.itemValue }</Checkbox>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={ styles['pannel-btns'] }>
            <Button 
              htmlType="button" 
              style={{
                marginRight: '20px',
                borderColor: '#EDEDED',
                borderRadius: '4px',
              }}
              onClick={ onReset }>
              重置
            </Button>
            <Button 
              style={{
                backgroundColor: '#E2A45A',
                borderColor: '#E2A45A',
                borderRadius: '4px',
              }}
              type="primary" 
              onClick={ onConfirm }>
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}