import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import util from '../../../../libs/util'

import styles from './EntityList.module.css'
import { Filter } from '../filter'
import { Paging } from '../../../../components/pagination'
import { NoRecord } from '../../../../components/norecord'

import {
  searchOwnerPointEntityList,
} from '../../../../api'

interface Props {
  submit?: () => void
}
const defaultForm = {
	// releaseStatus: 'RELEASE',
	operatorType: 'CINEMA',
	province: '',
	city: '',
	district: '',
}
const defaultPageSize = 10

const defaultNoRecordText = '暂无搜索结果'

export const EntityList: React.FC<Props> = (props) => {
	const history = useHistory()
	// 查询
	const submit = (filter) => {
		setQueryForm(Object.assign({}, queryForm, { ...filter }))
	}

	const [queryForm, setQueryForm] = useState({ ...defaultForm })
	const [isFirstTime, setIsFirstTime] = useState(true)
	const [noRecordText, setNoRecordText] = useState(defaultNoRecordText)
	const [isCompany] = useState(util.storage.get('myUserInfo')?.userType === 'COMPANY')
	useEffect(() => {
		getEntityList(1, defaultPageSize)
	}, [queryForm])

	const [entityList, setEntityList] = useState<any[]>([])
	const [total, setTotal] = useState(0)
	const getEntityList = async (pageNum, pageSize) => {
		const result = await searchOwnerPointEntityList({
			pageNum,
			pageSize,
			...queryForm,
		})

		const total = result.total
		const list = result.data
				
		if (isFirstTime) {
			// 无记录
			if (!total) {
				setNoRecordText(isCompany ? '无已授权影院，请联系平台商务人员' : '无已授权影院，请联系企业主授权')
			}
			// 如果只有一条,直接跳转到详情页
			if (total === 1) {
				history.push(`/assistant/pointlist/${list[0].resourceOrgEntityId}`)
				return
			}
		} else {
			setNoRecordText(defaultNoRecordText)
		}

		setEntityList(list)
		setTotal(total)
		setIsFirstTime(false)
	}

	const goToDetail = (entity) => {
		history.push(`/assistant/pointlist/${entity.resourceOrgEntityId}`)
	}
  return (
    <div className={ styles['entity-wrap'] }>
    	{/*过滤项*/}
    	<Filter submit={ submit } />

    	<div className={ styles['entity-cont'] }>
	    	{/*有记录*/}
	    	{ !!total && 
	    		<>
	    			{/*列表*/}
			    	<div className={ styles['entity-list'] }>
			    	{
			    		entityList.map((entity, index) => {
			    			return (
			    				<div 
				    				className={ styles['entity-item'] }
				    				key={ entity.resourceOrgEntityId }
				    				>
				    			<img className={ styles['entity-thumb'] } src={ entity.entityViewPath } alt="" />

				    			<div className={ styles['entity-info'] }>
				    				<div className={ styles['entity-name'] }>{ entity.entityName }</div>
				    				<div className={ styles['entity-location'] }>
				    					{ entity.provinceName }
				    					{ entity.cityName && <> | { entity.cityName } </> }
				    					{ entity.districtName && <> | { entity.districtName } </> }
				    				</div>
				    				<div className={ styles['entity-point-count'] }>影厅数量：<span className={ styles['count-number'] }>{ entity.pointCount }</span>个</div>
				    				<div 
				    					className={ styles['entity-opts'] }
				    					>
				    					<div className={ styles['entity-arrow'] }>
				    						<i className={ `ifont i-arrow-r ${ styles['icon-arrow-r'] }` }></i>
				    					</div>
				    					<div className={ styles['btn'] } onClick={ () => goToDetail(entity) }>进入工作台</div>
				    				</div>
				    			</div>
				    		</div>
			    			)
			    		})
			    	}
		    		</div>

		    		{/*翻页*/}
		    		<Paging
	        		total={ total }
	        		getList= { getEntityList }
	        		/>
        	</>
	    	}

	    	{/*无记录*/}
	    	{ !total && 
	    		<NoRecord noRecordText={ noRecordText } />
		    }
	    </div>
    </div>
  )
}