import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, Pagination, Table, Rate, Form, Input, Select, DatePicker, Popover, Tooltip, Slider, message,Spin } from 'antd'
import { FireFilled, ExclamationCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { useLocation, useHistory, Link } from 'react-router-dom'
import util from '../../../../libs/util'
import styles from './List.module.css'

import { filterContext, convertItem } from '../EntityList'
import { DetailModal } from '../detailModal/index'
import { SoleModal } from '../soleModal/index'

interface Props {
  childRef: any,
  locationTypeItems: Array<any>,
  cinemaPlayerTypeItems: Array<any>,
  cinemaChainCompanyItems: Array<any>,
}

export const directStoreEnabledItems = [{
  itemKey: 'true',
  itemValue: '是',
},
{
  itemKey: 'false',
  itemValue: '否',
}]
export const List: React.FC<Props> = ({ childRef, locationTypeItems, cinemaPlayerTypeItems, cinemaChainCompanyItems }) => {
  useImperativeHandle(childRef, () => {
    return {
      getEntityList,
      returnItems,
    }
  })

  const returnItems = () => {
    return {
      locationTypeItems,
      cinemaPlayerTypeItems,
      cinemaChainCompanyItems,
    }
  }

  const filter = useContext(filterContext)

  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getEntityList(pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 获取列表
  const getEntityList = async(num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    let params = filter
    if (params.directStoreEnabled === 'true') {
      params.directStoreEnabled = true
    } else {
      delete params.directStoreEnabled
    }
    
    const result = await util.request({
      method: 'POST',
      url: `/mall/spu/findOrgStateEntityList?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: params,
    })
    const data = result.data || []
    const total = result.total || 0
    setList(data)
    setTotal(total)
    setIsSubmiting(false)
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const [isSubmiting, setIsSubmiting] = useState(false)
  const history = useHistory()
  const goToDetailPage = (id) => {
    history.push(`/entity/detail/${ id }`)
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return index + 1
      },
    },
    {
      title: '场所名称',
      dataIndex: 'entityName',
    },
    {
      title: '专资编码',
      dataIndex: 'cinemaPropertyNumber',
    },
    {
      title: '是否为影管公司直营影院',
      dataIndex: 'directStoreEnabled',
      render(text, record, index) {
        return text ? '是' : '否'
      },
    },
    {
      title: '院线',
      dataIndex: 'cinemaChainId',
      render(text, record, index) {
        return convertItem(cinemaChainCompanyItems, text)
      },
    },
    {
      title: '开业时间',
      dataIndex: 'openDate',
    },
    {
      title: '位置类型',
      dataIndex: 'locationType',
      render(text, record, index) {
        return convertItem(locationTypeItems, text)
      },
    },
    {
      title: '营业时间',
      dataIndex: 'workStart',
      render(workStart, record, index) {
        return `${ record.workStart }-${ record.workEnd }`
      },
    },
    {
      title: '影院管理系统',
      dataIndex: 'cinemaPlayerType',
      render(text, record, index) {
        return convertItem(cinemaPlayerTypeItems, text)
      },
    },
    {
      title: '操作',
      dataIndex: 'options',
      render: (_, record) => (
        <>
          <span className={ styles['btn-table-link'] } onClick={ () => { showDetailModal(record.resourceEntityInfoId) } } >查看详情</span>
          <span className={ styles['btn-table-link'] } onClick={ () => { goToDetailPage(record.resourceEntityInfoId) } } >编辑</span>
          {/* 暂时隐藏入口 */}
          {/* <span className={ styles['btn-table-link'] } onClick={ () => { showSoleModal(record) } } >查看库存分布情况</span> */}
        </>
      ),
    },
  ]

  const detailModalRef: any = useRef()
  const showDetailModal = (id) => {
    detailModalRef.current.showModal(id)
  }

  const soleModalRef: any = useRef()
  const showSoleModal = (record) => {
    soleModalRef.current.showModal(record.resourceOrgEntityId, record.resourceEntityInfoId)
  }

  return(
    <div className={ styles['entity-cont'] }>
      <Spin spinning={ isSubmiting } tip="请稍候...">
        <div className={ styles['entity-table']}>
          <Table 
            rowKey="resourceEntityInfoId"
            columns={ columns } 
            dataSource={ list }
            pagination={ false } />
        </div>
        
        {
          // 翻页
          (list.length > 0) && (
            <div className={ `${ styles.paging } flex justify-end` }>
              <Pagination 
                showQuickJumper 
                showSizeChanger
                defaultCurrent={ defaultCurrent }
                current={ pageNum }
                pageSize={ pageSize }
                pageSizeOptions={ pageSizeOptions }
                total={ total } 
                onChange={ onPageChange }
                onShowSizeChange={ onShowSizeChange }
                 />
            </div>
          )
        }
      </Spin>

      {/* 详情 */}
      <DetailModal 
        childRef={ detailModalRef }
        locationTypeItems={ locationTypeItems  } 
        cinemaPlayerTypeItems={ cinemaPlayerTypeItems } 
        cinemaChainCompanyItems={ cinemaChainCompanyItems }
         />

      {/* 影院库存分布情况 */}
      <SoleModal 
        childRef={ soleModalRef }
         />
    </div>
  )
}