import React, { useState, useImperativeHandle } from 'react'
import styles from './GuideModal.module.css'

import { Modal } from 'antd'

import miniCode from '../../assets/images/common/mini-code.jpg'

interface Props {
  childRef: any,
}

export const GuideModal: React.FC<Props> = ({ childRef }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  useImperativeHandle(childRef, () => ({
    onToggle() {
      onToggle()
    }
  }))
  
  const onToggle = () => {
    setIsModalVisible(!isModalVisible)
  }

  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Modal 
      width={ 624 }
      title=""
      footer={ null }
      visible={ isModalVisible }
      closable={ false }
      onCancel={ onModalCancel }
      >
      <div className={ `${ styles['modal-cont'] }` }>
        <div className={ `${ styles['modal-tit'] }` }>请使用微信扫码<br/>登录热幕精灵影院广告管家微信小程序进行操作</div>
        <img width="240" src={ miniCode } alt="热幕精灵小程序" />
        <div className={ `${ styles['modal-btn-confirm'] }` } onClick={ onModalCancel }>好的，我知道了</div>
      </div>
    </Modal>
  )
}