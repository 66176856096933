import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './CompanySignIn.module.css'

import util from '../../../../libs/util'
import { getPhoneLoginCode, getOrgUsersByCode, codeLogin } from '../../../../api'

import { Form, Input, Button } from 'antd'
import { Icons } from '../../../../components'
import { inputStyle } from '../SignIn'

interface Props {
  handleSignIn?: any,
  color?: any,
  isCinema?: any,
}

export const CompanySignIn: React.FC<Props> = (props) => {
  const history = useHistory()

  const [tip, setTip] = useState<string>('发送验证码')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [validRandom, setValidRandom] = useState<string>('')
  const [verifyNumber, setVerifyNumber] = useState<string>('')
  const [countdown, setCountdown] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')

  // 发送短信验证码
  const getSMScode = async () => {
    util.tools.prevent(async () => {
      if(countdown !== 0) return
      const result = await util.request({
        method: 'POST',
        url: 'message/sendSMSVerifyMessage',
        data: {
          phoneNumber,
          verifyType: '01'
        }
      })

      if(result.data) {
        setValidRandom(result.data.validRandom)
        interval(result.data.intervalTime)
      }
    })
  }

  // 登录
  const onSignIn = async () => {
    const { code, data, message } = await getPhoneLoginCode({
      phoneArea: '86',
      productChannelId: `MEDIA_WEB`,
      validRandom,
      phoneNumber,
      verifyNumber,
    }, {
      headers: {
        userToken: '',
      }
    })

    if (code === 1) {
      setErrorMessage('')
      console.log(code, data, message)
      const result = await getOrgUsersByCode({
        tempCode: data,
      }, {
        headers: {
          userToken: '',
        }
      })

      if (result.code === 1) {
        const list = result.data
        console.log('getOrgUsersByCode', list)
        // 未入驻过
        if (!list.length) {
          util.bus.emit('handleSignUp')
        } else if (list.length > 1) {
          // 有多个
          // 去到列表页
          history.push({ 
            pathname: '/choose',
            state: {
              id: data,
              phoneNumber,
              isCinema: props.isCinema,
            },
          })
        } else {
          const res = await codeLogin({
            tempCode: data,
            userInId: list[0].userInId,
          })
          const userToken = res.data.userToken
          util.Cookies.set('userToken', userToken)
          props.handleSignIn()
        }
      } else {
        setErrorMessage(message)
      }
      
    } else {
      setErrorMessage(message)
    }
  }

  const onValuesChange = (values, allValues) => {
    setPhoneNumber(util.tools.space(allValues.phoneNumber))
    setVerifyNumber(util.tools.space(allValues.verifyNumber))
  }

  const interval = (interval) => {
    if(interval < 1) {
      setCountdown(0)
      return
    }
    interval = interval * 60
    const timer = setInterval(() => {
      setCountdown(--interval)
      if(interval === 0) {
        clearInterval(timer)
        setCountdown(0)
      }
    }, 1000)
  }

  return (
    <Form 
      name="basic" 
      initialValues={{ remember: true }} 
      onValuesChange={ onValuesChange }
      onFinish={ onSignIn }
    >
      <Form.Item
        name="phoneNumber"
        rules={[{ required: true, message: '请输入手机号' }]}
      >
        <Input 
          style={ inputStyle }
          bordered={ false }
          maxLength={ 11 }
          autoComplete="off" 
          placeholder="请输入手机号" 
        />
      </Form.Item>

      <Form.Item
        name="verifyNumber"
        rules={[{ required: true, message: '请输入6位验证码' }]}
      >
        <div className="pr">
          <Input 
            style={ inputStyle }
            bordered={ false }
            autoComplete="off" 
            maxLength={ 6 }
            placeholder="请输入6位验证码" 
          />
          <span style={{ color: props.color }} className={ `${ styles.code } pa` } onClick={ getSMScode }>{ countdown === 0 ? '发送验证码' : `重新发送(${ countdown }s)` }</span>
          
        </div>
      </Form.Item>
      { validRandom && <div className={ `${ styles['sms-tips'] }` }><span className={ `${ styles['icon-sms-tips'] } imediafont i-tips2` }></span>短信验证码已发送，可能会有延后，请耐心等待</div> }
      <div 
        className={ `${ styles['error-message'] } ${ errorMessage ? 'show' : 'show' } pa` }
      >{ errorMessage }</div>
      <Form.Item>
        <Button 
          style={{
            marginTop: '48px',
            marginBottom: '16px',
            height: '56px',
            borderRadius: '8px',
            background: props.color,
            borderColor: props.color,
            fontSize: '20px',
          }}
          className={ styles['submit-btn'] } 
          block 
          htmlType="submit"
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}