import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './OrderExec.module.css'

import { RightOutlined } from '@ant-design/icons'

import util from '../../../../../libs/util'

import adStatus1 from '../../../../../assets/images/icons/icon-ad-status-1.png'
import adStatus2 from '../../../../../assets/images/icons/icon-ad-status-2.png'
import adStatus3 from '../../../../../assets/images/icons/icon-ad-status-3.png'
import adStatus4 from '../../../../../assets/images/icons/icon-ad-status-4.png'

interface ListItem {
  icon: string,
  name: string,
  key: string,
  count: number,
  status: string,
}

const defaultList: ListItem[] = [
  {
    icon: adStatus1,
    status: 'PAY_WAIT',
    name: '待付款',
    key: 'mediaOrderPayWaitNumber',
    count: 0
  },
  {
    icon: adStatus2,
    status: 'DELIVERY_WAIT',
    name: '待上刊',
    key: 'mediaOrderDeliveryWaitNumber',
    count: 0
  },
  {
    icon: adStatus3,
    status: 'EXECUTING',
    name: '刊播中',
    key: 'mediaOrderExecutingNumber',
    count: 0
  },
  {
    icon: adStatus4,
    status: 'FINISHED',
    name: '已完成',
    key: 'mediaOrderFinishNumber',
    count: 0
  }
]

export const OrderExec: React.FC = () => {
  const history = useHistory()

  const [list , setList] = useState(JSON.parse(JSON.stringify(defaultList)))

  useEffect(() => {
    getOrderMediaSummary()
  }, [])

  const getOrderMediaSummary = async () => {
    const result = await util.request({
      method: 'GET',
      url: 'mall/media/order/getOrderMediaSummary',
      params: {
        searchModel: 1,
      }
    })

    const data = result.data || {}
    if (result.code === 1) {
      list.forEach(item => {
        const key = item.key
        const count = data[key]
        if (count) {
          item.count = count
        }
      })
      setList([...list])
    }
  }

  const goToAdPage = () => {
    history.push(`/ad-exec/list`)
  }

  const goToPage = (status) => {
    history.push(`/ad-exec/list?status=${ status }`)
  }

  return (
    <div className={ styles.order }>
      <h3 className={ `${ styles.title } flex justify-between` }>
        <span>广告执行单</span>
        <p className={ styles.all } onClick={ goToAdPage }>
          <span className="mr1">查看全部</span>
          <RightOutlined />
        </p>
      </h3>

      <div className={ `${ styles.content } flex justify-center align-center` }>
        <ul className="flex justify-around">
          {
            list.map((item, index) => {
              return (
                <li className={ `${ styles.item } tc` } key={ index } onClick={ () => { goToPage(item.status) } }>
                  <img width="33" src={ item.icon } alt="" />
                  <p className="mt2">{ item.name }</p>

                  <span className={ `${ styles.count } ${ item.count > 0 ? 'show' : 'hide' }` }>{ item.count }</span>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}