import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Button, Pagination, Table, Rate, Form, Input, Select, DatePicker, Popover, Tooltip, Slider, message,Spin } from 'antd'
import { FireFilled, ExclamationCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { useLocation, useHistory, Link } from 'react-router-dom'
import { getOrgEntityState } from '../../../../../api'
import util from '../../../../../libs/util'
import styles from './List.module.css'

import { filterRemoContext, setRemoFilterContext } from '../PointList'
import { RemoSoleModal } from '../soleModal/index'

// 
const operatorTypeItems = [
  {
    itemKey: 'CINEMA',
    itemValue: '银幕广告',
  },
  {
    itemKey: 'NORMAL',
    itemValue: 'LCD数字屏',
  },
]
const deviceOnlineItems = [
  {
    itemKey: 'ONLINE',
    itemValue: '在线',
  },
  {
    itemKey: 'OFFLINE',
    itemValue: '离线',
  },
]

interface Props {
  childRef: any,
}

export const RemoList: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => {
    return {
      getPointList,
    }
  })

  const soleModalRef: any = useRef()
  const showModal = (record) => {
    soleModalRef.current.showModal(record)
  }

  const filter = useContext(filterRemoContext)
  const setFilter = useContext(setRemoFilterContext)

  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getPointList(pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 获取点位列表
  const getPointList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    let params = {
      city: filter.city,
      district: filter.district,
      operatorType: filter.operatorType,
      entityName: filter.entityName,
      pointNumber: filter.pointNumber,
      pointName: filter.pointName,
      showPointStatus: 'RELEASE',
      useScope: 'SKU_SPLIT',
    }
    // delete params.searchType
    const result = await util.request({
      method: 'POST',
      url: `/mall/spu/searchPointSpuList?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: params,
    })
    const data = result.data || []
    const total = result.total || 0
    setList(data)
    setTotal(total)
    setIsSubmiting(false)
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  useEffect(() => {
    getTabList()
    getData()
  }, [])
  const [tabList, setTabList] = useState<any[]>([])
  // 统计点位主点位 (用于控制显示tab页)
  const getTabList = async() => {
    const result = await getOrgEntityState()
    
    if (result.code == 1) {
      const resData = result.data
      const hasNormal = resData['LCD_SCREEN'] > 0
      const hasCinema = resData['CINEMA_SCREEN'] > 0
      const hasMulti = hasNormal && hasCinema
      if (!hasMulti) {
        setFilter(state => {
          return {
            ...state,
            operatorType: hasCinema ? 'CINEMA' : 'NORMAL',
          }
        })
        setTabList(operatorTypeItems.filter(i => i.itemKey === (hasCinema ? 'CINEMA' : 'NORMAL')))
      } else {
        setTabList(operatorTypeItems)
      }
    }
  }
  useEffect(() => {
    getPointList(1)
  }, [filter.operatorType])


  const [isSubmiting, setIsSubmiting] = useState(false)

  // 切换tab
  const toggleTab = (id) => {
    setFilter(state => {
      return {
        ...state,
        operatorType: id,
      }
    })
  }
  const defaultColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return index + 1
      },
    },
    {
      title: '点位编号',
      dataIndex: 'pointNumber',
    },
    {
      title: '媒介类型',
      dataIndex: 'operatorType',
      render(text, record, index) {
        const obj = operatorTypeItems.find(i => i.itemKey === text)
        return obj ? obj.itemValue : '-'
      },
    },
    {
      title: '场所名称',
      dataIndex: 'entityName',
    },
    {
      title: '点位名称',
      dataIndex: 'pointName',
    },
    {
      title: '点位级别',
      dataIndex: 'pointGrade',
      render(text, record, index) {
        return text ? <div className={ styles['rate-list'] }>
          {
            new Array(text).fill(1).map((item, index) => {
              return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
            })
          }
        </div> : ''
      },
    },
    {
      title: '设备状态',
      dataIndex: 'deviceOnline',
      render(text, record, index) {
        const obj = deviceOnlineItems.find(i => i.itemKey === text)
        return obj ? (
          <div className={ `${ styles['flex'] }`}><div className={ `${ styles['icon-dot'] } ${ text === 'ONLINE' ? styles['success'] : styles['warn'] }` }></div>{ obj.itemValue }</div>
        ) : '-'
      },
    },
    {
      title: '操作',
      dataIndex: 'options',
      render: (_, record) => (
        <a className={ styles['btn-table-link'] } onClick={ () => showModal(record) }>查看库存</a>
      ),
    },
  ]
  const [columns, setColumns] = useState<any>(defaultColumns)
  
  const [loading, setLoading] = useState(false)
  const [isShowMode, setIsShowMode] = useState(false)
  // 获取点位列表
  const getData = async() => {
    setLoading(true)
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/biz_entity/getBizSaleEntityStateMore`,
      data: {},
    })
    if (code === 1) {
      setIsShowMode(data.settleType[0] === 'SHOW')
      setColumns(defaultColumns.filter(i => i.dataIndex !== 'pointGrade'))
    }
    setLoading(false)
  }

  return(
    <div className={ styles['point-cont'] }>
      <div className={ styles['point-tabs'] }>
        {
          tabList.map((record, index) => {
            return (
              <div 
                className={ `${ styles['point-tab'] } ${ filter.operatorType === record.itemKey ? styles['is-selected'] : '' }` }
                key={ record.itemKey }
                onClick={ () => toggleTab(record.itemKey) }
                >{ record.itemValue }</div>
            )
          })
        }
      </div>

      <Spin spinning={ isSubmiting } tip="请稍候...">
        <div className={ styles['point-table']}>
          <Table 
            rowKey="resourcePointId"
            columns={ columns } 
            dataSource={ list }
            pagination={ false } />
        </div>
        
        {
          // 翻页
          (list.length > 0) && (
            <div className={ `${ styles.paging } flex justify-end` }>
              <Pagination 
                showQuickJumper 
                showSizeChanger
                showTotal={ total => `共 ${total} 条`}
                defaultCurrent={ defaultCurrent }
                current={ pageNum }
                pageSize={ pageSize }
                pageSizeOptions={ pageSizeOptions }
                total={ total } 
                onChange={ onPageChange }
                onShowSizeChange={ onShowSizeChange }
                 />
            </div>
          )
        }
      </Spin>

      {/*库存弹窗*/}
      <RemoSoleModal isShowMode={ isShowMode } childRef={ soleModalRef } />
    </div>
  )
}