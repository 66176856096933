import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store'

import util from '../../../../libs/util'

import { AdExec, defaultAdExec } from '../../index'
import { ScreenEffectModal } from '../screen-effect-modal'
import { TechnicPriceModal } from '../technic-price-modal'
import styles from './OrderBottom.module.css'

interface Props {
  ad: AdExec,
  isEditMode?: boolean,
  isConfirmPage?: boolean,
  reloadFn?: Function,
}

export const OrderBottom: React.FC<Props> = ({ ad, isEditMode, isConfirmPage = false, reloadFn }) => {
  const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
  const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'MEDIA_OPERATION') : false
  })
  const checkPayAuth = () => {
    // 个人用户中，只有管理员能支付
    const hasAuth = userType !== 'COMPANY' && isManager || userType === 'COMPANY'
    if (!hasAuth) {
      Modal.warning({
        title: '暂无支付权限',
        content: `请联系企业/管理员/广告运营进行操作`,
        okText: '我知道了',
        closable: false,
        onOk() {
          isConfirmPage && history.goBack()
        },
      })
    }
    return hasAuth
  }

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  useEffect(() => {
    if (!ad.mallOrderPlanId) {
      return
    }
    getContentUploadEndTime(ad)
  }, [ad.planAim])

  // 取消广告执行单
  const toggleCancelOrderTips = () => {
    Modal.confirm({
      title: '是否确认取消广告执行单？',
      content: '',
      okText: '确认',
      cancelText: '暂不',
      onOk: onCancelOrder,
    })
  }
  const onCancelOrder = async() => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const { code, data } = await util.request({
      method: 'POST',
      url: `/mall/order/cancelOrderPlan/${ ad.mallOrderPlanId }`,
    })

    setIsSubmiting(false)
    if (code === 1) {
      message.success('取消成功')
      reloadFn && reloadFn()
    } else {
      message.error('操作失败')
    }
  }

  const [isSubmiting, setIsSubmiting] = useState(false)

  // 素材截止上传时间
  const [contentUploadEndTime, setContentUploadEndTime] = useState()
  const getContentUploadEndTime = async(ad) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/tool/getContentUploadEndTime`,
      params: {
        deliveryStart: ad.deliveryStart,
        operatorType: ad.operatorType,
      },
    })

    if (code === 1) {
      const time = data.contentUploadEndTime
      // 距离素材提交截止时间≤3天
      if (+new Date(time.replace(/-/g, '/')) - +new Date() <= 3 * 24 * 60 * 60 * 1000) {
        setContentUploadEndTime(time)
      }
    }
  }
  
  // 去收银台
  const goToCashierPage = () => {
    history.push(`/ad-exec/cashier/${ ad.mallOrderPlanId }/${ isConfirmPage }`)
  }

  const onPay = () => {
    if (!contentUploadEndTime) {
      if (!checkPayAuth()) {
        return
      }
      goToPay()
      return
    }
    Modal.confirm({
      title: '提示',
      content: `广告素材须在${contentUploadEndTime }前提交，请确保您有充足的素材准备时间`,
      cancelText: '素材准备时间不足，返回调整投放周期',
      okText: '已了解，立即支付',
      onOk() {
        goToPay()
      },
      onCancel() {
      },
    })
  }

  // 去支付
  const goToPay = async() => {
    if (isSubmiting) {
      return
    }

    if (ad.showPlanStatus === 'ORDER_WAIT') {
      if (!checkPayAuth()) {
        return
      }
      goToCashierPage()
      return
    }

    setIsSubmiting(true)

    // 更新订单(保存订单名)
    const updateResult = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName: ad.mediaDeliveryName,
      },
    })

    if (updateResult.code !== 1) {
      setIsSubmiting(false)
      message.error(updateResult.message || '操作失败')
      return
    }

    // 锁定订单状态
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/deliveryOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
      },
      loadingText: '提交中...',
    })

    setIsSubmiting(false)

    if (code === 1) {
      if (!checkPayAuth()) {
        return
      }
      goToCashierPage()
    } else {
      message.error(message || '操作失败')
    }
  }

  // 去结算
  const onConfirm = async() => {
    // 是待付款状态
    if (ad.showPlanStatus === 'ORDER_WAIT') {
      onPay()
      return
    }
    
    // 区间不满
    const deliveryStart = ad?.deliveryStart || ''
    const deliveryEnd = ad?.deliveryEnd || ''

    const orderTotal = ad.orderTotal
    const realDeliveryStart = orderTotal.realDeliveryStart
    const realDeliveryEnd = orderTotal.realDeliveryEnd

    const day = 24 * 60 * 60 * 1000
    const startDef = Math.floor((new Date(deliveryStart).getTime() - new Date(realDeliveryStart).getTime()) / day)
    const endDef = Math.floor((new Date(deliveryEnd).getTime() - new Date(realDeliveryEnd).getTime()) / day)

    // 部分日期无任何点位投放
    if (startDef < 0 || endDef > 0) {
      Modal.confirm({
        title: '提示',
        content: `投放周期部分日期无任何点位投放，若继续结算，投放周期将自动调整为${ util.tools.formatDateStr(orderTotal.realDeliveryStart) }-${ util.tools.formatDateStr(orderTotal.realDeliveryEnd) }`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan()
        },
        onCancel() {
        },
      })

      return
    }

    // 存在投放日期不满投的点位(innerTotal  < 点位数量 * 天数)
    if (orderTotal.innerTotal < orderTotal.pointUsableCount * ad.deliveryDays) {
      Modal.confirm({
        title: '存在投放日期不满投的点位',
        content: `是否确定选购该影院的点位？`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan()
        },
        onCancel() {
        },
      })

      return
    }

    // 清空失效点位
    if (orderTotal.disablePointCount > 0) {
      await clearDisabledPoint()
    }

    goToConfirmPage()
  }

  // 更新订单
  const updateOrderPlan = async() => {
    const orderTotal = ad.orderTotal

    // 更新订单(更新投放周期)
    const { code } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryContentDuration: ad.deliveryContentDuration,
        deliveryStart: orderTotal.realDeliveryStart,
        deliveryEnd: orderTotal.realDeliveryEnd,
      },
    })

    if (code !== 1) {
      return
    }

    goToConfirmPage()
  }

  // 清除失效点位
  const clearDisabledPoint = async() => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const { code } = await util.request({
      method: 'DELETE',
      url: `/mall/order/clearDisablePoint`,
      data: ad.mallOrderPlanId,
    })

    setIsSubmiting(false)

    if (code === 1) {
      message.success('失效点位清理成功')
      reloadFn && reloadFn()
    }
  }

  // 确认订单页
  const goToConfirmPage = ()=> {
    history.push(`/ad-exec/confirm/${ ad.mallOrderPlanId }`)
  }

  // 广告素材要求
  const showMaterialModal = () => {
    onPreviewEffect()
  }
  const screenEffectModalRef: any = useRef()
  const onPreviewEffect = () => {
    screenEffectModalRef.current.onToggle()
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  // 服务费
  const technicPriceModalRef: any = useRef()
  const onPreviewFee = () => {
    technicPriceModalRef.current.onToggle()
  }
  
  return (
  	<div className={ `${ styles['ad-page-cont'] } ${ styles['ad-page-bottom'] } ${ ((ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT' || ad?.showPlanStatus === 'DRAFT') && !isConfirmPage) ? styles['bottom'] : '' } ${ isConfirmPage ? styles['confirm'] : '' }` }>
        {
          ((isEditMode || ad?.showPlanStatus === 'DRAFT' || ad?.showPlanStatus === 'SAVE') && !isConfirmPage) && (
            <div className={ `${ styles['ad-tit-tips'] }` }>已选点位 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.pointCount }</span> 个，覆盖场所 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.entityCount }</span> 个</div>
          )
        }

        {
          isConfirmPage && (
            <div className={ `${ styles['ad-material-tips'] }` }>请阅读<span className={ `${ styles['ad-material-link'] }` } onClick={ showMaterialModal }>《广告素材制作要求》</span></div>
          )
        }

        <div className={ `${ styles['ad-page-bottom-l'] }` }>
          <div className={ `${ styles['ad-page-bottom-label'] }` }>智能刊播服务费</div>
          <div className={ `${ styles['icon-tips'] } ifont i-question` } onClick={ onPreviewFee }></div>
          <div className={ `${ styles['text-price'] }` }>
            <span className={ `${ styles['text-price-unit'] }` }>￥</span>
            <span className={ `${ styles['text-price-int'] }` }>{ (ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA') ? util.tools.splitNum2String(ad?.planAmount || ad?.orderTotal?.planAmount).int : 0 }</span>
            <span className={ `${ styles['text-price-dec'] }` }>{ (ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA') ? util.tools.splitNum2String(ad?.planAmount || ad?.orderTotal?.planAmount).dec : '' }</span>
          </div>
          {
            (ad?.showPlanStatus === 'DRAFT' && !isConfirmPage) && (
              <button className={ `${ styles['btn-comfirm'] } ${ styles['big-margin'] }` } disabled={ isSubmiting || ad?.orderTotal?.pointUsableCount <= 0 } onClick={ onConfirm }>去结算</button>
            )
          }
          {
            (isConfirmPage) && (
              <button className={ `${ styles['btn-comfirm'] }` } disabled={ isSubmiting || ad?.orderTotal?.pointUsableCount <= 0 } onClick={ goToPay }>去付款</button>
            )
          }
        </div>
        
        {/* 右边按钮 */}
        {/*待付款时*/}
        {
          ((ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT') && !isConfirmPage) && (
            <div className={ `${ styles['ad-page-bottom-r'] }` }>
              <span className={ `${ styles['btn-cancel'] }` } onClick={ toggleCancelOrderTips }>取消广告执行单</span>
              <button className={ `${ styles['btn-comfirm'] }` } disabled={ isSubmiting || ad.orderTotal?.pointUsableCount <= 0 } onClick={ goToPay }>去付款</button>
            </div>
          )
        }
        
        <ScreenEffectModal childRef={ screenEffectModalRef }  />

        <Modal 
          width={ 1104 }
          title="广告素材制作要求"
          footer={ null }
          visible={ isModalVisible }
          onCancel={ onModalCancel }
          >
        </Modal>

        { (ad?.mallOrderPlanId || ad?.mallOrderMediaId) && <TechnicPriceModal childRef={ technicPriceModalRef } mallOrderPlanId={ ad?.mallOrderPlanId } mallOrderMediaId={ ad?.mallOrderMediaId }  />
        }
      </div>
  )
}