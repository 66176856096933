import React, { useState, useEffect } from 'react'
import styles from './Staff.module.css'

import util from '../../../../../libs/util'

interface ListItem {
  value: number,
  name: string,
  id: string,
  color: string
}
// 店经理、机务人员、上刊人员、巡刊人员
const defaultList: ListItem[] = [
  {
    value: 0,
    name: '管理员',
    id: 'MEDIA_MANAGER',
    color: '#FE8135'
  },
  {
    value: 0,
    name: '店长',
    id: 'MEDIA_LEADER',
    color: '#2395FF'
  },
  {
    value: 0,
    name: '值班经理',
    id: 'MEDIA_WATCHKEEPER',
    color: '#FE8135'
  },
  {
    value: 0,
    name: '广告营销',
    id: 'MEDIA_OPERATION',
    color: '#2395FF'
  },
  // {
  //   value: 0,
  //   name: '上刊人员',
  //   id: 'MEDIA_PUBLISH',
  //   color: '#2395FF'
  // },
  {
    value: 0,
    name: '广告执行',
    id: 'MEDIA_CHECK',
    color: '#FE8135'
  }


]

export const Staff: React.FC = () => {
  const [list, setList] = useState<ListItem[]>(defaultList)

  useEffect(() => {
    getOrgSummary()
  }, [])

  const [total, setTotal] = useState(0)
  const getOrgSummary = async () => {
    const { code, data } = await util.request({
      method: 'POST',
      url: 'uc/org/getOrgSummary'
    })

    if(code === 1) {
      const { roleTotalList, mediaTotal } = data
      setTotal(mediaTotal)

      // defaultList.forEach(item => {
      //   item.value = 0
      // })

      // setList([...defaultList])

      // if(roleTotalList.length === 0) return
      // list.forEach(record => {
      //   const roleItemKey = record.id
      //   const index = roleTotalList.findIndex(i => i.roleItemKey === roleItemKey)
      //   if (index !== -1) {
      //     record.value = roleTotalList[index].roleTotal
      //     roleTotalList.splice(index, 1)
      //   }
      // })
      // setList([...list])
    }
  }

  return (
    <div className={ styles.staff }>
      <h3 className={ styles.title }>人员管理</h3>
      {/* <ul className={ `${ styles.content } flex justify-between` }>
        {
          list.map((item, index) => (
            <li className="tc" key={ index }>
              <div className={ `${ styles['content-num'] }` } style={{ color: item.color }}>{ item.value }</div>
              <p className="mt2">{ item.name }</p>
            </li>
          ))
        }
      </ul> */}
      <div className={ `${ styles['staff-cont'] }` }>
        <div className={ `${ styles['content-num'] }` }>{ total }</div>
        <div className="mt2">人员数量</div>
      </div>
    </div>
  )
}