import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Select, Table, } from 'antd'
import { approveStatusItems, mediaApproveStatusItems } from '../../list/filterPanel/FilterPanel'
import util from '../../../../libs/util'
import styles from './Audit.module.css'

interface Props {
  childRef: any,
  resourcePointId?: any,
}

export const Audit: React.FC<Props> = ({ childRef, resourcePointId }) => {
  useImperativeHandle(childRef, () => ({
    init() {
      init()
    }
  }))

  // useEffect(() => {
  //   init()
  // }, [])

  const init = async() => {
    getList()
  }

  // 审核记录
  const [list, setList] = useState<any[]>([])
  const getList = async() => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: `/mall/spu/getApprovePointSpuList`,
      params: {
        resourcePointId,
      },
    })

    if (code === 1) {
      setList(data)
    }
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return index + 1
      },
    },
    {
      title: '审核编号',
      dataIndex: 'approveNo',
    },
    {
      title: '申请时间',
      dataIndex: 'applyDate',
    },
    {
      title: '申请人',
      dataIndex: 'operatorUserName',
    },
    {
      title: '审核结果',
      dataIndex: 'mediaApproveStatus',
      render(text, record, index) {
        const obj = mediaApproveStatusItems.find(i => i.itemKey === text)
        return (
          <div className={ styles['flex'] }>
            {
              obj ? <><span className={ `${ styles['icon-dot'] } ${ obj ? styles[obj.className] : '' }` }></span>{ obj.itemValue }</> : ''
            }
          </div>
        )
      },
    },
    {
      title: '审核意见',
      dataIndex: 'mediaApproveOpinion',
    },
    {
      title: '审核时间',
      dataIndex: 'mediaApproveDateTime',
    },
    {
      title: '审核人员',
      dataIndex: 'mediaApproveUserName',
    },
  ]

  return (
    <>
      <Table 
        rowKey="mallPointSpuApproveId"
        columns={ columns } 
        dataSource={ list }
        pagination={ false } />
    </>
  )
}