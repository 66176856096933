const tools: any = {}

tools.env = (function() {
  const host = window.location.host
  let env = ''

  if(host.includes('dev') || host.includes('localhost')) {
    env = 'dev'
  }
  
  if(host.includes('test')) {
    env = 'test'
  }

  return env
})()

let allow = true

tools.prevent = function(fn, delay = 5000) {
  if(allow) {
    allow = false

    fn.apply(this, arguments)

    const timer = setTimeout(() => {
      allow = true
      clearTimeout(timer)
    }, delay)
  }
}

tools.yummy = function(value) {
  if(value === null || value === undefined || value === '') {
    return false
  }
  else if(Array.isArray(value)) {
    if(value.length === 0) {
      return false
    }
  }
  else if(Object.prototype.toString.call(value) === '[object Object]') {
    if(Object.keys(value).length === 0) {
      return false
    }
  }
  return true
}

tools.space = function(value: string) {
  if(typeof value === 'object') {
    return value
  }
  if(typeof value === 'undefined') {
    value = ''
    return value
  }
  if(typeof value === 'number') {
    value = '' + value
  }

  return value.replace(/\s/g, '')
}

tools.moneyFormat = function(value) {
  return (+value).toFixed(2).replace(/(?=\B(?:\d{3})+\b)(\d{3}(?:\.\d+$)?)/g,',$1')
}

tools.cut = function(value) {
  let [int, dec] = ['0', '.00']

  if(!value) {
    return {
      int,
      dec
    }
  }

  value = value + ''

  int = value.split('.')[0]
  dec = '.' + value.split('.')[1]

  return {
    int,
    dec
  }
}

tools.deepClone = function(obj = {}) {
  if(typeof obj !== 'object' || obj == null) {
    return obj
  }

  let result
  if(obj instanceof Array) {
    result = []
  }else {
    result = {}
  }

  for(let key in obj) {
    result = tools.deepClone(obj[key])
  }

  return result
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
tools.formatDate = (date, label) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join(label === undefined ? '-' : label) 
}
// YYYY-MM-DD to YYYY.MM.DD
tools.formatDateStr = (dateStr, splitStr) => {
  return dateStr ? dateStr.split('-').join(splitStr === undefined ? '.' : splitStr) : '';
}

tools.formatDateYm = (date, label) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  return [year, month].map(formatNumber).join(label === undefined ? '-' : label) 
}

// 返回对应枚举key的name
tools.getNameFromKey = (enumList, key, keyIsArray, label, valLabel, emptyText) => {
  if ((!key || keyIsArray && !key.length) && emptyText) {
    return emptyText
  }
  var keyLabel = label || 'itemKey'
  var valueLabel = valLabel || 'itemValue'
  if (keyIsArray) {
  // 数组
    var filterArr = enumList.filter(function(i) {
      return key.indexOf(i[keyLabel]) !== -1;
    });
    return filterArr.map(function(i) {
      return i[valueLabel]
    });
  } else {
  // 单个
    var filterArr = enumList.filter(function(i) {
      return i[keyLabel] === key;
    });
    return filterArr.length ? filterArr[0][valueLabel] : '-';
  }
}


tools.convertAdExecChannelName = (record) => {
  if (!record) {
    return ''
  }
  var { planAim, planAimExt } = record

  if (planAim === 'NORMAL' || planAim === 'KA') {
    return '热幕'
  } else if (planAim === 'SELF') {
    if (planAimExt === 0) {
      return '影院内宣广告'
    }
    return '影院自营广告'
  } else if (planAim === 'COOPERATION') {
    return '合作商渠道'
  } else if (planAim === 'EXCLUSIVE_AD') {
    return '独家代理'
  } else if (planAim === 'EXCLUSIVE_MEDIA') {
    return '代理商独家包销'
  } else if (planAim === 'VIP_PLAN') {
    return 'VIP专属'
  } else {
    return ''
  }
}

// 执行单状态
tools.adExecStatus = (value) => {
  switch (value) {
    case 'PAY_WAIT': return { text: '待付款', class: 'warn' }
    case 'DELIVERY_WAIT': return { text: '待上刊', class: 'warn' }
    case 'EXECUTING': return { text: '刊播中', class: 'warn' }
    case 'FINISHED': return { text: '已完成', class: 'warn' }
    case 'ORDER_TIMEOUT': return { text: '执行单关闭', class: 'warn' }
    default: return { text: '执行单关闭', class: 'warn' }
  }
}
// 导致执行单失效的原因
tools.adMediaExecTimeoutStatus = ({ approveStatus }, servicePhoneNumber) => {
  if (!approveStatus) {
    return
  }
  // NO,TIMEOUT_P,TIMEOUT,CANCEL,CONTENT_TIMEOUT,PAY_TIMEOUT,CANCEL_PAY
  if (approveStatus.indexOf('NO') !== -1) {
    return '全部点位谢绝投放'
  } else if (approveStatus.indexOf('TIMEOUT_P') !== -1) {
    return '平台未及时审核已提交素材'
  } else if(approveStatus.indexOf('CANCEL') !== -1) {
    return '执行单已取消'
  } else if(approveStatus.indexOf('CONTENT_TIMEOUT') !== -1) {
    return '超时未提交符合投放要求的素材'
  } else if (approveStatus.indexOf('PAY_TIMEOUT') !== -1) {
    return '超时未支付'
  } else if(approveStatus.indexOf('CANCEL_PAY') !== -1) {
    return '取消支付'
  } else {
    return ''
  }
}

tools.adExecTimeoutStatusList = (order) => {
  var approveStatus = order.approveStatus || ''
  var showPlanStatus = order.showPlanStatus || ''
  var showPlanStatusMore = order.showPlanStatusMore ? order.showPlanStatusMore.join(',') : ''
  var isRemo = order.planAim !== 'SELF' && order.planAim !== 'EXCLUSIVE_MEDIA'

  if (isRemo && showPlanStatus === 'ORDER_TIMEOUT' || (approveStatus.indexOf('NO') !== -1 && !showPlanStatusMore.length)) { 
    return '全部点位谢绝投放'
  } else if(approveStatus.indexOf('CONTENT_TIMEOUT') !== -1 || approveStatus.indexOf('CONTENT_NOT_MATCH') !== -1) {
    return '超时未提交符合投放要求的素材'
  } else if(approveStatus.indexOf('CANCEL_PAY') !== -1) {
    return '取消支付'
  } else if(approveStatus.indexOf('CANCEL') !== -1) {
    return '执行单已取消'
  } else if (approveStatus.indexOf('TIMEOUT_P') !== -1) {
    return '平台未及时审核已提交素材'
  } else if (approveStatus.indexOf('PAY_TIMEOUT') !== -1) {
    return '超时未支付'
  } else {
    return ''
  }
}
// 导致执行单失效的原因
tools.adExecTimeoutStatus = (order, servicePhoneNumber) => {
  if (!order) {
    return
  }
  var approveStatus = order.approveStatus || ''
  var showPlanStatus = order.showPlanStatus || ''
  var showPlanStatusMore = order.showPlanStatusMore ? order.showPlanStatusMore.join(',') : ''
  var isRemo = order.planAim !== 'SELF' && order.planAim !== 'EXCLUSIVE_MEDIA'
  // console.log(isRemo, approveStatus, showPlanStatus, showPlanStatusMore)
  if (isRemo && approveStatus === 'ORDER_TIMEOUT' || (showPlanStatus.indexOf('NO') !== -1 && !showPlanStatusMore)) { 
    return '全部点位谢绝投放'
  } else if(!isRemo ? showPlanStatus.indexOf('ORDER_TIMEOUT') !== -1 && showPlanStatusMore.indexOf('CONTENT_TIMEOUT') !== -1 : approveStatus.indexOf('CONTENT_TIMEOUT') !== -1 || approveStatus.indexOf('CONTENT_NOT_MATCH') !== -1) {
    return '超时未提交符合投放要求的素材'
  } else if(!isRemo && showPlanStatus.indexOf('ORDER_TIMEOUT') !== -1 && showPlanStatusMore.indexOf('CANCEL_PAY') !== -1) {
    return '取消支付'
  } else if(!isRemo ? showPlanStatus.indexOf('CANCEL') !== -1 : approveStatus.indexOf('CANCEL') !== -1) {
    return '执行单已取消'
  } else if (!isRemo ? showPlanStatus.indexOf('TIMEOUT_P') !== -1 : approveStatus.indexOf('TIMEOUT_P') !== -1) {
    return '平台未及时审核已提交素材'
  } else if (isRemo ? approveStatus.indexOf('PAY_TIMEOUT') !== -1 : showPlanStatus.indexOf('ORDER_TIMEOUT') !== -1 && showPlanStatusMore.indexOf('PAY_TIMEOUT') !== -1) {
    return '超时未支付'
  } else {
    return ''
  }
}

tools.hasLeftTime = (approveTimeOut) => {
  const now = new Date().getTime()
  // 1分钟
  const hasTime = new Date(new Date(approveTimeOut)).getTime() - now > 60 * 1000

  return hasTime
}
// 订单状态
// tools.orderStatus = (value, isAdExec) => {
//   switch (value) {
//     case 'DRAFT': return { text: '待提交', class: 'warn' }
//     case 'WAIT': return { text: '待支付', class: 'warn' }
//     case 'APPROVE_WAIT': return { text: '待审核', class: 'warn' }
//     case 'DELIVERY_WAIT': return { text: '待上刊', class: 'warn' }
//     case 'EXECUTING': return { text: '执行中', class: 'warn' }
//     case 'FINISHED': return { text: '已完成', class: 'warn' }
//     case 'ORDER_TIMEOUT': return { text: isAdExec ? '执行单关闭' : '交易关闭', class: 'warn' }
//   }
// }
// 执行状态
tools.showPlanStatus = (value, isAdExec) => {
  switch (value) {
    case 'DRAFT': return { text: '待提交', class: 'warn' }
    case 'ORDER_WAIT': return { text: '待付款', class: 'warn' }
    case 'APPROVE_WAIT': return { text: '待审核', class: 'warn' }
    case 'DELIVERY_WAIT': return { text: '待上刊', class: 'warn' }
    case 'EXECUTING': return { text: '刊播中', class: 'warn' }
    case 'FINISHED': return { text: '已完成', class: 'warn' }
    case 'ORDER_TIMEOUT': return { text: isAdExec ? '执行单关闭' : '交易关闭', class: 'warn' }
  }
}
tools.convertCountDownTime = (preDateStr, needSecond) => {
  var regComma = new RegExp('-', 'g')
  var dateStr = preDateStr?.replace(regComma, '/')
  var date = new Date(dateStr)
  var now = new Date()

  var def = date.getTime() - now.getTime()

  if (def <= 0) {
    return ''
  }

  var secondUnit = 1000
  var minuteUnit = 60 * secondUnit
  var hourUnit = 60 * minuteUnit
  var dayUnit = 24 * hourUnit

  var day = Math.floor(def / dayUnit)
  var hour = Math.floor((def - day * dayUnit) / hourUnit)
  var minute = Math.floor((def - day * dayUnit - hour * hourUnit) / minuteUnit)
  var second = Math.floor((def - day * dayUnit - hour * hourUnit - minute * minuteUnit) / secondUnit)

  var dayStr = day > 0 ? day + '天' : ''
  var hourStr = hour > 0 ? hour + '小时' : ''
  var minuteStr = minute > 0 ? minute + '分钟' : ''
  var secondStr = second > 0 ? second + '秒' : ''

  return dayStr + hourStr + minuteStr + (needSecond ? secondStr : '')
}

// 播放顺序
tools.convertPlayOrder = (timePeriods) => {
  if (!timePeriods || !timePeriods.length) {
    return '-'
  }

  if (timePeriods.length === 0 && parseInt(timePeriods[0]) === 0) {
    return '前1分钟'
  }

  return '前' + (parseInt(timePeriods[0]) + 1) + '到' + (parseInt(timePeriods[timePeriods.length - 1]) + 1) + '分钟'
}


tools.convertRoleName = (roleItemKey) => {
  switch (roleItemKey) {
    case 'AD_ROOT': 
      return '广告主'
    case 'AD_DELIVERY':
      return '投放人员'
    case 'AD_OPERATION':
      return '营销人员'
    
    case 'MEDIA_ROOT': 
      return '媒体主'
    case 'MEDIA_MANAGER':
      return '管理员'
    case 'MEDIA_DELIVERY':
      return '自用点位管理员'
    case 'MEDIA_PUBLISH':
      return '上刊人员'
    case 'MEDIA_CHECK':
      return '广告执行'
    case 'MEDIA_OPERATION':
      return '广告运营'
    case 'MEDIA_MARKETING':
      return '广告营销'
    case 'MEDIA_MECHANIC':
      return '机务人员'

    default:
      return '-'
  }
}

// 格式化金钱 三位一逗号
function formatMoney(money) {
  var str = money + ''
  var result = ''
  // 可能带负号
  var sign = new RegExp('^[0-9]+$').test(money[0]) ? '' : money[0]
  var i = 0
  if (sign) {
    str = str.substring(1)
  }
  var len = str.length
  for (; i < len; i++) {
    if ((len - i) % 3 === 0 && i !== 0) {
      result += ','
    }
    result += str[i]
  }
  return sign + result
}
// 0 => 0.00
tools.splitNum2String = (val) => {
  if (!val) {
    return {
      int: 0,
      dec: ''
    }
  }
  var value = '' + val
  var arrVal = value.split('.')
  var int = formatMoney(arrVal[0])
  var dec = arrVal[1]
  var objVal = {}
  if (arrVal.length === 1) {
    objVal = {
      int: int,
      dec: ''
    }
  } else if (arrVal.length === 2) {
    var dec = (dec.toString()+'00').substring(0, 2)
    if (parseInt(dec[1]) === 0) {
      if (parseInt(dec[0]) === 0) {
        dec = ''
      } else {
        dec = dec[0]
      }
    }
    objVal = {
      int: int,
      dec: dec ? '.' + dec : dec
    }
  }
  return objVal
}

tools.isContentCanSelect = (item, order) => {
  var auditStatus = item.auditStatus
  var approveStatus = item.approveStatus
  var contentDuration = item.contentDuration
  var isDurationValid = (Math.round(contentDuration / 1000) <= (order.leContentDuration / 1000))

  var t1 = auditStatus === 'YES' && (approveStatus === 'NONE' || approveStatus === 'WAIT') && isDurationValid

  var t2 = auditStatus === 'YES' && approveStatus  === 'YES' && item.industry.includes(order.industries) && isDurationValid

  if (t1 || t2) {
    return true
  }

  return false
}
// 从秒变成00:00:00
tools.fixedTime = (value, needHour, notNeedSec) => {
  // 默认单位是秒
  var time = parseInt(value);
  var h = Math.floor(time / 3600);
  var m = Math.floor((time % 3600) / 60);
  var s = Math.round(time % 3600) % 60;
  var hh = h < 10 ? '0' + h : h;
  var mm = m < 10 ? '0' + m : m;
  var ss = s < 10 ? '0' + s : s;
  var timestr = mm + (notNeedSec ? '' : (':' + ss));
  return needHour ? hh + ':' + timestr : timestr;
}

tools.convertStorage = (limit) => {
  let size = ''
  // 小于0.1KB，则转化成B
  if (limit < 0.1 * 1024) {
    size = `${ limit.toFixed(2) }B`
  } else if(limit < 0.1 * 1024 * 1024) {
  // 小于0.1MB，则转化成KB
    size = `${ (limit/1024).toFixed(2) }K`
  } else if(limit <= 5 * 1000 * 1024 * 1024) {
  // 小于等于5000MB，则转化成MB
    size = `${ (limit/(1024 * 1024)).toFixed(2) }M`
  } else {
  // 其他转化成GB
    size = `${ (limit/(1024 * 1024 * 1024)).toFixed(2) }G`
  }

  let sizeStr = size + ''
  const index = sizeStr.indexOf('.')
  const dou = sizeStr.substr(index + 1, 2)
  if (dou == '00') {
  // 判断后两位是否为00，如果是则删除00                                   
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}

tools.fixZero = (n, bit) => {
  bit = bit || 2
  n = n.toString().substring(0, bit)
  let result = new Array(bit - n.length)
  for (var i = 0; i < result.length; i++) {
    result[i] = '0'
  }
  return (result.join('') + n)
}

tools.convertTransactionType = (record, list) => {
  const appProduct = record.appProduct
  const transactionType = record.transactionType
  let result = '-'

  if (transactionType === 'ADD') {
    if (appProduct === 'AD_AGENT') {
      result = '广告代理佣金'
    } else if (appProduct === 'MEDIA_AGENT') {
      result = '媒体代理渠道费'
    } else if (appProduct === 'MALL_SUB_ORDER') {
      result = '广告点位收益'
    } else if (appProduct === 'MANAGE_ORDER') {
      result = '广告执行单-智能刊播服务费'
    } else {
      result = '充值'
    }
  } else {
    for (let i = 0, len = list.length; i < len; i++) {
      const item = list[i]
      if (item.itemKey === transactionType) {
        result = item?.itemValue
      }
    }
  }
  return result
}

// 导致订单失效的原因
tools.orderTimeoutStatus = (status, servicePhoneNumber) => {
  const list = status.split(',')
  if (list.includes('NO')) {
    return '全部点位谢绝投放'
  } else if(list.includes('CONTENT_TIMEOUT')) {
    return '广告主未提供符合投放要求的素材'
  } else if(list.includes('CANCEL')) {
    return '热幕平台已取消订单'
  } else if (list.includes('TIMEOUT_P')) {
    return '订单异常，请联系热幕客服' + servicePhoneNumber
  } else {
    return '-'
  } 
}

tools.convertPhone = (value) => {
  var phone = ''
  if (value) {
    phone = value.split('-')[1] || value
    return phone.substring(0,3) + ' **** ' + phone.slice(-4)
  }
  return '-'
}

// 字符串
tools.splitNum2StringFixed = (val) => {
  if (!val && val !== 0) {
    return ''
  }
  return tools.splitNum2String(val).int + tools.splitNum2String(val).dec
}

tools.moneyClearZero = (value) => {
  const arr = String(value).split('.')
  if (arr.length > 1) {
    const a = parseInt(arr[1].substring(0, 1))
    if (arr[1].length > 1) {
      const b = parseInt(arr[1].substring(1, 2))
      // .00 情况
      if (a == 0 && b == 0) {
        return arr[0]
      }
      // .10 情况
      if (b == 0) {
        return `${ arr[0] }.${ a }`
      }
    }
  }

  return value
}

tools.convertTimePeriods = (ad) => {
  const { timePeriods = [], playSequence = 99 } = ad
  const len = timePeriods.length
  if (timePeriods.length > 1) {
    return `影片映前第${ timePeriods[0] } - ${ timePeriods[len - 1] }分钟`
  } else {
    if (playSequence === 0) {
      return '倒1位置'
    } else {
      return `影片映前${ timePeriods[0] }分钟内`
    }
  }
}

tools.getAdExecType = (list, obj) => {
  var planAim = obj.planAim
  var planAimExt = obj.planAimExt
  var text = ''
  var len = list.length
  for (var i = 0; i < len; i++) {
    var item = list[i]
    if (item.id === planAim && (planAimExt !== 'null' ? item.planAimExt == planAimExt : true)) {
      text = item.name
      break
    }
  }
  return text
}

export default tools