import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Popconfirm, message} from 'antd'
import { RootState } from '../../../../../store'
import { getUserInfoAction } from '../../../../../store/user/action'
import styles from './Profile.module.css'

import util from '../../../../../libs/util'

import { Line, Nail, Icons } from '../../../../../components'
interface Props {
  switchRole?: any,
}
export const Profile: React.FC<Props> = ({ switchRole }) => {
  const dispatch = useDispatch()

  const [currUserInfo, setCurrUserInfo] = useState<any>({})
  const [bindingNumber, setBindingNumber] = useState('')
  const [curRoleKey, setCurRoleKey] = useState<any>()
  const [tempRoleKey, setTempRoleKey] = useState<any>()
  const [userRoleList, setUserRoleList] = useState<any>([])

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })

  useEffect(() => {
    if (!userInfo.myUserInfo.userType) {
      return
    }
    setCurrUserInfo(Object.assign({}, userInfo.myUserInfo, userInfo.personAuth))
    setBindingNumber(util.tools.convertPhone(userInfo.myUserInfo.phoneNumber))

    const myRoleList = userInfo.userRoleList
    if (userInfo.myUserInfo.userType !== 'COMPANY' && myRoleList && myRoleList.length) {
      setCurRoleKey(myRoleList[0].roleItemKey)
      // 如果是广告执行，跳转到欢迎页 TODO
      // && !history.location.pathname.includes('/welcome')
      if (myRoleList[0].roleItemKey === 'MEDIA_CHECK') {
        history.push('/welcome/personal/rolepermissions')
        console.log(history.location, history.location.pathname.includes('/welcome'))
      }
    }
    getUserRoleList()
  }, [userInfo])
  useEffect(() => {
    // getUserRoleList()
  }, [])

  const getUserRoleList = async() => {
    const { code, data = [] } = await util.request({
      method: 'GET',
      url: '/uc/getUserRoleList',
      data: {},
    })

    if (code === 1) {
      let roleIdList = userInfo.userRoleList.map(i => i.roleItemKey)
      setUserRoleList(data.filter(i => !roleIdList.includes(i.roleItemKey)))
    }
  }

  const history = useHistory()
  const goToUserPage = () => {
    history.push('/user/center')
  }

  const toggleRole = (id) => {
    setTempRoleKey(id)
  }
  const setRole = async () => {
    const { code, data = [] } = await util.request({
      method: 'POST',
      url: `/uc/switchUserRole?changeRoleItemKey=${ tempRoleKey }`,
      data: {},
    })

    if (code === 1) {
      message.success('切换成功')
      setCurRoleKey(tempRoleKey)
      dispatch(getUserInfoAction({}))
      switchRole && switchRole()
    }
  }
  const getRoleListNode = () => {
    return (
      <div className={ styles['role-list'] }>
        {
          userRoleList.map(role => {
            return (
              <div className={ `${ styles['role-item'] } ${ role.roleItemKey === tempRoleKey ? styles['is-selected'] : '' }` } onClick={ () => { toggleRole(role.roleItemKey) } } key={ role.roleItemKey }><Nail roleItemKey={ role.roleItemKey } style={ role.roleItemKey === tempRoleKey ? { borderColor: '#E2A45A', color: '#E2A45A', } : null } /></div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={ `${ styles.profile } flex align-center` }>
      <div className={ styles.avatar }>
        {
          currUserInfo?.avatarUrl ? 
          <img width="112" src={ currUserInfo?.avatarUrl } alt="" />
          :
          <div className={ `${ styles['icon-avatar'] } imediafont i-user-media }` }></div>
        }
      </div>
      <div className={ styles['main-info'] }>
        <h3 className={ `${ styles.name }` }>{ currUserInfo?.chinaName || '匿名' }
          {
            curRoleKey && curRoleKey ?
            <span className={ `${ styles['role-label'] }` }><Nail roleItemKey={ curRoleKey } /></span>
                : ''
            }
          {
            userRoleList.length >= 1 && (<Popconfirm placement="bottomLeft" icon={ '' } title={ getRoleListNode } onConfirm={ setRole } okText="确定" cancelText="取消"><div className={ `icon ${ styles['btn-change'] }` }>切换角色</div></Popconfirm>)
          }
        </h3>
        <div className={ `${ styles.excerpt } flex` }>热幕号：{ currUserInfo?.userShowId } <span className="ml1"><Icons color="#FE8135" icon="personal-auth" /></span></div>
        <div className={ `${ styles.excerpt } flex` }>绑定手机号：{ bindingNumber }{/*<Line />所属公司：{ userInfo.companyAuth?.chinaName }*/}</div>
      </div>

      <div className={ styles['btn-link'] } onClick={ goToUserPage }>进入用户中心<span className={ `${ styles['icon-link'] } imediafont i-arrow-right` }></span></div>
    </div>
  )
}