import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
	Modal,
	Button,
} from 'antd'

import stylesCommon from '../Modal.common.module.css'
import styles from './Modal.module.css'

interface Props {
  childRef: any,
}

export const HelpModal: React.FC<Props> = ({ childRef }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)

	const toggle = (show) => {
		setIsModalVisible(show === undefined ? !isModalVisible : show)
	}
	useImperativeHandle(childRef, () => {
    return {
    	toggle,
    }
  })
	const handleOk = () => {
		toggle(false)
	}

	const handleCancel = () => {
		toggle(false)
	}

	return (
		<Modal 
			width={ 860 }
			visible={ isModalVisible }
			footer={ null }
			onCancel={ handleCancel }
			closeIcon={
				<i className={ `ifont i-close-o ${ stylesCommon['icon-close'] }` }></i>
			}
			>
			<div className={ stylesCommon['title'] }>帮助中心</div>

			<div className={ stylesCommon['content'] }>
				<div className={ styles['help-cont-tit'] }>1、什么是热幕精灵-影院银幕广告助手？</div>
				<div className={ styles['help-pa'] }>
					<p className={ stylesCommon['content-pa'] }>1）热幕精灵-影院银幕广告助手负责每日自动下载当前工作影院次日需上刊的影院银幕广告素材文件</p>
					<p className={ stylesCommon['content-pa'] }>2）下载成功后自动转化为以下两种放映格式：</p>
					<div className={ styles['help-important'] }>
						【2D遮幅】   下载文件名后缀为 “ 2D_J_F ”<br/>
						【2D宽幅】   下载文件名后缀为 “ 2D_J_S ”<br/>
            {/*【3D遮幅】   下载文件名后缀为 “ 3D_J_F ”<br/>
            【3D宽幅】   下载文件名后缀为 “ 3D_J_S ”<br/>*/}
					</div>
				</div>

				<div className={ styles['help-cont-tit'] }>2、安装要求：</div>
				<div className={ styles['help-pa'] }>
					<p className={ stylesCommon['content-pa'] }>	1）一台PC机只能同时运行一个热幕精灵服务</p>
					<p className={ stylesCommon['content-pa'] }>2）一个热幕精灵服务只能激活单个影院的点位，所以每个影院需要一台PC机安装一个热幕热幕精灵</p>
					<p className={ stylesCommon['content-pa'] }>3）请勿在两台PC机上同时启动同一影院的热幕精灵服务，否则会造成广告素材下载异常。</p>
				</div>

				<div className={ styles['help-cont-tit'] }>3、热幕精灵安装不成功，如何处理 ？</div>
				<div className={ styles['help-pa'] }>
					<p className={ stylesCommon['content-pa'] }>	1）热幕精灵可能会被杀毒软件、电脑管家、防火墙等拦截，请退出相关应用或者取消拦截后重试。</p>
					<p className={ stylesCommon['content-pa'] }>2）如重试后，仍安装不成功，请联系客服人员0756-1234567</p>
				</div>

			</div>
    </Modal>
	)
}